<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Validando')">
</jqxLoader>

<div #ciudadanoContainer id="ciudadanoGrid" style="overflow-x: inherit; overflow-y: hidden; height: 100%; width: 100%;">
  <div id="toolbarContainer" style="height: 8%; display: flex; align-items: center">
    <div style="float: left; height: 22px; width: 80px; padding-left: 3px;">
      <button type="submit" style="width: 75px; height: 25px; align-items: center;" (onClick)="onCrearCiudadano()">
      <div style="float: right; padding-top: 2px;">{{translate('Crear')}}</div>
      <img style="float: left; height: 18px; width: 18px;" src="../assets/images/mas.png"></button>
    </div>

    <div style="float: left; height: 22px; width: 90px; padding-left: 3px;">
      <button type="submit" style="width: 85px; height: 25px; align-items: center;" (onClick)="onValidateCiudadano()">
      <div style="float: right; padding-top: 2px;">{{translate('Validar')}}</div>
      <img style="float: left; height: 18px; width: 18px;" src="../assets/images/ok.png"></button>
    </div>

    <div style="float: left; height: 22px; width: 105px; padding-left: 3px;">
      <button type="submit" style="width: 100px; height: 25px; align-items: center;" (onClick)="onDeclineCiudadano()">
      <div style="float: right; padding-top: 2px;">{{translate('Rechazar')}}</div>
      <img style="float: left; height: 18px; width: 18px;" src="../assets/images/cancel.png"></button>
    </div>
  </div>

  <jqxGrid #gridCiudadanos [theme]="environment.tema" [height]="'91%'" [width]="'99%'" [source]="adapterCiudadanos"
    [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false"
    [columns]="columnsCiudadanos" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
    [altrows]="true" [showstatusbar]="true" [statusbarheight]="0" [showaggregates]="false" [rowsheight]="25"
    [columnsheight]="20" [enabletooltips]="false" [editable]="false" [groupable]="true" [selectionmode]="'checkbox'"
    [columnmenuopening]="columnmenuopening" (onGroupschanged)="resizeColumns()" (onRowdoubleclick)="rowDoubleClick($event)"
    (onFilter)="resizeColumns()">
  </jqxGrid>
</div>

<div #formCiudadano></div>
<div #tarjetasCiudadano></div>
