<jqxWindow #form [width]="260" [theme]="theme" [height]="300" [zIndex]="100" [autoOpen]="true" [isModal]="false"
    [position]="getFormPos(form)" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow: hidden;">
        {{translate('Itinerarios')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="200" [source]="dataAdapter" [columnsresize]="true"
            [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'" [selectionmode]="'checkbox'"
            [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false" [filterrowheight]="30"
            [rowsheight]="25" [columnsheight]="25" [enabletooltips]="false" [showaggregates]="true"
            [showstatusbar]="true" [statusbarheight]="20" [localization]="langGrid" (onRowselect)="onSelect($event)"
            (onRowunselect)="onSelect($event)">
        </jqxGrid>

        <div style="margin-top: 0px; float:left;">
            <div style="float:left; margin-top: 4px;">
                <input [(ngModel)]="visible" type="checkbox" (change)="onChangeVisible($event)" />
                {{translate('Ver')}}
            </div>
        </div>
        <div style="position:absolute; bottom:5px; left:4px;clear: both;">
            <jqxButton style="float: left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardar($event)"
                [value]="translate('Guardar')">
            </jqxButton>
        </div>


    </div>
</jqxWindow>