import { NgForm } from '@angular/forms';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { TurnoTrabajoModel } from 'src/app/services/resources/models/turno-trabajo.model';

import { InputNumberComponent } from '../../../input-number/input-number.component';
import { MovilesCatalogComponent } from '../../../resources/moviles-catalog/moviles-catalog.component';

@Component({
  selector: 'app-movil-edit',
  templateUrl: './movil-edit.component.html',
  styleUrls: ['./movil-edit.component.css']
})
export class MovilEditComponent extends CustomForms implements OnInit {
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('formMovil') formMovil: NgForm;

  viewInit = false;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  public movil: MovilModel = new MovilModel();

  ejecutado: boolean = false;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() {
    super();
  }

  ngOnInit(): void {
  }

  public init(componentRef: any, movil: MovilModel){
    this.componentRef = componentRef;

    this.movil = movil ? movil : new MovilModel();
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.windowForm);
    this.windowForm.setTitle(this.translate('Vehiculo'));
    this.viewInit = true;
  }

  onClose(){
    if (this.componentRef) {
      this.componentRef.destroy();
      MovilesCatalogComponent._this.form.expand();
    }
  }

  onChangeDisponible(value: boolean) {
    this.movil.disponible = value;
    if(this.movil.disponible){
      this.movil.estadoVehiculo = '';
    }
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  //ESTADO VEHICULO
  columnsEstadoVehiculo: any[] =
  [
    { text: this.translate('Estado'), datafield: 'estado', width: 150 },
  ];

  public sourceEstadoVehiculo: string [] =
  [
    '',
    'BAJA',
    'TALLER'
  ];


  //TURNO TRABAJO
  @ViewChild('dropDownTurnoTrabajo') dropDownTurnoTrabajo: jqxDropDownButtonComponent;
  @ViewChild('gridTurnoTrabajo') gridTurnoTrabajo: jqxGridComponent;
  gridDropTurnoTrabajo: boolean;
  textTurnoTrabajo: string = '';

  turnosTrabajo: TurnoTrabajoModel[] = [
    { 'id': 1, 'nombre' : 'MAÑANA', 'horaInicio': new Date(2020, 1, 1, 6, 0, 0), 'horaFin': new Date(2020, 1, 1, 14, 0, 0) },
    { 'id': 2, 'nombre' : 'TARDE', 'horaInicio': new Date(2020, 1, 1, 14, 0, 0), 'horaFin': new Date(2020, 1, 1, 22, 0, 0) },
    { 'id': 3, 'nombre' : 'NOCHE', 'horaInicio': new Date(2020, 1, 1, 22, 0, 0), 'horaFin': new Date(2020, 1, 1, 6, 0, 0) }
  ];

  onCloseDropTurnoTrabajo(event: any) {
    if(this.gridTurnoTrabajo){
      this.gridDropTurnoTrabajo = true;
    }
    this.dropDownTurnoTrabajo.close();
  }

  onOpenDropTurnoTrabajo(event: any) {
    if(this.gridTurnoTrabajo){
      this.gridDropTurnoTrabajo = false;
    }
  }

  columnsTurnoTrabajo: any[] =
  [
    { text: this.translate('Turno'), datafield: 'nombre', width: 150 },
  ];

  public sourceTurnoTrabajo: any =
  {
    datatype: 'array',
    datafields: [
      { name: 'nombre', type: 'string' }
    ],
    localdata: this.turnosTrabajo
  };

  public dataAdapterTurnoTrabajo: any = new jqx.dataAdapter(this.sourceTurnoTrabajo);

  onRowSelectTurnoTrabajo(event: any) {
    if(this.viewInit){
      this.movil.turnoTrabajo.push(this.turnosTrabajo[event.args.rowindex]);
    }

    this.textTurnoTrabajo = '';
    this.gridTurnoTrabajo.selectedrowindexes().forEach(element => {
      this.textTurnoTrabajo += this.turnosTrabajo[element].nombre + ';';
    });
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.textTurnoTrabajo + '</div>';
    this.dropDownTurnoTrabajo.setContent(content);
  }

  onRowUnselectTurnoTrabajo(event: any) {
    if(this.viewInit){
      this.movil.turnoTrabajo.splice(event.args.rowindex, 1);
    }

    this.textTurnoTrabajo = '';
    this.gridTurnoTrabajo.selectedrowindexes().forEach(element => {
      this.textTurnoTrabajo += this.turnosTrabajo[element].nombre + ';';
    });
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.textTurnoTrabajo + '</div>';
    this.dropDownTurnoTrabajo.setContent(content);
  }
}
