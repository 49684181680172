import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild, ViewContainerRef } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';

import { MapBounds } from 'movisat-maps';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import {
  MapCircle,
  MapComponent,
  MapLatLng,
  MapPolygon
} from 'src/app/imports';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { AlarmsService } from 'src/app/services/alarms/alarms.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { AlarmModel } from 'src/app/services/alarms/models/alarm.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import * as xlsx from 'xlsx';
import { ZonesComponent } from '../../zones/zones.component';

@Component({
  selector: 'app-alarmsgeo',
  templateUrl: './alarmsgeo.component.html',
  styleUrls: ['./alarmsgeo.component.css']
})
export class AlarmsgeoComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridAlarmas') gridAlarmas: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('formAsig') formAsig: jqxWindowComponent;
  @ViewChild('cbZonas') cbZonas: jqxDropDownListComponent;
  @ViewChild('ebNombre') ebNombre: jqxInputComponent;
  @ViewChild('horaEntrada') horaEntrada: jqxDateTimeInputComponent;
  @ViewChild('horaSalida') horaSalida: jqxDateTimeInputComponent;
  @ViewChild('gridMoviles1') gridMoviles1: jqxGridComponent;
  @ViewChild('gridMoviles2') gridMoviles2: jqxGridComponent;
  @ViewChild('menuContainer', { read: ViewContainerRef }) menuContainer;

  private map: MapComponent;
  public alarmaSelec: AlarmModel;
  public alarmaEdit: AlarmModel;
  public dataSource: any;
  public dataAdapter: any;
  public mostrarEditForm = false;
  public mostrarAsigForm = false;
  private componentRef = null;
  private component: any = null;
  public environment = environment;
  public canEdit = true;
  private circle: MapCircle = null;
  private polygons: MapPolygon[] = [];
  private alarmas: AlarmModel[] = [];
  private zonas: ZonaModel[] = [];
  private moviles = [];
  private movilesAsig = [];
  formZonas: boolean = true;
  subcribe: any;
  zona: ZonaModel;
  mapWidth: number;
  mapHeight: number;
  inputDate: jqxDateTimeInputComponent;

  public dias = {
    lunes: false,
    martes: false,
    miercoles: false,
    jueves: false,
    viernes: false,
    sabado: false,
    domingo: false
  };

  // Preparo las columnas del grid
  public columns = [
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total_alarmas') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Zona'), columntype: 'textbox', filtertype: 'textbox', datafield: 'zona', align: 'center' },
    { text: AppComponent.translate('Radio') + ' (m)', columntype: 'textbox', filtertype: 'textbox', datafield: 'radio', align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Superficie') + ' (m²)', columntype: 'textbox', filtertype: 'textbox', datafield: 'area', align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Dias'), columntype: 'textbox', filtertype: 'textbox', datafield: 'dias', align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('E'), columntype: 'textbox', filtertype: 'textbox', datafield: 'entrada', align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Hora_entrada'), columntype: 'textbox', filtertype: 'date', datafield: 'horaEntrada', width: 85, align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow },
    { text: AppComponent.translate('S'), columntype: 'textbox', filtertype: 'textbox', datafield: 'salida', align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Hora_salida'), columntype: 'textbox', filtertype: 'date', datafield: 'horaSalida', align: 'center', cellsalign: 'center', cellsrenderer: this.renderRow }
  ];

  // Variables para el grid de móviles no asignados
  public movilesSinAsignar: MovilModel[] = [];
  public sourceMoviles1: any = [];
  public dataAdapterMoviles1;
  public columnsMoviles1 = [
    { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 50, align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 50 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso', width: 100 },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    }
  ];

  // Variables para el grid de moviles asignados
  public movilesAsignados: MovilModel[] = [];
  public sourceMoviles2: any = [];
  public dataAdapterMoviles2;
  public columnsMoviles2 = [
    { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 50, align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 50 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso', width: 100 },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService,
    private alarmsService: AlarmsService,
    private zonesService: ZonesService,
    private resourcesService: ResourcesService,
    private modal: NzModalService,
    private renderer: Renderer2, private el: ElementRef) {
    super();
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(AppComponent.translate('Alarmas_geograficas'));
    await this.getAlarms();
    this.addCustomForm(this.form);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.circle) {
      this.map.removeCircle(this.circle)
    }
    if (this.polygons) {
      this.polygons.forEach(polygon => {
        this.map.removePolygon(polygon);
      });
    }
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Obtengo las alarmas por empresa
  async getAlarms() {
    this.loader.open();
    this.alarmas = await this.alarmsService.getAlarmas();
    if (this.alarmas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'zona', type: 'string', map: 'Zona>Nombre' },
          { name: 'radio', type: 'number', map: 'Zona>Radio' },
          { name: 'area', type: 'number', map: 'Zona>Area' },
          { name: 'dias', type: 'string', map: 'Dias' },
          { name: 'entrada', type: 'date', map: 'Entrada' },
          { name: 'horaEntrada', type: 'string', map: 'HoraEntrada' },
          { name: 'salida', type: 'string', map: 'Salida' },
          { name: 'horaSalida', type: 'date', map: 'HoraSalida' }
        ],
        localdata: this.alarmas,
        sortcolumn: 'nombre',
        sortdirection: 'asc'
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    } else {
      this.alarmas = [];
    }
    Utils.renderSizeGrid(this.gridAlarmas);
    this.loader.close();
  }

  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'horaEntrada':
      case 'horaSalida':
        if (value.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
          value = value ? DateUtils.formatTime(value) : '';
        } else {
          value = '';
        }
        return '<div style="text-align: ' + columnproperties.cellsalign + ';">' + value + '</div>';
      case 'entrada':
      case 'salida':
        value = value ? 'X' : '';
        return '<div style="text-align: ' + columnproperties.cellsalign + ';">' + value + '</div>';
      case 'radio': return '<div style="margin-right: 4px; text-align: right;">' + NumberUtils.format(value, 0) + '</div>';
      case 'area': return '<div style="text-align: right; margin-right: 4px;' + columnproperties.cellsalign + ';">' + NumberUtils.format(value, 0) + '</div>';
    }
  }


  public columnmenuopening(menu?: any, datafield?: any, height?: number | string): boolean | void {
    if (menu.length === 1) {
      const divElement: HTMLElement = menu[0];
      const dateTimeInputs = divElement.querySelectorAll('.jqx-datetimeinput');
      if (dateTimeInputs && dateTimeInputs.length > 0) {
        dateTimeInputs.forEach((input: HTMLElement) => {
          const elementRef = new ElementRef(input);
          const jqxDateTimeInput = new jqxDateTimeInputComponent(elementRef);
          this.inputDate = jqwidgets.createInstance('#' + jqxDateTimeInput.elementRef.nativeElement.id, 'jqxDateTimeInput', { width: '100%', height: 25, formatString: 'HH:mm:ss' });
          this.inputDate.setOptions({ showTimeButton: true, showCalendarButton: false });
        });
      }
    }
  }

  onRowSelect(event: any) {
    this.alarmaSelec = this.alarmas[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    if (this.canEdit) {
      this.alarmaSelec = this.alarmas[event.args.rowindex];
      this.onEditarAlarma();
    }
  }

  onCrearAlarma() {
    if (!this.canEdit) {
      return;
    }
    // Creo una alarma vacía para rellenar
    this.alarmaEdit = new AlarmModel();
    this.dias.lunes = false;
    this.dias.martes = false;
    this.dias.miercoles = false;
    this.dias.jueves = false;
    this.dias.viernes = false;
    this.dias.sabado = false;
    this.dias.domingo = false;
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  onEditarAlarma() {
    if (this.alarmaSelec) {
      this.alarmaEdit = Utils.clone(this.alarmaSelec);
      this.dias.lunes = this.alarmaEdit.Dias.indexOf('L') > -1;
      this.dias.martes = this.alarmaEdit.Dias.indexOf('M') > -1;
      this.dias.miercoles = this.alarmaEdit.Dias.indexOf('X') > -1;
      this.dias.jueves = this.alarmaEdit.Dias.indexOf('J') > -1;
      this.dias.viernes = this.alarmaEdit.Dias.indexOf('V') > -1;
      this.dias.sabado = this.alarmaEdit.Dias.indexOf('S') > -1;
      this.dias.domingo = this.alarmaEdit.Dias.indexOf('D') > -1;
      this.alarmaEdit.HoraEntrada = new Date(this.alarmaEdit.HoraEntrada);
      this.alarmaEdit.HoraSalida = new Date(this.alarmaEdit.HoraSalida);
      if (this.alarmaEdit.HoraEntrada.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
        this.alarmaEdit.HayHoraEntrada = true;
      } else {
        this.alarmaEdit.HayHoraEntrada = false;
      }
      if (this.alarmaEdit.HoraSalida.getDate() !== new Date('0001-01-01T00:00:00').getDate()) {
        this.alarmaEdit.HayHoraSalida = true;
      } else {
        this.alarmaEdit.HayHoraSalida = false;
      }
      this.openEditForm();
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Muestra el formulario de edición de alarmas
  openEditForm() {
    this.mostrarEditForm = true;
    if (this.form !== undefined) {
      this.form.collapse();
      this.form.disable();
    }
  }

  // Borrar una alarma
  onBorrarAlarma() {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaSelec !== null) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_alarma') + ': ' + this.alarmaSelec.Nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          await this.alarmsService.deleteAlarma(this.alarmaSelec.Id);
          for (let i = 0; i < this.alarmas.length; i++) {
            if (this.alarmas[i].Id === this.alarmaSelec.Id) {
              this.alarmas.splice(i, 1);
              break;
            }
          }
          this.alarmaSelec = null;
          this.gridAlarmas.updatebounddata('cells');
        }
      });
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onVerAlarma() {
    if (this.alarmaSelec) {
      let center: any;
      if (this.circle) {
        this.map.removeCircle(this.circle)
        this.circle = null;
      }
      if (this.polygons) {
        this.polygons.forEach(polygon => {
          this.map.removePolygon(polygon);
        });
        this.polygons = null;
      }
      if (this.alarmaSelec.Zona.TipoGeo === 0) { // Círculo
        this.circle = this.map.addCircle({
          dataModel: this.alarmaSelec,
          content: '<b>' + this.alarmaSelec.Nombre + '</b><hr>' + this.alarmaSelec.Zona.Nombre + '<br>' +
            'Radio: ' + NumberUtils.format(this.alarmaSelec.Zona.Radio, 0) + ' m.',
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(this.alarmaSelec.Zona.Lat, this.alarmaSelec.Zona.Lng),
          radius: this.alarmaSelec.Zona.Radio,
          draggable: false,
          editable: false
        });
        center = new MapLatLng(this.alarmaSelec.Zona.Lat, this.alarmaSelec.Zona.Lng);
        this.map.setCenter(center);
        if (this.map.zoom < 18) {
          this.map.setZoom(18);
        }
      } else { // Polígono
        if (this.alarmaSelec.Zona.Geometria) {
          let swPoint = new MapLatLng(180, 90);
          let nePoint = new MapLatLng(-180, -90);
          this.polygons = this.map.addPolygonsFromGeoJson(this.alarmaSelec.Zona.Geometria, false);
          this.polygons.forEach(polygon => {
            polygon.points.forEach(punto => {
              if (punto.point.lat > nePoint.lat) {
                nePoint.lat = punto.point.lat;
              }
              if (punto.point.lng > nePoint.lng) {
                nePoint.lng = punto.point.lng;
              }
              if (punto.point.lat < swPoint.lat) {
                swPoint.lat = punto.point.lat;
              }
              if (punto.point.lng < swPoint.lng) {
                swPoint.lng = punto.point.lng;
              }
            });
          });
          this.map.fitTo(new MapBounds(swPoint, nePoint));
        }
      }
      this.form.collapse();
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  subscribeZona() {
    if (!this.subcribe) {
      this.subcribe = this.zonesService
        .getZona()
        .subscribe((zona: ZonaModel) => this.onSelectZona(zona));
    }
  }

  //asigna el objeto zona
  onSelectZona(zona: ZonaModel) {
    if (zona) {
      this.zona = zona;
    }
    if (!this.zona) { // si no recupera ninguna zona de subsribeZona, se asigna la zona de la alarma que estamos editando o creando
      this.zona = this.alarmaEdit.Zona;
    }
  }

  async selectZonaEdit() {
    //obtiene todas las zonas
    this.zonas = await this.zonesService.getZonas();
    this.onSelectZona(this.zona); //recoge la zona creada
    if (this.zona) { //si existe la zona, se asigna y si no existe aparece la primera opcion
      this.alarmaEdit.Zona = this.zona;
    } else {
      this.alarmaEdit.Zona = this.zonas[0];
    }
    this.cbZonas.clear();
    if (this.zonas) {
      this.zonas.forEach((zona, index) => {
        this.cbZonas.addItem({ label: zona.Nombre, value: zona.Id });
        if (this.alarmaEdit.Zona && this.alarmaEdit.Zona.Id === zona.Id) {
          this.cbZonas.selectedIndex(index)
        }
      });
    }
    this.ebNombre.focus();
    this.loader.close();
  }
  //cuando se expande al ventana editZone, carga todos los datos
  onExpandEdit(event) {
    if (event) {
      //this.subscribeZona();
      this.selectZonaEdit()
    }
  }

  async onOpenEdit() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.formEdit.setTitle(AppComponent.translate('Edicion_alarmas'));
      this.addCustomForm(this.formEdit);
    }, 100);
    this.loader.open();
    //si no existe una zona creada, directamente va a la funcion donde recorre todas las zonas creadas anteriormente
    if (!this.zona) {
      this.subscribeZona(); //procesa y asigna la zona creada en la ventana de editZona
    }
    this.selectZonaEdit() // Recorre y asigna las zonas y construye el desplegable

  }

  onChangeZona(event: any) {
    if (this.zonas) {
      this.zonas.forEach(zona => {
        if (zona.Id == event.args.item.value) {
          this.alarmaEdit.Zona = zona;
        }
      });
    }
  }

  onChangeCtrlEntrada(event: any) {
    if (!this.alarmaEdit.Entrada) {
      this.alarmaEdit.HayHoraEntrada = false;
      this.alarmaEdit.HoraEntrada = null;
    }
  }

  onChangeCtrlSalida(event: any) {
    if (!this.alarmaEdit.Salida) {
      this.alarmaEdit.HayHoraSalida = false;
      this.alarmaEdit.HoraSalida = null;
    }
  }

  onChangeHoraEntrada(event: any) {
    if (!this.alarmaEdit.HayHoraEntrada) {
      this.alarmaEdit.HoraEntrada = null;
    } else {
      if (this.horaEntrada.getDate() === null) {
        this.alarmaEdit.HoraEntrada = new Date();
      }
    }
  }

  onChangeHoraSalida(event: any) {
    if (!this.alarmaEdit.HayHoraSalida) {
      this.alarmaEdit.HoraSalida = null;
    } else {
      if (this.horaSalida.getDate() === null) {
        this.alarmaEdit.HoraSalida = new Date();
      }
    }
  }

  onChangeRepetir(event: any) {
    if (!this.alarmaEdit.Repetir) {
      this.dias.lunes = false;
      this.dias.martes = false;
      this.dias.miercoles = false;
      this.dias.jueves = false;
      this.dias.viernes = false;
      this.dias.sabado = false;
      this.dias.domingo = false;
      this.alarmaEdit.Dias = '';
    } else {
      if (this.alarmaEdit.Dias === null || this.alarmaEdit.Dias.length < 1) {
        this.dias.lunes = true;
        this.dias.martes = true;
        this.dias.miercoles = true;
        this.dias.jueves = true;
        this.dias.viernes = true;
        this.dias.sabado = true;
        this.dias.domingo = true;
        this.alarmaEdit.Dias = 'LMXJVSD';
      }
    }
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    this.form.enable();
    this.form.expand();
    this.zona = null;
  }

  // Centrar en una zona
  onVerZona(event: any) {
    if (this.alarmaEdit.Zona) {
      let swPoint = new MapLatLng(180, 90);
      let nePoint = new MapLatLng(-180, -90);
      if (this.circle) {
        this.map.removeCircle(this.circle)
        this.circle = null;
      }
      if (this.polygons) {
        this.polygons.forEach(polygon => {
          this.map.removePolygon(polygon);
        });
        this.polygons = null;
      }
      if (this.alarmaEdit.Zona.TipoGeo === 0) { // Círculo
        this.circle = this.map.addCircle({
          dataModel: this.alarmaEdit.Zona,
          content: this.alarmaEdit.Zona.Nombre,
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(this.alarmaEdit.Zona.Lat, this.alarmaEdit.Zona.Lng),
          radius: this.alarmaEdit.Zona.Radio,
          draggable: false,
          editable: false
        });
        const center = new MapLatLng(this.alarmaEdit.Zona.Lat, this.alarmaEdit.Zona.Lng);
        // Calculo el cuadrado que contiene la circunferencia
        swPoint = GeoUtils.getNewLatLng(center, -this.alarmaEdit.Zona.Radio, -this.alarmaEdit.Zona.Radio);
        nePoint = GeoUtils.getNewLatLng(center, this.alarmaEdit.Zona.Radio, this.alarmaEdit.Zona.Radio);
      } else { // Polígono
        if (this.alarmaEdit.Zona.Geometria) {
          this.polygons = this.map.addPolygonsFromGeoJson(this.alarmaEdit.Zona.Geometria, false);
          this.polygons.forEach(polygon => {
            polygon.points.forEach(punto => {
              if (punto.point.lat > nePoint.lat) {
                nePoint.lat = punto.point.lat;
              }
              if (punto.point.lng > nePoint.lng) {
                nePoint.lng = punto.point.lng;
              }
              if (punto.point.lat < swPoint.lat) {
                swPoint.lat = punto.point.lat;
              }
              if (punto.point.lng < swPoint.lng) {
                swPoint.lng = punto.point.lng;
              }
            });
          });
        } else {
          return;
        }
      }
      this.map.fitTo(new MapBounds(swPoint, nePoint));
      this.form.collapse();
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onGuardarAlarma(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.alarmaEdit.Nombre === null || this.alarmaEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (!this.alarmaEdit.Entrada && !this.alarmaEdit.Salida) {
      MainComponent.getInstance().showError('ATENCION', 'Indique_alarma_entrada_salida', 2000);
      return;
    }
    this.alarmaEdit.Dias = '';
    this.alarmaEdit.Dias += this.dias.lunes ? 'L' : '';
    this.alarmaEdit.Dias += this.dias.martes ? 'M' : '';
    this.alarmaEdit.Dias += this.dias.miercoles ? 'X' : '';
    this.alarmaEdit.Dias += this.dias.jueves ? 'J' : '';
    this.alarmaEdit.Dias += this.dias.viernes ? 'V' : '';
    this.alarmaEdit.Dias += this.dias.sabado ? 'S' : '';
    this.alarmaEdit.Dias += this.dias.domingo ? 'D' : '';
    if (this.alarmaEdit.Repetir && this.alarmaEdit.Dias.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Indique_dias_semana', 2000);
      return;
    }
    if (!this.alarmaEdit.HayHoraEntrada) {
      this.alarmaEdit.HoraEntrada = new Date('0001-01-01T00:00:00');
    }
    if (!this.alarmaEdit.HayHoraSalida) {
      this.alarmaEdit.HoraSalida = new Date('0001-01-01T00:00:00');
    }
    this.saveAlarma();
  }

  // Guardo la alarma
  async saveAlarma() {
    const response = await this.alarmsService.saveAlarma(this.alarmaEdit);
    if (response) {
      if (this.alarmaEdit.Id === 0) {
        this.alarmas.push(response);
      } else {
        for (let i = 0; i < this.alarmas.length; i++) {
          if (this.alarmas[i].Id === this.alarmaEdit.Id) {
            this.alarmas[i] = { ...response };
            break;
          }
        }
      }
      this.alarmaEdit = { ...response };
      this.alarmaSelec = { ...this.alarmaEdit };
      this.gridAlarmas.updatebounddata('data');
      MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    this.formEdit.close();
  }

  onAsignarAlarma() {
    if (this.alarmaSelec) {
      this.mostrarAsigForm = true;
      if (this.form !== undefined) {
        this.form.collapse();
        this.form.disable();
      }
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onOpenAsig() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.formAsig.setTitle(AppComponent.translate('Asignacion_alarmas'));
      this.addCustomForm(this.formAsig);
      this.updateListaDisponibles();
    }, 100);
  }

  onCloseAsig() {
    this.mostrarAsigForm = false;
    this.form.enable();
    this.form.expand();
  }

  async updateListaDisponibles() {
    try {
      this.loader.open();
      // Recupero todos los móviles asignados a la alarma
      const movilesAsig = new Map<number, number>();
      this.movilesAsig = [];
      const result = await this.alarmsService.getAlarmaMoviles(this.alarmaSelec.Id);
      if (result) {
        result.forEach(movilId => {
          movilesAsig.set(movilId, movilId)
          this.movilesAsig.push(this.resourcesService.getMovil(movilId));
        });
      }
      // Recupero todos los móviles y quito los que ya están asignados
      const moviles = await this.resourcesService.getMoviles();
      if (moviles) {
        this.moviles = [];
        moviles.forEach(movil => {
          if (!movilesAsig.get(movil.Codigo)) {
            this.moviles.push(movil);
          }
        });
        // Actualizo la lista de móviles sin asignar
        this.sourceMoviles1 = {
          datatype: 'json',
          datafields: [
            { name: 'codigo', map: 'Codigo' },
            { name: 'tipo', map: 'TipoMovil>Acronimo' },
            { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
            { name: 'nombre', map: 'Nombre' }
          ],
          localdata: this.moviles,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles1 = new jqx.dataAdapter(this.sourceMoviles1);
        // Actualizo la lista de móviles asignados
        this.sourceMoviles2 = {
          datatype: 'json',
          datafields: [
            { name: 'codigo', map: 'Codigo' },
            { name: 'tipo', map: 'TipoMovil>Acronimo' },
            { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
            { name: 'nombre', map: 'Nombre' }
          ],
          localdata: this.movilesAsig,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles2 = new jqx.dataAdapter(this.sourceMoviles2);
      }
    } finally {
      this.loader.close();
    }
  }

  onMasClick() {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles1.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesAsig.push(this.moviles[rowIndex]);
        moviles.set(this.moviles[rowIndex].Codigo, this.moviles[rowIndex].Codigo);
      });
      for (let i = this.moviles.length - 1; i >= 0; i--) {
        if (moviles.get(this.moviles[i].Codigo)) {
          this.moviles.splice(i, 1);
        }
      };
      this.gridMoviles1.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

  onMenosClick() {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles2.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.moviles.push(this.movilesAsig[rowIndex]);
        moviles.set(this.movilesAsig[rowIndex].Codigo, this.movilesAsig[rowIndex].Codigo);
      });
      for (let i = this.movilesAsig.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesAsig[i].Codigo)) {
          this.movilesAsig.splice(i, 1);
        }
      };
      this.gridMoviles2.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

  async onGuardarAsig() {
    this.loader.open();
    // Almaceno los móviles asignados
    let movilesId: number[] = [];
    if (this.movilesAsig.length) {
      this.movilesAsig.forEach(movil => {
        movilesId.push(movil.Codigo);
      });
    }
    const result = await this.alarmsService.saveMovilesAlarma(this.alarmaSelec.Id, movilesId);
    this.loader.close();
    if (result) {
      MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    this.formAsig.close();
  }

  onChangeAreasZonas(event) {
    this.formEdit.collapse();
    this.createComponentZones()
  }

  createComponentZones() {

    this.component = this.menuContainer.createComponent(ZonesComponent);
    this.component.instance.init(this.component, false, this.formEdit, this.formZonas);
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  onExportar() {
    if (this.gridAlarmas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.gridAlarmas.exportdata('json');
      let datos = JSON.parse(json);
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Zonas') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.gridAlarmas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.gridAlarmas.hidecolumn('Botones');
      let gridContent = this.gridAlarmas.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.gridAlarmas.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }
}
