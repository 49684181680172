<jqxWindow #windowForm [width]="900" [height]="600" [zIndex]="100" [isModal]="false" [theme]="environment.tema"
  [position]="getFormPos(windowForm)" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
  (onClose)="onClose()" [title]="translate('Cerradura')">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Cerradura") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <form #formConfiguracion="ngForm">
      <div class="row tab"
        style="background-color: transparent; display: flex; padding: 0%; overflow-y: hidden !important;">
        <span style="width: 100%;">
          <div class="rowC">
            <fieldset>
              <div class="row">
                <span style="width: 55%; clear: both">
                  <div style="margin-top: 5px;">
                    {{ translate("Descripcion_breve") }}
                  </div>
                  <jqxInput [(ngModel)]="configuracionCerradura.nombre" [width]="'100%'"
                    [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 30%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Servidor") }}
                  </div>
                  <jqxInput [(ngModel)]="configuracionCerradura.host" [width]="'100%'"
                    [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 15%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Puerto") }}
                  </div>
                  <app-input-number [value]="configuracionCerradura.puerto"
                    (onChange)="configuracionCerradura.puerto = $event"
                    [render]="renderer()" [width]="'100%'" [renderizar]="false">
                  </app-input-number>
                </span>
              </div>

              <div class="row">
                <span style="width: 60%; float: left;">
                  <div style="margin-top: 5px;">
                    {{ translate("Tipo_contenedor") }}
                  </div>
                  <jqxDropDownButton #dropDownContenedor [width]="'100%'" (onClose)="onCloseDropContenedor()"
                    (onOpen)="onOpenDropContenedor()">
                    <div [hidden]="gridDropContenedor" style="width: 100%;">
                      <jqxGrid #gridContenedor [height]="150" [source]="adapterGridContenedor" [columnsresize]="true"
                        [selectionmode]="'singlerow'" [columns]="columnsGridContenedor" [sortable]="true"
                        [altrows]="true" [showrowlines]="true" [rowsheight]="20" [columnsheight]="20"
                        [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowContenedorClick($event)"
                        [showheader]="false">
                      </jqxGrid>
                    </div>
                  </jqxDropDownButton>
                </span>
                <span style="width: 40%; float: left;">
                  <div style="margin-top: 5px;">
                    {{ translate("Tipo_cerradura") }}
                  </div>
                  <jqxDropDownButton #dropDownCerradura [width]="'100%'" (onClose)="onCloseDropCerradura()"
                    (onOpen)="onOpenDropCerradura()">
                    <div [hidden]="gridDropCerradura" style="width: 100%;">
                      <jqxGrid #gridCerradura [theme]="environment.tema" [width]="dropDownCerradura?.scrollWidth"
                        [height]="150" [source]="adapterGridCerradura" [columnsresize]="true"
                        [selectionmode]="'singlerow'" [columns]="columnsGridCerradura" [sortable]="true"
                        [altrows]="true" [showrowlines]="true" [rowsheight]="20" [columnsheight]="20"
                        [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowCerraduraClick($event)"
                        [showheader]="false">
                      </jqxGrid>
                    </div>
                  </jqxDropDownButton>
                </span>
              </div>
            </fieldset>

            <fieldset style="margin-top: 10px;">
              <legend>
                {{ translate("Configuracion_modem") }}
              </legend>

              <div class="row">
                <span style="width: 35%;">
                  <div style="margin-top: 5px;">
                    APN
                  </div>
                  <jqxInput [(ngModel)]="configuracionCerradura.apn" [width]="'100%'" required name='apn'
                    [placeHolder]="translate('Input_vacio')">
                  </jqxInput>
                </span>
                <span style="width: 25%;">
                  <div style="margin-top: 5px;">
                    {{ translate("USUARIO") }}
                  </div>
                  <jqxInput [(ngModel)]="configuracionCerradura.apnUsuario" [width]="'100%'"
                    [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 25%;">
                  <div style="margin-top: 5px;">
                    {{ translate("PASSWORD") }}
                  </div>
                  <jqxInput [(ngModel)]="configuracionCerradura.apnPassw" [width]="'100%'"
                    [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 15%;">
                  <div style="margin-top: 5px;">
                    PIN
                  </div>
                  <jqxInput [(ngModel)]="configuracionCerradura.pin" [width]="'100%'"
                    [ngModelOptions]="{standalone: true}" [maxLength]="4">
                  </jqxInput>
                </span>
              </div>
            </fieldset>

            <fieldset style="margin-top: 10px;">
              <legend>
                {{ translate("Configuracion_comunicaciones") }}
              </legend>

              <div class="row">
                <span style="width: 30%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Comunicaciones") }}
                  </div>
                  <jqxDropDownButton #dropDownComunicacion style="float: left" [width]="'100%'"
                    (onClose)="onCloseDropComunicacion()" (onOpen)="onOpenDropComunicacion()">
                    <div [hidden]="gridDropComunicacion" style="width: 100%;">
                      <jqxGrid #gridComunicacion [theme]="environment.tema" [width]="dropDownComunicacion?.scrollWidth"
                        [height]="150" [source]="adapterGridComunicacion" [columnsresize]="true"
                        [selectionmode]="'singlerow'" [columns]="columnsGridComunicacion" [sortable]="true"
                        [altrows]="true" [showrowlines]="true" [rowsheight]="20" [columnsheight]="20"
                        [enabletooltips]="true" [localization]="langGrid" (onRowclick)="onRowComunicacionClick($event)"
                        [showheader]="false">
                      </jqxGrid>
                    </div>
                  </jqxDropDownButton>
                </span>
                <span style="width: 25%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Hora_envio") }}
                  </div>
                  <jqxDateTimeInput [value]="horaEnvio" (onChange)="setHoraEnvio($event.target.value)" [width]="'100%'"
                    [showTimeButton]="true" [showCalendarButton]="false" [formatString]="'HH:mm'">
                  </jqxDateTimeInput>
                </span>
                <span style="width: 25%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Intervalo_envio") }} (min.)
                  </div>
                  <app-input-number [value]="configuracionCerradura.intervaloEnvio"
                    (onChange)="configuracionCerradura.intervaloEnvio = $event"
                    [render]="renderer()" [width]="'100%'" [renderizar]="false">
                  </app-input-number>
                </span>
              </div>
            </fieldset>

            <fieldset style="margin-top: 10px;">
              <legend>
                {{ translate("Interacciones_usuarios") }}
              </legend>

              <div class="row">
                <span style="width: 20%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Tiempo_apertura") }} (seg.)
                  </div>
                  <app-input-number [value]="configuracionCerradura.tiempoAbrir"
                    (onChange)="configuracionCerradura.tiempoAbrir = $event"
                    [render]="renderer()" [width]="'100%'" [renderizar]="false">
                  </app-input-number>
                </span>
                <span style="width: 20%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Tiempo_aporte") }} (seg.)
                  </div>
                  <app-input-number [value]="configuracionCerradura.tiempoAporte"
                    (onChange)="configuracionCerradura.tiempoAporte = $event"
                    [render]="renderer()" [width]="'100%'" [renderizar]="false">
                  </app-input-number>
                </span>
                <span style="width: 20%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Tiempo_cierre") }} (seg.)
                  </div>
                  <app-input-number [value]="configuracionCerradura.tiempoCerrar"
                    (onChange)="configuracionCerradura.tiempoCerrar = $event" [render]="renderer()" [width]="'100%'">
                  </app-input-number>
                </span>
              </div>
            </fieldset>

            <fieldset style="margin-top: 10px;">
              <legend>
                {{ translate("Configuracion_alarma") }}
              </legend>

              <div class="row">
                <span style="width: 40%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Alarmas") }}
                  </div>
                  <jqxDropDownButton #dropDownAlarma style="float: left" [width]="'100%'"
                    (onClose)="onCloseDropAlarma()" (onOpen)="onOpenDropAlarma()">
                    <div [hidden]="gridDropAlarma" style="width: 100%;">
                      <jqxGrid #gridAlarma [theme]="environment.tema" [width]="dropDownAlarma?.scrollWidth"
                        [height]="150" [source]="adapterGridAlarma" [columnsresize]="true" [selectionmode]="'checkbox'"
                        [columns]="columnsGridAlarma" [sortable]="true" [altrows]="true" [showrowlines]="true"
                        [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [localization]="langGrid"
                        (onRowselect)="onRowAlarmaSelect($event)" (onRowunselect)="onRowAlarmaUnselect($event)"
                        [showheader]="false">
                      </jqxGrid>
                    </div>
                  </jqxDropDownButton>
                </span>
                <span style="width: 20%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Alarma_bateria_baja") }} (volt)
                  </div>
                  <app-input-number [value]="configuracionCerradura.bateriaBaja"
                    (onChange)="configuracionCerradura.bateriaBaja = $event"
                    [render]="renderer()" [width]="'100%'" [decimal]="2">
                  </app-input-number>
                </span>
                <span style="width: 20%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Alarma_bateria_critica") }} (volt)
                  </div>
                  <app-input-number [value]="configuracionCerradura.bateriaCritica"
                    (onChange)="configuracionCerradura.bateriaCritica = $event"
                    [render]="renderer()" [width]="'100%'" [decimal]="2">
                  </app-input-number>
                </span>
                <span style="width: 20%;">
                  <div style="margin-top: 5px;">
                    {{ translate("Alarma_temperatura") }} (ºC)
                  </div>
                  <app-input-number [value]="configuracionCerradura.temperatura"
                    (onChange)="configuracionCerradura.temperatura = $event"
                    [render]="renderer()" [width]="'100%'" [decimal]="2">
                  </app-input-number>
                </span>
              </div>
            </fieldset>
          </div>
        </span>
      </div>

      <div style="
          position: absolute;
          bottom: 5px;
          left: 0px;
          margin-top: 23px;
          clear: both;
        ">

        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
          (onClick)="onGuardar()" [value]="translate('Guardar')">
        </jqxButton>
      </div>
    </form>
  </div>
</jqxWindow>
