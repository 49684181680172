<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form)" [width]="500" [height]="450" [zIndex]="999"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
      {{translate('Listado_tarjetas')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div style="float: left; height: '100%'; width: '100%';">
      <div>
        <app-header #header [exportar]="true" (eventExportar)="onExportar()">
        </app-header>
      </div>

      <div style="float:left; margin-top: 6px; width: 100%; height: 100%;">
        <jqxGrid #gridTarjetas [theme]="environment.tema" [width]="'100%'" [height]="'93%'" [source]="adapterTarjetas"
          [columnsresize]="true" [localization]="langGrid" [filterable]="false" [columns]="columnsTarjetas"
          [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
          [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
          [columnsheight]="20" [enabletooltips]="true" [editable]="false" [groupable]="true">
        </jqxGrid>
      </div>
    </div>
  </div>
</jqxWindow>
