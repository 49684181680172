<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth"
  [height]="mapHeight" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Catalogo_elementos_BDT") }}
  </div>
  <div class="form" style="float: left; overflow-x: hidden">
    <div style="float: left; width: 100%; height: 471px">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter"
        [columnsresize]="true" [filterable]="true" [selectionmode]="'singlerow'" [columns]="columns" [sortable]="true"
        [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="25"
        [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20" [rowdetails]="true"
        [initrowdetails]="initrowdetails" [rowdetailstemplate]="rowdetailstemplate" [localization]="langGrid"
        (onBindingcomplete)="onBindingComplete()" (onRowexpand)="onRowExpand($event)">
      </jqxGrid>
    </div>
  </div>
</jqxWindow>