import { AfterViewInit, Component, HostListener, Input, OnInit, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';

import { MapBounds, MapCircle, MapComponent, MapLatLng, MapPolygon, MapMarker, MapPolygonPoint } from 'src/app/imports';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CartoService } from 'src/app/services/carto/carto.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { BdtService, BDT_RECURSOS } from 'src/app/services/bdt/bdt.service';

import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { BdtRecursoModel } from 'src/app/services/bdt/models/bdt-recurso.model';
import { EntradaModel } from 'src/app/services/zones/models/zona-entrada.model';
import { AreaDetail, } from 'src/app/services/carto/models/area-detail.model';

import { EntradaSalidaComponent } from './entrada-salida/entrada-salida.component';
import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { jqxButtonComponent } from 'jqwidgets-ng/jqxbuttons';
import { Spinkit, SpinnerVisibilityService } from 'ng-http-loader';

export enum TiposMaster {
  ZONAADMIN = 1,
  MANUAL = 2,
  ELEMENTO = 3,
  EDIFICIOS = 4,
}
export enum Aplicacion {
  ECOSATLITE = 11,
  GESCONSAT = 14
}

@Component({
  selector: 'app-zones',
  templateUrl: './zones.component.html',
  styleUrls: ['./zones.component.css']
})

export class ZonesComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridZonas') gridZonas: jqxGridComponent;
  @ViewChild('formEditZona') formEditZona: jqxWindowComponent;
  @ViewChild('cbTiposZonas') cbTiposZonas: jqxDropDownListComponent;
  @ViewChild('cbTiposGeo') cbTiposGeo: jqxDropDownListComponent;
  @ViewChild('cbTiposMaster') cbTiposMaster: jqxDropDownListComponent;
  @ViewChild('btnEdit') btnEdit: jqxButtonComponent;
  theme = environment.tema;
  titleWindow: string = '';
  titleEditWindow: string = '';
  selectTipoMaster: number = 1;
  hiddenTipoZonas: boolean = false;
  hiddenTipoMaster: boolean = false;
  hiddenGeometria: boolean = false;
  hiddenRadius: boolean = false;
  hiddenInputSearch: boolean = true;
  hiddenDrawButton: boolean = false;
  hiddenELementContainer: boolean = false;
  infoMarker: any;
  infoNombre: any;
  infoElement: any;
  hideBdtCombo: any;
  inputSearch: any;
  timer: any;
  superficie: any;
  stringSearch = '';
  public areas = new Map<number, string>();
  adminAreas;
  areaDetail: AreaDetail;
  polygonPoints: MapLatLng[] = [];
  public static _this: ZonesComponent;
  private map: MapComponent;
  public zonaSelec: ZonaModel;
  public zonaEdit: ZonaModel;
  public dataSource;
  public dataAdapter;
  public mostrarEditZona = false;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  zonas: ZonaModel[] = [];
  public circle: MapCircle;
  circles: MapCircle[] = [];
  polygon: MapPolygon = null;
  polygons: MapPolygon[] = [];
  public polygonGeoJson = null;
  public subscriptionOnMapClick = null;
  private subscriptionOnRadiusChange = null;
  private subscriptionOnMarkerClick = null;
  private subscriptionOnPolygonDragEnd = null;
  private tipos: BdtRecursoModel[] = [];
  private rowIndex = -1;
  private select: any = [];
  public modoManual = false;
  private editAlarm: any;
  private formZones: boolean;
  public change: boolean = false;
  public viewGridEntradasSalidas: boolean;
  editEntradas: boolean;
  public idZonaEntrada: number;
  public entradas: EntradaModel[] = [];
  private marker: MapMarker;
  public markers: MapMarker[] = [];
  private entrada: EntradaModel = new EntradaModel();
  checkSelect: ZonaModel[] = [];
  rows: any[] = [];
  row: number;
  indexRow: number[] = [];
  mapWidth: number;
  mapHeight: number;
  public spinkit = Spinkit;

  // Preparo las columnas del grid
  public columns;
  //filtro para el grid
  addfilter = (): void => {
    let filtergroup = new jqx.filter();
    let filtervalue = 'Manual';
    let filtercondition = 'contains';
    let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);
    filtergroup.addfilter(1, filter1);
    // add the filters
    this.gridZonas.addfilter('tipo', filtergroup);
    // apply the filters
    this.gridZonas.applyfilters();
  };
  creandoZonaMasEntradas: boolean = false;

  initGrid() {
    this.columns = [
      { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true, },
      {
        text: AppComponent.translate('Nombre'), width: '20%', columntype: 'textbox', cellsalign: 'left', filtertype: 'textbox', datafield: 'nombre', editable: false,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: AppComponent.translate('Tipo'), columntype: 'textbox',width: '20%', filtertype: 'checkedlist', datafield: 'tipo', hidden: this.modoManual, editable: false },
      { text: AppComponent.translate('Geometria'), columntype: 'textbox',width: '20%', filtertype: 'checkedlist', datafield: 'tipo_geo', editable: false },
      { text: AppComponent.translate('Radio') + ' (m)', columntype: 'textbox',width: '20%', filtertype: 'number', datafield: 'radio', editable: false, cellsrenderer: this.renderRow },
      { text: AppComponent.translate('Superficie') + ' (m²)', columntype: 'textbox', width: '20%',filtertype: 'number', datafield: 'area', editable: false, cellsrenderer: this.renderRow },
      { text: AppComponent.translate('Entradas'), columntype: 'textbox', filtertype: 'number',width: '20%', cellsalign: 'right', datafield: 'entradas', editable: false, },
      { text: AppComponent.translate('Salidas'), columntype: 'textbox', filtertype: 'number',width: '20%', datafield: 'salidas', cellsalign: 'right', editable: false, menu: false, sortable: false, },
      { text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'input',width: '20%', datafield: 'observ', editable: false, menu: false, sortable: false, }
    ];
  }

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService,
    private zonesService: ZonesService,
    private modal: NzModalService,
    private bdtService: BdtService,
    private configService: ConfigService,
    private cartoService: CartoService,
    private spinner: SpinnerVisibilityService
  ) {
    super();
    ZonesComponent._this = this;

  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.initGrid();
    this.canEdit = true; // TODO: por hacer...
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();

    if (this.modoManual) {
      this.titleWindow = this.translate('Poligonos');
      this.titleEditWindow = this.translate('Edicion_poligonos')
    } else {
      // Si la aplicación es gesconsat, se cambia el nombre de las dos ventanas y se oculta la opción de edificios/instalaciones
      if (MainComponent.getInstance().ssoTicket.Aplicacion.Id == Aplicacion.GESCONSAT) {
        this.hideBdtCombo = true;
        this.titleWindow = this.translate('Zonas_cartograficas');
        this.titleEditWindow = this.translate('Edicion_zonas_cartograficas')
      } else {
        this.hideBdtCombo = false;
        this.titleWindow = this.translate('Edificios_instalaciones');
        this.titleEditWindow = this.translate('Edicion_edificios_instalaciones')
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    // Cambia el título de la ventana
    this.form.setTitle(this.titleWindow);
    this.tipos = await this.bdtService.getRecursos(BDT_RECURSOS.Edificios);
    // Recupero los filtros
    this.select = await this.configService.getUsuEmpApp('zones-filter', null);
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
    await this.getZones();
    this.countEntrSal();
    this.onCloseForm();
    Utils.renderSizeGrid(this.gridZonas);
  }

  countEntrSal(zona?: ZonaModel) {
    const countEntrSalForZone = (zona: ZonaModel) => {
      let counts = { entradas: 0, salidas: 0, entradas_salidas: 0 };

      zona.Entradas?.forEach(entrada => {
        switch (entrada.Tipo) {
          case 1:
            counts.entradas++;
            break;
          case 2:
            counts.salidas++;
            break;
          case 3:
            counts.entradas_salidas++;
            break;
        }
      });

      zona.EntradasCount = counts.entradas;
      zona.SalidasCount = counts.salidas;
      zona.EntradasSalidasCount = counts.entradas_salidas;
      this.gridZonas.updatebounddata();
    };

    if (zona) {
      countEntrSalForZone(zona);
      this.gridZonas.refresh();
    } else {
      this.zonas.forEach(countEntrSalForZone);
    }
  }


  // Este método es llamado por el creador del componente
  public init(componentRef: any, modoManual: boolean, editAlarm: any, formZones: boolean) {
    this.componentRef = componentRef;
    this.modoManual = modoManual;
    this.editAlarm = editAlarm;
    this.formZones = formZones;
  }

  onCloseForm() {
    if (this.formZones) {
      this.form.destroy();
      this.zonaEdit = new ZonaModel();
      this.zonaSelec = this.zonaEdit;
      this.onEditarZona(null);
      this.mostrarEditZona = true;
    }
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.circle) {
      this.map.removeCircle(this.circle)
      this.circle = null;
    }
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
    }
    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
    }
    if (EntradaSalidaComponent._this) {
      EntradaSalidaComponent._this.removeMarker();
    }

    // Guardo el filtro de zonas visibles
    this.saveFilter();
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    if (this.editAlarm) {
      this.editAlarm.expand();
    }

    if (this.markers.length > 0) {
      this.markers.forEach(marker => {
        this.map.removeMarker(marker);
        marker = null;
        this.markers = [];
      });
    }

    if (this.subscriptionOnMapClick) this.subscriptionOnMapClick.unsubscribe();
    if (this.subscriptionOnMarkerClick) this.subscriptionOnMarkerClick.unsubscribe();

  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Obtengo las zonas de la empresa
  async getZones() {
    this.spinner.show();
    this.zonas = await this.zonesService.getZonas();
    if (this.zonas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'Id' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'tipo', type: 'string', map: 'TipoNombre' },
          { name: 'tipo_geo', type: 'string', map: 'TipoGeoNombre' },
          { name: 'radio', type: 'number', map: 'Radio' },
          { name: 'area', type: 'number', map: 'Area' },
          { name: 'entradas', type: 'number', map: 'EntradasCount' },
          { name: 'salidas', type: 'number', map: 'SalidasCount' },
          { name: 'entradas_salidas', type: 'number', map: 'EntradasSalidasCount' },
          { name: 'observ', type: 'string', map: 'Observaciones' }
        ],
        localdata: this.zonas,
      }
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);

    } else {
      this.zonas = [];
    }
    this.spinner.hide();
  }

  // Cuando se ha cargado la lista
  onBindingComplete() {
    if (this.gridZonas) {
      this.gridZonas.sortby('nombre', 'asc');
      const rows = this.gridZonas.getrows();
      //si solo se pueden crear polígonos, mostrar solo zonas manuales
      if (this.modoManual) {
        this.addfilter();
      }

      if (rows) {
        rows.forEach(row => {
          if (this.select.find(s => s.id === row.id) !== undefined) {
            this.gridZonas.selectrow(row.boundindex);
          }
        });
      }
    }
  }

  renderRow(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    let backgroundColor = '';
    if (row === ZonesComponent._this.rowIndex) {
      backgroundColor = 'height: 25px; background-color: lightgray;';
    }
    switch (columnfield) {
      case 'radio':
        return '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format(value, 0) + '</div>';
      case 'area':
        return '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format(value, 0) + '</div>';

      default:
        return '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' + NumberUtils.format(value, 0) + '</div>';
    }
  }

  onRowSelect(event: any) {
    const rowSel = this.gridZonas.getselectedrowindexes();
    this.row = event.args.rowindex;
    this.rowIndex = event.args.rowindex;
    if (rowSel && rowSel.length < 2) {
      this.gridZonas.clearselection();
      this.gridZonas.selectrow(event.args.rowindex);
    }
    this.gridZonas.updatebounddata('cells');
    this.zonaSelec = this.zonas[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    this.onEditarZona(event);
  }

  // Guardo el filtro de zonas visibles
  public async saveFilter() {
    const zonas: any[] = [];
    // Si se trata del modo de "sólo polígonos" guardamos todas las zonas de otros
    // tipos que esten seleccionadas en el filtro
    if (this.modoManual) {
      this.zonas.forEach(zona => {
        if (zona.Tipo !== TiposMaster.MANUAL && this.select.find(s => s.id === zona.Id) !== undefined) {
          zonas.push({ id: zona.Id });
        }
      });
    }
    const rowsSelec = this.gridZonas.getselectedrowindexes();
    if (rowsSelec) {
      rowsSelec.forEach(i => {
        zonas.push({ id: this.zonas[i].Id });
      });
    }
    await this.configService.setUsuEmpApp('zones-filter', JSON.stringify(zonas));
    // Notifico que se ha cambiado el filtro de zonas visibles
    this.zonesService.loadZones();
    this.zonesService.setFilterVisible();
  }

  // Centrar en una zona
  onVer(event: any) {
    // compruebo si existen zonas seleccionadas y las borro
    if (this.circles.length) {
      this.circles.forEach(element => {
        this.map.removeCircle(element);
      });
      this.circles = [];
    }

    if (this.polygons.length) {
      this.polygons.forEach(element => {
        this.map.removePolygon(element);
      });
      this.polygons = [];
    }

    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
    }

    if (this.checkSelect.length) {
      this.checkSelect.forEach(zona => {
        if (zona.TipoGeo === 0 && zona.Lat !== 0) { // Círculo
          this.circle = this.map.addCircle({
            dataModel: zona,
            content: zona.Nombre,
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            position: new MapLatLng(zona.Lat, zona.Lng),
            radius: zona.Radio,
            draggable: false,
            editable: false
          });
          this.circles.push(this.circle);

        } else { // Polígono
          this.zonaEdit = zona;
          this.drawPolygon(zona.Geometria);
          this.polygons.push(this.polygonGeoJson);
        }
      });
      this.encuadrarZonasSelec(this.checkSelect);
      this.form.collapse();
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  encuadrarZonasSelec(zonas: ZonaModel[]) {
    let globalSWPoint = new MapLatLng(180, 90);
    let globalNEPoint = new MapLatLng(-180, -90);
    // Procesa cada zona
    zonas.forEach(zona => {
      let swPoint, nePoint;
      if (zona.TipoGeo === 0) { // Si es un círculo
        let center = new MapLatLng(zona.Lat, zona.Lng);
        swPoint = GeoUtils.getNewLatLng(center, -zona.Radio, -zona.Radio);
        nePoint = GeoUtils.getNewLatLng(center, zona.Radio, zona.Radio);
      } else { // Si es un polígono
        let bounds = this.calculatePolygonBounds(zona.Geometria); // Devuelve un objeto con swPoint y nePoint
        swPoint = bounds.swPoint;
        nePoint = bounds.nePoint;
      }
      globalSWPoint.lat = Math.min(globalSWPoint.lat, swPoint.lat);
      globalSWPoint.lng = Math.min(globalSWPoint.lng, swPoint.lng);
      globalNEPoint.lat = Math.max(globalNEPoint.lat, nePoint.lat);
      globalNEPoint.lng = Math.max(globalNEPoint.lng, nePoint.lng);
    });
    this.map.fitTo(new MapBounds(globalSWPoint, globalNEPoint));
  }

  calculatePolygonBounds(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      });
    });
    let swPoint = new MapLatLng(Math.min(...arrLat), Math.min(...arrLng));
    let nePoint = new MapLatLng(Math.max(...arrLat), Math.max(...arrLng));
    return { swPoint, nePoint };
  }

  onCrearZona(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (event) {
      this.viewGridEntradasSalidas = false;
      this.zonaEdit = new ZonaModel();
      this.editEntradas = false;
      this.creandoZonaMasEntradas = true;
      setTimeout(() => {
        EntradaSalidaComponent._this.disabledBotton(true);
      }, 500);
    }
    this.zonaSelec = this.zonaEdit;
    //this.onEditarZona(null);
    this.openEditZonaForm();
  }

  onEditarZona(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaSelec?.Id > 0) {
      if (event) {
        this.viewGridEntradasSalidas = true;
        this.editEntradas = true;
      }
      // Creo una copia para preservar el contenido original
      this.zonaEdit = Utils.clone(this.zonaSelec);
      this.openEditZonaForm();
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onOpenEditZona() {
    const t = setTimeout(async () => {
      clearTimeout(t);
      this.addCustomForm(this.formEditZona);
      //se cambia el titulo de la ventana
      this.formEditZona.setTitle(this.titleEditWindow);
      this.cbTiposMaster.addItem({ label: this.translate('Zonas_administrativas'), value: TiposMaster.ZONAADMIN });
      this.cbTiposMaster.addItem({ label: this.translate('Manual'), value: TiposMaster.MANUAL });
      // if (this.zonaEdit.Id === 0) {
      //   this.cbTiposMaster.addItem({ label: this.translate('Asociada_elemento'), value: TiposMaster.ELEMENTO });
      // }
      this.cbTiposMaster.addItem({ label: this.translate('Asociada_elemento'), value: TiposMaster.ELEMENTO });
      if (!this.hideBdtCombo) {
        this.cbTiposMaster.addItem({ label: this.translate('Edificios_instalaciones'), value: TiposMaster.EDIFICIOS });
      }
      //para dibujar solo polígonos, se selecciona del dropdown manual
      if (this.modoManual) {
        this.hiddenTipoMaster = true;
        this.cbTiposMaster.selectIndex(1);
      }

      // Relleno el combo de tipos de zonas
      this.tipos = await this.bdtService.getRecursos(BDT_RECURSOS.Edificios);
      this.tipos = this.tipos.sort((a, b) => {
        if (a.Nombre < b.Nombre) return -1;
        if (a.Nombre > b.Nombre) return 1;
        return 0;
      });

      this.cbTiposZonas.clear();
      this.tipos.forEach((tipo, i) => {
        this.cbTiposZonas.addItem({ label: tipo.Nombre, value: tipo.Id });
        if (this.zonaEdit.Id > 0 && this.zonaEdit.TipoBDT === tipo.Id) {
          this.cbTiposZonas.selectIndex(i);
        }
      });
      if (this.zonaEdit.Id < 1) {
        this.cbTiposZonas.selectIndex(0);
      }
      // Relleno el combo de tipos de geometrías
      this.cbTiposGeo.clear();
      this.cbTiposGeo.addItem({ label: AppComponent.translate('Circulo'), value: 0 });
      this.cbTiposGeo.addItem({ label: AppComponent.translate('Poligono'), value: 1 });
      this.cbTiposGeo.selectIndex(this.zonaEdit.TipoGeo);

      if (this.zonaEdit.Id > 0) {
        this.cbTiposGeo.disabled(true);
      }

      //se selecciona por defecto el primer valor del combo
      if (this.zonaEdit.Tipo != 0) {
        this.cbTiposMaster.selectIndex(this.zonaEdit.Tipo - 1);
        this.cbTiposMaster.disableItem(3)
      } else {
        this.cbTiposMaster.selectIndex(0);
      }
    }, 0);

  }

  onKey(nombre: string) {
    if (nombre.length > 0) {
      setTimeout(() => {
        EntradaSalidaComponent._this.disabledBotton(false);
      }, 200);
    } else {
      setTimeout(() => {
        EntradaSalidaComponent._this.disabledBotton(true);
      }, 200);
    }
  }

  deleteElementsMaps() {
    if (this.circle) {
      this.map.removeCircle(this.circle)
      this.circle = null;
    }

    if (this.polygon) {
      this.map.removePolygon(this.polygon)
      this.polygon = null;
    }

    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
      this.polygonGeoJson = null;
    }

    if (!this.creandoZonaMasEntradas) {
      EntradaSalidaComponent._this.removeMarker();
    }

  }


  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnRadiusChange) {
      this.subscriptionOnRadiusChange.unsubscribe();
      this.subscriptionOnRadiusChange = null;
    }

    if (this.subscriptionOnPolygonDragEnd) {
      this.subscriptionOnPolygonDragEnd.unsubscribe();
      this.subscriptionOnPolygonDragEnd = null;
    }

    if (this.subscriptionOnMapClick) this.subscriptionOnMapClick.unsubscribe();
    if (this.subscriptionOnMarkerClick) this.subscriptionOnMarkerClick.unsubscribe();

    EntradaSalidaComponent._this.unsubscribeMapEvents();
  }

  async onCloseEditZona() {
    this.map.setMousePointer('');
    this.unsubscribeMapEvents();
    this.deleteElementsMaps();
    this.mostrarEditZona = false;
    this.form.enable();
    this.form.expand();
    this.formEditZona.destroy();
    if (this.editAlarm) {
      this.zonesService.onSendZona(this.zonaEdit);
      this.editAlarm.expand();
    }

  }

  // Muestra el formulario de edición de zonas
  openEditZonaForm() {
    this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
    this.mostrarEditZona = true;
    this.areas.clear();
    if (this.form) {
      this.form.collapse();
      this.form.disable();
    }
    this.polygonPoints = [];
    // Si se está modificando una zona la pinto en el mapa
    if (this.zonaEdit.Id > 0) {
      this.zonesService.hideZone(this.zonaEdit.Id);
      //comprobar si es zona administrativa
      if (this.zonaEdit.Tipo == TiposMaster.ZONAADMIN) {
        this.drawPolygon(this.zonaEdit.Geometria);
      } else {
        this.drawZone();
      }
    }

    // Me subscribo a los cambios de radio en los círculos
    if (this.subscriptionOnRadiusChange) {
      this.subscriptionOnRadiusChange.unsubscribe();
    }
    this.subscriptionOnRadiusChange = this.map.subscribeOnCircleRadiusChange(this, this.onChangeRadiusOnMap);
    // Me subscribo a los cambios en los polígonos
    if (this.subscriptionOnPolygonDragEnd) {
      this.subscriptionOnPolygonDragEnd.unsubscribe();
    }
    this.subscriptionOnPolygonDragEnd = this.map.subscribeOnPolygonDragEnd(this, this.onChangePolygonOnMap);
  }

  // Cuando se cambia el tipo de zona
  onChangeTipoZona(event: any) {
    this.zonaEdit.TipoBDT = event.args.item.value;
  }

  //Cuando se cambia el tipo de creación de zona
  onChangeTipoMaster(event) {
    this.zonaEdit.Tipo = event.args.item.value;
    this.selectTipoMaster = event.args.item.value;
    if (this.zonaEdit.Id < 1) {
      this.resetParams();
      EntradaSalidaComponent._this.disabledBotton(true);
    }

    switch (event.args.item.value) {
      //ocultamos opciones para seleccion "zonas admin"
      case TiposMaster.ZONAADMIN:
        this.hiddenTipoZonas = true;
        this.hiddenGeometria = true;
        this.hiddenInputSearch = false;
        this.hiddenELementContainer = true;
        this.hiddenRadius = true;
        this.hiddenDrawButton = true;
        this.zonaEdit.TipoGeo = 1; // Polígono
        this.zonaEdit.TipoBDT = 0;
        break;
      //ocultamos opciones para seleccion "manual"
      case TiposMaster.MANUAL:
        this.hiddenTipoZonas = true;
        this.hiddenGeometria = false;
        this.hiddenInputSearch = true;
        this.hiddenELementContainer = true;
        this.hiddenRadius = false;
        this.hiddenDrawButton = false;
        this.zonaEdit.TipoBDT = 0;
        if (this.zonaEdit.Id < 1) {
          this.zonaEdit.TipoGeo = 0; // Círculo
        }
        break;
      //ocultamos opciones para seleccion "Asociada a elem"
      case TiposMaster.ELEMENTO:
        this.hiddenTipoZonas = true;
        this.hiddenGeometria = true;
        this.hiddenInputSearch = true;
        this.hiddenELementContainer = false;
        this.hiddenRadius = true;
        this.hiddenDrawButton = true;
        this.subscriptionOnMarkerClick = this.map.subscribeOnMarkerClick(this, this.onMarkerClick);
        this.zonaEdit.TipoGeo = 0; // Círculo
        this.zonaEdit.TipoBDT = 0;
        break;
      //ocultamos opciones para seleccion "edificios e instal"
      case TiposMaster.EDIFICIOS:
        if (this.zonaEdit.Id > 1 && this.zonaEdit.TipoBDT > 0) {
          this.zonaEdit.TipoBDT = this.cbTiposZonas.getSelectedItem().value;
        } else {
          this.cbTiposZonas.selectIndex(0);
        }
        this.hiddenTipoZonas = false;
        this.hiddenGeometria = false;
        this.hiddenInputSearch = true;
        this.hiddenELementContainer = true;
        this.hiddenRadius = false;
        this.hiddenDrawButton = false;
        if (this.zonaEdit.Id < 1) {
          this.zonaEdit.TipoGeo = 0; // Círculo
        }
        break;
    }
  }

  async onChangeInput(event) {
    let input = event.target.value;
    this.areas.clear();
    this.zonaEdit.Nombre = null;
    //borrar polígono del mapa
    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
    }
    //si key está indefinido, significa que se ha seleccionado un elemento de la lista
    if (event.key && input != '') {
      clearTimeout(this.timer);
      await this.getPromiseTimeOut();
      this.getAdminAreas(input);
    }
  }

  async getAdminAreas(searchString: string = '') {
    this.areas.clear();
    let adminAreas = await this.cartoService.searchAdminAreas(searchString);
    adminAreas.forEach(area => {
      this.areas.set(area.id, area.name + ', ' + area.prov_name);
    });
  }

  async getPromiseTimeOut() {
    return new Promise(resolve => {
      this.timer = setTimeout(() => {
        clearTimeout(this.timer);
        resolve(1);
      }, 500);
    });
  }
  //cuando se selecciona una opción de la búsqueda de zona administrativa
  async onSelectInput(option) {
    if (option.target.value !== "") {
      const id = this.getKey(option.target.value);
      EntradaSalidaComponent._this.disabledBotton(false);
      this.areaDetail = await this.cartoService.getAreaDetail(id);
      this.drawPolygon(this.areaDetail);
      this.zonaEdit.Area = Math.round(Number(this.areaDetail.properties.surface));
      this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
      this.zonaEdit.Nombre = this.areaDetail.properties.name;
    } else {
      EntradaSalidaComponent._this.disabledBotton(true);
    }
  }

  //buscar key a partir de un value
  getKey(value) {
    return [...this.areas].find(([key, val]) => val == value)[0];
  }

  drawPolygon(area) {
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
    }
    if (this.polygonGeoJson) {
      this.map.removeGeoJson(this.polygonGeoJson);
      this.polygonGeoJson = null;
    }

    this.polygonGeoJson = this.map.addGeoJson({
      geometry: area.geometry,
      type: 'Feature'
    }, {
      dataModel: this.zonaEdit,
      content: this.zonaEdit.Nombre,
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 1,
      fillColor: '#0000ff',
      fillOpacity: 0.1,
      editable: false
    });
    if (this.zonaEdit) {
      this.zonaEdit.TipoGeo = 1;
      this.zonaEdit.Geometria = area;
    }
    this.centeredPolygons(area);
  }

  centeredPolygons(geoJson) {
    let arrLat = [];
    let arrLng = [];
    let arrayCoordenadas = geoJson.geometry.coordinates;
    arrayCoordenadas.forEach(polygon => {
      polygon[0].forEach(coord => {
        arrLat.push(coord[1]);
        arrLng.push(coord[0]);
      })
    });
    //calcular máximos y minimos para centar ppolígono en el mapa
    let arrLatMax = Math.max(...arrLat);
    let arrLatMin = Math.min(...arrLat);
    let arrLngmax = Math.max(...arrLng);
    let arrLngMin = Math.min(...arrLng);
    let coordMax = new MapLatLng(arrLatMax, arrLngmax);
    let coordMin = new MapLatLng(arrLatMin, arrLngMin);
    let mapBounds = new MapBounds(coordMin, coordMax);
    //funcion que centra el mapa a un area
    this.map.fitTo(mapBounds);
  }

  //al cambiar de selección,reiniciar datos que hayan podido cambiar
  resetParams() {
    this.map.setMousePointer('');
    this.superficie = 0;
    this.zonaEdit.Nombre = null;
    this.zonaEdit.Nombre = '';
    this.stringSearch = '';
    this.infoElement = null;
    this.infoMarker = null;
    this.infoNombre = null;
    if (this.subscriptionOnMapClick) this.subscriptionOnMapClick.unsubscribe();
    if (this.subscriptionOnMarkerClick) this.subscriptionOnMarkerClick.unsubscribe();
    if (this.circle) {
      this.map.removeCircle(this.circle);
      this.circle = null;
    }
    if (this.polygon) this.map.removePolygon(this.polygon);
    if (this.polygonGeoJson) this.map.removeGeoJson(this.polygonGeoJson);
  }

  //ordenar la lista de resulados de búsqueda en el orden original
  public keepOriginalOrder(a, b) {
    return a.key;
  }

  // Cuando se cambia el tipo de geometría
  onChangeTipoGeo(event: any) {
    this.zonaEdit.TipoGeo = event.args.item.value;
    if (this.zonaEdit.Id < 1) {
      if (this.circle) {
        this.map.removeCircle(this.circle)
        this.circle = null;
      }
      if (this.polygon) {
        this.map.removePolygon(this.polygon);
        this.polygon = null;
      }
    }
  }

  // Cuando se modifica el radio del círculo sobre la cartografía
  onChangeRadiusOnMap(_this: ZonesComponent, circle: MapCircle) {
    _this.zonaEdit.Radio = circle.radius;
  }

  // Cuando se modifica el radio del círculo sobre la cartografía
  onChangePolygonOnMap(_this: ZonesComponent, point: MapPolygonPoint) {
    _this.calcArea();
    _this.superficie = NumberUtils.format(_this.zonaEdit.Area, 0);
  }

  // Cuando se modifica el radio del círculo en el formulario
  onChangeRadius() {
    if (this.circle) {
      this.circle.setRadius(Number.parseInt('' + this.zonaEdit.Radio));
    }
  }

  // Cuando se pulsa el botón para dibujar una zona nueva o para modificarla
  onDibujarZona(event: any) {
    if (!this.canEdit) {
      return;
    }
    this.zonaEdit.Radio = Number.parseInt('' + this.zonaEdit.Radio);
    if (this.zonaEdit.TipoGeo === 0 && this.zonaEdit.Radio < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_radio', 2000);
      return;
    }
    if (this.zonaEdit.TipoGeo === 0) {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_punto_cartografia', 2000);
    } else {
      MainComponent.getInstance().showInfo('ATENCION', 'Seleccione_puntos_poligono_cartografia', 2000);
    }
    // Me subscribo al evento click sobre el mapa sólo si es una zona nueva
    if (this.zonaEdit.Id < 1) {
      // Cambio el puntero del ratón sobre el mapa
      // this.map.setMousePointer('assets/images/center.png');
      if (this.subscriptionOnMapClick) {
        this.subscriptionOnMapClick.unsubscribe();
      }
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onMapClick);
    } else {
      // Dibujo las zonas sobre el mapa
      this.drawZone();
    }
    if (this.circle) {
      this.circle.setRadius(this.zonaEdit.Radio);
    }
    this.formEditZona.collapse();
    // if (this.map.zoom < 15) {
    //   this.map.setZoom(15);
    // }
  }

  drawZone() {
    // Pinto sobre el mapa la zona
    if (this.circle) {
      this.map.removeCircle(this.circle)
      this.circle = null;

    }
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
    }
    if (this.zonaEdit.TipoGeo === 0) { // Círculo
      if (this.zonaEdit.Lat > 0) {
        this.zonaEdit.Geometria = {};
        this.circle = this.map.addCircle({
          dataModel: this.zonaEdit,
          content: this.zonaEdit.Nombre,
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(this.zonaEdit.Lat, this.zonaEdit.Lng),
          radius: this.zonaEdit.Radio,
          draggable: true,
          editable: true
        });
        this.map.setCenter(new MapLatLng(this.zonaEdit.Lat, this.zonaEdit.Lng));
      }
    } else { // Polígono
      this.superficie = NumberUtils.format(this.zonaEdit.Area, 0);
      //cargar polígono en el mapa a partir de un geojson
      this.polygon = this.map.addPolygonsFromGeoJson(this.zonaEdit.Geometria, true, {
        dataModel: this.zonaEdit,
        content: this.zonaEdit.Nombre,
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        zIndex: 100,
      })[0];
      this.centeredPolygons(this.zonaEdit.Geometria);
    }
  }

  deleteZones() {
    if (this.zonaSelec) {
      switch (this.zonaSelec.TipoGeo) {
        case 0: // Círculo
          if (this.circle) {
            this.map.removeCircle(this.circle);
            this.circle = null;
          }
          break;
        case 1: // Polígono
          if (this.polygon) {
            this.map.removePolygon(this.polygon);
            this.polygon = null;
          }
          if (this.polygonGeoJson) {
            this.map.removeGeoJson(this.polygonGeoJson);
            this.polygonGeoJson = null;
          }
          break;
      }
    }
  }

  // Cuando se pincha sobre el mapa, este método sólo se llama cuando es una zona nueva
  onMapClick(_this: ZonesComponent, position: MapLatLng) {
    // Calculo la posición que corresponde con el centro del icono que he puesto como puntero del ratón
    const point = _this.map.latLngToScreenPoint(position);
    // point.x += 16; // El icono es de 32x32
    // point.y += 16;
    const newPosition = _this.map.screenPointToLatLng(point);
    switch (_this.zonaEdit.TipoGeo) {
      case 0: // Círculo
        _this.zonaEdit.Radio = Number.parseInt('' + _this.zonaEdit.Radio);
        _this.zonaEdit.Lat = newPosition.lat;
        _this.zonaEdit.Lng = newPosition.lng;
        if (_this.circle) {
          _this.map.removeCircle(_this.circle);
        }
        _this.circle = _this.map.addCircle({
          dataModel: _this.zonaEdit,
          content: _this.zonaEdit.Nombre,
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          position: new MapLatLng(_this.zonaEdit.Lat, _this.zonaEdit.Lng),
          radius: _this.zonaEdit.Radio,
          draggable: true,
          editable: true
        });
        break;
      case 1: // Polígono
        if (!_this.polygon) {
          _this.polygon = _this.map.addPolygon({
            dataModel: _this.zonaEdit,
            content: _this.zonaEdit.Nombre,
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            editable: true,
            zIndex: 100,
          });
        }
        _this.map.addPolygonPoint(_this.polygon, {
          position: new MapLatLng(newPosition.lat, newPosition.lng)
        });
        _this.polygonPoints.push(new MapLatLng(newPosition.lat, newPosition.lng));
        _this.calcArea();
        _this.superficie = NumberUtils.format(_this.zonaEdit.Area, 0);
        break;
    }
  }

  onMarkerClick(_this: any, marker: MapMarker) {
    // Compruebo que se trata de un elemento
    if (marker.dataModel.hasOwnProperty('RequiereVolum')) {
      _this.zonaEdit.ElementoId = marker.dataModel.Id;
      _this.infoMarker = marker.icon;
      _this.infoElement = marker.dataModel.Nombre;
      _this.infoNombre = marker.dataModel.Equipamiento.Elemento.Nombre;
      _this.zonaEdit.Radio = marker.dataModel.Equipamiento.AreaInfluencia;
      _this.zonaEdit.Nombre = marker.dataModel.Nombre;
      if (_this.circle) {
        _this.map.removeCircle(_this.circle);
      }
      _this.circle = _this.map.addCircle({
        dataModel: _this.zonaEdit,
        content: _this.zonaEdit.Nombre,
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 1,
        fillColor: '#0000ff',
        fillOpacity: 0.1,
        position: new MapLatLng(marker.position.lat, marker.position.lng),
        radius: marker.dataModel.Equipamiento.AreaInfluencia,
        draggable: false,
        editable: false
      });
    }
  }

  // Borrar una zona
  onBorrarZona(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.checkSelect.length > 0) {
      // si hay varias zonas seleccionadas, se borran las zonas seleccionadas
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quieres_borrar_zonas') + ':' + this.checkSelect.map(zona => zona.Nombre).join(', ') + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzZIndex: 999,
        nzOnOk: async () => {
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          //creamos una lista de IDs que queremos eliminar.
          const idsParaEliminar = this.checkSelect.map(zona => zona.Id);
          // filtramos la lista de zonas para quitar las zonas que se van a eliminar.
          this.zonas = this.zonas.filter(z => !idsParaEliminar.includes(z.Id));
          await Promise.all(this.checkSelect.map(zona => {
            return this.zonesService.deleteZona(zona.Id);
          }));

          this.deleteZones();
          this.checkSelect = [];
          this.dataSource.localdata = this.zonas;
          this.gridZonas.updatebounddata();
          this.gridZonas.unselectrow(this.gridZonas.getselectedrowindex());
          Utils.renderSizeGrid(this.gridZonas);
        }
      });
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  async onGuardarZona(): Promise<void> {
    if (!this.canEdit) {
      return;
    }
    if (this.zonaEdit.Nombre === null || this.zonaEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    if (this.zonaEdit.TipoGeo === 0) {
      if (!this.circle) {
        MainComponent.getInstance().showError('ATENCION', 'Seleccione_punto_cartografia', 2000);
        return;
      }
      this.zonaEdit.Lat = this.circle.center.lat;
      this.zonaEdit.Lng = this.circle.center.lng;
      this.zonaEdit.Puntos = null;
      this.zonaEdit.Area = 0;
      if (this.zonaEdit.Lat === 0 && this.zonaEdit.Lng === 0) {
        MainComponent.getInstance().showError('ATENCION', 'Seleccione_punto_cartografia', 2000);
        return;
      }
      if (this.zonaEdit.Radio < 1) {
        MainComponent.getInstance().showError('ATENCION', 'Introduzca_radio', 2000);
        return;
      }
      this.zonaEdit.Geometria = null;
    } else {
      this.zonaEdit.Radio = 0;
      this.zonaEdit.Lat = 0;
      this.zonaEdit.Lng = 0;
      if (this.selectTipoMaster !== TiposMaster.ZONAADMIN) {
        if (!this.polygon) {
          MainComponent.getInstance().showError('ATENCION', 'Defina_poligono', 2000);
          return;
        }
        this.zonaEdit.Geometria = this.polygon2Geojson([this.polygon]);
        this.calcArea();
      } else {
        if (!this.zonaEdit.Geometria) {
          MainComponent.getInstance().showError('ATENCION', 'Defina_poligono', 2000);
          return;
        }
      }
    }
    return await this.saveZona();
  }

  //Crear multipolígono geojson a partir de polígonos
  polygon2Geojson(polygons: MapPolygon[]) {
    let geometry = { 'type': 'MultiPolygon', 'coordinates': [] };
    let geojson = { 'geometry': geometry };
    polygons.forEach(polygon => {
      let arrPoints = [];
      polygon.points.forEach(polygonPoint => {
        arrPoints.push([polygonPoint.point.lng, polygonPoint.point.lat]);
      });
      if (this.polygonPoints.length === 0) {
        this.polygonPoints = [];
        const coordenadas = this.zonaEdit.Geometria.geometry.coordinates[0][0];
        coordenadas.forEach((element: any, i) => {
          if (i < coordenadas.length - 1) {
            this.polygonPoints.push(new MapLatLng(element[1], element[0]));
          }
        });
      }
      //la primera y la última coordenada han de ser la misma, eso significa que el poligono está cerrado
      if (this.polygonPoints[0].lat !== this.polygonPoints[this.polygonPoints.length - 1].lat ||
        this.polygonPoints[0].lng !== this.polygonPoints[this.polygonPoints.length - 1].lng) {
        arrPoints.push(arrPoints[0]);
      }
      geometry.coordinates.push([arrPoints]);
    });
    geojson.geometry = geometry;
    geometry.type = 'MultiPolygon';
    return geojson;
  }

  // Calcula el área del polígono
  calcArea() {
    this.zonaEdit.Area = 0;
    for (let i = 0; i < this.polygon.points.length - 1; i++) {
      const p1 = this.polygon.points[i].point;
      const p2 = this.polygon.points[i + 1].point;
      this.zonaEdit.Area += this.degToRad(p2.lng - p1.lng) *
        (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    }
    // Invento un último punto igual al primero
    const p1 = this.polygon.points[this.polygon.points.length - 1].point;
    const p2 = this.polygon.points[0].point;
    this.zonaEdit.Area += this.degToRad(p2.lng - p1.lng) *
      (2 + Math.sin(this.degToRad(p1.lat)) + Math.sin(this.degToRad(p2.lat)));
    this.zonaEdit.Area = Math.abs(this.zonaEdit.Area * 6378137 * 6378137 / 2.0);
    this.zonaEdit.Area = Number.parseInt(this.zonaEdit.Area.toFixed(2));
  }

  // Pasa grados a radianes
  degToRad(deg: number): number {
    return deg * Math.PI / 180.0;
  }

  // Guardo la zona
  async saveZona() {
    if (!this.canEdit) {
      return;
    }
    const response = await this.zonesService.saveZona(this.zonaEdit);

    if (response) {
      this.idZonaEntrada = response.Id;
      // Elimino los agregados por que dan problemas de referencia circular
      delete response.map;
      delete response.circle;
      delete response.polygons;
      if (this.zonaEdit.Id === 0) {
        this.zonas.push(response);
        // Selecciono la nueva zona como visible
        this.select.push({ id: response.Id });
      } else {
        for (let i = 0; i < this.zonas.length; i++) {
          if (this.zonas[i].Id === this.zonaEdit.Id) {
            this.zonas[i] = Utils.clone(response);
            break;
          }
        }
      }
      this.zonaEdit = Utils.clone(response);
      this.zonaSelec = Utils.clone(this.zonaEdit);
      this.dataSource.localdata = this.zonas;
      this.gridZonas.updatebounddata();
      this.gridZonas.refreshdata();
      MainComponent.getInstance().showInfo('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    if (!this.creandoZonaMasEntradas) {
      this.formEditZona.close();
    } else {
      this.formEditZona.open();
    }

  }
  // Capturo las pulsaciones de teclado
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Escape' && this.mostrarEditZona) {
      // En el caso de los polígonos con ESC borramos el último punto añadido
      if (this.zonaEdit.Id > 0 && this.polygonPoints.length < 1) {
        this.polygonPoints = [];
        const coordenadas = this.zonaEdit.Geometria.geometry.coordinates[0][0];
        coordenadas.forEach((element: any, i) => {
          if (i < coordenadas.length - 1) {
            this.polygonPoints.push(new MapLatLng(element[1], element[0]));
          }
        });
      }
      if (this.polygonPoints && this.polygonPoints.length > 0) {
        this.polygonPoints.pop();
        this.map.removePolygon(this.polygon);
        this.polygon = this.map.addPolygon({
          strokeColor: '#ff0000',
          strokeOpacity: 0.3,
          strokeWeight: 1,
          fillColor: '#0000ff',
          fillOpacity: 0.1,
          editable: true,
          zIndex: 100,
        });

        this.polygonPoints.forEach(punto => {
          this.map.addPolygonPoint(this.polygon, {
            content: this.zonaEdit.Nombre,
            position: punto
          });
        });
      }
    }

    if (event.key === 'Enter' && this.mostrarEditZona) {
      if (this.circle) {
        this.circle.setRadius(this.zonaEdit.Radio);
      }
    }
    // capturo la tecla control para añadir zonas al array de zonas seleccionadas
    if (event.key === 'Control') {
    }
  }

  onChecked(event: any) {
    switch (event.args.rowindex.length) {
      case 0:
        this.checkSelect = [];
        break;
      default:
        let rows: any[] = this.gridZonas.getrows();
        let idRow: number[] = this.gridZonas.getselectedrowindexes();
        rows.forEach((element, index) => {
          this.zonaSelec = this.zonas.find((elem) => elem.Id == element.id && idRow.includes(element.boundindex));
          if (this.zonaSelec) {
            this.checkSelect.push(this.zonaSelec);
          }
        });
        this.checkSelect = Utils.onChangeArray(this.checkSelect);
        break;
    }

    // si hay mas de un check seleccionado, se deshabilita el botón de editar
    if (this.checkSelect.length > 1) {
      this.btnEdit.disabled(true)
    } else {
      this.btnEdit.disabled(false)
      this.zonaSelec = this.checkSelect[0];
    }
  }

  // deseleccion de elementos en el grid modelos
  onUnselect(event: any) {
    switch (event.args.rowindex.length) {
      case 0:
        this.checkSelect = [];
        break;

      default:
        let row: any = this.gridZonas.getrowdatabyid(event.args.rowindex);
        this.checkSelect = this.checkSelect.filter(element => element.Id !== row.id);
        if (this.checkSelect.length == 1) {
          this.btnEdit.disabled(false);
          this.zonaSelec = this.checkSelect[0];
        } else if (this.checkSelect.length == 0) {
          this.zonaSelec = null;
        }
        break;
    }
  }

  onExportar(event: any) {
    if (this.gridZonas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.gridZonas.exportdata('json');
      let datos = JSON.parse(json);
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Zonas') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint(event: any) {
    if (this.gridZonas.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.gridZonas.hidecolumn('Botones');
      let gridContent = this.gridZonas.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.gridZonas.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }

}
