import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CustomForms } from '../../forms/custom-forms';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';

@Component({
  selector: 'app-ciudadanos-listado-tarjetas',
  templateUrl: './ciudadanos-listado-tarjetas.component.html',
  styleUrls: ['./ciudadanos-listado-tarjetas.component.css']
})
export class CiudadanosListadoTarjetasComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridTarjetas') gridTarjetas: jqxGridComponent;

  private componentRef = null;
  public theme = environment.tema;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  tarjetas: TarjetaModel[] = [];
  ciudadano;

  renderMasterColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">'+this.translate('Administrador')+'</div>';
    }
    else{
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">'+this.translate('Blanca')+'</div>';
    }
  };

  public columnsTarjetas = [
    { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat', editable: false, width: 'auto' },
    { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', editable: false, width: 'auto' },
    { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'master', cellsrenderer: this.renderMasterColumn, width: 'auto' },
  ];

  public sourceTarjetas = {
    datatype: 'json',
    datafields: [
      { name:'nsMovisat', type: 'string', map: 'nsMovisat' },
      { name:'descripcion', type: 'string', map: 'descripcion' },
      { name:'master', type: 'string', map: 'master' }
    ],
    localdata: this.tarjetas
  };

  public dataAdapterTarjetas = new jqx.dataAdapter(this.sourceTarjetas);

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private ssoService: SsoService) {
    super();
  }

  public init(componentRef: any, ciudadano: CiudadanoModel): void {
    this.componentRef = componentRef;
    this.ciudadano = ciudadano;
    this.tarjetas = ciudadano.tarjetas;
    this.sourceTarjetas.localdata = this.tarjetas;
    this.dataAdapterTarjetas.dataBind();
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(this.translate('Tarjetas') + ' ' + this.ciudadano.nombre + ' ' + this.ciudadano.apellidos);
    this.addCustomForm(this.form);
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }
}
