import {
  Component,
  OnInit,
  ViewChild,
} from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { JqxDomService } from 'src/app/services/jqwidgets/jqx-dom.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';

import { ElementsDetailComponent } from '../elements-detail/elements-detail.component';
import { Utils } from 'src/app/utils/utils';

@Component({
  selector: 'app-elements-catalog',
  templateUrl: './elements-catalog.component.html',
  styleUrls: ['./elements-catalog.component.css'],
})
export class ElementsCatalogComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;

  public static _this: ElementsCatalogComponent;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  catEquipamiento: BdtCatalogoEquipamientoModel[] = [];
  private catEquip = new Map<number, BdtCatalogoEquipamientoModel>();
  private select: any = [];
  private fromIAConfig = false;
  private detailComponents: any[] = [];
  mapWidth: number;
  mapHeight: number;

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  public columns = [
    {
      text: 'Id',
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'id',
      width: 60,
      hidden: true,
    },
    {
      text: 'U.Max',
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'unidadesMax',
      width: 75,
      align: 'center',
      cellsalign: 'center',
      //hidden: true,
      cellsrenderer: this.numberrenderer,
    },
    {
      text: AppComponent.translate('Unidades'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'unidades',
      width: 75,
      align: 'center',
      cellsalign: 'center',
      //hidden: true,
      aggregates: [
        {
          Total: function (aggregatedValue, currentValue: number) {
            return currentValue
              ? aggregatedValue + currentValue
              : aggregatedValue;
          },
        },
      ],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring =
            '<div style="text-align: center; margin-left: 4px;">' +
            aggregates['Total'] +
            ' </div>';
        }
        return renderstring;
      },
      cellsrenderer: this.numberrenderer,
    },
    {
      text: '',
      columntype: 'image',
      datafield: 'imagen',
      width: 16,
      filterable: false,
      sortable: false,
      cellsrenderer: this.imagerenderer,
    },
    {
      text: AppComponent.translate('Elemento'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'elemento',
      width: 130,
      aggregates: [
        {
          Total: function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          },
        },
      ],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring =
            '<div style="text-align: left; margin-left: 4px;">' +
            AppComponent.translate('Equipamientos') +
            ': ' +
            aggregates['Total'] +
            ' </div>';
        }
        return renderstring;
      },
    },
    {
      text: AppComponent.translate('Marca'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      datafield: 'marca',
      width: 150,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Modelo'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      datafield: 'modelo',
      width: 210,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Tipo'),
      columntype: 'textbox',
      filtertype: 'checkedlist',
      datafield: 'tipo',
      width: 180,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Residuo'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'residuo',
      width: 250,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Capacidad'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'capacidad-lit',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Carga_maxima'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'cma',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Altura_libre'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'altura',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrendererDecimales
    },
    {
      text: AppComponent.translate('Ancho'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'ancho',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrendererDecimales
    },
    {
      text: AppComponent.translate('Largo'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'largo',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrendererDecimales
    },
    {
      text: AppComponent.translate('Tara'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'tara',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Coste'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'coste',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Años_vida'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'anyos',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Precio_compra'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'precio',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Año_amortizacion'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'amortizacion',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Mantenimiento_actual'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'mantenimiento',
      width: 80,
      hidden: this.elemService.elemGenericos,
      cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Radio'),
      columntype: 'textbox',
      filtertype: 'number',
      datafield: 'radio',
      width: 80,
      cellsrenderer: this.numberrenderer
    },
    // {
    //   text: '', datafield: 'mas', columntype: 'button', width: 6,
    //   cellsrenderer: (): string => {
    //     return '+';
    //   },
    //   buttonclick: (row: number): void => {
    //     const dataRecord: any = this.grid.getrowdata(row);
    //     let i = 0;
    //     // this.myWindow.position({ x: 68, y: 368 });
    //     // this.myWindow.open();
    //   }
    // }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private bdtService: BdtService,
    private configService: ConfigService,
    public elemService: ElementsService,
    private jqxDomService: JqxDomService
  ) {
    super();
    ElementsCatalogComponent._this = this;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(
      this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2)
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    if (MainComponent.getInstance().ssoTicket.Aplicacion.Id === 14) {
      this.form.setTitle(AppComponent.translate('Catalogo_BDT'));
    } else {
      if (!this.fromIAConfig) {
        this.form.setTitle(AppComponent.translate('Catalogo_elementos_BDT'));
      } else {
        this.form.setTitle(
          AppComponent.translate('Catalogo_elementos_BDT') +
          ' - ' +
          AppComponent.translate('IA')
        );
      }
    }
    // Recupero el filtro guardado
    if (!this.fromIAConfig) {
      this.select = await this.configService.getUsuEmpApp(
        'elem-model-filter',
        null
      );
    } else {
      this.select = await this.configService.getEmp('elem-model-IA', null);
    }
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
    // Recupero el catálogo de elementos de la empresa
    this.getCatalogoEquipamiento();
    Utils.renderSizeGrid(this.grid);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, fromIAConfig: boolean) {
    this.componentRef = componentRef;
    this.fromIAConfig = fromIAConfig;
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    ElementsCatalogComponent._this = null;
    // Destruyo los componentes
    this.detailComponents.forEach((cpn) => {
      cpn.destroy();
    });
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onBindingComplete() {
    if (this.grid) {
      const rows = this.grid.getrows();
      if (rows) {
        rows.forEach((row) => {
          if (this.select.find((s) => s.id === row.id) !== undefined) {
            this.grid.selectrow(row.boundindex);
          }
        });
      }
    }
  }

  // Recupera el catálogo de elementos de la empresa
  async getCatalogoEquipamiento() {
    let filterIA: any = await this.configService.getEmp('elem-model-IA', null);
    if (filterIA) {
      filterIA = JSON.parse(filterIA);
    }
    this.loader.open();
    this.catEquipamiento = [];
    const response = await this.bdtService.getCatalogoEquipamiento();
    if (response) {
      response.forEach((cat) => {
        cat.Unidades2 = 0;
        MainComponent.getInstance().elementsList.forEach(elem => {
          if (elem.Equipamiento.Id === cat.Equipamiento.Id) {
            cat.Unidades2++;
          }
        });
        if (!MainComponent.getInstance().isEcoEvolution || this.fromIAConfig) {
          this.catEquip.set(cat.IdEquipamiento, cat);
          this.catEquipamiento.push(cat);
        } else {
          if (
            !filterIA ||
            filterIA.find((s) => s.id === cat.IdEquipamiento) !== undefined
          ) {
            this.catEquip.set(cat.IdEquipamiento, cat);
            this.catEquipamiento.push(cat);
          }
        }
      });
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'IdEquipamiento' },
          { name: 'unidadesMax', type: 'int', map: 'Unidades' },
          { name: 'unidades', type: 'int', map: 'Unidades2' },
          { name: 'imagen', type: 'image', map: 'Equipamiento>Icono' },
          { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
          { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
          {
            name: 'elemento',
            type: 'string',
            map: 'Equipamiento>Elemento>Nombre',
          },
          {
            name: 'tipo',
            type: 'string',
            map: 'Equipamiento>Elemento>Tipo>Nombre',
          },
          {
            name: 'residuo',
            type: 'string',
            map: 'Equipamiento>ResiduosNombres',
          },
          { name: 'capacidad-lit', type: 'int', map: 'Equipamiento>Capacidad', },
          {
            name: 'cma',
            type: 'int',
            map: 'Equipamiento>CargaMaximaAutorizada',
          },
          { name: 'altura', type: 'int', map: 'Equipamiento>AlturaLibre', },
          { name: 'ancho', type: 'int', map: 'Equipamiento>Ancho', },
          { name: 'largo', type: 'int', map: 'Equipamiento>Largo', },
          { name: 'tara', type: 'int', map: 'Equipamiento>Tara', },
          { name: 'coste', type: 'int', map: 'Equipamiento>Coste', },
          { name: 'anyos', type: 'int', map: 'Equipamiento>AnyoVida', },
          { name: 'precio', type: 'int', map: 'Equipamiento>PrecioCompra', },
          {
            name: 'amortizacion',
            type: 'int',
            map: 'Equipamiento>AnyoAmortizacion',
          },
          {
            name: 'mantenimiento',
            type: 'int',
            map: 'Equipamiento>MantenimientoActual',
          },
          { name: 'radio', type: 'int', map: 'Equipamiento>AreaInfluencia' },
        ],
        localdata: this.catEquipamiento,
        sortcolumn: 'marca',
        sortdirection: 'asc',
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
    }

    // const t = setTimeout(() => {
    //   clearTimeout(t);
    //   this.grid.attrColumns.forEach((column: any) => {
    //     this.grid.autoresizecolumn(column.datafield, column.columntype);
    //   });
    // }, 500);

    this.loader.close();
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  imagerenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value && value.length > 50) {
      return (
        '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' +
        value +
        '"/>'
      );
    }
    return '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/elemento.png"/>';
  }

  rowdetailstemplate(index: number): any {
    return {
      rowdetails: '<div class="elem-detail" style="width: 300px;"></div>',
      rowdetailsheight: 380,
    };
  }

  initrowdetails(
    index: number,
    htmlElement: HTMLElement,
    gridElement: any,
    dataRecord: any
  ): void {
    if (htmlElement) {
      const container = document.createElement('div');
      htmlElement.appendChild(container);
      // Añado el componente de detalle y lo inicializo con el id del equipamiento
      const component: any =
        ElementsCatalogComponent._this.jqxDomService.loadComponent(
          ElementsDetailComponent,
          container
        );
      component.componentRef.instance.init(component, dataRecord.id);
      ElementsCatalogComponent._this.detailComponents.push(
        component.componentRef
      );
    }
  }

  onRowExpand(event: any) {
    const args = event.args;
    const details = args.details;
    const rowBoundIndex = args.rowindex;
    setTimeout(() => {
      this.grid.scrolloffset(rowBoundIndex * this.grid.rowsheight(), 0);
    }, 250);
  }
}
