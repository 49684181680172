import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { MovilesSelectModule } from '../moviles-select/moviles-select.module';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';
import { ElementosSelectModule } from '../elementos-select/elementos-select.module';

import { TotalVolumComponent } from './volum/total-volum/total-volum.component';
import { ListadoVolumComponent } from './volum/listado-volum/listado-volum.component';
import { ResumenVolumComponent } from './volum/resumen-volum/resumen-volum.component';
import { ListadoSensorComponent } from './sensor/listado-sensor/listado-sensor.component';
import { ListadoAlarmasComponent } from './alarmas/listado-alarmas/listado-alarmas.component';
import { ListadoAlarmaComponent } from './cerraduras/listado-alarma/listado-alarma.component';
import { ListadoMovilidadComponent } from './movilidad/listado-movilidad/listado-movilidad.component';
import { ListadoCerraduraComponent } from './cerraduras/listado-cerradura/listado-cerradura.component';
import { ListadoIdentificacionComponent } from './cerraduras/listado-identificacion/listado-identificacion.component';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { HistoricoEnviosDispIdentificadorComponent } from './historico-envios-disp-identificador/historico-envios-disp-identificador.component';
import { jqxCheckBoxModule } from 'jqwidgets-ng/jqxcheckbox';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { FormsModule } from '@angular/forms';
import { jqxTabsModule } from 'jqwidgets-ng/jqxtabs';
import { MapModule } from 'movisat-maps';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [
    TotalVolumComponent,
    ListadoVolumComponent,
    ResumenVolumComponent,
    ListadoSensorComponent,
    ListadoAlarmaComponent,
    ListadoAlarmasComponent,
    ListadoMovilidadComponent,
    ListadoCerraduraComponent,
    ListadoIdentificacionComponent,
    HistoricoEnviosDispIdentificadorComponent,

  ],
  imports: [
    CommonModule,
    FormsModule,
    jqxGridModule,
    jqxWindowModule,
    jqxLoaderModule,
    jqxButtonModule,
    jqxInputModule,
    jqxSplitterModule,
    PeriodoSelectModule,
    HeaderModule,
    MovilesSelectModule,
    jqxDropDownListModule,
    ElementosSelectModule,
    jqxNumberInputModule,
    jqxCheckBoxModule,
    jqxTabsModule,
    MapModule
  ]
})
export class ReportsModule { }
