import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { AccionModel } from 'src/app/services/auditoria/models/accion.model';
import { Utils } from 'src/app/utils/utils';
import { SsoService } from 'src/app/services/sso/sso.service';

@Component({
  selector: 'app-acciones',
  templateUrl: './acciones.component.html',
  styleUrls: ['./acciones.component.css']
})
export class AccionesComponent extends CustomForms implements OnInit {

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');
  public accion: AccionModel = new AccionModel();
  private componentRef = null;
  public theme = environment.tema;
  dataAdapter: any;
  dataSource: any;
  row: number;

  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('formAccion') formAccion: jqxWindowComponent;


  acciones: AccionModel[] = [];
  viewForm: boolean = false;

  constructor(private auditoriaService: AuditoriaService, private modal: NzModalService, private ssoService: SsoService) {
    super();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.getAcciones();
  }
  /**Inicializa el componente
  */
  ngAfterViewInit() {
    this.form.setTitle(AppComponent.translate('Acciones'));
    this.formAccion.setTitle(AppComponent.translate('Acciones'));
    this.addCustomForm(this.form);
    this.addCustomForm(this.formAccion);
    Utils.renderSizeGrid(this.myGrid);
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  //Configuración del grid
  columns: any = [
    {
      text: this.translate('Nombre'), datafield: 'Nombre', editable: false, type: 'string',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: this.translate('Observaciones'), datafield: 'Observaciones', editable: false, type: 'string' },
  ]

  //Evento click en el grid
  onRowclick(event: any): void {
    this.row = event.args.row.bounddata.Id;
  }

  onRowdoubleclick(event: any) {
    this.row = event.args.row.bounddata.Id;
    this.onEditarAccion(event);
  }

  //Carga de datos
  async getAcciones() {
    this.acciones = await this.auditoriaService.getAcciones();
    if (this.acciones) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'Id' },
          { name: 'Nombre', type: 'string', map: 'Nombre' },
          { name: 'Observaciones', type: 'string', map: 'Observaciones' },
        ],
        localdata: this.acciones,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    }
  }

  //Control de los datos del formulario
  controlForm(): boolean {
    if (!this.accion.Nombre) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
    } else {
      return true;
    }
  }

  //Acciones botones
  onCrearAccion(event) {
    this.viewForm = true;
    this.accion = new AccionModel();
    this.formAccion.open(); //Abre el formulario
    this.form.collapse();
  }

  async onSaveAccion(event) {
    // Comprobamos que los datos del formulario son correctos
    if (!this.controlForm()) {
      return;
    } else {
      // Si el id de la acción es distinto de null, es que estamos editando
      if (this.accion.Id) {
        // filtramos el array de acciones para eliminar la acción que estamos editando
        this.acciones = this.acciones.filter(accion => (accion.Id !== this.accion.Id));
        this.accion = await this.auditoriaService.addAccion(this.accion);
        // Si el id de la acción es null, es que estamos creando
      } else {
        let accion: any = {
          Nombre: this.accion.Nombre,
          Observaciones: this.accion.Observaciones
        }
        this.accion = await this.auditoriaService.addAccion(accion);
      }
      if (this.accion) {
        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_almacenado',
          2000
        );
        this.acciones.push(this.accion);
        this.myGrid.updatebounddata();
        this.formAccion.close();
        this.form.expand();
      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }
  }

  onEditarAccion(event) {
    this.viewForm = true;
    // recojo el id de la acción seleccionada
    let id = this.row;
    // si no hay ninguna acción seleccionada, salgo
    if (!id) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    // busco la acción en el array de acciones
    let accion = this.acciones.find(x => x.Id == id);
    // si la acción existe, la cargo en el formulario
    if (accion) {
      this.accion = accion;
    }
    // Abro el formulario
    this.formAccion.open(); //Abre el formulario
    this.form.collapse();
  }

  onBorrarAccion(event) {
    this.viewForm = false;
    // recojo el id de la acción seleccionada
    let id = this.row;
    // si no hay ninguna acción seleccionada, salgo
    if (id == null || id == undefined) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    let elem = this.acciones.find(elem => elem.Id == id);
    if (elem) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_elemento') + ': ' + elem.Nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          await this.auditoriaService.delAccion(elem.Id);
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          this.acciones.splice(this.acciones.indexOf(elem), 1);
          this.myGrid.updatebounddata();
        }
      });
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onCloseForm() {
    this.form.expand();
    this.viewForm = false;
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }
}
