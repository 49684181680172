<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #form [theme]="environment.tema" [width]="1100" [height]="492" [zIndex]="50" [position]="getFormPos(form)"
  [autoOpen]="true" [zIndex]="90" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_alarmas_geo')}}
  </div>
  <div class="form" style="float:left; overflow: hidden;">
    <div style="float: left; margin-top: 2px; height: '100%'; width: '266px';">
      <jqxSplitter #mainSplitter [panels]="[{ min: 150, size: 220 }, { min: 815 }]" [height]="450" [width]="1085">
        <div>
          <div class="form">
            <span style="margin-left: 4px;">{{translate('Subflotas')}}</span>
          </div>
          <div style="height: 440px;">
            <app-moviles-select (passArray)="movilesSelected($event)"></app-moviles-select>
          </div>
        </div>
        <div>
          <div style="height: 406px;">
            <div>
              <app-header #header
                [exportar]="true" [periodo]="true" [filtro]="true"
                [noTrimestre]="true" [noSemestre]="true" [noLibre]="true"
                (eventExportar)="onExportar()" (eventFilter)="onAceptar()">
              </app-header>
            </div>
            <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
              [source]="dataAdapter" [columnsresize]="true" [sortable]="true" [showrowlines]="false"
              [showcolumnlines]="true" [columns]="columns" [altrows]="true" [enabletooltips]="true"
              [filterable]="false" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true"
              [rowsheight]="20" [columnsheight]="20" [localization]="langGrid"
              [columngroups]="columngroups" [groupable]="true">
            </jqxGrid>
          </div>
        </div>
      </jqxSplitter>
    </div>
  </div>
</jqxWindow>
