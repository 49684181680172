<div class="container" id="toolbar">

  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" (change)="toggleChange($event)"
    [style.visibility]="hiddenButton ? 'hidden' : 'visible'" multiple>
    <mat-button-toggle class="button-toogle" value="home" #home>
      <mat-icon>
        <span class="material-icons-outlined" [title]="translate('ir_casa')">home</span>
      </mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="circle" #circle>
      <mat-icon>
        <span class="material-icons-outlined" [title]="translate('Crear_circulo')">circle</span>
      </mat-icon>
    </mat-button-toggle>
    <mat-button-toggle value="polyline" #polygon>
      <mat-icon>
        <span class="material-icons-outlined" [title]="translate('Crear_poligono')">polyline</span>
      </mat-icon>
    </mat-button-toggle>
  </mat-button-toggle-group>
  <button [matBadge]="badgeButton" matBadgePosition="before" matBadgeColor="primary" mat-raised-button color="primary"
    [title]="translate('herramientas_cartografia')" (click)="onClickTools()">
    <mat-icon>
      <span class="material-icons-outlined">construction</span>
    </mat-icon>
  </button>
</div>
<jqxWindow
  #form
  [theme]="environment.tema"
  [width]="300"
  [showCloseButton]="true"
  [showCollapseButton]="true"
  [autoOpen]="false"
  [zIndex]="90"
  [height]="300"
  [resizable]="false"
  (onClose)="onClose()"
  [position]="getFormPos(form)"
>
  <div class="formHeader" style="overflow-x: hidden;">
    {{ translate("Edicion_poligonos") }}
  </div>
  <div class="form">
    <div class="row">
      <p class="p-title">
        <b>{{ translate("Geometria") }}</b>
      </p>
      <p class="subtitle">{{ translate(geometria) }}</p>
    </div>
    <div class="row" style="margin-top: 10px">
      <p class="p-title">
        <b>{{ translate("Nombre") }}</b>
      </p>
      <jqxInput [width]="290" [height]="25" [(ngModel)]="zona.Nombre" style="margin-top: 5px">
      </jqxInput>
    </div>
    <div class="row" style="margin-top: 5px">
      <p class="p-title">
        <b>{{ translate("Observaciones") }}</b>
      </p>
      <jqxInput [width]="290" [height]="25" [(ngModel)]="zona.Observaciones" style="margin-top: 5px">
      </jqxInput>
    </div>
    <div class="row" [hidden]="geometria != 'Circulo'" style="margin-top: 5px">
      <p class="p-title">
        <b>{{ translate("Radio") }}</b>
      </p>
      <jqxInput [width]="50" [height]="25" [(ngModel)]="zona.Radio" (onChange)="onChangeRadius()"
        style="margin-top: 5px">
      </jqxInput>
    </div>
    <div class="row" [hidden]="geometria != 'Poligono'">
      <p class="p-title">
        <b>{{ translate("Superficie") }}</b>
      </p>
      <p class="p-title">{{ superficie }} m<sup>2</sup></p>
    </div>

    <div class="columns" style="margin-top: 14%">
      <jqxButton
        style="float: left; margin-left: 1px"
        [width]="100"
        [height]="26"
        [textImageRelation]="'imageBeforeText'"
        [textPosition]="'left'"
        [imgSrc]="'/assets/images/mas.png'"
        [imgPosition]="'left'"
        [value]="translate('Guardar')"
        (onClick)="onGuardar($event)"
      >
      </jqxButton>

      <jqxButton
        style="float: left; margin-left: 6px"
        [textImageRelation]="'imageBeforeText'"
        [imgSrc]="'/assets/images/borrar.png'"
        [width]="100"
        [height]="26"
        [textPosition]="'left'"
        [imgPosition]="'left'"
        [value]="translate('Borrar')"
        (onClick)="onBorrar($event)"
      >
      </jqxButton>
    </div>

  </div>
</jqxWindow>