import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxInputComponent } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { FlotaService } from 'src/app/services/flota/flota.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';

@Component({
  selector: 'app-subflotas',
  templateUrl: './subflotas.component.html',
  styleUrls: ['./subflotas.component.css']
})
export class SubflotasComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('gridMoviles1') gridMoviles1: jqxGridComponent;
  @ViewChild('gridMoviles2') gridMoviles2: jqxGridComponent;
  @ViewChild('formEdit') formEdit: jqxWindowComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('ebNombre') ebNombre: jqxInputComponent;

  private componentRef = null;
  public environment = environment;
  public subflotas: SubflotaModel[] = [];
  public subflotaSelec: SubflotaModel = null;
  public subflotaEdit: SubflotaModel = null;
  public subflotaResto: SubflotaModel = null;
  public mostrarEditForm = false;
  public canEdit = true;

  // Variables para el grid de subflotas
  public sourceSubflotas: any = [];
  public dataAdapterSubflotas = new jqx.dataAdapter(this.sourceSubflotas);
  public columnsSubflotas = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 60, hidden: true },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 300,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total_subflotas') + ': ' +
            aggregates["Total"] + ' </div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Moviles'), columntype: 'textbox', filtertype: 'textbox', datafield: 'totMoviles', width: 70, align: 'center',
      cellsalign: 'right', cellsrenderer: this.numberrenderer,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + currentValue;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observ', width: 360
    }
  ];

  // Variables para el grid de resto de flota
  public movilesResto: MovilModel[] = [];
  public sourceMoviles1: any = [];
  public dataAdapterMoviles1;
  public columnsMoviles1 = [
    { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 50, align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 50 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso', width: 100 },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total_moviles_resto') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    }
  ];

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; text-align: right;margin-top: 2px">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; text-align: right;margin-top: 2px">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  // Variables para el grid de moviles de la subflota
  public movilesSubflota: MovilModel[] = [];
  public sourceMoviles2: any = [];
  public dataAdapterMoviles2;
  public columnsMoviles2 = [
    { text: 'Cod', columntype: 'textbox', filtertype: 'textbox', datafield: 'codigo', width: 50, align: 'center', cellsalign: 'center' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 50 },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso', width: 100 },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre', width: 200,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left;">' + AppComponent.translate('Total_moviles_subflota') + ': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private flotaService: FlotaService,
    private resourcesService: ResourcesService,
    private ssoService: SsoService,
    private modal: NzModalService
  ) {
    super();
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Subflotas'));
    // Creo una subflota vacia para rellenar
    this.subflotaEdit = this.getEmptySubflota();
    // Recupero las subflotas
    this.getSubflotas();
  }

  // Este método es llamado por el creador del componente
  public init(componentref: any) {
    this.componentRef = componentref;
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  async getSubflotas() {
    try {
      this.loader.open();
      this.subflotaSelec = null;
      this.subflotas = await this.flotaService.getSubflotas();
      // Creo la subflota que engloba todos los móviles sin asignar
      if (!this.subflotas) {
        this.subflotas = [];
      }
      await this.getSubflotaResto();
      this.subflotas.push(this.subflotaResto);
      if (this.subflotas) {
        this.sourceSubflotas = {
          datatype: 'json',
          datafields: [
            { name: 'id', type: 'string', map: 'Id' },
            { name: 'nombre', type: 'string', map: 'Nombre' },
            { name: 'totMoviles', type: 'number', map: 'MovilesId>length' },
            { name: 'observ', type: 'string', map: 'Observaciones' }
          ],
          localdata: this.subflotas,
          sortcolumn: 'id',
          sortdirection: 'asc'
        };
        this.dataAdapterSubflotas = new jqx.dataAdapter(this.sourceSubflotas);
      }
    } catch (e) {
      console.log(e);
    }
    this.loader.close();
    const t = setTimeout(() => {
      clearTimeout(t);
      this.grid.autoresizecolumns();
    }, 500);
  }

  // Creo la subflota con los móviles no asignados
  async getSubflotaResto(): Promise<void> {
    this.subflotaResto = this.getEmptySubflota();
    this.subflotaResto.Nombre = AppComponent.translate('Resto_flota');
    this.subflotaResto.Observaciones = AppComponent.translate('Moviles_sin_subflota');
    // Recupero todos los móviles
    const moviles = await this.resourcesService.getMoviles();
    // Recupero todos los móviles asignados a alguna subflota
    const movilesAsig = new Map<number, number>();
    if (this.subflotas) {
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId) {
          subflota.MovilesId.forEach(movil => {
            movilesAsig.set(movil, movil);
          });
        }
      });
    }
    // Me quedo con los móviles que no están asignados
    if (moviles) {
      moviles.forEach(movil => {
        if (!movilesAsig.get(movil.Codigo)) {
          this.subflotaResto.MovilesId.push(movil.Codigo);
        }
      });
    }
  }

  // Actualiza los móviles de la frota resto después de asignar móviles
  // a una subflota o si se borra una subflota
  async updateSubflotaResto(): Promise<void> {
    // Recupero todos los móviles
    const moviles = await this.resourcesService.getMoviles();
    // Recupero todos los móviles asignados a alguna subflota
    const movilesAsig = new Map<number, number>();
    if (this.subflotas) {
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId && subflota.Id > 0) { // Los moviles de la flota resto no cuentan
          subflota.MovilesId.forEach(movil => {
            movilesAsig.set(movil, movil);
          });
        }
      });
    }
    // Me quedo con los móviles que no están asignados
    this.subflotaResto.MovilesId = [];
    if (moviles) {
      moviles.forEach(movil => {
        if (!movilesAsig.get(movil.Codigo)) {
          this.subflotaResto.MovilesId.push(movil.Codigo);
        }
      });
    }
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Crea una subflota sin datos
  getEmptySubflota(): SubflotaModel {
    const subflota = new SubflotaModel();
    subflota.Id = 0;
    subflota.EmpresaId = this.ssoService.getTicket().Empresa.IdGestion;
    subflota.Nombre = '';
    subflota.Observaciones = '';
    subflota.MovilesId = [];
    return subflota;
  }

  onRowSubflotaSelect(event: any) {
    this.subflotaSelec = this.subflotas[event.args.rowindex];
  }

  onRowdoubleclick(event: any) {
    this.subflotaSelec = this.subflotas[event.args.rowindex];
    this.onEditarSubflotaClick(event);
  }

  // Crear una nueva subflota
  onCrearSubflotaClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    // Creo una subflota vacía para rellenar
    this.subflotaEdit = this.getEmptySubflota();
    this.mostrarEditForm = true;
    this.form.collapse();
    this.form.disable();
  }

  // Editar la subflota seleccionada
  onEditarSubflotaClick(event: any) {
    if (this.subflotaSelec) {
      if (this.subflotaSelec.Id > 0) {
        this.subflotaEdit = { ...this.subflotaSelec };
        if (this.form !== undefined) {
          this.form.collapse();
          this.form.disable();
        }
        this.mostrarEditForm = true;
      }
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // Borrar la subflota seleccionada
  onBorrarSubflotaClick(event: any) {
    if (!this.canEdit || this.subflotaSelec.Id === 0) {
      return;
    }
    if (this.subflotaSelec !== null) {
      this.deleteSubflota();
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onGuardarClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    if (this.subflotaEdit.Nombre === null || this.subflotaEdit.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return;
    }
    // Recupero los móviles asignados a la subflota
    this.subflotaEdit.MovilesId = [];
    if (this.movilesSubflota.length > 0) {
      this.movilesSubflota.forEach(movil => {
        this.subflotaEdit.MovilesId.push(movil.Codigo);
      });
    }
    this.saveSubflota();
  }

  // Guardo la subflota
  async saveSubflota() {
    // Recupero los móviles no asignados a ninguna subflota
    if (this.movilesResto.length > 0) {
      const sfAux = this.getEmptySubflota();
      sfAux.Id = 0; // Para que se desasignen los móviles
      sfAux.MovilesId = [];
      this, this.movilesResto.forEach(movil => {
        sfAux.MovilesId.push(movil.Codigo);
      });
      // Desasigno los móviles que no están en ninguna subflota
      await this.flotaService.setSubflotaMoviles(sfAux);
    }
    this.loader.open();
    // Almaceno la subflota con todos los móviles asignados
    const result = await this.flotaService.saveSubflota(this.subflotaEdit);
    this.loader.close();
    if (result) {
      if (this.subflotaEdit.Id === 0) {
        this.subflotas.push(result);
      } else {
        for (let i = 0; i < this.subflotas.length; i++) {
          if (this.subflotas[i].Id === this.subflotaEdit.Id) {
            this.subflotas[i] = { ...result };
            break;
          }
        }
      }
      this.subflotaEdit = { ...result };
      this.subflotaSelec = { ...this.subflotaEdit };
      await this.updateSubflotaResto();
      this.grid.updatebounddata('data');
      MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
    } else {
      MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
    }
    this.formEdit.close();
  }

  deleteSubflota() {
    this.modal.confirm({
      nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
      nzContent: AppComponent.translate('Borrar_subflota') + ': ' + this.subflotaSelec.Nombre + ' ?',
      nzCentered: true,
      nzCancelText: AppComponent.translate('CANCELAR'),
      nzOkText: AppComponent.translate('SI'),
      nzOnOk: async () => {
        const result = await this.flotaService.deleteSubflota(this.subflotaSelec.Id);
        if (result) {
          for (let i = 0; i < this.subflotas.length; i++) {
            if (this.subflotas[i].Id === this.subflotaSelec.Id) {
              this.subflotas.splice(i, 1);
              break;
            }
          }
          this.subflotaSelec = null;
          await this.updateSubflotaResto();
          this.grid.updatebounddata('data');
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
        }
      }
    });
  }

  onOpenEditSubflota() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.formEdit.setTitle(AppComponent.translate('Edicion_subflotas'));
      this.ebNombre.focus();
      this.updateListaDisponibles();
      this.addCustomForm(this.formEdit);
    }, 0);
  }

  onCloseEdit() {
    this.mostrarEditForm = false;
    if (this.form !== undefined) {
      this.form.enable();
      this.form.expand();
    }
  }

  async updateListaDisponibles() {
    try {
      this.loader.open();
      // Recupero todos los móviles asignados a alguna subflota
      const otrosMovilesAsig = new Map<number, number>();
      const movilesAsig = new Map<number, number>();
      this.subflotas.forEach(subflota => {
        if (subflota.MovilesId) {
          subflota.MovilesId.forEach(movil => {
            if (subflota.Id !== 0) { // Los móviles de la flota resto no cuentan
              if (subflota.Id !== this.subflotaEdit.Id) {
                otrosMovilesAsig.set(movil, movil);
              } else {
                movilesAsig.set(movil, movil);
              }
            }
          });
        }
      });
      // Recupero todos los móviles
      const result = await this.resourcesService.getMoviles()
      if (result) {
        this.movilesResto = [];
        this.movilesSubflota = [];
        result.forEach(movil => {
          if (!movilesAsig.get(movil.Codigo)) {
            if (!otrosMovilesAsig.get(movil.Codigo)) {
              this.movilesResto.push(movil);
            }
          } else {
            this.movilesSubflota.push(movil);
          }
        });
        // Actualizo la lista de móviles sin asignar
        this.sourceMoviles1 = {
          datatype: 'json',
          datafields: [
            { name: 'codigo', map: 'Codigo' },
            { name: 'tipo', map: 'TipoMovil>Nombre' },
            { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
            { name: 'nombre', map: 'Nombre' }
          ],
          localdata: this.movilesResto,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles1 = new jqx.dataAdapter(this.sourceMoviles1);
        const tMoviles1 = setTimeout(() => {
          clearTimeout(tMoviles1);
          this.gridMoviles1.autoresizecolumns();
        }, 500);
        // Actualizo la lista de móviles asignados
        this.sourceMoviles2 = {
          datatype: 'json',
          datafields: [
            { name: 'codigo', map: 'Codigo' },
            { name: 'tipo', map: 'TipoMovil>Nombre' },
            { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
            { name: 'nombre', map: 'Nombre' }
          ],
          localdata: this.movilesSubflota,
          sortcolumn: 'codigo',
          sortdirection: 'asc'
        };
        this.dataAdapterMoviles2 = new jqx.dataAdapter(this.sourceMoviles2);
        const tMoviles2 = setTimeout(() => {
          clearTimeout(tMoviles2);
          this.gridMoviles2.autoresizecolumns();
        }, 500);
      }
    } finally {
      this.loader.close();
    }
  }

  onMasClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles1.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesSubflota.push(this.movilesResto[rowIndex]);
        moviles.set(this.movilesResto[rowIndex].Codigo, this.movilesResto[rowIndex].Codigo);
      });
      for (let i = this.movilesResto.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesResto[i].Codigo)) {
          this.movilesResto.splice(i, 1);
        }
      };
      this.gridMoviles1.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

  onMenosClick(event: any) {
    if (!this.canEdit) {
      return;
    }
    const moviles = new Map<number, number>();
    const rowsSelec = this.gridMoviles2.getselectedrowindexes();
    if (rowsSelec.length > 0) {
      rowsSelec.forEach(rowIndex => {
        this.movilesResto.push(this.movilesSubflota[rowIndex]);
        moviles.set(this.movilesSubflota[rowIndex].Codigo, this.movilesSubflota[rowIndex].Codigo);
      });
      for (let i = this.movilesSubflota.length - 1; i >= 0; i--) {
        if (moviles.get(this.movilesSubflota[i].Codigo)) {
          this.movilesSubflota.splice(i, 1);
        }
      };
      this.gridMoviles2.clearselection();
      this.gridMoviles1.updatebounddata('data');
      this.gridMoviles2.updatebounddata('data');
    }
  }

}
