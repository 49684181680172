import { ChangeDetectorRef, Component, Input, OnInit, SimpleChanges, ViewChild, ViewContainerRef } from '@angular/core';

import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { NzModalService } from 'ng-zorro-antd/modal';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';

import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';

import { CiudadanosEditComponent } from './ciudadanos-edit/ciudadanos-edit.component';
import { CiudadanosListadoTarjetasComponent } from './ciudadanos-listado-tarjetas/ciudadanos-listado-tarjetas.component';
import { SsoService } from 'src/app/services/sso/sso.service';
import { TicketModel } from 'src/app/services/sso/models/ticket.model';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';

@Component({
  selector: 'app-ciudadanos',
  templateUrl: './ciudadanos.component.html',
  styleUrls: ['./ciudadanos.component.css']
})
export class CiudadanosComponent extends CustomForms implements OnInit {
  // Componentes del HTML
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('gridCiudadanos', { static: false }) gridCiudadanos: jqxGridComponent;
  @ViewChild('formCiudadano', { read: ViewContainerRef }) editCiudadanoComponent;
  @ViewChild('tarjetasCiudadano', { read: ViewContainerRef }) tarjetasCiudadanoComponent;
  formCiudadano: any;
  tarjetasCiudadano: any;

  // Entradas
  @Input() show = false;

  // Funcionalidad
  static _this: any;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  // Grid ciudadanos
  ciudadanos: CiudadanoModel[] = [];

  // Comprobaciones
  firstLoad = true;
  public ecoIdentificadores = false;
  public ecoCompostaje = false;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private ciudadanosService: CiudadanosService,
    private modal: NzModalService,
    private ssoService: SsoService,
    private cd: ChangeDetectorRef,
  ) {
    super();
    CiudadanosComponent._this = this;
  }

  public static getInstance(): CiudadanosComponent {
    return CiudadanosComponent._this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    let ticketSso: TicketModel = JSON.parse(sessionStorage.getItem('ticketSSO'));

    // Comprueba si tiene el modulo ecoidentificadores
    ticketSso.Modulos.forEach(element => {
      if(element.Id === 4){
        this.ecoIdentificadores = true;
      }
    });

    // Comprueba si esta en la aplicacion de ecoCompostaje
    if(ticketSso.Aplicacion.Id == 4){
      this.ecoCompostaje = true;
    }

    this.initGrid();
  }

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'show':
            this.show = changes[propName].currentValue;
            if(this.show != false){
              setTimeout(() => {
                this.getCiudadanos();
              }, 400);
            }
            break;
          default:
            break;
        }
      }
    }
  }

  /* GRID de ciudadanos */

  // Obtener ciudadanos
  async getCiudadanos() {
    this.ciudadanos = await this.ciudadanosService.getCiudadanos();

    this.ciudadanos.forEach(element => {
      element.direccionCatastral = element.catastro ? element.catastro?.direccion + ', ' + element.catastro?.numero : '';

      if(element.estadoValidacion === 0 || element.estadoValidacion === 14) {
        element.validadoTexto = this.translate('Pendiente');
      }else if(element.estadoValidacion === 1 ||element.estadoValidacion === 15) {
        element.validadoTexto = this.translate('Aceptado');
      }else if(element.estadoValidacion === 2 ||element.estadoValidacion === 16) {
        element.validadoTexto = this.translate('Rechazado');
      }
    })

    if (!this.ciudadanos) {
      this.ciudadanos = [];
    }else {
      if(this.columnsCiudadanos && this.sourceCiudadanos) {
        this.sourceCiudadanos.localdata = this.ciudadanos;

        this.adapterCiudadanos.dataBind();
        setTimeout(() => {
          if(this.firstLoad){
            this.defaultFilter();
          }

          this.resizeColumns();
        }, 700);
      }
    }
  }

  // Por defecto cargo filtro de pendientes
  defaultFilter(){
    let filtergroup = new jqx.filter();
    let filter_or_operator = 1;  // El operador OR.
    let filtervalue = this.translate('Pendiente');
    let filtercondition = 'equal';
    let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

    filtergroup.addfilter(filter_or_operator, filter1);
    this.gridCiudadanos.addfilter('estadoValidacion', filtergroup);
    this.gridCiudadanos.applyfilters();

    this.firstLoad = false;
  }

  // Renderizado de numeros en el grid
  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div class="jqx-grid-cell-right-align" style="margin-top: 6.5px;">'
        + value.toString() +
        '</div>'
      );
    }else if (value === 0) {
      return (
        '<div class="jqx-grid-cell-right-align" style="margin-top: 6.5px;">'
        + value.toString() +
        '</div>'
      );
    }
  }

  // Creacion de los botones de accion de las columnas
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';
    btnContainer.style.justifyContent = 'center';

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Editar')+`">
        <i class="fa-solid fa-pen-to-square"></i>
      </button>
    `;

    btnEdit.addEventListener('click', (event: any) => {
      let data = this.gridCiudadanos.getrowdata(row);
      this.onEditarCiudadano(data);
    });
    btnContainer.appendChild(btnEdit);

    let rowdata;
    if(isNaN(row)) {
      rowdata = row.bounddata;
    }else {
      rowdata = this.gridCiudadanos.getrowdata(row);
    }

    // Muestra el boton de validar y rechazar unicamente si esta pendiente
    if(rowdata && rowdata.estadoValidacion === AppComponent.translate('Pendiente')) {
      // Boton validar
      const btnValidate = document.createElement('div');
      btnValidate.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Validar')+`">
          <i class="fa-solid fa-circle-check"></i>
        </button>
      `;

      btnValidate.addEventListener('click', async (event: any) => {
        let data = this.gridCiudadanos.getrowdata(row);

        this.loader.open();
        await this.ciudadanosService.validateCiudadano(data.id, 15);
        this.loader.close();
        this.getCiudadanos();
      });
      btnContainer.appendChild(btnValidate);

      // Boton rechazar
      const btnDecline = document.createElement('div');
      btnDecline.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Rechazar')+`">
          <i class="fa-solid fa-ban"></i>
        </button>
      `;

      btnDecline.addEventListener('click', (event: any) => {
        let data = this.gridCiudadanos.getrowdata(row);

        this.ciudadanosService.validateCiudadano(data.id, 16);
        this.getCiudadanos();
      });
      btnContainer.appendChild(btnDecline);
    }


    // Boton ver tarjetas, solo si tiene app ecoIdentificadores
    if(this.ecoIdentificadores) {
      const btnSeeCards = document.createElement('div');
      btnSeeCards.innerHTML = `
        <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Ver_tarjetas')+`">
          <i class="fa-solid fa-address-card"></i>
        </button>
      `;

      btnSeeCards.addEventListener('click', (event: any) => {
        let data = this.gridCiudadanos.getrowdata(row);
        this.onSeeTarjetas(data);
      });
      btnContainer.appendChild(btnSeeCards);
    }

    // Boton de borrar
    const btnDelete = document.createElement('div');
    btnDelete.innerHTML = `
      <button class="button" style="height: 23px; cursor: pointer;" title="`+AppComponent.translate('Borrar')+`">
        <i class="fa-solid fa-trash"></i>
      </button>
    `;

    btnDelete.addEventListener('click', (event: any) => {
      let data = this.gridCiudadanos.getrowdata(row);
      this.onBorrarCiudadano(data);
    });
    btnContainer.appendChild(btnDelete);

    htmlElement.appendChild(btnContainer);
  }

  // Grid ciudadanos
  public columnsCiudadanos;
  public sourceCiudadanos;
  public adapterCiudadanos;

  // Inicializacion grid
  initGrid() {
    this.columnsCiudadanos = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', hidden: true },
      {
        width: '170px',
        text: this.translate('Acciones'),
        columntype: 'text',
        editable: false,
        datafield: 'botones',
        groupable: false,
        menu: false,
        sortable: false,
        clickable: false,
        filterable: false,
        resizable: false,
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
      },
      {
        text: this.translate('Validado'), columntype: 'textbox', datafield: 'estadoValidacion', width: 'auto', sortable: false,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('Pendiente'),
          this.translate('Aceptado'),
          this.translate('Rechazado')
        ],
      },
      { text: this.translate('Num_tarjetas'), columntype: 'textbox', datafield: 'numeroTarjetas', cellsrenderer: this.numberrenderer, width: 'auto', hidden: !this.ecoIdentificadores },
      { text: this.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', width: 'auto' },
      { text: this.translate('Apellidos'), columntype: 'textbox', datafield: 'apellidos', width: 'auto' },
      { text: this.translate('Dni'), columntype: 'textbox', datafield: 'nif', width: 'auto' },
      { text: this.translate('Telefono'), columntype: 'textbox', datafield: 'telefono', cellsrenderer: this.numberrenderer, width: 'auto' },
      { text: this.translate('Correo_electronico'), columntype: 'textbox', datafield: 'correoElectronico', width: 'auto' },

      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', width: 'auto', hidden: !this.ecoCompostaje},

      { text: this.translate('Numero_catastro'), columntype: 'textbox', datafield: 'numeroCatastro', width: 'auto' },
      { text: this.translate('Direccion_catastro'), columntype: 'textbox', datafield: 'direccionCatastro', width: 'auto' },
      { text: this.translate('Titular_catastro'), columntype: 'textbox', datafield: 'titularCatastro', width: 'auto' },

      { text: this.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', width: 'auto' },
      { text: this.translate('Numero'), columntype: 'textbox', datafield: 'numero', cellsrenderer: this.numberrenderer, width: 'auto' },
      { text: this.translate('Codigo_postal'), columntype: 'textbox', datafield: 'codigoPostal', cellsrenderer: this.numberrenderer, width: 'auto' },
      { text: this.translate('Poblacion'), columntype: 'textbox', datafield: 'poblacion', width: 'auto' },
      { text: this.translate('Municipio'), columntype: 'textbox', datafield: 'municipio', width: 'auto' },
      { text: this.translate('Provincia'), columntype: 'textbox', datafield: 'provincia', width: 'auto' },
      { text: this.translate('Comunidad_autonoma'), columntype: 'textbox', datafield: 'comunidadAutonoma', width: 'auto' },
      { text: this.translate('Puerta'), columntype: 'textbox', datafield: 'puerta', cellsrenderer: this.numberrenderer, width: 'auto' },
      { text: this.translate('Planta'), columntype: 'textbox', datafield: 'planta', cellsrenderer: this.numberrenderer, width: 'auto' },

      // TODO: Descomentar columna en la version 2
      //{ text: this.translate('Zona'), columntype: 'textbox', datafield: 'zona', width: 'auto', hidden: !this.ecoCompostaje }
    ];

    this.sourceCiudadanos = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'id' },
        { name: 'botones', type: 'string' },
        { name: 'estadoValidacion', type: 'string', map: 'validadoTexto' },
        { name: 'numeroTarjetas', type: 'int', map: 'tarjetas>length' },

        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'apellidos', type: 'string', map: 'apellidos' },
        { name: 'nif', type: 'string', map: 'nif' },
        { name: 'telefono', type: 'int', map: 'telefono' },
        { name: 'correoElectronico', type: 'string', map: 'correoElectronico' },

        { name: 'tipo', type: 'string', map: 'tipo' },

        { name: 'numeroCatastro', type: 'string', map: 'numeroCatastro' },
        { name: 'direccionCatastro', type: 'string', map: 'direccionCatastral' },
        { name: 'titularCatastro', type: 'string', map: 'catastro>titular' },

        { name: 'direccion', type: 'string', map: 'direccion' },
        { name: 'numero', type: 'int', map: 'numero' },
        { name: 'codigoPostal', type: 'int', map: 'codigoPostal' },
        { name: 'poblacion', type: 'string', map: 'poblacion' },
        { name: 'municipio', type: 'string', map: 'municipio' },
        { name: 'provincia', type: 'string', map: 'provincia' },
        { name: 'comunidadAutonoma', type: 'string', map: 'comunidadAutonoma' },
        { name: 'puerta', type: 'string', map: 'puerta' },
        { name: 'planta', type: 'string', map: 'planta' },

        // TODO: Descomentar columna en la version 2
        //{ name: 'zona', type: 'int', map: 'idZona'}
      ],
      localdata: this.ciudadanos
    };

    this.adapterCiudadanos = new jqx.dataAdapter(this.sourceCiudadanos);
  }


  // Ajusto el menu del filtro en funcion de la posicion
  columnmenuopening = (menu: any, datafield: any, height: any): void => {
    let menuElement = <HTMLElement>document.getElementById(menu[0].id);
    let areaGestion = <HTMLElement>document.getElementById('ciudadanoGrid').children[1];
    let column = this.gridCiudadanos.getcolumn(datafield)['element'];

    /* En caso de que vaya a sobresalir por debajo de la pantalla lo ajusto hacia arriba */
    if(height > areaGestion.offsetHeight) {
      let top = height - areaGestion.offsetHeight + 80;

      menuElement.parentElement.style.top = '-'+top+'px';
    }

    /* Ajuste del menu para que se vea el boton de filtro en el grid */
    setTimeout(() => {
      let columnPos = column.offsetLeft + column.offsetWidth;
      let menuWidth = menuElement.offsetWidth;

      if(columnPos + menuWidth >= areaGestion.offsetWidth) {
        menuElement.parentElement.style.left = '-20px';
      }else {
        menuElement.parentElement.style.left = '20px';
      }
    }, 0)
  };


  // Ajusta el ancho de las columnas de forma automatica
  resizeColumns() {
    this.gridCiudadanos.attrColumns.forEach((column: any) => {
      try{
        if(column.datafield !== 'botones') {
          this.gridCiudadanos.autoresizecolumn(column.datafield, column.columntype);
        }
      }catch(e) {
      }
    })
  }

  // Metodo llamada al agrupar/desagrupar
  groupChanged(event: any) {
    this.resizeColumns();
  }

  // Doble click sobre una fila
  rowDoubleClick(event: any) {
    this.onEditarCiudadano(event.args.row.bounddata)
  }
  /* FIN GRID */

  /* ACCIONES DE LOS BOTONES */
  public onCrearCiudadano() {
    this.formCiudadano = this.editCiudadanoComponent.createComponent(CiudadanosEditComponent);
    this.formCiudadano.instance.init(this.formCiudadano);
  }

  public onEditarCiudadano(event: any) {
    let ciudadano = this.ciudadanos.find(x => x.id === event.id);

    this.formCiudadano = this.editCiudadanoComponent.createComponent(CiudadanosEditComponent);
    this.formCiudadano.instance.init(this.formCiudadano, ciudadano);
  }

  public onSeeTarjetas(event: any) {
    let ciudadano = this.ciudadanos.find(x => x.id === event.id);

    this.tarjetasCiudadano = this.tarjetasCiudadanoComponent.createComponent(CiudadanosListadoTarjetasComponent);
    this.tarjetasCiudadano.instance.init(this.tarjetasCiudadano, ciudadano);
  }

  async onValidateCiudadano() {
    let noValidados: string[] = [];
    await this.gridCiudadanos.getselectedrowindexes().forEach(async element => {
      this.loader.open();
      let data = this.gridCiudadanos.getrowdata(element);

      let validado = await this.ciudadanosService.validateCiudadano(data.id, 15);
      if(!validado){
        noValidados.push(data.nombre + ' ' + data.apellidos);
      }
    });
    this.loader.close();
    if(noValidados.length != 0){
      let info = '';
      noValidados.forEach(elem => {
        info += '<br>' + elem;
      });
      MainComponent.getInstance().showInfo(
      'ATENCION',
      'Error 3',
      5000,
      info
    );
    }
    this.getCiudadanos();
  }

  public onDeclineCiudadano() {
    this.gridCiudadanos.getselectedrowindexes().forEach(element => {
      let data = this.gridCiudadanos.getrowdata(element);

      data.forEach(ciudadano => {
        this.ciudadanosService.validateCiudadano(ciudadano.id, 16);
      });
    });
    this.getCiudadanos();
  }

  /* Muestra la confirmación del borrado */
  public onBorrarCiudadano(event: any) {
    let ciudadano = this.ciudadanos.find(x => x.id === event.id);

    this.modal.confirm({
      nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
      nzContent: this.translate('Borrar_ciudadano') + ' "' + ciudadano.nombre + ' ' + ciudadano.apellidos + '"?',
      nzCentered: true,
      nzCancelText: this.translate('CANCELAR'),
      nzOkText: this.translate('SI'),
      nzOnOk: async () => {
        this.removeCiudadano(ciudadano);
        this.modal.closeAll();
      }
    });
  }

  /* Metodo que se llama al confirmar el borrado */
  public async removeCiudadano(ciudadano: CiudadanoModel) {
    if (await this.ciudadanosService.deleteCiudadano(ciudadano.id)) {
      this.ciudadanos.splice(this.ciudadanos.indexOf(ciudadano), 1);
      this.adapterCiudadanos = new jqx.dataAdapter(this.sourceCiudadanos);

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_borrado',
        2000
      );
    }else {
      MainComponent.getInstance().showWarning(
        'Error',
        'Error_api',
        2000
      );
    }
  }
  /* FIN ACCIONES BOTONES */
}
