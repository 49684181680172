<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [theme]="theme" [position]="getFormPos(form)" [width]="elemService.elemGenericos ? 290 : 1000"
  [height]="340" [zIndex]="50" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Filtro_elementos") }}
  </div>
  <div class="form" style="float: left; overflow-x: hidden">
    <div style="float: left; width: 100%; height: 270px">
      <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'100%'" [source]="dataAdapter" [columnsresize]="true"
        [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'" [selectionmode]="'checkbox'"
        [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false" [filterrowheight]="30"
        [rowsheight]="25" [columnsheight]="25" [enabletooltips]="true" [showaggregates]="true" [showstatusbar]="true"
        [statusbarheight]="20" [localization]="langGrid" (onBindingcomplete)="onBindingComplete()"
        (onRowexpand)="onRowExpand($event)">
      </jqxGrid>
    </div>
    <div style="float: left; margin-top: 8px">
      <jqxButton style="float: left" [width]="100" [height]="26" [textImageRelation]="'imageBeforeText'"
        [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'" [value]="translate('Guardar')"
        (onClick)="onGuardar()">
      </jqxButton>
    </div>
  </div>
</jqxWindow>