import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CatastroModel } from '../ciudadanos/models/catastro.model';
import { SsoService } from '../sso/sso.service';

@Injectable({
  providedIn: 'root'
})
export class CatastroService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  async getCatastros(): Promise<CatastroModel[]> {
    let response: CatastroModel[] = [];

    try {
      await this.http.get<CatastroModel[]>(this.urlApi + '/api/referencias/catastrales').toPromise().then(
        res => {
          response = res;
        }, error => {
          response = [];
        }
      );
    } catch (e) {
      response = [];
    }

    return response;
  }
}
