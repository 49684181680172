import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';


import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { NumberUtils } from 'src/app/utils/number-utils';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ResourcesService } from 'src/app/services/resources/resources.service';

import { MovilModel } from 'src/app/services/resources/models/movil.model';

import { MovilEditComponent } from '../moviles/movil-edit/movil-edit.component';
import { DateUtils } from 'src/app/utils/date-utils';
import * as xlsx from 'xlsx';


@Component({
  selector: 'app-moviles-catalog',
  templateUrl: './moviles-catalog.component.html',
  styleUrls: ['./moviles-catalog.component.css']
})
export class MovilesCatalogComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('formMovil', { read: ViewContainerRef }) editMovilComponent;

  public static _this: MovilesCatalogComponent;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  private movilesList: MovilModel[] = [];
  private moviles = new Map<number, MovilModel>();
  private select: any = [];
  private fromIAConfig = false;
  formMovil: any;
  public movilSelect: MovilModel;
  selectRow: any;
  mapWidth: number;
  mapHeight: number;

  intervalScroll: any;

  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  public columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
    { text: '', columntype: 'image', datafield: 'imagen', cellsrenderer: this.imagerenderer },
    {
      text: AppComponent.translate('Descripcion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombre',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            aggregates["Total"] + ' </div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: 'matricula' },
    { text: AppComponent.translate('Subflota'), columntype: 'textbox', filtertype: 'textbox', datafield: 'subflota' },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo' },
    { text: AppComponent.translate('Recurso'), columntype: 'textbox', filtertype: 'textbox', datafield: 'recurso' },
    { text: AppComponent.translate('Clase'), columntype: 'textbox', filtertype: 'textbox', datafield: 'clase' },
    { text: AppComponent.translate('Carga_maxima'), columntype: 'textbox', filtertype: 'textbox', datafield: 'cma', cellsrenderer: this.numberrenderer },
    { text: AppComponent.translate('Ancho'), columntype: 'textbox', filtertype: 'textbox', datafield: 'ancho', cellsrenderer: this.numberrendererDecimales },
    { text: AppComponent.translate('Largo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'largo', cellsrenderer: this.numberrendererDecimales },
    { text: 'CO²/km', columntype: 'textbox', filtertype: 'textbox', datafield: 'co2km', width: 80, cellsrenderer: this.numberrendererDecimales },
    { text: AppComponent.translate('Coste'), columntype: 'textbox', filtertype: 'textbox', datafield: 'coste', cellsrenderer: this.numberrenderer },
    { text: AppComponent.translate('Años_vida'), columntype: 'textbox', filtertype: 'textbox', datafield: 'anyos', cellsrenderer: this.numberrenderer },
    { text: AppComponent.translate('Precio_compra'), columntype: 'textbox', filtertype: 'textbox', datafield: 'precio', cellsrenderer: this.numberrenderer },
    { text: AppComponent.translate('Año_amortizacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'amortizacion', cellsrenderer: this.numberrenderer },
    { text: AppComponent.translate('Mantenimiento_actual'), columntype: 'textbox', filtertype: 'textbox', datafield: 'mantenimiento', cellsrenderer: this.numberrenderer },
    { text: AppComponent.translate('Año_matriculacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'matriculacion', cellsrenderer: this.numberrenderer }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(private ssoService: SsoService,
    private configService: ConfigService,
    private resourcesService: ResourcesService) {
    super();
    MovilesCatalogComponent._this = this;
  }

  async ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    Utils.renderSizeGrid(this.grid);
    if (!this.fromIAConfig) {
      this.form.setTitle(AppComponent.translate('Vehiculos'));
    } else {
      this.form.setTitle(AppComponent.translate('Vehiculos') + ' - ' + AppComponent.translate('IA'));
    }
    // Recupero el filtro guardado
    if (!this.fromIAConfig) {
      this.select = await this.configService.getUsuEmpApp('moviles-visibles', null);
    } else {
      this.select = await this.configService.getEmp('moviles-IA', null);
    }
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
    // Recupero los móviles de la empresa
    this.getMoviles();
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, fromIAConfig: boolean) {
    this.componentRef = componentRef;
    this.fromIAConfig = fromIAConfig;
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Guardo los filtros y destruyo el componente
  public async onGuardar() {
    // Guardo la configuración
    const modelos: any[] = [];
    const rowsSelec = this.grid.getselectedrowindexes();
    if (rowsSelec) {
      rowsSelec.forEach(i => {
        modelos.push({ id: this.movilesList[i].Codigo });
      });
    }
    // Guardo la variable de configuración con los datos del filtro
    if (!this.fromIAConfig) {
      await this.configService.setUsuEmpApp('moviles-visibles', JSON.stringify(modelos));
    } else {
      await this.configService.setEmp('moviles-IA', JSON.stringify(modelos));
    }
    // Mando actualizar el filtro para que se repinten los móviles
    this.resourcesService.setFilterVisible();
    // Cierro el formulario
    this.form.close();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onBindingComplete() {
    const rows = this.grid.getrows();
    if (rows) {
      rows.forEach(row => {
        if (this.select.find(s => s.id === row.id) !== undefined) {
          this.grid.selectrow(row.boundindex);
        }
      });
    }

    const t = setTimeout(() => {
      clearTimeout(t);
      this.grid.autoresizecolumns();
    }, 500);
  }

  // Recupera los móviles de la empresa
  async getMoviles() {
    let filterIA: any = await this.configService.getEmp('moviles-IA', null);
    if (filterIA) {
      filterIA = JSON.parse(filterIA);
    }
    this.loader.open();
    const result = await this.resourcesService.getMoviles(true);
    this.movilesList = [];
    if (result) {
      result.forEach(movil => {
        if (!MainComponent.getInstance().isEcoEvolution || this.fromIAConfig) {
          this.movilesList.push(movil);
          this.moviles.set(movil.Codigo, movil);
        } else {
          if (!filterIA || filterIA.find(s => s.id === movil.Codigo) !== undefined) {
            this.movilesList.push(movil);
            this.moviles.set(movil.Codigo, movil);
          }
        }
      });
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'Codigo' },
          { name: 'imagen', type: 'image', map: 'ConjuntoVehiculo>Icono' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'matricula', type: 'string', map: 'Matricula' },
          { name: 'subflota', map: 'Subflota>Nombre' },
          { name: 'tipo', type: 'string', map: 'TipoMovil>Nombre' },
          { name: 'recurso', map: 'ConjuntoVehiculo>Recurso>Nombre' },
          { name: 'clase', map: 'Clase>Nombre' },
          { name: 'cma', map: 'ConjuntoVehiculo>CargaMaximaNeta' },
          { name: 'ancho', map: 'ConjuntoVehiculo>Ancho' },
          { name: 'largo', map: 'ConjuntoVehiculo>Largo' },
          { name: 'co2km', map: 'ConjuntoVehiculo>Kmco2' },
          { name: 'coste', map: 'ConjuntoVehiculo>Coste' },
          { name: 'anyos', map: 'ConjuntoVehiculo>AnyoVida' },
          { name: 'precio', map: 'ConjuntoVehiculo>PrecioCompra' },
          { name: 'amortizacion', map: 'ConjuntoVehiculo>AnyoAmortizacion' },
          { name: 'mantenimiento', map: 'ConjuntoVehiculo>MantenimientoAnual' },
          { name: 'matriculacion', map: 'ConjuntoVehiculo>AnyoMatriculacion' },
        ],
        localdata: this.movilesList,
        sortcolumn: 'nombre',
        sortdirection: 'asc'
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
    }
    this.loader.close();
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return value ? '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' + value + '"/>' :
      '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/car.png" />';
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  public onEditar() {
    if (this.movilSelect) {
      this.form.collapse();

      this.formMovil = this.editMovilComponent.createComponent(MovilEditComponent);
      this.formMovil.instance.init(this.formMovil, this.movilSelect);
    }
  }

  public onRowClick(event: any) {
    if (!this.movilSelect) {
      this.movilSelect = this.movilesList[event.args.rowindex];
      // Recoge la fila donde esta localizado el raton
      this.selectRow = event.args.row.owner.that.mousecaptureposition.clickedrow;

      this.selectRow.cells.forEach(cell => {
        cell.classList.add('selectedCell');
      });
    } else {
      if (this.movilSelect.Codigo !== this.movilesList[event.args.rowindex].Codigo) {
        this.selectRow.cells.forEach(cell => {
          cell.classList.remove('selectedCell');
        });

        this.movilSelect = this.movilesList[event.args.rowindex];
        this.selectRow = event.args.row.owner.that.mousecaptureposition.clickedrow;
        this.selectRow.cells.forEach(cell => {
          cell.classList.add('selectedCell');
        });
      } else {
        this.movilSelect = null;
        this.selectRow.cells.forEach(cell => {
          cell.classList.remove('selectedCell');
        });
        this.selectRow = null;
      }
    }

    this.scrollController();
  }

  public onRowDoubleClick(event: any) {
    if (this.selectRow) {
      this.selectRow.cells.forEach(cell => {
        cell.classList.remove('selectedCell');
      });
    }

    this.movilSelect = this.movilesList[event.args.rowindex];
    this.selectRow = event.args.row.owner.that.mousecaptureposition.clickedrow;
    this.selectRow.cells.forEach(cell => {
      cell.classList.add('selectedCell');
    });

    this.scrollController();

    setTimeout(() => {
      this.onEditar();
    }, 200);
  }

  scrollController() {
    let scrollTop = this.grid.scrollposition().top;
    if (this.intervalScroll) {
      clearInterval(this.intervalScroll);
    }

    /*
      En caso de que se tenga un row elegido se comprueba se inicializa
      un intervalo para comprobar si hay modificacion en el scroll
      en caso de que haya modificacion se deselecciona el movil
    */
    if (this.selectRow) {
      this.intervalScroll = setInterval(() => {
        if (scrollTop !== this.grid.scrollposition().top) {
          if (this.selectRow) {
            this.selectRow.cells.forEach(cell => {
              cell.classList.remove('selectedCell');
            });
            this.selectRow = null;
            this.movilSelect = null;
          }
        }
      }, 0);
    }
  }

  onExportar() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.grid.hidecolumn('imagen');
      const json = this.grid.exportdata('json');
      let datos = JSON.parse(json);
      let datosRow = this.grid.getrows();
      datos.forEach((element, index) => {
        element[this.translate('Subflota')] = datosRow.map((item) => item.subflota)[index];
      });
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);
      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Vehiculos') + '.xlsx');
      this.grid.showcolumn('imagen');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.grid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.grid.hidecolumn('imagen');
      let gridContent = this.grid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.grid.showcolumn('imagen');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }



}
