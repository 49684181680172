export class ElemImageModel {
  id: number;
  idElemento: number;
  guidElement: string;
  fecha: Date;
  imagen: string;

  constructor(){

  }
}
