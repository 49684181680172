<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [width]="1500" [height]="600" [maxWidth]="'100vw'" [maxHeight]="'100vh'" [zIndex]="100"
  [isModal]="false" [position]="getFormPos(form)" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="false" (onClose)="onClose()" (onOpen)="onOpen()" (onCollapse)="onCollapse($event)"
  (onExpand)="onExpand($event)">

  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Edicion_elementos") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #elemRibbon [theme]="environment.tema" [position]="'top'">
      <ul style="width: 100%; text-align: left">
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Informacion") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Detalle") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Perifericos_IOT") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Instalaciones") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Gestion") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Historico_coordenadas") }}
        </li>
      </ul>

      <div style="height: 100%;">
        <div class="row tab"
        style="background-color: transparent; display: flex; padding: 0%; overflow-y: hidden !important;">
          <app-elements-edit-informacion
            [form]="form"
            [elemEdit]="elemEdit"
            [crearMultiples]="crearMultiples"
            [map]="map"
            [markerHistorico]="markerHistorico"
            [expanded]="expanded"
            [closed]="closed"
            (catEquipSelecChange)="catEquipSelec = $event">
          </app-elements-edit-informacion>
        </div>

        <div class="row tab"
        style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-elements-edit-detalle
          [elemEdit]="elemEdit"
          [expanded]="expanded"
          [closed]="closed">
          </app-elements-edit-detalle>
        </div>

        <div style="overflow: hidden; background-color: transparent; margin-top: 5px;">
          <app-elements-edit-perifericos
          [elemEdit]="elemEdit"
          [expanded]="expanded"
          [closed]="closed">
          </app-elements-edit-perifericos>
        </div>

        <div class="row tab" style="overflow: hidden; background-color: transparent">
          <app-elements-edit-instalaciones
          [elemEdit]="elemEdit"
          [expanded]="expanded"
          [closed]="closed">
          </app-elements-edit-instalaciones>
        </div>

        <div style="overflow: hidden; background-color: transparent">
          <app-elements-edit-gestion
          [elemEdit]="elemEdit"
          [expanded]="expanded"
          [closed]="closed">
          </app-elements-edit-gestion>
        </div>

        <div style="overflow: hidden; background-color: transparent">
          <app-elements-edit-historico-coordenadas
          [elemEdit]="elemEdit"
          [expanded]="expanded"
          [closed]="closed">
          </app-elements-edit-historico-coordenadas>
        </div>
      </div>
    </jqxRibbon>

    <div style="
      position: absolute;
      bottom: 5px;
      left: 0px;
      margin-top: 23px;
      clear: both;">
      <jqxButton *ngIf="!crearMultiples" style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
        (onClick)="onGuardar($event)" [value]="translate('Guardar')" [disabled]="!canEdit">
      </jqxButton>
      <jqxButton *ngIf="elemEdit.Id == 0; else elem_existe_tmpl" style="float: left; margin-left: 5px" [width]="100"
        [height]="26" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
        [imgSrc]="'/assets/images/posicion.png'" [value]="translate('Posicionar')" (onClick)="onPosicionar()"
        [hidden]="!canEdit">
      </jqxButton>
      <ng-template #elem_existe_tmpl>
        <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
          [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/localizar.png'"
          [value]="translate('Centrar')" (onClick)="onPosicionar()" [hidden]="!canEdit">
        </jqxButton>
      </ng-template>

      <div *ngIf="elemEdit.Id==0" style="margin-left: 500px; margin-top: 10px">
        <input [(ngModel)]="crearMultiples" type="checkbox" [disabled]="!canEdit"
          (change)="onCreacionMultiple($event)" />
        {{translate('Creacion_multiple')}}
      </div>
    </div>
  </div>
</jqxWindow>
