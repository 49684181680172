import { Component, OnInit, AfterViewInit, ViewChild, Input } from '@angular/core';

import { AppComponent } from 'src/app/app.component';

import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputComponent } from 'jqwidgets-ng/jqxdatetimeinput';

import { SsoService } from 'src/app/services/sso/sso.service';

export enum PeriodoSelectTipo {
  DIA,
  SEMANA,
  MES,
  TRIMESTRE,
  SEMESTRE,
  ANNO,
  LIBRE
}

@Component({
  selector: 'app-periodo-select',
  templateUrl: './periodo-select.component.html',
  styleUrls: ['./periodo-select.component.css']
})
export class PeriodoSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('cbPeriodo') cbPeriodo: jqxDropDownListComponent;
  @ViewChild('fecha1') fecha1: jqxDateTimeInputComponent;
  @ViewChild('fecha2') fecha2: jqxDateTimeInputComponent;
  @Input() height = 20;
  @Input() noDia = false;
  @Input() noSemana = false;
  @Input() noMes = false;
  @Input() noTrimestre = false;
  @Input() noSemestre = false;
  @Input() noAnno = false;
  @Input() noLibre = false;

  // Periodo deleccionado por defecto
  public periodoSelec = PeriodoSelectTipo.DIA;

  // Variables para devolver las fechas de inicio y fin
  public desde: Date;
  public hasta: Date;

  // Pongo por defecto los textos en español
  public localization = 'es';

  constructor(private ssoService: SsoService) { }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.localization = this.ssoService.getTicket().Usuario.Idioma.Codigo;
  }

  ngAfterViewInit(): void {
    //this.fecha1.culture(this.ssoService.getTicket().Usuario.Idioma.Codigo);
    //this.fecha2.culture(this.ssoService.getTicket().Usuario.Idioma.Codigo);
    if (!this.noDia) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Dia'), value: PeriodoSelectTipo.DIA });
    }
    if (!this.noSemana) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Semana'), value: PeriodoSelectTipo.SEMANA });
    }
    if (!this.noMes) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Mes'), value: PeriodoSelectTipo.MES });
    }
    if (!this.noTrimestre) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Trimestre'), value: PeriodoSelectTipo.TRIMESTRE });
    }
    if (!this.noSemestre) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Semestre'), value: PeriodoSelectTipo.SEMESTRE });
    }
    if (!this.noAnno) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Año'), value: PeriodoSelectTipo.ANNO });
    }
    if (!this.noLibre) {
      this.cbPeriodo.addItem({ label: AppComponent.translate('Libre'), value: PeriodoSelectTipo.LIBRE });
    }
    const t = setTimeout(() => {
      clearTimeout(t);
      this.setDiaActual(new Date());
    }, 500);
  }

  setPeriodo(option: number) {
    this.periodoSelec = option;
    switch (option) {
      case PeriodoSelectTipo.DIA:
        this.cbPeriodo.selectedIndex(0);
        break;
      case PeriodoSelectTipo.SEMANA:
        this.cbPeriodo.selectedIndex(1);
        break;
      case PeriodoSelectTipo.MES:
        this.cbPeriodo.selectedIndex(2);
        break;
      case PeriodoSelectTipo.TRIMESTRE:
        this.cbPeriodo.selectedIndex(3);
        break;
      case PeriodoSelectTipo.SEMESTRE:
        this.cbPeriodo.selectedIndex(4);
        break;
      case PeriodoSelectTipo.ANNO:
        this.cbPeriodo.selectedIndex(5);
        break;
      case PeriodoSelectTipo.LIBRE:
        this.cbPeriodo.selectedIndex(6);
        break;
    }
  }

  getFechaIni(): Date {
    return this.desde;
  }

  getFechaFin(): Date {
    return this.hasta;
  }

  getPeriodo() {
    return this.periodoSelec;
  }

  onSelectPeriodo(event: any) {
    this.periodoSelec = event.args.item.value;
    switch (event.args.item.value) {
      case PeriodoSelectTipo.DIA:
        this.setDiaActual(new Date());
        break;
      case PeriodoSelectTipo.SEMANA:
        this.setSemanaActual(new Date());
        break;
      case PeriodoSelectTipo.MES:
        this.setMesActual(new Date());
        break;
      case PeriodoSelectTipo.TRIMESTRE:
        this.setTrimestreActual(new Date());
        break;
      case PeriodoSelectTipo.SEMESTRE:
        this.setSemestreActual(new Date());
        break;
      case PeriodoSelectTipo.ANNO:
        this.setAnnoActual(new Date());
        break;
      case PeriodoSelectTipo.LIBRE:
        this.setDiaActual(new Date());
        break;
    }
    this.fecha1.setDate(this.desde);
    this.fecha2.setDate(this.hasta);
  }

  onChangeFecha() {
    switch (this.cbPeriodo.getSelectedItem().value) {
      case PeriodoSelectTipo.DIA:
        this.setDiaActual(this.fecha1.getDate());
        break;
      case PeriodoSelectTipo.SEMANA:
        this.setSemanaActual(this.fecha1.getDate());
        break;
      case PeriodoSelectTipo.MES:
        this.setMesActual(this.fecha1.getDate());
        break;
      case PeriodoSelectTipo.TRIMESTRE:
        this.setTrimestreActual(this.fecha1.getDate());
        break;
      case PeriodoSelectTipo.SEMESTRE:
        this.setSemestreActual(this.fecha1.getDate());
        break;
      case PeriodoSelectTipo.ANNO:
        this.setAnnoActual(this.fecha1.getDate());
        break;
      case PeriodoSelectTipo.LIBRE:
        this.setDiaLibre();
        break;
    }
    this.fecha1.setDate(this.desde);
    this.fecha2.setDate(this.hasta);
  }

  onChangeFecha2() {
    this.hasta = this.fecha2.getDate();
  }

  setDiaActual(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    // Calculo fecha de inicio
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setDiaLibre() {
    this.desde = this.fecha1.getDate();
    this.hasta = this.fecha2.getDate();
    if (this.desde > this.hasta) {
      this.hasta = new Date(this.desde);
    }
    // Cojo la fecha de inicio
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Cojo la fecha de fin
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setDiaAnterior(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let dia = this.desde.getDate();
    let mes = this.desde.getMonth() + 1;
    let anno = this.desde.getFullYear();
    if (dia > 1) {
      dia--;
    } else {
      if (mes > 1) {
        mes--;
        dia = this.getDiasMes(mes, anno);
      } else {
        dia = 31;
        mes = 12;
        anno--;
      }
    }
    // Calculo fecha de inicio
    this.desde.setFullYear(anno);
    this.desde.setMonth(mes - 1);
    this.desde.setDate(dia);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(anno);
    this.hasta.setMonth(mes - 1);
    this.hasta.setDate(dia);
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);

    this.fecha1.setDate(this.desde);
    this.fecha2.setDate(this.hasta);

  }

  setSemanaActual(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let dia = this.desde.getDate();
    let mes = this.desde.getMonth() + 1;
    let anno = this.desde.getFullYear();
    // Busco el lunes de la semana actual
    while (this.desde.getDay() !== 1) {
      if (dia > 0) {
        dia--;
      } else {
        if (mes > 1) {
          mes--;
          dia = this.getDiasMes(mes, anno);
        } else {
          dia = 31;
          mes = 12;
          anno--;
        }
      }
      this.desde.setFullYear(anno);
      this.desde.setMonth(mes - 1);
      this.desde.setDate(dia);
    }
    // Calculo fecha de inicio
    this.desde.setFullYear(anno);
    this.desde.setMonth(mes - 1);
    this.desde.setDate(dia);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(anno);
    this.hasta.setMonth(mes - 1);
    this.hasta.setDate(dia + 6);
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setSemanaAnterior(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let dia = this.desde.getDate();
    let mes = this.desde.getMonth() + 1;
    let anno = this.desde.getFullYear();
    // Busco el lunes de la semana actual
    while (this.desde.getDay() !== 1) {
      if (dia > 0) {
        dia--;
      } else {
        if (mes > 1) {
          mes--;
          dia = this.getDiasMes(mes, anno);
        } else {
          dia = 31;
          mes = 12;
          anno--;
        }
      }
      this.desde.setFullYear(anno);
      this.desde.setMonth(mes - 1);
      this.desde.setDate(dia);
    }
    if (dia > 7) {
      dia -= 7;
    } else {
      if (mes > 1) {
        mes--;
        dia = this.getDiasMes(mes, anno) - (7 - dia);
      } else {
        anno--;
        mes = 12;
        dia = this.getDiasMes(mes, anno) - (7 - dia);
      }
    }
    // Calculo fecha de inicio
    this.desde.setFullYear(anno);
    this.desde.setMonth(mes - 1);
    this.desde.setDate(dia);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(anno);
    this.hasta.setMonth(mes - 1);
    this.hasta.setDate(dia + 6);
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setMesActual(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    // Calculo fecha de inicio
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setDate(this.getDiasMes(this.hasta.getMonth() + 1, this.hasta.getFullYear()));
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setMesAnterior(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let mes = this.desde.getMonth() + 1;
    let anno = this.desde.getFullYear();
    if (mes > 1) {
      mes--;
    } else {
      mes = 12;
      anno--;
    }
    // Calculo fecha de inicio
    this.desde.setFullYear(anno);
    this.desde.setMonth(mes - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(anno);
    this.hasta.setMonth(mes - 1);
    this.hasta.setDate(this.getDiasMes(mes, anno));
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setTrimestreActual(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let mes = this.desde.getMonth() + 1;
    // Calculo el primer mes del trimestre actual
    if (mes < 4) {
      mes = 1;
    } else {
      if (mes < 7) {
        mes = 4;
      } else {
        if (mes < 10) {
          mes = 7;
        } else {
          mes = 10;
        }
      }
    }
    // Calculo fecha de inicio
    this.desde.setMonth(mes - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setMonth(mes + 2 - 1);
    this.hasta.setDate(this.getDiasMes(mes + 2, this.hasta.getFullYear()));
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setTrimestreAnterior(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let mes = this.desde.getMonth() + 1;
    let anno = this.desde.getFullYear();
    // Calculo el primer mes del trimestre actual
    if (mes < 4) {
      mes = 1;
    } else {
      if (mes < 7) {
        mes = 4;
      } else {
        if (mes < 10) {
          mes = 7;
        } else {
          mes = 10;
        }
      }
    }
    // Calculo el primer mes del trimestre anterior
    if (mes > 3) {
      mes -= 3;
    } else {
      mes = 10;
      anno--;
    }
    // Calculo fecha de inicio
    this.desde.setFullYear(anno);
    this.desde.setMonth(mes - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(anno);
    this.hasta.setMonth(mes + 2 - 1);
    this.hasta.setDate(this.getDiasMes(mes + 2, anno));
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setSemestreActual(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let mes = this.desde.getMonth() + 1;
    // Calculo el primer mes del semestre actual
    mes = mes < 7 ? 1 : 7;
    // Calculo fecha de inicio
    this.desde.setMonth(mes - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setMonth(mes + 5 - 1);
    this.hasta.setDate(this.getDiasMes(mes + 5, this.hasta.getFullYear()));
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setSemestreAnterior(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    let mes = this.desde.getMonth() + 1;
    let anno = this.desde.getFullYear();
    // Calculo el primer mes del semestre actual
    mes = mes < 7 ? 1 : 7;
    // Calculo el primer mes del semestre anterior
    if (mes > 6) {
      mes = 1;
    } else {
      mes = 7;
      anno--;
    }
    // Calculo fecha de inicio
    this.desde.setFullYear(anno);
    this.desde.setMonth(mes - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(anno);
    this.hasta.setMonth(mes + 5 - 1);
    this.hasta.setDate(this.getDiasMes(mes + 5, anno));
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setAnnoActual(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    // Calculo fecha de inicio
    this.desde.setMonth(1 - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setMonth(12 - 1);
    this.hasta.setDate(31);
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  setAnnoAnterior(date: Date) {
    this.desde = new Date(date.getTime());
    this.hasta = new Date(date.getTime());
    // Calculo fecha de inicio
    this.desde.setFullYear(this.desde.getFullYear() - 1);
    this.desde.setMonth(1 - 1);
    this.desde.setDate(1);
    this.desde.setHours(0);
    this.desde.setMinutes(0);
    this.desde.setSeconds(0);
    // Calculo fecha de fin
    this.hasta.setFullYear(this.hasta.getFullYear() - 1);
    this.hasta.setMonth(12 - 1);
    this.hasta.setDate(31);
    this.hasta.setHours(23);
    this.hasta.setMinutes(59);
    this.hasta.setSeconds(59);
  }

  // Devuelve el número de días del mes
  getDiasMes(mes: number, anno: number) {
    switch (mes) {
      case 1:
      case 3:
      case 5:
      case 7:
      case 8:
      case 10:
      case 12:
        return 31;
      case 2:
        if ((anno % 4) === 0) {
          return 29;
        }
        return 28;
      default:
        return 30;
    }
  }
}
