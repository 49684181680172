import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';

import { CuadriculasComponent } from './cuadriculas.component';

@NgModule({
  declarations: [
    CuadriculasComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    ReactiveFormsModule
  ],
  exports: [
    CuadriculasComponent
  ]
})
export class CuadriculasModule { }
