import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InfogeoModel } from './models/infogeo.model';
import { SsoService } from '../sso/sso.service';

@Injectable({
  providedIn: 'root'
})
export class MapsService {
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  // https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?prox=41.8842%2C-87.6388%2C250&mode=retrieveAddresses&maxresults=1&gen=9&apiKey=H6XyiCT0w1t9GgTjqhRXxDMrVj9h78ya3NuxlwM7XUs
  async getInfogeoFromPoint(lat: number, lng: number, radius: number, maxResult: number): Promise<InfogeoModel> {
    try {
      const httpOptions = {
        headers: null,
        withCredentials: false
      };
      const result: any = await this.http.get('https://reverse.geocoder.ls.hereapi.com/6.2/reversegeocode.json?prox=' +
        lat + '%2C' + lng + '%2C' + radius + '&mode=retrieveAddresses&maxresults=' +
        maxResult + '&gen=9&apiKey=' + environment.hereApiKeyRest, httpOptions).toPromise();
      if (result.Response !== undefined && result.Response.View !== undefined && result.Response.View.length > 0 &&
        result.Response.View[0].Result !== undefined && result.Response.View[0].Result.length > 0 &&
        result.Response.View[0].Result[0].Location !== undefined && result.Response.View[0].Result[0].Location.Address !== undefined) {
        return JSON.parse(JSON.stringify(result.Response.View[0].Result[0].Location.Address));
      }
    } catch (e) {
      console.log("InfoGeo: " + e);
    }
    return null;
  }

  async getCoordinatesByDirection(
    numero: string,
    calle: string,
    municipio: string,
    provincia: string,
    comunidadAutonoma: string,
    codigoPostal: string,
    poblacion: string
  ): Promise<any> {
    let response = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }

    let direction: any = new Object();
    direction.Street = calle;
    direction.PostalCode = codigoPostal;
    direction.District = poblacion;
    direction.Locality = municipio;
    direction.AdministrativeArea1 = provincia;
    direction.AdministrativeArea2 = comunidadAutonoma;
    direction.StreetNumber = numero;

    try {
      response = await this.http.post<any>(
        this.urlApi + '/api/maps?mapType=1',
        JSON.stringify(direction),
        httpOptions
      ).toPromise();
    }catch(e){
      response = null;
    }

    return response;
  }
}
