import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TokenModel } from '../sso/models/token.model';
import { SsoService } from '../sso/sso.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private token: TokenModel = null;
  private tokenBdt: TokenModel = null;
  private tokenIA: TokenModel = null;
  private timer = null;
  private timerBdt = null;
  private timerIA = null;
  tokenCarto: any;
  timerCarto: NodeJS.Timeout;

  constructor(private http: HttpClient, private ssoService: SsoService) { }

  // Pide un token a la API
  private async getToken(): Promise<TokenModel> {
    try {
      this.token = null;
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const body = new URLSearchParams();
      body.set('grant_type', environment.grantTypeApi);
      body.set('client_id', environment.clientIdApi);
      body.set('client_secret', environment.clientSecretApi);
      body.set('username', environment.userNameApi);
      body.set('password', environment.passwordApi);
      // Pido el token y lo guardo
      const p = this.ssoService.getTicket().UrlApi;
      this.token = await this.http.post<TokenModel>(this.ssoService.getTicket().UrlApi + '/api/token',
        body.toString(), httpOptions).toPromise();
      sessionStorage.setItem('tokenApi', this.token.access_token);
      // Activo un temporizador para pedir un nuevo token un minuto antes que caduque
      if (this.token.expires_in >= 120) {
        this.startTimer(this.token.expires_in - 60);
      } else {
        this.startTimer(Math.round(this.token.expires_in / 2));
      }
    } catch {
    }
    return this.token;
  }

  // Inicia el temporizador para refrescar el token de la api
  private startTimer(interval: number) {
    this.stopTimer();
    this.timer = setTimeout(() => {
      this.getToken();
    }, interval * 1000);
  }

  // Finaliza el temporizador de refresco de tocken de la api
  private stopTimer() {
    if (this.timer !== null) {
      clearTimeout(this.timer);
    }
    this.timer = null;
  }

  // Pido un token a la API
  public async initSessionApi(): Promise<boolean> {
    try {
      this.token = await this.getToken();
      if (this.token !== null) {
        return true;
      }
    } catch {
    }
    sessionStorage.removeItem('tokenApi');
    return false;
  }

  // Recupero la versión de la api
  async getApiVersion(): Promise<any> {
    try {
      const res = await this.http.get(this.ssoService.getTicket().UrlApi, { responseType: 'text' }).toPromise();
      if (res && res.length > 0) {
        // 'MOVISAT, Tecnomovilidad\nVersión de la API 220606.01'
        const apiVer = res.split('\n')[1].substring(res.split('\n')[1].lastIndexOf(' ') + 1);
        return {
          apiVer: apiVer
        }
      }
    } catch {
    }
  }

  // Pide un token a la API de la BDT
  private async getTokenBdt(): Promise<TokenModel> {
    try {
      this.tokenBdt = null;
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const body = new URLSearchParams();
      body.set('grant_type', environment.grantTypeBdt);
      body.set('PASSWORD_CLIENT_ID', environment.clientIdBdt);
      body.set('PASSWORD_CLIENT_SECRET', environment.clientSecretBdt);
      body.set('email', environment.userNameBdt);
      body.set('password', environment.passwordBdt);
      // Pido el token y lo guardo
      this.tokenBdt = await this.http.post<TokenModel>(environment.urlApiBdt + '/login',
        body.toString(), httpOptions).toPromise();
      sessionStorage.setItem('tokenBdt', this.tokenBdt.access_token);


      // Activo un temporizador para pedir un nuevo token un minuto antes que caduque
      if (this.tokenBdt.expires_in >= 120) {
        this.startTimerBdt(this.tokenBdt.expires_in - 60);
      } else {
        this.startTimerBdt(Math.round(this.tokenBdt.expires_in / 2));
      }
    } catch {
    }
    return this.tokenBdt;
  }

  // Inicia el temporizador para refrescar el token de la api de la BDT
  private startTimerBdt(interval: number) {
    this.stopTimerBdt();
    this.timerBdt = setTimeout(() => {
      this.getTokenBdt();
    }, interval * 1000);
  }

  // Finaliza el temporizador de refresco de tocken de la api de la BDT
  private stopTimerBdt() {
    if (this.timerBdt !== null) {
      clearTimeout(this.timerBdt);
    }
    this.timerBdt = null;
  }

  // Pido un token a la API de la BDT
  public async initSessionApiBdt(): Promise<boolean> {
    try {
      this.tokenBdt = await this.getTokenBdt();
      if (this.tokenBdt !== null) {
        return true;
      }
    } catch {
    }
    sessionStorage.removeItem('tokenBdt');
    return false;
  }

  // Pide un token a la API de IA
  private async getTokenIA(): Promise<TokenModel> {
    try {
      this.tokenIA = null;
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const body = new URLSearchParams();
      body.set('grant_type', environment.grantTypeIA);
      body.set('client_id', environment.clientIdIA);
      body.set('client_secret', environment.clientSecretIA);
      body.set('username', environment.userNameIA);
      body.set('password', environment.passwordIA);
      // Pido el token y lo guardo
      this.tokenIA = await this.http.post<TokenModel>(this.ssoService.getTicket().UrlApiIA + '/token/',
        body.toString(), httpOptions).toPromise();
      sessionStorage.setItem('tokenIA', this.tokenIA.access_token);
      // Activo un temporizador para pedir un nuevo token un minuto antes que caduque
      if (this.tokenIA.expires_in >= 120) {
        this.startTimerIA(this.tokenIA.expires_in - 60);
      } else {
        this.startTimerIA(Math.round(this.tokenIA.expires_in / 2));
      }
    } catch (e) {
      console.log(e);
    }
    return this.tokenIA;
  }

  // Inicia el temporizador para refrescar el token de la api de IA
  private startTimerIA(interval: number) {
    this.stopTimerIA();
    this.timerIA = setTimeout(() => {
      this.getTokenIA();
    }, interval * 1000);
  }

  // Finaliza el temporizador de refresco de tocken de la api de IA
  private stopTimerIA() {
    if (this.timerIA !== null) {
      clearTimeout(this.timerIA);
    }
    this.timerIA = null;
  }

  // Pido un token a la API de IA
  public async initSessionApiIA(): Promise<boolean> {
    try {
      this.tokenIA = await this.getTokenIA();
      if (this.tokenIA !== null) {
        return true;
      }
    } catch {
    }
    sessionStorage.removeItem('tokenIA');
    return false;
  }

  // Pide un token a la API de Cartografía
  private async getTokenCarto(): Promise<TokenModel> {
    try {
      this.tokenCarto = null;
      // Preparo la cabecera
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/x-www-form-urlencoded'
        })
      };
      const body = new URLSearchParams();
      body.set('grant_type', environment.carto.grantType);
      body.set('client_id', environment.carto.clientId);
      body.set('client_secret', environment.carto.clientSecret);
      body.set('username', environment.carto.userName);
      body.set('password', environment.carto.password);
      // Pido el token y lo guardo
      this.tokenCarto = await this.http.post<TokenModel>(environment.carto.baseUrl + '/token/',
        body.toString(), httpOptions).toPromise();
      sessionStorage.setItem('tokenCarto', this.tokenCarto.access_token);
      // Activo un temporizador para pedir un nuevo token un minuto antes que caduque
      if (this.tokenCarto.expires_in >= 120) {
        this.startTimerCarto(this.tokenCarto.expires_in - 60);
      } else {
        this.startTimerCarto(Math.round(this.tokenCarto.expires_in / 2));
      }
    } catch (e) {
      console.log(e);
    }
    return this.tokenCarto;
  }

  // Inicia el temporizador para refrescar el token de la api de Cartografía
  private startTimerCarto(interval: number) {
    this.stopTimerCarto();
    this.timerCarto = setTimeout(() => {
      this.getTokenCarto();
    }, interval * 1000);
  }

  // Finaliza el temporizador de refresco de tocken de la api de Cartografía
  private stopTimerCarto() {
    if (this.timerCarto !== null) {
      clearTimeout(this.timerCarto);
    }
    this.timerCarto = null;
  }

  // Pido un token a la API de Cartografía
  public async initSessionApiCarto(): Promise<boolean> {
    try {
      this.tokenCarto = await this.getTokenCarto();
      if (this.tokenCarto !== null) {
        return true;
      }
    } catch {
    }
    sessionStorage.removeItem('tokenCarto');
    return false;
  }

}
