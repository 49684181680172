import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxRibbonModule } from 'jqwidgets-ng/jqxribbon';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';
import { jqxDropDownButtonModule } from 'jqwidgets-ng/jqxdropdownbutton';

import { CiudadanosComponent } from './ciudadanos.component';
import { CiudadanosEditComponent } from './ciudadanos-edit/ciudadanos-edit.component';
import { CiudadanosTarjetasComponent } from './ciudadanos-tarjetas/ciudadanos-tarjetas.component';
import { DireccionListadoComponent } from './direccion/direccion-listado/direccion-listado.component';
import { CiudadanosDireccionEditComponent } from './ciudadanos-direccion-edit/ciudadanos-direccion-edit.component';
import { CiudadanosListadoTarjetasComponent } from './ciudadanos-listado-tarjetas/ciudadanos-listado-tarjetas.component';
import { SelectorCatastroComponent } from './direccion/selector-catastro/selector-catastro.component';
import { MapModule } from 'movisat-maps';

@NgModule({
  declarations: [
    CiudadanosComponent,
    CiudadanosEditComponent,
    DireccionListadoComponent,
    CiudadanosTarjetasComponent,
    CiudadanosDireccionEditComponent,
    CiudadanosListadoTarjetasComponent,
    SelectorCatastroComponent,
  ],
  imports: [
    MapModule,
    FormsModule,
    CommonModule,
    jqxGridModule,
    jqxInputModule,
    jqxRibbonModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    ReactiveFormsModule,
    jqxDateTimeInputModule,
    jqxDropDownButtonModule,
  ],
  exports: [
    CiudadanosComponent
  ]
})
export class CiudadanosModule { }
