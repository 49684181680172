<jqxWindow #form [theme]="theme" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth" [height]="mapHeight"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" (onClose)="onClose()" [resizable]="true">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Cerradura')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
      <div>
        <app-header #header
          [exportar]="true" [imprimir]="true"
          (eventExportar)="onExportar()" (eventImprimir)="onPrint()">
        </app-header>
      </div>
        <div style="width: 100%; height: 100%;">
            <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'80%'" [source]="dataAdapter"
                [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columns"
                [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="25"
                [columnsheight]="25" [editable]="false" [groupable]="true" [selectionmode]="'checkbox'"
                (onRowselect)="onSelect($event)" (onCellclick)="onCellClick($event)"
                (onRowunselect)="onUnSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>

            <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                [imgSrc]="'/assets/images/localizar.png'" [value]="translate('Centrar')" (onClick)="onPosicionar()">
            </jqxButton>
            <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="142" [height]="26"
                [hidden]="true" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                [imgSrc]="'/assets/images/localizar.png'" [value]="translate('Pedir_localizacion')"
                (onClick)="onLocalizar()">
            </jqxButton>
        </div>
    </div>
</jqxWindow>


<div hidden="viewForm" style="z-index: 1001;">
    <jqxWindow #formDetalle [theme]="theme"  [zIndex]="1001" [position]="getFormPos(formDetalle)" [width]="650"
        [height]="400" [autoOpen]="false" [showCloseButton]="true" [showCollapseButton]="true"
        (onClose)="onCloseForm()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Acciones')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden; ">
            <div>
                <div class="row">
                    <div>
                        <div>
                            {{translate('Tipo_producto')}}
                        </div>
                        <jqxInput [width]="210" [height]="25" [(ngModel)]="cerradura.tipoOperacionStr"
                            [disabled]="true">
                        </jqxInput>
                    </div>
                    <div>
                        <div>
                            {{translate('Ns_movisat')}}
                        </div>
                        <jqxInput [width]="210" [height]="25" [(ngModel)]="cerradura.nsMovisat" [disabled]="true">
                        </jqxInput>
                    </div>
                    <div>
                        <div>
                            {{translate('Ns_fabricante')}}
                        </div>
                        <jqxInput [width]="215" [height]="25" [(ngModel)]="cerradura.nsFabricante" [disabled]="true">
                        </jqxInput>
                    </div>
                </div>
                <div class="row">
                    <div>
                        <div>
                            {{translate('Imei')}}
                        </div>
                        <jqxInput [width]="290" [height]="25" [rtl]="true" [(ngModel)]="cerradura.imei"
                            [disabled]="true">
                        </jqxInput>

                        <div style="margin-top: 2px;">
                            {{translate('Sim')}}
                        </div>
                        <jqxInput [width]="290" [height]="25" [rtl]="true" [(ngModel)]="cerradura.sim"
                            [disabled]="true">
                        </jqxInput>
                    </div>

                    <div>
                    </div>

                    <div>
                        <fieldset>
                            <legend>
                                <div>IMSI</div>
                            </legend>
                            <div>
                                <jqxInput [width]="340" [height]="25" [rtl]="true" [ngModel]="cerradura.imsi"
                                    [disabled]="true">
                                </jqxInput>
                            </div>

                            <div>
                                <div>
                                    MCC
                                </div>
                                <jqxInput [width]="70" [height]="25" [rtl]="true" [(ngModel)]="cerradura.mcc"
                                    [disabled]="true">
                                </jqxInput>
                            </div>
                            <div>
                                <div>
                                    MNC
                                </div>
                                <jqxInput [width]="70" [height]="25" [rtl]="true" [(ngModel)]="cerradura.mnc"
                                    [disabled]="true">
                                </jqxInput>
                            </div>
                            <div>
                                <div>
                                    MSIN
                                </div>
                                <jqxInput [width]="200" [height]="25" [rtl]="true" [(ngModel)]="cerradura.icc"
                                    [disabled]="true">
                                </jqxInput>
                            </div>
                        </fieldset>
                    </div>
                </div>

            </div>

            <div class="row">
                <div>
                    <div>
                        {{translate('Nombre_elemento')}}
                    </div>
                    <jqxInput [width]="365" [height]="25" [ngModel]="cerradura?.elemento?.nombre" [disabled]="true">
                    </jqxInput>
                </div>

                <div>
                    <div>
                        {{translate('Matricula')}}
                    </div>
                    <jqxInput [width]="270" [height]="25" [ngModel]="cerradura?.elemento?.tag?.matricula"
                        [disabled]="true">
                    </jqxInput>
                </div>
            </div>
            <div class="row">
                <div>
                    <div>
                        {{translate('Marca')}}
                    </div>
                    <jqxInput [width]="315" [height]="25" [ngModel]="cerradura?.elemento?.marca" [disabled]="true">
                    </jqxInput>
                </div>
                <div>
                    <div>
                        {{translate('Modelo')}}
                    </div>
                    <jqxInput [width]="320" [height]="25" [ngModel]="cerradura?.elemento?.modelo" [disabled]="true">
                    </jqxInput>
                </div>

            </div>
            <div class="row">
                <div>
                    <div>
                        {{translate('Version')}}
                    </div>
                    <jqxInput [width]="315" [height]="25" [ngModel]="cerradura?.versoft" [disabled]="true">
                    </jqxInput>
                </div>
                <div>
                    <div>
                        {{translate('F_ult_conex')}}
                    </div>
                    <jqxInput [width]="320" [height]="25" [ngModel]="cerradura?.fechaModificacionStr" [disabled]="true">
                    </jqxInput>
                </div>

            </div>

            <div class="row">
                <div>
                    <div>
                        {{translate('Observaciones')}}
                    </div>
                    <jqxInput [width]="640" [height]="50" [ngModel]="cerradura?.elemento?.observaciones"
                        [disabled]="true">
                    </jqxInput>
                </div>
            </div>
        </div>
    </jqxWindow>
</div>
