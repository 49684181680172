import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { PeriodoSelectComponent } from '../periodo-select/periodo-select.component';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { jqxNumberInputComponent } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListComponent } from 'jqwidgets-ng/jqxdropdownlist';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  // Elementos html
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('searchInput') searchInput: HTMLInputElement;
  @ViewChild('checkMasXmetros') checkMasXmetros: jqxCheckBoxComponent;
  @ViewChild('currencyInput') currencyInput: jqxNumberInputComponent;
  @ViewChild('cbVolumetricos') cbVolumetricos: jqxDropDownListComponent;

  // Periodo select
  @Input() height: number = 25;
  @Input() noDia: boolean = false;
  @Input() noSemana: boolean = false;
  @Input() noMes: boolean = false;
  @Input() noTrimestre: boolean = false;
  @Input() noSemestre: boolean = false;
  @Input() noAnno: boolean = false;
  @Input() noLibre: boolean = false;

  // Mostrar/ocultar componentes
  @Input() exportar: boolean = false;
  @Input() imprimir: boolean = false;
  @Input() periodo: boolean = false;
  @Input() checkMetros: boolean = false;
  @Input() buscador: boolean = false;
  @Input() filtro: boolean = false;
  @Input() resetFiltro: boolean = false;
  @Input() volumetricos: boolean = false;

  // Lanza al accion cuando se pulsa un boton
  @Output() eventExportar: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventImprimir: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventCheckMasMetros: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventChangedNumber: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventFilter: EventEmitter<Boolean> = new EventEmitter;
  @Output() eventResetFilter: EventEmitter<Boolean> = new EventEmitter;


  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
  }

  // Detecta cambios en variables de entrada
  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      this[propName] = changes[propName].currentValue;
    }
  }

  onExportar() {
    this.eventExportar.emit(true);
  }

  onPrint() {
    this.eventImprimir.emit(true);
  }

  changeMasXmetros(event: any) {
    if (event.args.checked) {
      this.currencyInput.disabled(true);
      this.currencyInput.disabled(false);
    } else {
      this.currencyInput.disabled(true);
    }

    this.eventCheckMasMetros.emit(true);
  }

  onFilter() {
    this.eventFilter.emit(true);
  }

  onResetFilter() {
    this.eventResetFilter.emit(true);
  }
}
