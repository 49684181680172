<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth"
  [height]="mapHeight" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
      {{translate('Alarmas_geograficas')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div>
      <app-header #header
        [exportar]="true" [imprimir]="true"
        (eventExportar)="onExportar()" (eventImprimir)="onPrint()">
      </app-header>
    </div>
    <div style="float:left; margin-top: 6px; width: 100%;">
      <jqxGrid #gridAlarmas [theme]="environment.tema" [width]="'100%'" [height]="'90%'" [source]="dataAdapter"
        [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columns"
        [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
        [showstatusbar]="true" [statusbarheight]="20" [columnmenuopening]='columnmenuopening'
        [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [editable]="false"
        (onRowclick)=" onRowSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
      </jqxGrid>
    </div>
    <div style="float: left; margin-top: 6px;">
      <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
        [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"' [value]="translate('Ver')"
        (onClick)="onVerAlarma()">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
        [value]="translate('Crear')" (onClick)="onCrearAlarma()" [hidden]="!canEdit">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
        [value]="translate('Editar')" (onClick)="onEditarAlarma()" [hidden]="!canEdit">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"'
        [value]="translate('Borrar')" (onClick)="onBorrarAlarma()" [hidden]="!canEdit">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 20px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/subflota.png"'
        [value]="translate('Asignar')" (onClick)="onAsignarAlarma()" [hidden]="!canEdit">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit [position]="getFormPos(formEdit)" [width]="453" [height]="360" [zIndex]="100" [autoOpen]="true"
        [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
        (onClose)="onCloseEdit()" (onOpen)="onOpenEdit()" (onExpand)="onExpandEdit($event)">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_alarmas')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div style="margin-top: 5px; clear: both;">
                {{translate('Zona_cartografica')}}
                <jqxDropDownList #cbZonas style="float:left" [width]="385" [height]="25" [selectedIndex]="0"
                    (onSelect)="onChangeZona($event)">
                </jqxDropDownList>
                <div style="float: left;">
                    <jqxButton style="float:left; margin-left: 2px;" [width]='25' [height]='25'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                        [imgSrc]='"/assets/images/ver.png"' (onClick)="onVerZona($event)">
                    </jqxButton>
                    <jqxButton style="float:left; margin-left: 2px;" [width]='25' [height]='25'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                        [imgSrc]='"/assets/images/add-sb.png"' (onClick)="onChangeAreasZonas($event)">
                    </jqxButton>
                </div>
            </div>
            <div style="margin-top: 5px; float:left; clear:both;">
                {{translate('Nombre')}}
            </div>
            <div style="float:left; clear:both;">
                <jqxInput #ebNombre [(ngModel)]="alarmaEdit.Nombre" [width]="437" [height]="25" [disabled]="!canEdit">
                </jqxInput>
            </div>
            <div style="margin-top: 10px; float:left;">
                <input [(ngModel)]="alarmaEdit.Entrada" type="checkbox" (change)="onChangeCtrlEntrada($event)"
                    [disabled]="!canEdit" /> {{translate('Controlar_entrada')}}
            </div>
            <div style="float: right; margin-top: 10px; margin-right: 5px;">
                <jqxDateTimeInput #horaEntrada [(ngModel)]="alarmaEdit.HoraEntrada" [width]="95" [height]="22"
                    [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                    [showCalendarButton]="false"
                    [disabled]="!alarmaEdit.Entrada || !alarmaEdit.HayHoraEntrada || !canEdit">
                </jqxDateTimeInput>
            </div>
            <div style="margin-top: 10px; float:right; margin-right: 5px;">
                {{translate('Hora_maxima')}}
                <input [(ngModel)]="alarmaEdit.HayHoraEntrada" type="checkbox" (change)="onChangeHoraEntrada($event)"
                    [disabled]="!alarmaEdit.Entrada || !canEdit" />
            </div>
            <div style="margin-top: 10px; float:left; clear: both">
                <input [(ngModel)]="alarmaEdit.Salida" type="checkbox" (change)="onChangeCtrlSalida($event)"
                    [disabled]="!canEdit" /> {{translate('Controlar_salida')}}
            </div>
            <div style="float: right; margin-top: 10px; margin-right: 5px;">
                <jqxDateTimeInput #horaSalida [(ngModel)]="alarmaEdit.HoraSalida" [width]="95" [height]="22"
                    [dropDownVerticalAlignment]="'top'" [formatString]="'HH:mm:ss'" [showTimeButton]="true"
                    [showCalendarButton]="false"
                    [disabled]="!alarmaEdit.Salida || !alarmaEdit.HayHoraSalida || !canEdit">
                </jqxDateTimeInput>
            </div>
            <div style="margin-top: 10px; margin-right: 5px; float:right;">
                {{translate('Hora_maxima')}}
                <input [(ngModel)]="alarmaEdit.HayHoraSalida" type="checkbox" (change)="onChangeHoraSalida($event)"
                    [disabled]="!alarmaEdit.Salida || !canEdit" />
            </div>
            <div style="margin-top: 14px; float:left; clear: both">
                <input [(ngModel)]="alarmaEdit.Repetir" type="checkbox" (change)="onChangeRepetir($event)"
                    [disabled]="!canEdit" /> {{translate('Repetir')}}
            </div>
            <div style="float: left; margin-left: 15px;">
                <div style="float:left; clear: both;">
                    <div style="float:left; margin-left: 1px;">L</div>
                    <div style="float:left; margin-left: 7px;">M</div>
                    <div style="float:left; margin-left: 5px;">X</div>
                    <div style="float:left; margin-left: 8px;">J</div>
                    <div style="float:left; margin-left: 7px;">V</div>
                    <div style="float:left; margin-left: 7px;">S</div>
                    <div style="float:left; margin-left: 5px;">D</div>
                </div>
                <div style="float:left; clear: both;">
                    <input [(ngModel)]="dias.lunes" type="checkbox" style="float:left;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                    <input [(ngModel)]="dias.martes" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                    <input [(ngModel)]="dias.miercoles" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                    <input [(ngModel)]="dias.jueves" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                    <input [(ngModel)]="dias.viernes" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                    <input [(ngModel)]="dias.sabado" type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                    <input [(ngModel)]="dias.domingo " type="checkbox" style="float:left; margin-left: 2px;"
                        [disabled]="!alarmaEdit.Repetir || !canEdit" />
                </div>
            </div>
            <div style="margin-top: 10px; float:left; clear:both; ">
                {{translate('Lista_emails')}} (sep = ;)
            </div>
            <div style="float:left; clear: both; ">
                <jqxInput [(ngModel)]="alarmaEdit.Emails" [width]="437" [height]="25" [disabled]="!canEdit">
                </jqxInput>
            </div>
            <div style="margin-top: 5px; float:left; clear:both; ">
                {{translate('Observaciones')}}
            </div>
            <div style="float:left; clear: both; ">
                <jqxInput [(ngModel)]="alarmaEdit.Observaciones" [width]="437" [height]="25" [disabled]="!canEdit">
                </jqxInput>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both;">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarAlarma($event)"
                    [value]="translate('Guardar')" [disabled]="!canEdit">
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
    <div #menuContainer></div>
</div>

<div *ngIf="mostrarAsigForm">
    <jqxWindow #formAsig style="overflow: hidden;" [theme]="environment.tema" [position]="getFormPos(formAsig)"
        [width]="950" [height]="445" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true"
        [showCollapseButton]="true" [resizable]="false" (onClose)="onCloseAsig()" (onOpen)="onOpenAsig()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Asignacion_alarmas')}}
        </div>
        <div class="form" style="float:left; overflow: hidden;">
            <div style="float: left; margin-top: 5px; clear: both;">
                <div style="float: left; width: 450px; height: 340px;">
                    <div style="float: none; padding-top: 4px;">{{translate('Moviles_disponibles')}}</div>
                    <jqxGrid #gridMoviles1 [theme]="environment.tema" [height]="'100%'" [width]="'100%'"
                        [source]="dataAdapterMoviles1" [columnsresize]="true" [sortable]="true" [showfilterrow]="true"
                        [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true"
                        [showrowlines]="false" [columns]="columnsMoviles1" [altrows]="true" [enabletooltips]="true"
                        [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true" [filterrowheight]="30"
                        [rowsheight]="20" [columnsheight]="20" [showaggregates]="true" [showstatusbar]="true"
                        [statusbarheight]="20" [localization]="langGrid" [disabled]="!canEdit">
                    </jqxGrid>
                </div>
                <div style="float: left; margin-left: 6px; margin-top: 150px; width: 25px;">
                    <jqxButton style="float:left; clear: both;" [width]='25' [height]='26'
                        [textImageRelation]='"imageAfterText"' [textPosition]='"left"' [imgPosition]='"right"'
                        [imgSrc]='"/assets/images/derecha.png"' (onClick)="onMasClick()" [disabled]="!canEdit">
                    </jqxButton>
                    <jqxButton style="float:left; margin-top: 5px; clear: both;" [width]='25' [height]='26'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"'
                        [imgSrc]='"/assets/images/izquierda.png"' (onClick)="onMenosClick()" [disabled]="!canEdit">
                    </jqxButton>
                </div>
                <div style="float: right; margin-left: 4px; width: 450px; height: 340px;">
                    <div style="float: none; padding-top: 4px;">{{translate('Moviles_asignados')}}</div>
                    <jqxGrid #gridMoviles2 [theme]="environment.tema" [height]="'100%'" [width]="'100%'"
                        [source]="dataAdapterMoviles2" [columnsresize]="true" [sortable]="true" [showfilterrow]="true"
                        [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true"
                        [showrowlines]="false" [columns]="columnsMoviles2" [altrows]="true" [enabletooltips]="true"
                        [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true" [filterrowheight]="30"
                        [rowsheight]="20" [columnsheight]="20" [showaggregates]="true" [showstatusbar]="true"
                        [statusbarheight]="20" [localization]="langGrid" [disabled]="!canEdit">
                    </jqxGrid>
                </div>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 15px; clear: both;">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]="'imageBeforeText'"
                    [textPosition]="'center'" [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarAsig()"
                    [value]="translate('Guardar')" [disabled]="!canEdit">
                </jqxButton>
            </div>
        </div>
    </jqxWindow>


</div>
