<jqxWindow #form [theme]="theme" [position]="getFormPos(form, 0)" [width]="mapWidth" [height]="mapHeight" [zIndex]="999"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()"
    [resizable]="true">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Historico_envios_dispositivo_identificador')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <jqxTabs #myTabs [width]="'100%'" [height]="'100%'" [theme]="theme" [scrollable]="false">
            <ul>
                <li>I</li>
                <li>II</li>
            </ul>
            <div style="width: 100%;">
              <div>
                <app-header #header
                  [exportar]="true" [imprimir]="true" [periodo]="true" [buscador]="true" [checkMetros]="true" [filtro]="true"
                  [noTrimestre]="true" [noSemestre]="true" [noLibre]="true"
                  (eventExportar)="onExportar()" (eventFilter)="onAceptar()" (eventResetFilter)="onResetFilter()" (eventCheckMasMetros)="changeMasXmetros()">
                </app-header>
              </div>
              <jqxGrid #myGrid [theme]="theme" [width]="'99%'" [height]="'90%'" [source]="dataAdapter"
                  [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
                  [columns]="columns" [altrows]="true" [filterable]="true" [showstatusbar]="true"
                  [statusbarheight]="20" [rowdetails]="true" [showaggregates]="true" [rowsheight]="20"
                  [columnsheight]="20" [localization]="langGrid" [groupable]="true" [selectionmode]="'multiplerows'"
                  [rowdetailstemplate]="rowdetailstemplate" [initrowdetails]="initRowDetails"
                  (onRowexpand)="rowExpand($event)" (onRowdoubleclick)="onRowdoubleclick($event)"
                  (onSort)="onSort($event)" (onRowclick)="onRowClick($event)">
              </jqxGrid>
            </div>
            <div style="height: 460px;">
                <movisat-map [provider]="mapProvider" (mapReady)="onMapReady($event)" [cartoType]="cartoType"
                    [lang]="lang" [texts]="langService.getCurrentTexts()" [searchCountry]="searchCountry" [zoom]="zoom"
                    [center]="center"></movisat-map>
            </div>
        </jqxTabs>
    </div>
</jqxWindow>
