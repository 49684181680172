<div style="width: 260px; height: 100%;">
    <jqxDropDownList #selectSubflotas [width]="'101%'" [height]="'25px'" [selectedIndex]="0"
        (onSelect)="onChangeSubflotas($event)">
    </jqxDropDownList>
    <jqxGrid #grid [theme]="environment.tema" style="margin-top: 0px;" [width]="'100%'" [height]="'93%'"
        [source]="dataAdapter" [columnsresize]="true" [filterable]="true" [showfilterrow]="false" [filtermode]="'excel'"
        [selectionmode]="'checkbox'" [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false"
        [filterrowheight]="30" [rowsheight]="25" [columnsheight]="20" [enabletooltips]="true" [showaggregates]="true"
        [showstatusbar]="true" [showtoolbar]="true" [statusbarheight]="20" [toolbarheight]="32"
        [localization]="langGrid" [rendertoolbar]="createToolBar" (onRowselect)="onSelect()"
        (onRowunselect)="onSelect()">
    </jqxGrid>
</div>