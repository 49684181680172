export const environment = {
  production: true,

  apiSSO: 'https://api.sso.movisat.com',
  webSSO: 'https://my.movisat.com',

  refresMovilInterval: 60000, // Intervalo de refresco móviles
  refresPosInterval: 15000, // Intervalo de refresco posiciones
  refresInfoGeoInterval: 15000, // Intervalo de refresco de la información geográfica de los móviles
  tema: 'material', // Tema para los componentes JQWidgets

  jqLicense: '75CE8878-FCD1-4EC7-9249-BA0F153A5DE8', // Licencia para los componentes JqWidgets

  // Apikeys para los mapas
  hereApiKeyRest: 'aIgBsvCxZ4of7oky548NbfL2TkNmS80BFxugP8qEQL4',
  hereApiKey: 'a4z5-gM5BQkV7MuMc3abCDhycwT4ZHaKGaWFfSwuNBU',
  googleApiKey: 'AIzaSyA5gkvX4XR8KN6KnhW72rMvfrmjkaACehM',

  // API SSO
  grantTypeSSO: 'password',
  clientIdSSO: 'U32FFNX5AG39DECT',
  clientSecretSSO: 'M65Y4L658QNFKQ2PSQWX5S5XEC7WDNBVSV2UH2GRGNZGEUATQX',
  userNameSSO: 'movisat@np',
  passwordSSO: 'M0v15AT@np2021',

  // API NP
  grantTypeApi: 'password',
  clientIdApi: 'OGUyYjMwYTMtZGYyMC00NDI3LTg3ZDEtNjIwYTY4YTA5N2Nk',
  clientSecretApi: 'NzZkZjQ5MTA4YzNlMDY0NmY0NDIzNzM1N2I3YmZhYTE=',
  userNameApi: 'movisat@np',
  passwordApi: 'M0v15AT@np2021',

  // API BDT
  urlApiBdt: 'https://api.bdt.movisat.com',
  grantTypeBdt: 'password',
  clientIdBdt: '2',
  clientSecretBdt: 'HoPqWmkPjUsQ87KIoCl5RriFnkJpBT9vGzFMd9dv',
  userNameBdt: 'userNp@movisat.com',
  passwordBdt: 'XCsEHZQK7u2IRusb5ffb',

  // API IA
  grantTypeIA: 'password',
  clientIdIA: 'S1crjOue0VeU0nmPTJQclWxuNQdWpjy4YMWlfoOV',
  clientSecretIA: 'e7Qo74duNmSM6TyO4YhoGdJN8l8IGrWKQh9GNvrK0SaoMKKBUHOnnL9dgtdX92v12nSTU4olr6ppTDfuHwSx8yGrANZDTJTtE7yRpsuFGOGD58Npq9Y2YNL71gyv4jam',
  userNameIA: 'ecoevolution@movisat.com',
  passwordIA: 'M0v15AT@ia2021',

  //API CARTO
  carto: {
    baseUrl: 'https://api.carto.movisat.com',
    grantType: 'password',
    clientId: 'S1crjOue0VeU0nmPTJQclWxuNQdWpjy4YMWlfoOV',
    clientSecret: 'e7Qo74duNmSM6TyO4YhoGdJN8l8IGrWKQh9GNvrK0SaoMKKBUHOnnL9dgtdX92v12nSTU4olr6ppTDfuHwSx8yGrANZDTJTtE7yRpsuFGOGD58Npq9Y2YNL71gyv4jam',
    userName: 'carto@movisat.com',
    password: 'M0v15AT@carto2021'
  }
}
