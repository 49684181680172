<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #form [theme]="environment.tema" [width]="1110" [height]="528" [zIndex]="50" [position]="getFormPos(form)"
  [autoOpen]="true" [zIndex]="90" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_volumetricos')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <app-header #header
      [exportar]="true" [periodo]="true" [volumetricos]="true" [filtro]="true"
      [noAnno]="true" [noTrimestre]="true" [noSemestre]="true" [noLibre]="false"
      (eventExportar)="onExportar()" (eventFilter)="onAceptar()">
    </app-header>

    <div style="float:left; margin-top: 6px; width: 100%; height: 400px;">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
        [columnsresize]="true" [sortable]="true" [showrowlines]="false" [showcolumnlines]="true"
        [columns]="columns" [altrows]="true" [sortmode]="'many'" [enabletooltips]="true" [filterable]="false"
        [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
        [columnsheight]="20" [localization]="langGrid" [groupable]="true" [showtoolbar]="true"
        [rendertoolbar]="createToolBar" (onBindingcomplete)="onBindingComplete()"
        (onRowclick)="onRowClick($event)">
      </jqxGrid>
    </div>
    <div style="float: left; margin-top: 8px; width: 100%;">
      <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
        [textPosition]='"left"' [imgSrc]='"/assets/images/center.png"' [value]="translate('Centrar')"
        (onClick)="onCentrar($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]='90' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/grafica.png"'
        [value]="translate('Grafico')" (onClick)="onGrafica($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]='150' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/resumen.png"'
        [value]="translate('Resumen') + ' ' + translate('Periodo').toLowerCase()" (onClick)="onResumen($event)">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 4px;" [width]='80' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/total.png"'
        [value]="translate('Total')" (onClick)="onTotal($event)">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
