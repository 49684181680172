import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DateUtils } from 'src/app/utils/date-utils';
import { SsoService } from '../sso/sso.service';
import { SensorDatosModel } from './models/sensor-datos.model';
import { SensorModel } from './models/sensor.model';
import { MovilModel } from '../resources/models/movil.model';
import { ResourcesService } from '../resources/resources.service';

@Injectable({
  providedIn: 'root'
})
export class SensorsService {
  private ready = false;
  private loadTimer = null;
  private sensores = new Map<number, SensorModel>();
  private TIME_SENSOR = 60000; // Intervalo para recuperar los sensores

  constructor(
    private http: HttpClient,
    private ssoService: SsoService,
    private resourceServices: ResourcesService) {

    this.loadSensors();
    // Recupero los sensores cada minuto
    this.loadTimer = setTimeout(() => {
      this.loadSensors();
    }, this.TIME_SENSOR);
  }

  // Recupera todos los sensores de la empresa actual
  private async loadSensors(): Promise<void> {
    try {
      // Recupero todos los sensores de la empresa
      const result = await this.http.get<SensorModel[]>(this.ssoService.getTicket().UrlApi + '/api/sensores').toPromise();
      if (result) {
        // Meto todos los sensores en una tabla hash
        result.forEach(sensor => {
          sensor.Icono = sensor.Icono ? 'data:image/png;base64,' + sensor.Icono : 'assets/images/sensores/1.png';
          this.sensores.set(sensor.Id, sensor);
        });
        this.ready = true;
      }
    } catch (e) {
      console.log(e);
    } finally {
      if (this.loadTimer !== null) {
        clearTimeout(this.loadTimer);
      }
      // Recupero los sensores cada minuto
      this.loadTimer = setTimeout(() => {
        this.loadSensors();
      }, this.TIME_SENSOR);
    }
  }

  // Devuelve los sensores almacenados en la tabla, si todavía no se han
  // recuperado todos lo hago en este momento
  public async getSensors(): Promise<SensorModel[]> {
    const result: SensorModel[] = [];
    if (!this.ready) {
      await this.loadSensors();
    }
    // Genero el array a partir de la tabla hash
    for (const [key, value] of this.sensores) {
      if (value.Codigo) {  // Sólo sensores de la BDT
        result.push(value);
      }
    }
    result.sort((a, b) => (a.Nombre < b.Nombre) ? -1 : 1)
    return result;
  }


  // Recupera todos los sensores de un móvil
  public async getSensorsByMovil(movil: number): Promise<SensorModel[]> {
    let result: SensorModel[] = [];
    try {
      // Recupero todos los sensores del móvil
      result = await this.http.get<SensorModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/sensores/movil/catalogo?movil=' + movil).toPromise();
      if (result) {
        // Meto todos los sensores en una tabla hash
        result.forEach(sensor => {
          sensor.Icono = sensor.Icono ? 'data:image/png;base64,' + sensor.Icono : 'assets/images/sensores/1.png';
        });
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }


  // Devuelve los sensores de un móvil entre fechas
  async getSensoresMovil(movil: number, desde: Date, hasta: Date): Promise<SensorDatosModel[]> {
    let result: SensorDatosModel[] = [];
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };
      const body = {
        movil: movil,
        desde: DateUtils.formatDateTime(desde, false),
        hasta: DateUtils.formatDateTime(hasta, false)
      }
      const res = await this.http.post<SensorDatosModel[]>(this.ssoService.getTicket().UrlApi + '/api/sensores/movil',
        JSON.stringify(body), httpOptions).toPromise();
      if (res) {
        res.forEach(row => {
          row.Fecha = new Date(row.Fecha);
          row.sensor = this.getSensor(row.SensorId);
          if (row.sensor && row.sensor.Codigo) { // Sólo sensores de la BDT
            row.movil = this.resourceServices.moviles.get(row.MovilId);
            if (row.movil) {
              result.push(row);
            }
          }
        });
      }
    } catch {
    }
    return result;
  }

  // Devuelve los sensores de un array de móviles entre fechas
  async getSensoresMoviles(movilesSelect: MovilModel[], desde: Date, hasta: Date): Promise<SensorDatosModel[]> {
    this.loadSensors();
    const codigosMovil: number[] = [];
    movilesSelect.forEach(movil => {
      codigosMovil.push(movil.Codigo);
    });
    let result: SensorDatosModel[] = [];
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        }),
      };
      const body = {
        moviles: codigosMovil,
        desde: DateUtils.formatDateTime(desde, false),
        hasta: DateUtils.formatDateTime(hasta, false)
      }
      const res = await this.http.post<SensorDatosModel[]>(this.ssoService.getTicket().UrlApi + '/api/sensores/moviles',
        JSON.stringify(body), httpOptions).toPromise();
      if (res) {
        res.forEach(row => {
          row.Fecha = new Date(row.Fecha);
          row.sensor = this.getSensor(row.SensorId);
          if (row.sensor && row.sensor.Codigo) { // Sólo sensores de la BDT
            row.movil = this.resourceServices.moviles.get(row.MovilId);
            if (row.movil) {
              result.push(row);
            }
          }
        });
      }
    } catch {
    }
    return result;
  }

  // Devuelve la información de un sensor
  public getSensor(id: number): SensorModel {
    const sensor = this.sensores.get(id);
    return sensor ? sensor : null;
  }
}
