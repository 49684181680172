import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';

import { SsoService } from 'src/app/services/sso/sso.service';
import { SensorsService } from 'src/app/services/sensors/sensors.service';
import { InformesService } from 'src/app/services/reports/reports-service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { SubflotaModel } from 'src/app/services/flota/models/subflota.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { SensorMovilidadModel } from 'src/app/services/reports/models/sensor-movilidad-model';
import { InformeMovilidadModel } from 'src/app/services/reports/models/informe-movilidad-model';
import { EDigitalMovilidadModel } from 'src/app/services/reports/models/edigital-movilidad-model';

import { PeriodoSelectTipo } from '../../../periodo-select/periodo-select.component';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-listado-movilidad',
  templateUrl: './listado-movilidad.component.html',
  styleUrls: ['./listado-movilidad.component.css']
})
export class ListadoMovilidadComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('mainSplitter') mainSplitter: jqxSplitterComponent;

  private componentRef = null;
  public environment = environment;
  public eventSplitter: boolean;
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;

  // Variables para generar el listado
  private informe: InformeMovilidadModel[] = [];
  private entradasDigitales: EDigitalMovilidadModel[] = [];
  private sensores: SensorMovilidadModel[] = [];
  private movilesSelect: MovilModel[] = [];
  public movilesInforme: MovilModel[] = [];
  public subflotas: SubflotaModel[] = [];
  public dataSource: any = [];
  public dataAdapter = new jqx.dataAdapter(this.dataSource);

  // Preparo las columnas del grid
  public columns: any[];
  public columngroups = [];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private informesService: InformesService,
    private sensorService: SensorsService,
    private auditoriaService: AuditoriaService) {
    super();
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Informe_movilidad'));

    const t = setTimeout(() => {
      clearTimeout(t);
      this.header.periodoSelect.setPeriodo(PeriodoSelectTipo.DIA);
      this.fechaIni = this.header.periodoSelect.getFechaIni();
      this.fechaFin = this.header.periodoSelect.getFechaFin();
      this.initListado();
    }, 500);
    this.mainSplitter
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  initListado() {
    this.columngroups = [
      { text: AppComponent.translate('Tiempo_ignicion'), align: 'center', name: 'ignicionGroup' },
      { text: AppComponent.translate('Paradas'), align: 'center', name: 'paradasGroup' }
    ];
    this.columns = [
      { text: AppComponent.translate('Movil'), columntype: 'textbox', filtertype: 'textbox', datafield: 'movil', width: 180 },
      { text: AppComponent.translate('Inicio'), columntype: 'date', filtertype: 'date', datafield: 'fechaIA', cellsformat: 'dd/MM/yy HH:mm:ss', width: 140 },
      { text: AppComponent.translate('Fin'), columntype: 'date', filtertype: 'date', datafield: 'fechaFA', cellsformat: 'dd/MM/yy HH:mm:ss', width: 140 },
      {
        text: AppComponent.translate('Total'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoTotalSegundos',
        align: 'center', width: 100, cellsalign: 'right', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Activa'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'ignicionGroup', datafield: 'tiempoIgnicionActivaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Inactiva'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'ignicionGroup', datafield: 'tiempoIgnicionInactivaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Ralenti'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoRalentiSegundos',
        align: 'center', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Marcha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tiempoMarchaSegundos',
        align: 'center', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Distancia') + ' (km)', columntype: 'textbox', filtertype: 'textbox', datafield: 'distanciaTotal',
        align: 'center', width: 110, cellsalign: 'center',
        cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
          return '<div style="text-align: center;">' + NumberUtils.format(value, 0) + '</div>';
        },
        aggregates: [{
          'Tot': (aggregatedValue: number, currentValue: number) => {
            return currentValue + aggregatedValue;
          }
        }],
        aggregatesrenderer: (aggregates: number) => {
          return aggregates["Tot"] ? '<div style="text-align: right; margin-right: 4px">' + NumberUtils.format(aggregates["Tot"], 0) + '</div>' : '';
        }
      },
      {
        text: AppComponent.translate('Num_paradas'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'numeroParadas', width: 100, cellsalign: 'center', cellsrenderer: this.numberrenderer
      },
      {
        text: AppComponent.translate('Tiempo_parado'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadoSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Minimo'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadaMinimaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Maximo'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadaMaximaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      },
      {
        text: AppComponent.translate('Medio'), columntype: 'textbox', filtertype: 'textbox', align: 'center',
        columngroup: 'paradasGroup', datafield: 'tiempoParadaMediaSegundos', width: 100, cellsalign: 'center', cellsrenderer: this.secondsRenderer
      }
    ];
    // Creo las columnas para las entradas digitales
    if (this.entradasDigitales) {
      this.entradasDigitales.forEach(ed => {
        // Creo un grupo para cada entrada
        this.columngroups.push({
          text: ed.nombreSensor, align: 'center', name: ed.entradaDigital + '-edGroup'
        });
        // Creo las columnas
        this.columns.push({
          text: AppComponent.translate('Activaciones'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
          columngroup: ed.entradaDigital + '-edGroup', datafield: ed.entradaDigital + '-ed-numeroActivaciones', width: 80, cellsalign: 'right',
          cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
            return '<div style="text-align: center;">' + NumberUtils.format(value, 0) + '</div>';
          }
        });
        this.columns.push({
          text: AppComponent.translate('Distancia') + ' (Km)', columntype: 'textbox', filtertype: 'textbox', align: 'right',
          columngroup: ed.entradaDigital + '-edGroup', datafield: ed.entradaDigital + '-ed-distancia', width: 110, cellsalign: 'right',
          cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
            return '<div style="text-align: right; margin-right: 4px; margin-top: 5px">' + NumberUtils.format(value, 2) + '</div>';
          },
          aggregates: [{
            'TotDistED': (aggregatedValue: number, currentValue: number) => {
              if (currentValue) {
                return currentValue + aggregatedValue;
              }
              return aggregatedValue;
            }
          }],
          aggregatesrenderer: (aggregates: number) => {
            return aggregates["TotDistED"] ? '<div style="text-align: right; margin-right: 4px;">' + NumberUtils.format(aggregates["TotDistED"], 2) + '</div>' : '';
          }
        });
        this.columns.push({
          text: AppComponent.translate('Duracion'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
          columngroup: ed.entradaDigital + '-edGroup', datafield: ed.entradaDigital + '-ed-duracion', width: 100, cellsalign: 'right', cellsrenderer: this.secondsRenderer
        });
      });
    }
    // Creo las columnas para los sensores
    if (this.sensores) {
      this.sensores.forEach(se => {
        if (se.idSensor === 42) { // Peso neto
          // Creo un grupo para cada sensor
          this.columngroups.push({
            text: se.nombreSensor, align: 'center', name: se.idSensor + '-seGroup'
          });
          // Creo las columnas del grupo
          this.columns.push({
            text: AppComponent.translate('Cantidad'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
            columngroup: se.idSensor + '-seGroup', datafield: se.idSensor + '-se-cantidad', width: 100, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right; margin-right: 4px;">' + NumberUtils.format(value, 2) + '</div>';
            },
            // aggregates: [{
            //   'TotCantSE': (aggregatedValue: number, currentValue: number) => {
            //     if (currentValue) {
            //       return currentValue + aggregatedValue;
            //     }
            //     return aggregatedValue;
            //   }
            // }],
            // aggregatesrenderer: (aggregates: number) => {
            //   return aggregates["TotCantSE"] ? '<div style="text-align: center;">' + NumberUtils.format(aggregates["TotCantSE"], 2) + '</div>' : '';
            // }
          });
          this.columns.push({
            text: AppComponent.translate('Suma'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
            columngroup: se.idSensor + '-seGroup', datafield: se.idSensor + '-se-suma', width: 110, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right;margin-right: 4px;">' + NumberUtils.format(value, 2) + '</div>';
            },
            // aggregates: [{
            //   'TotSumSE': (aggregatedValue: number, currentValue: number) => {
            //     if (currentValue) {
            //       return currentValue + aggregatedValue;
            //     }
            //     return aggregatedValue;
            //   }
            // }],
            // aggregatesrenderer: (aggregates: number) => {
            //   return aggregates["TotSumSE"] ? '<div style="text-align: center;">' + NumberUtils.format(aggregates["TotSumSE"], 2) + '</div>' : '';
            // }
          });
          this.columns.push({
            text: AppComponent.translate('Media'), columntype: 'textbox', filtertype: 'textbox', align: 'right',
            columngroup: se.idSensor + '-seGroup', datafield: se.idSensor + '-se-media', width: 80, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right; margin-right: 4px;">' + NumberUtils.format(value, 2) + '</div>';
            }
          });
        } else {
          // Creo las columnas sin grupo
          this.columns.push({
            text: se.nombreSensor, columntype: 'textbox', filtertype: 'textbox', align: 'right',
            datafield: se.idSensor + '-se-cantidad', width: 100, cellsalign: 'right',
            cellsrenderer: (row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any) => {
              return '<div style="text-align: right; margin-right: 4px">' + NumberUtils.format(value, 2) + '</div>';
            },
            // aggregates: [{
            //   'TotCantSE': (aggregatedValue: number, currentValue: number) => {
            //     if (currentValue) {
            //       return currentValue + aggregatedValue;
            //     }
            //     return aggregatedValue;
            //   }
            // }],
            // aggregatesrenderer: (aggregates: number) => {
            //   return aggregates["TotCantSE"] ? '<div style="text-align: center;">' + NumberUtils.format(aggregates["TotCantSE"], 2) + '</div>' : '';
            // }
          });
        }
      });
    }
  }

  async onAceptar() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    const moviles: number[] = [];
    if (this.movilesSelect.length > 0) {
      this.movilesSelect.forEach(mov => {
        moviles.push(mov.Codigo);
      });
    } else {
      MainComponent.showWarning('ATENCION', 'Seleccione_movil', 2000);
      return;
    }
    let periodo = 0;
    switch (this.header.periodoSelect.getPeriodo()) {
      case 0:
        periodo = 1; // Dia
        break;
      case 1:
        periodo = 2; // Semana
        break;
      case 2:
        periodo = 3; // Mes
        break;
      default:
        periodo = 4; // Año
        break;
    }
    this.loader.open();
    // Recupero los sensores
    await this.sensorService.getSensors();
    // Recupero el listado de movilidad
    const result = await this.informesService.getInformeMovilidad(periodo, moviles, this.fechaIni, this.fechaFin);
    this.informe = result.datos;
    this.entradasDigitales = result.entradasDigitales;

    // TODO: Esto es temporal hasta que se sepa que hay realmente conectado en cada una de las entradas digitales
    this.entradasDigitales.forEach(entrada => {
      entrada.nombreSensor = 'Entrada digital ' + entrada.entradaDigital;
    });
    this.sensores = [];
    result.sensores.forEach(sensor => {
      const s = this.sensorService.getSensor(sensor.idSensor);
      if (s && s.Codigo) { // Sólo sensores BDT
        this.sensores.push(sensor);
      }
    });
    // this.sensores = result.sensores;
    this.generaInforme();
    this.generateEventSplitter();
    this.loader.close();

  }
  // Metodo para cerrar el splitter cuando le damos clic al boton aceptar
  generateEventSplitter() {
    this.mainSplitter.collapse();
  }

  // Genera el informe con los datos obtenidos
  generaInforme() {
    if (this.informe !== undefined && this.informe !== null) {
      this.initListado();
      const datafields = [
        { name: 'movil', map: 'Movil>Nombre', type: 'string' },
        { name: 'fechaIA', type: 'date' },
        { name: 'fechaFA', type: 'date' },
        { name: 'tiempoTotalSegundos', type: 'number' },
        { name: 'tiempoIgnicionActivaSegundos', type: 'number' },
        { name: 'tiempoIgnicionInactivaSegundos', type: 'number' },
        { name: 'tiempoRalentiSegundos', type: 'number' },
        { name: 'tiempoMarchaSegundos', type: 'number' },
        { name: 'distanciaTotal', type: 'int' },
        { name: 'numeroParadas', type: 'int' },
        { name: 'tiempoParadoSegundos', type: 'number' },
        { name: 'tiempoParadaMinimaSegundos', type: 'number' },
        { name: 'tiempoParadaMaximaSegundos', type: 'number' },
        { name: 'tiempoParadaMediaSegundos', type: 'number' }
      ];
      // Añado los campos para las entradas digitales
      if (this.entradasDigitales) {
        this.entradasDigitales.forEach((ed, i) => {
          datafields.push({ name: ed.entradaDigital + '-ed-numeroActivaciones', map: 'entradasDigitales>' + ed.entradaDigital + '>numeroActivaciones', type: 'int' });
          datafields.push({ name: ed.entradaDigital + '-ed-distancia', map: 'entradasDigitales>' + ed.entradaDigital + '>distancia', type: 'string' });
          datafields.push({ name: ed.entradaDigital + '-ed-duracion', map: 'entradasDigitales>' + ed.entradaDigital + '>duracionSegundos', type: 'number' });
        });
      }
      // Añado los campos para los sensores
      if (this.sensores) {
        this.sensores.forEach((se, i) => {
          datafields.push({ name: se.idSensor + '-se-cantidad', map: 'sensores>' + se.idSensor + '>cantidad', type: 'float' });
          if (se.idSensor === 42) { // Peso neto
            datafields.push({ name: se.idSensor + '-se-suma', map: 'sensores>' + se.idSensor + '>suma', type: 'float' });
            datafields.push({ name: se.idSensor + '-se-media', map: 'sensores>' + se.idSensor + '>media', type: 'float' });
          }
        });
      }
      this.dataSource = {
        datatype: 'json',
        datafields: datafields,
        localdata: this.informe
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);

      const t = setTimeout(() => {
        clearTimeout(t);
        this.grid.autoresizecolumns();
      }, 500);
    }
  }

  movilesSelected(mov: MovilModel[]) {
    this.movilesSelect = mov;
  }

  onExportar() {
    // this.grid.exportdata('xls', DateUtils.formatDateAMDhms(new Date()) + '_InformeMovilidad');
    const json = this.grid.exportdata('json');
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(JSON.parse(json));
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_InformeMovilidad.xlsx');
    this.auditoria.AccionId = Accion.EXPORTAR_INFORME_MOVILIDAD;
    this.auditoriaService.addAuditoria(this.auditoria);
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; text-align: right;">' +
        NumberUtils.format(value, 2) +
        '</div>'
      );
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  secondsRenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; text-align: right;">' +
        DateUtils.numberToDaysHoursMinutesSeconds(value) +
        '</div>'
      );
    } else {
      return (
        '<div style="margin-right: 4px; text-align: right;">' +
        value +
        '</div>'
      );
    }
  }
}
