<div id="fieldDetalleGeneral">
  <fieldset>
    <legend>{{ translate('Generales') }}</legend>

    <div class="row">
      <span style="width: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tiempo_recogida") }}
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.TiempoRecogida"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.TiempoRecogida = $event : ''"
          [render]="renderer()" [width]="'100%'" [renderizar]="false">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tipo_material") }}
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.TipoMaterial"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.TipoMaterial = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2" [renderizar]="false">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tiene_ruedas_pregunta") }}
        </div>
        <jqxDropDownList [ngModel]="elemEdit.Equipamiento?.Ruedas"
          (ngModelChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Ruedas = $event : null"
          [width]="'100%'" [dropDownHeight]="60" [source]="sourceRuedas" [selectedIndex]="1">
        </jqxDropDownList>
      </span>
    </div>

    <div class="row">
      <span style="width: 100%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Area_influencia") }} (m)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.AreaInfluencia"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AreaInfluencia = $event : ''"
          [render]="renderer()" [width]="'100%'">
        </app-input-number>
      </span>
    </div>
  </fieldset>
</div>

<div id="fieldDetallePesosMedidas">
  <fieldset>
    <legend>{{ translate('Pesos_medidas') }}</legend>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Ancho") }} (cm)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.Ancho"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Ancho = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Alto") }} (cm)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.Altura"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Altura = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Ancho_interior") }} (cm)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.AnchoInterior"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AnchoInterior = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Altura_interior") }} (cm)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.AltoInterior"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.AltoInterior = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Largo") }} (cm)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.Largo"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Largo = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Tara") }} (Kg)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.Tara"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.Tara = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>
    </div>

    <div class="row">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Capacidad") }} (L)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.CapacidadLitros"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CapacidadLitros = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>

      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Capacidad") }} (Kg)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.CapacidadKilos"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CapacidadKilos = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>
    </div>

    <div class="row" style="justify-content: start;">
      <span style="width: 50%;">
        <div style="margin-top: 5px; clear: both">
          {{ translate("Carga_maxima") }} (Kg)
        </div>
        <app-input-number [value]="elemEdit.Equipamiento?.CargaMaximaAutorizada"
          (onChange)="elemEdit.Equipamiento != null ? elemEdit.Equipamiento.CargaMaximaAutorizada = $event : ''"
          [render]="renderer()" [width]="'100%'" [decimal]="2">
        </app-input-number>
      </span>
    </div>
  </fieldset>
</div>

<div id="fieldDetalleInformacionGrafica">
  <fieldset>
    <legend>{{ translate('Informacion_grafica') }}</legend>

    <div class="row">
      <span>
        <div style="margin-top: 5px; clear: both">
          {{ translate("Icono_elemento") }}
          <img #imgPrincipal
            *ngIf="elemEdit?.Equipamiento?.Icono && elemEdit?.Equipamiento?.Icono?.length > 100"
            src="data:image/png;base64,{{elemEdit.Equipamiento?.Icono}}" style="width: 100%; height: 100%;">
        </div>
      </span>
    </div>

    <div class="row">
      <span>
        <div style="margin-top: 5px; clear: both">
          {{ translate("Icono_punto_ubicacion") }}
          <img #imgPrincipal
            *ngIf="elemEdit?.Pu?.Equipamiento?.Icono && elemEdit?.Pu?.Equipamiento?.Icono?.length > 100 "
            src="data:image/png;base64,{{elemEdit.Pu?.Equipamiento?.Icono}}"
            style="width: 100%; height: 100%;">
        </div>
      </span>
    </div>
  </fieldset>
</div>

<div id="fieldDetalleClasesElemento">
  <fieldset>
    <legend>{{ translate('Clases_asociadas_elemento') }}</legend>
    <div class="row"></div>
  </fieldset>
</div>
