import {
  OnInit,
  Component,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';
import { NgForm } from '@angular/forms';

import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { ConfiguracionCerraduraService } from 'src/app/services/cerraduras/configuracion-cerradura.service';

import { ConfiguracionCerraduraModel } from 'src/app/services/cerraduras/models/configuracionCerradura.model';

import { InputNumberComponent } from '../../input-number/input-number.component';
import { ConfiguracionCerradurasComponent } from '../configuracion-cerraduras.component';
import { SsoService } from 'src/app/services/sso/sso.service';

@Component({
  selector: 'app-configuracion-cerraduras-edit',
  templateUrl: './configuracion-cerraduras-edit.component.html',
  styleUrls: ['./configuracion-cerraduras-edit.component.css']
})
export class ConfiguracionCerradurasEditComponent extends CustomForms implements OnInit {
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('formConfiguracion') formConfiguracion: NgForm;
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  ejecutado: boolean = false;

  private viewInit: boolean = false;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');
  public configuracionCerradura: ConfiguracionCerraduraModel = new ConfiguracionCerraduraModel();

  /* Muestra la hora de envio inicial en el componente */
  horaEnvio = new Date('1993-10-13 00:00');

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  constructor(private configuracionCerraduraService: ConfiguracionCerraduraService, private ssoService: SsoService) {
    super();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.initGridContenedor();
    this.initGridCerradura();
    this.initGridAlarma();
    this.initGridComunicacion();

    /* Seteo la hora de envio, que lleva la configuracion, a un formato leible por el componente */
    this.horaEnvio.setHours(Math.floor(this.configuracionCerradura.horaEnvio / 60));
    this.horaEnvio.setMinutes(this.configuracionCerradura.horaEnvio % 60);
  }

  public init(componentRef: any, configuracionCerradura: ConfiguracionCerraduraModel) {
    this.componentRef = componentRef;

    this.configuracionCerradura = configuracionCerradura ? configuracionCerradura : new ConfiguracionCerraduraModel();
  }

  async ngAfterContentInit() {
    this.configuracionCerradura.bateriaBaja /= 100;
    this.configuracionCerradura.bateriaCritica /= 100;
    this.configuracionCerradura.temperatura /= 10;
  }

  async ngAfterViewInit() {
    this.addCustomForm(this.windowForm);

    /*
      Establece los textos de los desplegables
      al cargar una configuración y marca los checks
    */
    if ((this.configuracionCerradura.alarmas & 1) > 0) {
      this.gridAlarma.selectrow(0);
    }

    if ((this.configuracionCerradura.alarmas & 2) > 0) {
      this.gridAlarma.selectrow(1);
    }

    if ((this.configuracionCerradura.alarmas & 4) > 0) {
      this.gridAlarma.selectrow(2);
    }

    if ((this.configuracionCerradura.alarmas & 8) > 0) {
      this.gridAlarma.selectrow(3);
    }

    if ((this.configuracionCerradura.alarmas & 16) > 0) {
      this.gridAlarma.selectrow(4);
    }

    if ((this.configuracionCerradura.alarmas & 32) > 0) {
      this.gridAlarma.selectrow(5);
    }

    if ((this.configuracionCerradura.alarmas & 64) > 0) {
      this.gridAlarma.selectrow(6);
    }

    if ((this.configuracionCerradura.alarmas & 128) > 0) {
      this.gridAlarma.selectrow(7);
    }

    if ((this.configuracionCerradura.alarmas & 256) > 0) {
      this.gridAlarma.selectrow(8);
    }

    if (this.configuracionCerradura.tipoContenedor != null) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridContenedor[this.configuracionCerradura.tipoContenedor + 1].tipo + '</div>';
      this.dropDownContenedor.setContent(content);
    }

    if (this.configuracionCerradura.tipoCerradura != null) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridCerradura[this.configuracionCerradura.tipoCerradura].tipo + '</div>';
      this.dropDownCerradura.setContent(content);
    }

    if (this.configuracionCerradura.tecnologia != null) {
      let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridComunicacion[this.configuracionCerradura.tecnologia].tipo + '</div>';
      this.dropDownComunicacion.setContent(content);
    }
    /* Fin establecer texto de los desplegables */

    this.viewInit = true;
  }

  onClose() {
    if (this.componentRef) {
      this.configuracionCerradura.bateriaBaja *= 100;
      this.configuracionCerradura.bateriaCritica *= 100;
      this.configuracionCerradura.temperatura *= 10;

      this.componentRef.destroy();
      ConfiguracionCerradurasComponent._this.getConfiguraciones();
      ConfiguracionCerradurasComponent._this.form.expand();
    }
  }

  async onGuardar(): Promise<void> {
    if (this.formConfiguracion.invalid) {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Rellena_campos_obligatorios',
        2000
      );
    } else {
      let configuracionCerradura = await this.configuracionCerraduraService.createConfiguracionCerradura(this.configuracionCerradura);

      if (configuracionCerradura != null) {
        this.windowForm.close();

        this.configuracionCerradura.id = configuracionCerradura.id;

        ConfiguracionCerradurasComponent._this.getConfiguraciones();

        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_almacenado',
          2000
        );
      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }
  }

  /* Convierte y guarda la hora de envio */
  setHoraEnvio(event: any) {
    let hora = event.split(':');
    this.configuracionCerradura.horaEnvio = hora[1] * 1 + hora[0] * 60;
  }

  /* Grid tipo contenedor */
  @ViewChild('dropDownContenedor') dropDownContenedor: jqxDropDownButtonComponent;
  @ViewChild('gridContenedor') gridContenedor: jqxGridComponent;

  private arrayGridContenedor;
  public adapterGridContenedor;
  public sourceGridContenedor;
  public columnsGridContenedor;

  gridDropContenedor: boolean;

  initGridContenedor() {
    this.arrayGridContenedor = [
      { 'tipo': '', 'value': null },
      { 'tipo': this.translate("Contenedor_tipo_apertura_vaciado"), 'value': 0 },
      { 'tipo': this.translate("Contenedor_tipo_comparte_trampilla"), 'value': 1 },
      { 'tipo': this.translate("Contenedor_tipo_soterrado"), 'value': 2 },
      { 'tipo': this.translate("Papelera"), 'value': 3 }
    ];

    this.columnsGridContenedor = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridContenedor = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridContenedor
    };

    this.adapterGridContenedor = new jqx.dataAdapter(this.sourceGridContenedor);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridContenedor.autoresizecolumns();
    }, 500);
  }

  onOpenDropContenedor() {
    if (this.gridDropContenedor) {
      this.gridDropContenedor = false;
    }
  }

  onCloseDropContenedor() {
    if (this.gridContenedor) {
      this.gridDropContenedor = true;
    }
    this.dropDownContenedor.close();
  }

  onRowContenedorClick(event: any) {
    this.configuracionCerradura.tipoContenedor = this.arrayGridContenedor[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridContenedor[event.args.rowindex].tipo + '</div>';
    this.dropDownContenedor.setContent(content);
    this.dropDownContenedor.close();
  }

  /* Grid tipo cerradura */
  @ViewChild('dropDownCerradura') dropDownCerradura: jqxDropDownButtonComponent;
  @ViewChild('gridCerradura') gridCerradura: jqxGridComponent;

  private arrayGridCerradura;
  public adapterGridCerradura;
  public sourceGridCerradura;
  public columnsGridCerradura;

  gridDropCerradura: boolean;

  initGridCerradura() {
    this.arrayGridCerradura = [
      { 'tipo': this.translate("No_equipada"), 'value': 0 },
      { 'tipo': this.translate("Cerradura_welock"), 'value': 1 }
    ];

    this.columnsGridCerradura = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridCerradura = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridCerradura
    };

    this.adapterGridCerradura = new jqx.dataAdapter(this.sourceGridCerradura);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridCerradura.autoresizecolumns();
    }, 500);
  }

  onOpenDropCerradura() {
    if (this.gridDropCerradura) {
      this.gridDropCerradura = false;
    }
  }

  onCloseDropCerradura() {
    if (this.gridCerradura) {
      this.gridDropCerradura = true;
    }
    this.dropDownCerradura.close();
  }

  onRowCerraduraClick(event: any) {
    this.configuracionCerradura.tipoCerradura = this.arrayGridCerradura[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridCerradura[event.args.rowindex].tipo + '</div>';
    this.dropDownCerradura.setContent(content);
    this.dropDownCerradura.close();
  }

  /* Grid tipo comunicaciones */
  @ViewChild('dropDownComunicacion') dropDownComunicacion: jqxDropDownButtonComponent;
  @ViewChild('gridComunicacion') gridComunicacion: jqxGridComponent;

  private arrayGridComunicacion;
  public adapterGridComunicacion;
  public sourceGridComunicacion;
  public columnsGridComunicacion;

  gridDropComunicacion: boolean;

  initGridComunicacion() {
    this.arrayGridComunicacion = [
      { 'tipo': "NB IoT", 'value': 0 },
      { 'tipo': "LTE Cat M", 'value': 1 },
      { 'tipo': "GPRS 2G", 'value': 2 }
    ];

    this.columnsGridComunicacion = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridComunicacion = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridComunicacion
    };

    this.adapterGridComunicacion = new jqx.dataAdapter(this.sourceGridComunicacion);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridComunicacion.autoresizecolumns();
    }, 500);
  }

  onOpenDropComunicacion() {
    if (this.gridDropComunicacion) {
      this.gridDropComunicacion = false;
    }
  }

  onCloseDropComunicacion() {
    if (this.gridComunicacion) {
      this.gridDropComunicacion = true;
    }
    this.dropDownComunicacion.close();
  }

  onRowComunicacionClick(event: any) {
    this.configuracionCerradura.tecnologia = this.arrayGridComunicacion[event.args.rowindex].value;
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.arrayGridComunicacion[event.args.rowindex].tipo + '</div>';
    this.dropDownComunicacion.setContent(content);
    this.dropDownComunicacion.close();
  }

  /* Grid tipo alarmas */
  @ViewChild('dropDownAlarma') dropDownAlarma: jqxDropDownButtonComponent;
  @ViewChild('gridAlarma') gridAlarma: jqxGridComponent;
  textAlarmas: string = '';

  private arrayGridAlarmas;
  public adapterGridAlarma;
  public sourceGridAlarma;
  public columnsGridAlarma;

  gridDropAlarma: boolean;

  initGridAlarma() {
    this.arrayGridAlarmas = [
      { 'tipo': this.translate("Alarma_bateria_baja"), 'value': 1 },
      { 'tipo': this.translate("Alarma_bateria_critica"), 'value': 2 },
      { 'tipo': this.translate("Cambio_bateria"), 'value': 4 },
      { 'tipo': this.translate("Temperatura_elevada"), 'value': 8 },
      { 'tipo': this.translate("Vaciado"), 'value': 16 },
      { 'tipo': this.translate("Diagnostico"), 'value': 32 },
      { 'tipo': this.translate("Puerta_abierta"), 'value': 64 },
      { 'tipo': this.translate("Puerta_violentada"), 'value': 128 },
      { 'tipo': this.translate("Vandalismo"), 'value': 256 }
    ];

    this.columnsGridAlarma = [
      { text: this.translate('Tipo'), columntype: 'textbox', datafield: 'tipo' }
    ];

    this.sourceGridAlarma = {
      datatype: 'array',
      datafields: [
        { name: 'tipo', type: 'string' }
      ],
      localdata: this.arrayGridAlarmas
    };

    this.adapterGridAlarma = new jqx.dataAdapter(this.sourceGridAlarma);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridAlarma.autoresizecolumns();
    }, 500);
  }

  onOpenDropAlarma() {
    if (this.gridDropAlarma) {
      this.gridDropAlarma = false;
    }
  }

  onCloseDropAlarma() {
    if (this.gridAlarma) {
      this.gridDropAlarma = true;
    }
    this.dropDownAlarma.close();
  }

  onRowAlarmaSelect(event: any) {
    if (this.viewInit) {
      this.configuracionCerradura.alarmas = this.configuracionCerradura.alarmas + this.arrayGridAlarmas[event.args.rowindex].value;
    }

    this.textAlarmas = '';
    this.gridAlarma.selectedrowindexes().forEach(element => {
      this.textAlarmas += this.arrayGridAlarmas[element].tipo + ';';
    });

    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.textAlarmas + '</div>';
    this.dropDownAlarma.setContent(content);
  }

  onRowAlarmaUnselect(event: any) {
    if (this.viewInit) {
      this.configuracionCerradura.alarmas = this.configuracionCerradura.alarmas - this.arrayGridAlarmas[event.args.rowindex].value;
    }

    this.textAlarmas = '';
    this.gridAlarma.selectedrowindexes().forEach(element => {
      this.textAlarmas += this.arrayGridAlarmas[element].tipo + ';';
    });
    let content = '<div style="position: relative; margin-left: 3px; margin-top: 5px;">' + this.textAlarmas + '</div>';
    this.dropDownAlarma.setContent(content);
  }
}
