<div class="group-botom" style="height: 34px; margin-left: 2px; border: 2px solid #aadbe9; padding: 2px; background-color: #f4f7f7;">
  <jqxButton *ngIf="exportar" class="button" id="btnJqx_export" [style]="'float: left; cursor: pointer !important; display: flex; align-items:center'"
    [width]='25' [height]='25' (onClick)="onExportar()" [title]="translate('Exportar')">
    <i class="fa fa-file-excel fa-lg"></i>
  </jqxButton>
  <jqxButton *ngIf="imprimir" class="button" style="float: left; margin-left: 5px; cursor: pointer; justify-content: center; align-items: center; display: flex;"
    [width]='25' [height]='25' [title]="translate('Imprimir')" (onClick)="onPrint()">
    <i class="fa fa-print fa-lg"></i>
  </jqxButton>
  <div *ngIf="buscador" style="float: left; margin-left: 5%; display: flex; align-items: flex-end;">
    <div style="float: left; background-color: white; background-image: url(../assets/images/search.png);
      background-repeat: no-repeat; background-position: 4px center; background-size: 18px; display: flex; align-items: center; width: 206px;
      margin-top: 2px; padding-top: 2px; padding-left: 28px; height: 25px; border: 1px solid rgba(0, 0, 0, 0.5); border-radius: 3px; overflow: hidden;">
      <input #searchInput type="text" style="border: 0;width: 100%; outline: none;" placeholder="{{ translate('Buscar') }}..." autocorrect="off" autocapitalize="off" spellcheck="off">
    </div>
  </div>

  <div *ngIf="periodo" style="float: left; margin-left: 5%; display: flex; align-items: flex-end;">
    <span style="float:left; margin-right: 5px">{{translate('Periodo')}}</span>
    <app-periodo-select #periodoSelect [noDia]="noDia" [noSemana]="noSemana" [noMes]="noMes"
      [noTrimestre]="noTrimestre" [noSemestre]="noSemestre" [noAnno]="noAnno" [noLibre]="noLibre"
      [height]="height">
    </app-periodo-select>
  </div>

  <div *ngIf="checkMetros" style="float: left; display: flex;align-items: center; gap: 2px;">
    <div style="display: flex;align-items: center;margin-left: 5px;">
      <jqxCheckBox #checkMasXmetros (onChange)="changeMasXmetros($event)" [theme]="theme"
        style="margin-left: 10px; float: left;" [height]="20">
      </jqxCheckBox>
      <span style="margin-left: 4px;">{{translate('Lecturas_alejadas')}}</span>
      <jqxNumberInput #currencyInput [theme]="theme" [width]="50" [height]="25"
        [spinButtons]="true" [inputMode]="'simple'" [decimalDigits]="0" [style]="'margin-left: 4px'">
      </jqxNumberInput>
      <span style="margin-left: 4px;">{{translate('Metros')}}</span>
    </div>

    <div style="display: flex; align-items: center; margin-left: 5px;">
      <jqxCheckBox #checkIdentificaciones (onChange)="changeMasXmetros($event)" [theme]="theme"
        style="margin-left: 10px; float: left;" [width]="20" [height]="20" [hidden]="true">
      </jqxCheckBox> <span hidden="true" style="margin-left: 4px;">Con identificaciones</span>
    </div>
  </div>

  <div *ngIf="volumetricos" style="float: left; display: flex; align-items: center; margin-left: 5px;">
    <div style="float: left;">{{translate('Volumetricos')}}</div>
    <jqxDropDownList #cbVolumetricos style="float:left; margin-left: 4px;" [width]="230" [height]="24"
        [selectedIndex]="0">
    </jqxDropDownList>
  </div>

  <div *ngIf="filtro" style="float: right;">
    <jqxButton class="button" style="float:left; margin-left: 5px; cursor: pointer; justify-content: center; align-items: center; display: flex;" [width]='25' [height]='25'
      [title]="translate('Filtrar')" (onClick)="onFilter()">
      <img src="/assets/images/filter.png" style="height: 20px; width: 20px;">
    </jqxButton>
  </div>

  <div *ngIf="resetFiltro" style="float: right;">
    <jqxButton class="button" style="float:left; margin-left: 5px; cursor: pointer; justify-content: center; align-items: center; display: flex;" [width]='25' [height]='25'
      [title]="translate('Restablecer_filtros')" (onClick)="onResetFilter()">
      <img src="/assets/images/filter_cancel.png" style="height: 20px; width: 20px;">
    </jqxButton>
  </div>
</div>
