import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';

import { BdtEquipamientoModel } from 'movisat-ia/lib/services/bdt/models/bdt-equipamiento.model';
import { BdtCatalogoEquipamientoModel } from 'src/app/services/bdt/models/bdt-catalogo-equipamiento.model';

import { SsoService } from 'src/app/services/sso/sso.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { JqxDomService } from 'src/app/services/jqwidgets/jqx-dom.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { ElementsCatalogComponent } from '../elements-catalog/elements-catalog.component';

@Component({
  selector: 'app-elements-select',
  templateUrl: './elements-select.component.html',
  styleUrls: ['./elements-select.component.css']
})
export class ElementsSelectComponent extends CustomForms implements OnInit {

  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;

  public static _this: ElementsSelectComponent;
  private componentRef = null;
  public environment = environment;
  public canEdit = true;
  catEquipamiento: BdtCatalogoEquipamientoModel[] = [];
  private catEquipaminetoModel: BdtEquipamientoModel;
  private catEquip = new Map<number, BdtCatalogoEquipamientoModel>();
  private select: any = [];
  private fromIAConfig = false;
  private detailComponents: any[] = [];

  theme = environment.tema;
  // Variables para el grid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);
  public columns = [
    {
      text: 'Id',
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'id',
      width: 60,
      hidden: true,
    },
    {
      text: AppComponent.translate('Unidades'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'unidades',
      width: 75,
      align: 'center',
      cellsalign: 'center',
      hidden: true,
      aggregates: [
        {
          Total: function (aggregatedValue, currentValue: number) {
            return currentValue
              ? aggregatedValue + currentValue
              : aggregatedValue;
          },
        },
      ],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring =
            '<div style="text-align: center; margin-left: 4px;">' +
            aggregates['Total'] +
            ' </div>';
        }
        return renderstring;
      },
    },
    {
      text: '',
      columntype: 'image',
      datafield: 'imagen',
      width: 16,
      filterable: false,
      sortable: false,
      cellsrenderer: this.imagerenderer,
    },
    {
      text: AppComponent.translate('Elemento'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'elemento',
      width: 180,
      aggregates: [
        {
          Total: function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          },
        },
      ],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates['Total'] !== undefined) {
          renderstring =
            '<div style="text-align: left; margin-left: 4px;">' +
            AppComponent.translate('Equipamientos') +
            ': ' +
            aggregates['Total'] +
            ' </div>';
        }
        return renderstring;
      },
    },
    {
      text: AppComponent.translate('Marca'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'marca',
      width: 'auto',
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Modelo'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'modelo',
      width: 230,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Tipo'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'tipo',
      width: 150,
      hidden: this.elemService.elemGenericos,
    },
    {
      text: AppComponent.translate('Residuo'),
      columntype: 'textbox',
      filtertype: 'textbox',
      datafield: 'residuo',
      width: 190,
      hidden: this.elemService.elemGenericos,
    },
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private bdtService: BdtService,
    private configService: ConfigService,
    public elemService: ElementsService,
    private jqxDomService: JqxDomService
  ) {
    super();
    ElementsSelectComponent._this = this;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    this.langGrid = JqWidgets.getLocalization(
      this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2)
    );
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    if (MainComponent.getInstance().ssoTicket.Aplicacion.Id === 14) {
      this.form.setTitle(AppComponent.translate('Catalogo_BDT'));
    } else {
      if (!this.fromIAConfig) {
        this.form.setTitle(AppComponent.translate('Filtro_elementos'));
      } else {
        this.form.setTitle(
          AppComponent.translate('Filtro_elementos') +
          ' - ' +
          AppComponent.translate('IA')
        );
      }
    }
    // Recupero el filtro guardado
    if (!this.fromIAConfig) {
      this.select = await this.configService.getUsuEmpApp(
        'elem-model-filter',
        null
      );
    } else {
      this.select = await this.configService.getEmp('elem-model-IA', null);
    }
    if (this.select) {
      this.select = JSON.parse(this.select);
    } else {
      this.select = [];
    }
    // Recupero el catálogo de elementos de la empresa
    this.getCatalogoEquipamiento();
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, fromIAConfig: boolean) {
    this.componentRef = componentRef;
    this.fromIAConfig = fromIAConfig;
  }

  // Cierro el formulario y destruyo el componente
  public async onClose() {
    ElementsCatalogComponent._this = null;
    // Destruyo los componentes
    this.detailComponents.forEach((cpn) => {
      cpn.destroy();
    });
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  // Guardo los filtros y destruyo el componente
  public async onGuardar() {
    // Guardo la configuración
    const modelos: any[] = [];
    const rowsSelec = this.grid.getselectedrowindexes();
    if (rowsSelec) {
      rowsSelec.forEach((i) => {
        modelos.push({ id: this.catEquipamiento[i].IdEquipamiento });
      });
    }
    // Guardo la variable de configuración con los datos del filtro
    if (!this.fromIAConfig) {
      await this.configService.setUsuEmpApp(
        'elem-model-filter',
        JSON.stringify(modelos)
      );
    } else {
      await this.configService.setEmp('elem-model-IA', JSON.stringify(modelos));
    }
    // Mando actualizar el filtro para que se repinten los cluster
    this.elemService.setFilterVisible();
    // Cierro el formulario
    this.form.close();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  onBindingComplete() {
    if (this.grid) {
      const rows = this.grid.getrows();
      if (rows) {
        rows.forEach((row) => {
          if (this.select.find((s) => s.id === row.id) !== undefined) {
            this.grid.selectrow(row.boundindex);
          }
        });
      }
    }
  }

  // Recupera el catálogo de elementos de la empresa
  async getCatalogoEquipamiento() {
    let filterIA: any = await this.configService.getEmp('elem-model-IA', null);
    if (filterIA) {
      filterIA = JSON.parse(filterIA);
    }
    this.loader.open();
    this.catEquipamiento = [];
    const response = await this.bdtService.getCatalogoEquipamiento();
    if (response) {
      response.forEach((cat) => {
        if (!MainComponent.getInstance().isEcoEvolution || this.fromIAConfig) {
          this.catEquip.set(cat.IdEquipamiento, cat);
          this.catEquipamiento.push(cat);
        } else {
          if (
            !filterIA ||
            filterIA.find((s) => s.id === cat.IdEquipamiento) !== undefined
          ) {
            this.catEquip.set(cat.IdEquipamiento, cat);
            this.catEquipamiento.push(cat);
          }
        }
      });
      this.source = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'IdEquipamiento' },
          { name: 'unidades', type: 'int', map: 'Unidades' },
          { name: 'imagen', type: 'image', map: 'Equipamiento>Icono' },
          { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
          { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
          {
            name: 'elemento',
            type: 'string',
            map: 'Equipamiento>Elemento>Nombre',
          },
          {
            name: 'tipo',
            type: 'string',
            map: 'Equipamiento>Elemento>Tipo>Nombre',
          },
          {
            name: 'residuo',
            type: 'string',
            map: 'Equipamiento>Residuo>Nombre',
          },
        ],
        localdata: this.catEquipamiento,
        sortcolumn: 'marca',
        sortdirection: 'asc',
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);

      const t = setTimeout(() => {
        clearTimeout(t);
        this.grid.autoresizecolumns();
      }, 500);
    }
    this.loader.close();
  }

  imagerenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value && value.length > 50) {
      return (
        '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' +
        value +
        '"/>'
      );
    }
    return '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/elemento.png"/>';
  }

  onRowExpand(event: any) {
    const args = event.args;
    const details = args.details;
    const rowBoundIndex = args.rowindex;
    setTimeout(() => {
      this.grid.scrolloffset(rowBoundIndex * this.grid.rowsheight(), 0);
    }, 250);
  }

}
