<jqxWindow #form [theme]="environment.tema" [width]="800" [showCloseButton]="true" [showCollapseButton]="true"
    [zIndex]="90" [height]="500" [resizable]="false" [position]="getFormPos(form)" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden; height: 20px; border: 0px; margin: 0px; padding: 4px;">
        {{translate('Anotaciones')}}
    </div>
    <div style="float: left; overflow: hidden;" class="form">
        <jqxEditor #editor [(ngModel)]='text' [width]="'100%'" [height]="430"
            [tools]="'bold italic underline | format font size | color background | left center right | outdent indent | ul ol | link | clean'"
            [localization]="lang">
        </jqxEditor>
        <jqxButton style="float: left; margin-left: 0px; margin-top: 5px;" [width]='100' [height]='26'
            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"'
            (onClick)="onGuardar($event)" [value]="translate('Guardar')">
        </jqxButton>
    </div>
</jqxWindow>