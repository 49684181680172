import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxSplitterModule } from 'jqwidgets-ng/jqxsplitter';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { MovilesSelectComponent } from './moviles-select.component';


@NgModule({
  declarations: [
    MovilesSelectComponent
  ],
  imports: [
    CommonModule,
    jqxGridModule,
    jqxSplitterModule,
    jqxDropDownListModule,
  ],
  exports: [
    MovilesSelectComponent
  ]
})
export class MovilesSelectModule { }
