import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { SsoService } from 'src/app/services/sso/sso.service';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { MainComponent } from '../../main/main.component';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-ciudadanos-tarjetas',
  templateUrl: './ciudadanos-tarjetas.component.html',
  styleUrls: ['./ciudadanos-tarjetas.component.css']
})
export class CiudadanosTarjetasComponent implements OnInit {
  // Componentes del HTML
  @ViewChild('gridTarjetasAsignadas') gridTarjetasAsignadas: jqxGridComponent;
  @ViewChild('gridTarjetasLibres') gridTarjetasLibres: jqxGridComponent;

  // Entradas
  @Input() ciudadano: CiudadanoModel;
  @Input() guardar = false;

  // Funcionalidad
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  // Tarjeta seleccionada para mover entre un grid y otro
  public tarjetaAsignadaSelect: TarjetaModel;
  public tarjetaLibreSelect: TarjetaModel;

  // Listados de tarjetas que se van a asociar/desasociar al guardar el ciudadano
  public tarjetasToAssociate: any[] = [];
  public tarjetasToDisassociate: any[] = [];

  constructor(private tarjetaServicio: TarjetasService,
    private modal: NzModalService,
    private ssoService: SsoService) {
  }

  // Traducción de los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  async ngOnInit(): Promise<void> {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    await this.getTarjetasLibres();
    this.initGridTarjetasAsignadas();
    this.initGridTarjetasLibres();
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'ciudadano': {
            this.ciudadano = changes[propName].currentValue;
            break;
          }
          case 'guardar': {
            this.guardar = changes[propName].currentValue;
            if (this.guardar == true) {
              this.onGuardar();
            }
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  // Al llamar el metodo se hace la peticion al servidor
  onGuardar(): void {
    this.tarjetaServicio.associateTarjetasToCiudadano(this.tarjetasToAssociate, this.ciudadano.id);
    this.tarjetaServicio.disassociateTarjetasFromCiudadano(this.tarjetasToDisassociate, this.ciudadano.id);
  }

  // Se llama cuando se cambia una tarjeta libre al grid de ciudadano
  async associateTarjetasToCiudadano() {
    // Si no está en la lista de asociadas la añado
    if (!this.tarjetasToAssociate.find(x => x.id === this.tarjetaLibreSelect.id)) {
      this.tarjetasToAssociate.push({ "id": this.tarjetaLibreSelect.id, "master": false });
    }

    let index = 0;
    // Si está en la lista de desasociadas la quito
    for (index = 0; index < this.tarjetasToDisassociate.length; index++) {
      if (this.tarjetasToDisassociate[index].id === this.tarjetaLibreSelect.id) {
        this.tarjetasToDisassociate.splice(index, 1);
        break;
      }
    }

    // Si no está añado la tarjeta a la lista de tarjetas del ciudadano
    if (!this.ciudadano.tarjetas.find(x => x.id === this.tarjetaLibreSelect.id)) {
      this.ciudadano.tarjetas.push(this.tarjetaLibreSelect);
    }

    // Si está en la lista de tarjetas libres la quito
    for (index = 0; index < this.tarjetasLibres.length; index++) {
      if (this.tarjetasLibres[index].id === this.tarjetaLibreSelect.id) {
        this.tarjetasLibres.splice(index, 1);
        break;
      }
    }

    this.gridTarjetasLibres.clearselection();
    this.tarjetaLibreSelect = null;

    this.updateGrids();
  }

  // Se llama cuando se cambia una tarjeta del grid de ciudadano a libre
  async disassociateTarjetasFromCiudadano() {
    // Si no está en la lista de desasociadas la añado
    if (!this.tarjetasToDisassociate.find(x => x.id === this.tarjetaAsignadaSelect.id)) {
      this.tarjetasToDisassociate.push({ "id": this.tarjetaAsignadaSelect.id, "master": false });
    }

    let index = 0;
    // Si está en la lista de asociadas la quito
    for (index = 0; index < this.tarjetasToAssociate.length; index++) {
      if (this.tarjetasToAssociate[index].id === this.tarjetaAsignadaSelect.id) {
        this.tarjetasToAssociate.splice(index, 1);
        break;
      }
    }

    // Si está en la lista de tarjetas del ciudadano la quito
    for (index = 0; index < this.ciudadano.tarjetas.length; index++) {
      if (this.ciudadano.tarjetas[index].id === this.tarjetaAsignadaSelect.id) {
        this.ciudadano.tarjetas.splice(index, 1);
        break;
      }
    }

    // Si no está en la lista de tarjetas libres la añado
    if (!this.tarjetasLibres.find(x => x.id === this.tarjetaAsignadaSelect.id)) {
      this.tarjetasLibres.push(this.tarjetaAsignadaSelect);
    }

    this.gridTarjetasAsignadas.clearselection();
    this.tarjetaAsignadaSelect = null;

    this.updateGrids();
  }

  // Actualiza los datos de los grids
  updateGrids() {
    this.gridTarjetasAsignadas.updatebounddata();
    this.gridTarjetasLibres.updatebounddata();
  }

  /* Grid asignadas */
  columnsTarjetasAsignadas;
  public sourceTarjetasAsignadas: any;
  public adapterTarjetasAsignadas;

  initGridTarjetasAsignadas() {
    this.columnsTarjetasAsignadas = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
      { text: 'NSMovisat', columntype: 'textbox', datafield: 'nsMovisat', editable: false },
      { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion', editable: false, },
      { text: this.translate('Master'), columntype: 'checkbox', datafield: 'master' },
    ];

    this.sourceTarjetasAsignadas = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'descripcion', type: 'string', map: 'descripcion' },
        { name: 'master', type: 'boolean', map: 'master' }
      ]
    };

    this.sourceTarjetasAsignadas.localdata = this.ciudadano.tarjetas;
    this.adapterTarjetasAsignadas = new jqx.dataAdapter(this.sourceTarjetasAsignadas);

    this.ciudadano.tarjetas.forEach(tarjeta => {
      this.tarjetasToAssociate.push({ "id": tarjeta.id, "master": tarjeta.master });
    });
  }

  onCellEdit(event: any) {
    this.tarjetasToAssociate.find(t => t.id == event.args.row.id).master = event.args.value;
    this.ciudadano.tarjetas.find(t => t.id == event.args.row.id).master = event.args.value;
  }

  onRowSelectAsignada(event: any) {
    this.tarjetaAsignadaSelect = this.ciudadano.tarjetas[event.args.rowindex];
  }

  /* Grid Libres */

  // Obtiene las tarjetas libres
  async getTarjetasLibres() {
    this.tarjetasLibres = await this.tarjetaServicio.getTarjetasLibres();
  }

  public tarjetasLibres: TarjetaModel[] = [];
  public columnsTarjetasLibres;
  public sourceTarjetasLibres: any;
  public adapterTarjetasLibres;

  initGridTarjetasLibres() {
    this.columnsTarjetasLibres = [
      { text: 'Id', columntype: 'textbox', datafield: 'id', width: 80, hidden: true },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: 'nsMovisat' },
      { text: this.translate('Descripcion'), columntype: 'textbox', datafield: 'descripcion' },
    ];

    this.sourceTarjetasLibres = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'id' },
        { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
        { name: 'descripcion', type: 'string', map: 'descripcion' }
      ],
      localdata: this.tarjetasLibres
    };

    this.adapterTarjetasLibres = new jqx.dataAdapter(this.sourceTarjetasLibres);
  }

  onRowSelectLibre(event: any) {
    this.tarjetaLibreSelect = this.tarjetasLibres[event.args.rowindex];
  }

  async onNewCard() {
    try {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_grabar_tarjeta'),
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('ACEPTAR'),
        nzZIndex: 999,
        nzOnOk: async () => {
          // De momento no hay zonas, todas las tarjetas se graban sin zona
          const idZona = 0;
          const ultimaTarjeta: TarjetaModel = await this.tarjetaServicio.getUltimaTarjeta(idZona);
          const tarjeta = new TarjetaModel();
          tarjeta.empresa = this.ssoService.getTicket().Empresa.IdGestion;
          tarjeta.idZona = idZona;
          tarjeta.idInterno = ultimaTarjeta ? ultimaTarjeta.idInterno + 1 : 1;
          tarjeta.idTarjeta = (tarjeta.idInterno & 0xFFFFF) + ((idZona & 0xFFF) << 20);
          const res = await this.tarjetaServicio.grabaTarjeta(tarjeta);
          if (res) {
            if ((res as any).Error) {
              MainComponent.showError('ATENCION', (res as any).Error, 3000);
            } else {
              tarjeta.uuid = res.uuid;
              tarjeta.nsMovisat = res.nsMovisat;
              tarjeta.descripcion = "TARJETA " + tarjeta.idZona + "/" + tarjeta.idInterno;
              this.tarjetaLibreSelect = await this.tarjetaServicio.creaTarjeta(tarjeta);
              if (this.tarjetaLibreSelect) {
                // Si la tarjeta creada ya la tenía el ciudadano, la quito de la lista para que
                // se almacene de nuevo con el nuevo identificador interno asignado
                for (let i = 0; i < this.ciudadano.tarjetas.length; i++) {
                  if (this.ciudadano.tarjetas[i].id === this.tarjetaLibreSelect.id) {
                    this.ciudadano.tarjetas.splice(i, 1);
                    break;
                  }
                }
                this.associateTarjetasToCiudadano();
                MainComponent.getInstance().showSuccess('ATENCION', 'Tarjeta_grabada', 2000);
              }
              return;
            }
          } else {
            MainComponent.showError('ATENCION', 'No_hay_conexion_app_nfcwrite', 5000);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  }

}
