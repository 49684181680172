import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxDropDownButtonComponent } from 'jqwidgets-ng/jqxdropdownbutton';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

@Component({
  selector: 'app-elements-edit-instalaciones',
  templateUrl: './elements-edit-instalaciones.component.html',
  styleUrls: ['./elements-edit-instalaciones.component.css', '../elements-edit.component.css']
})
export class ElementsEditInstalacionesComponent implements OnInit {
  @Input() elemEdit: ElementoModel;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  selectedImg = null;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
    this.initGridTipoVolumentrico();
  }


  /* APARTADO INSTALACION VOLUMETRICO */
  /* Tipo volumetrico */
  @ViewChild('dropTipoDispositivoVolum') dropTipoDispositivoVolum: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownTipoDispositivoVolum') gridDropDownTipoDispositivoVolum: jqxGridComponent;
  gridDropTipoDispositivoVolum: boolean;

  columnsTipoDispositivoVolum;
  private sourceTipoDispositivoVolum;
  public dataAdapterTipoDispositivoVolum;

  initGridTipoVolumentrico() {
    this.columnsTipoDispositivoVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceTipoDispositivoVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onOpenDropTipoDispositivoVolum(event:any){

  }

  onCloseDropTipoDispositivoVolum(event:any){

  }

  onRowClickTipoDispositivoVolum(event:any){

  }

  /* Estado instalacion volumetrico */
  @ViewChild('dropEstadoInstalacionVolum') dropEstadoInstalacionVolum: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownEstadoInstalacionVolum') gridDropDownEstadoInstalacionVolum: jqxGridComponent;

  gridDropEstadoInstalacionVolum: boolean;

  columnsEstadoInstalacionVolum;
  private sourceEstadoInstalacionVolum;
  public dataAdapterEstadoInstalacionVolum;

  initGridEstadoInstalacionVolum() {
    this.columnsEstadoInstalacionVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceEstadoInstalacionVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onChangeInstalacionVolumetrico(event) {
    if (event.target.checked) {
      this.elemEdit.RequiereVolum = true;
    } else {
      this.elemEdit.RequiereVolum = false;
    }
  }


  onOpenDropEstadoInstalacionVolum(event:any){

  }

  onCloseDropEstadoInstalacionVolum(event:any){

  }

  onRowClickEstadoInstalacionVolum(event:any){

  }

  /* Imagenes instalacion volumetrico */

  /* Grid incidencias instalacion volumetrico */
  @ViewChild('gridIncidenciasVolum') gridIncidenciasVolum: jqxGridComponent;
  columnsIncidenciasVolum;
  private sourceIncidenciasVolum;
  public dataAdapterIncidenciasVolum;

  initGridIncidenciasVolum() {
    this.columnsIncidenciasVolum =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Tipo'), datafield: 'tipo', width: 100 },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Estado'), datafield: 'estado', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
    ];

    this.sourceIncidenciasVolum = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  /* Imagenes incidencias instalacion volumetrico */

  /* FIN APARTADO INSTALACION VOLUMETRICO */

  /* APARTADO INSTALACION TAG */
  /* Tipo tag */
  @ViewChild('dropTipoDispositivoTag') dropTipoDispositivoTag: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownTipoDispositivoTag') gridDropDownTipoDispositivoTag: jqxGridComponent;
  gridDropTipoDispositivoTag: boolean;

  columnsTipoDispositivoTag;
  private sourceTipoDispositivoTag;
  public dataAdapterTipoDispositivoTag;

  initGridTipoTag() {
    this.columnsTipoDispositivoTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceTipoDispositivoTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onOpenDropTipoDispositivoTag(event:any){

  }

  onCloseDropTipoDispositivoTag(event:any){

  }

  onRowClickTipoDispositivoTag(event:any){

  }

  /* Estado instalacion tag */
  @ViewChild('dropEstadoInstalacionTag') dropEstadoInstalacionTag: jqxDropDownButtonComponent;
  @ViewChild('gridDropDownEstadoInstalacionTag') gridDropDownEstadoInstalacionTag: jqxGridComponent;
  gridDropEstadoInstalacionTag: boolean;

  columnsEstadoInstalacionTag;
  private sourceEstadoInstalacionTag;
  public dataAdapterEstadoInstalacionTag;

  initGridEstadoInstalacionTag() {
    this.columnsEstadoInstalacionTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
    ];

    this.sourceEstadoInstalacionTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  onChangeInstalacionTag(event) {
    if (event.target.checked) {
      this.elemEdit.RequiereTag = true;
    } else {
      this.elemEdit.RequiereTag = false;
    }
  }

  onOpenDropEstadoInstalacionTag(event:any){

  }

  onCloseDropEstadoInstalacionTag(event:any){

  }

  onRowClickEstadoInstalacionTag(event:any){

  }

  /* Imagenes instalacion tag */

  /* Grid incidencias instalacion tag */
  @ViewChild('gridIncidenciasTag') gridIncidenciasTag: jqxGridComponent;

  columnsIncidenciasTag;
  private sourceIncidenciasTag;
  public dataAdapterIncidenciasTag;


  initGridIncidenciasTag() {
    this.columnsIncidenciasTag =
    [
      { text: 'ID', datafield: 'id', hidden: true },
      { text: this.translate('Tipo'), datafield: 'tipo', width: 100 },
      { text: this.translate('Fecha'), datafield: 'fecha', width: 100 },
      { text: this.translate('Estado'), datafield: 'estado', width: 100 },
      { text: this.translate('Descripcion'), datafield: 'descripcion', width: 150 },
    ];

    this.sourceIncidenciasTag = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number' },
      ],
    };
  }

  /* Imagenes incidencias instalacion tag */

  /* FIN APARTADO INSTALACION TAG */
}
