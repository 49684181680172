import { CiudadanoModel } from "./ciudadano.model";

export class TarjetaModel {
  id: number;
  descripcion: string;
  empresa: number;
  idTarjeta: number;
  idZona: number;
  idInterno: number;
  tecnologia: number;
  nombreTecnologia: string;
  uuid: number;
  idUsuario: number;
  fechaCreacion: Date;
  fechaModificacion: Date;
  fechaBaja: Date;
  nsMovisat: string;
  master: boolean;
  Virtual: boolean;

  ciudadano: CiudadanoModel;
}
