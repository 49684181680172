import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxCheckBoxModule } from "jqwidgets-ng/jqxcheckbox";
import { jqxComboBoxModule } from 'jqwidgets-ng/jqxcombobox';
import { jqxNumberInputModule } from 'jqwidgets-ng/jqxnumberinput';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { ZonesComponent } from './zones.component';
import { EntradaSalidaComponent } from './entrada-salida/entrada-salida.component';
import { NgHttpLoaderModule } from 'ng-http-loader';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [
    ZonesComponent,
    EntradaSalidaComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    jqxWindowModule,
    jqxGridModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxDropDownListModule,
    jqxInputModule,
    jqxNumberInputModule,
    jqxComboBoxModule,
    jqxCheckBoxModule,
    NgHttpLoaderModule.forRoot(),
    HeaderModule
  ]
})
export class ZonesModule { }
