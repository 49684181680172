<jqxWindow #windowForm [theme]="environment.tema" [position]="getFormPos(windowForm)" [zIndex]="999" [width]="1275"
  [maxWidth]="'100vw'" [height]="520" [showCloseButton]="true" [autoOpen]="true" [showCollapseButton]="true"
  [resizable]="false" (onClose)="onClose()">

  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Asignacion_configuracion_cerraduras") }}
  </div>

  <div class="form" style="float:left; overflow-x: hidden;">
    <div style="width: 100%;height: 410px; display: flex; flex-direction: row; gap: 15px;">
      <div style="width: 50%;">
        <div>
          {{ translate('Configuracion_cerraduras') }}
        </div>
        <jqxGrid #gridConfiguraciones [theme]="environment.tema" [width]="'100%'" [height]="455"
          [source]="adapterGridConfiguracion" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
          [showfilterrow]="true" [columns]="columnsGridConfiguracion" [showrowlines]="false" [showcolumnlines]="true"
          [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
          [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [editable]="false"
          (onRowclick)="onRowClick($event)">
        </jqxGrid>
      </div>
      <div style="width: 50%;">
        <div>
          {{ translate('Cerraduras_asignadas') }}
        </div>
        <jqxGrid id="gridAsignadas" #gridCerradurasAsignadas [theme]="environment.tema" [width]="'100%'"
          [height]="'50%'" [source]="adapterGridCerradurasAsignadas" [columnsresize]="true" [localization]="langGrid"
          [filterable]="true" [columns]="columnsGridCerraduras" [showfilterrow]="true" [showrowlines]="false"
          [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true"
          [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true"
          [editable]="false" [selectionmode]="'checkbox'" (onRowselect)="onRowSelectAsignada($event)"
          (onRowunselect)="onRowUnselectAsignada($event)"> </jqxGrid>

        <!-- Deshabilitar si no hay row seleccionado -->
        <div class="row" style=" justify-content: center; display: flex;">
          <jqxButton style="float:left; margin-right: 5px;" [width]="25" [height]="25" [imgHeight]="15" [imgWidth]="15"
            [imgSrc]='"/assets/images/arriba.png"' [imgPosition]="'center'" (onClick)="asociarCerraduras()"
            [disabled]="!(configuracionSelect && cerradurasLibresCheck.length > 0)">
          </jqxButton>
          <jqxButton style="float:left; margin-left: 5px;" [width]="25" [height]="25" [imgHeight]="15" [imgWidth]="15"
            [imgSrc]='"/assets/images/abajo.png"' [imgPosition]="'center'" (onClick)="desasociarCerraduras()"
            [disabled]="!(configuracionSelect && cerradurasAsignadasCheck.length > 0)">
          </jqxButton>
        </div>

        <div>
          {{ translate('Cerraduras_disponible') }}
        </div>
        <jqxGrid id="gridLibres" #gridCerradurasLibres [theme]="environment.tema" [width]="'100%'" [height]="'50%'"
          [source]="adapterGridCerradurasLibres" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
          [columns]="columnsGridCerraduras" [pageable]="false" [showfilterrow]="true" [showrowlines]="false"
          [showcolumnlines]="true" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
          [showaggregates]="true" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true"
          [selectionmode]="'checkbox'" (onRowselect)="onRowSelectLibre($event)"
          (onRowunselect)="onRowUnselectLibre($event)"> </jqxGrid>
      </div>
    </div>
  </div>
</jqxWindow>
