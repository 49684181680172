import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { AdministradorService } from 'src/app/services/administrador/administrador.service';

import { RolModel } from 'src/app/services/sso/models/rol.model';
import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { TareaModel } from 'src/app/services/administrador/models/tarea.model';

import { EditTareaComponent } from '../edit-tarea/edit-tarea.component';
import { NumberUtils } from 'src/app/utils/number-utils';

@Component({
  selector: 'app-listado-tareas',
  templateUrl: './listado-tareas.component.html',
  styleUrls: ['./listado-tareas.component.css']
})
export class ListadoTareasComponent extends CustomForms implements OnInit {
  @ViewChild('windowJob') windowJob: jqxWindowComponent;
  @ViewChild('gridJobs') gridJobs: jqxGridComponent;
  @ViewChild('formJob', { read: ViewContainerRef }) editTareaComponent;

  public static _this: ListadoTareasComponent;
  private componentRef = null;
  environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  private jobsList: TareaModel[] = [];

  private rowIndex = -1;
  jobSelect: TareaModel;
  formJob: any;

  usuario: UsuarioModel;
  rol: RolModel;

  editable = false;

  mapHeight;
  mapWidth;

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private administradorService: AdministradorService,
    private ssoService: SsoService
  ) {
    super();
    ListadoTareasComponent._this = this;
  }

  init(componentRef: any){
    this.usuario = this.ssoService.getTicket().Usuario;
    this.rol = this.ssoService.getTicket().Rol;
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    if(this.usuario.Interno && this.rol.Nombre === 'Administrador') {
      this.editable = true;
    }
    /* ELIMINAR EN UN FUTURO PARA DEJARLO POR PERMISOS */
    this.editable = true;

    this.initGrid();
  }

  async ngAfterViewInit(){
    this.addCustomForm(this.windowJob);
    this.windowJob.setTitle(AppComponent.translate('Jobs'));

    await this.getJobs();
  }

  onExpand(){
    this.gridJobs.updatebounddata();

    this.resizeColumns();
  }

  resizeColumns(){
    setTimeout(() => {
      this.gridJobs.attrColumns.forEach((column: any) => {
        try{
          this.gridJobs.autoresizecolumn(column.datafield, column.columntype);
        }catch(e){
        }
      })
    }, 100);
  }

  onClose(){
    if(this.componentRef){
      this.componentRef.destroy();
    }
  }

  async getJobs(){
    try{
      this.jobsList = await this.administradorService.getTareas();
    }catch(e){
      this.jobsList = [];
    }

    this.sourceJobs.localdata = this.jobsList;
    this.adapterJobs = new jqx.dataAdapter(this.sourceJobs);

    this.resizeColumns();
  }

  /* GRID JOBS */
  /*
    Comprueba si la tarea esta activada o no
    y marcar el check en el listado
  */
  renderCheckColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
    if (value == 1 || value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">Si</div>';
    }
    else {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">No</div>';
    }
  };


  public columnsJobs;

  public sourceJobs;
  public adapterJobs;

  initGrid() {
    this.columnsJobs = [
      { text: 'id', columntype: 'textbox', datafield: 'id', width: 1, hidden: true },
      { text: this.translate('Nombre'), columntype: 'textbox', datafield: 'nombre', width: 'auto', editable: false },
      { text: 'Trigger', columntype: 'textbox', datafield: 'nombreTrigger', width: 'auto', editable: false },
      { text: this.translate('Grupo'), columntype: 'textbox', datafield: 'grupo', width: 'auto', editable: false,
        filtertype: 'checkedlist'
      },
      { text: this.translate('Segundos'), columntype: 'textbox', datafield: 'segundos', width: 'auto', cellsrenderer: this.numberrenderer, editable: this.editable },
      { text: this.translate('Expresion_cron'), columntype: 'textbox', datafield: 'cronExpresion', with: 'auto', editable: this.editable },
      { text: this.translate('Activa'), columntype: 'checkbox', datafield: 'activado', width: 'auto', editable: this.editable,
        filtertype: 'checkedlist',
        filteritems: [
          this.translate('SI'),
          this.translate('NO')
        ],
      }
    ]

    this.sourceJobs = {
      datatype: 'json',
      groupsrenderer: this.groupsrenderer,
      datafields: [
        { name: 'id', type: 'int', map: 'id' },
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'nombreTrigger', type: 'string', map: 'nombreTrigger' },
        { name: 'grupo', type: 'string', map: 'grupo' },
        { name: 'segundos', type: 'int', map: 'segundos' },
        { name: 'cronExpresion', type: 'string', map: 'cronExpresion' },
        { name: 'activado', type: 'boolean', map: 'activado' }
      ],
      localdata: this.jobsList,
    }
  }

  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
        <b>`+ AppComponent.translate('Activa') +`: </b>
    `;

    //Renderiza los grupos de tipo booleano con el texto para cada idioma
    if(group === true){
      showText += AppComponent.translate('SI');
      if(data.subGroups.length == 0){
        showText += '(' + data?.subItems.length + ')';
      }else{
        showText += '(' + data?.subGroups.length + ')';
      }
      return showText;
    }else if(group === false){
      showText += AppComponent.translate('NO');
      if(data.subGroups.length == 0){
        showText += '(' + data?.subItems.length + ')';
      }else{
        showText += '(' + data?.subGroups.length + ')';
      }
      return showText + `</div>`;
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  /* Acciones del grid */
  onCellClick(event: any){
    if(!event.args.column.editable){
      if (this.rowIndex != event.args.rowindex) {
        this.rowIndex = event.args.rowindex;
        this.jobSelect = this.jobsList[event.args.rowindex];
        this.gridJobs.selectrow(this.rowIndex);
      }else if(this.rowIndex === event.args.rowindex) {
        this.gridJobs.unselectrow(this.rowIndex);
        this.rowIndex = null;
        this.jobSelect = null;
      }
    }
  }

  onRowdoubleclick(event: any) {
    if(this.editable){
      this.rowIndex = event.args.rowindex;
      this.jobSelect = this.jobsList[event.args.rowindex];
      this.gridJobs.selectrow(this.rowIndex);

      this.onEditarJob();
    }
  }

  onRowSelect(event: any) {
    this.rowIndex = event.args.rowindex;
    this.jobSelect = this.jobsList[event.args.rowindex];

    if(this.gridJobs.getselectedrowindexes().length > 1){
      this.gridJobs.clearselection();
      this.gridJobs.selectrow(event.args.rowindex);
    }
  }

  onRowUnselect(event: any){
    this.rowIndex = null;
    this.jobSelect = null;
  }

  onEditarJob(){
    if (this.jobSelect) {
      this.windowJob.collapse();

      this.formJob = this.editTareaComponent.createComponent(EditTareaComponent);
      this.formJob.instance.init(this.formJob, this.jobSelect);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  onCellvaluechanged(event: any) {
    let fila = this.gridJobs.getrowdata(event.args.rowindex);

    this.updateTarea(fila);
  }

  onfilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if(column === 'activado'){
        let filter1;
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if(element.value === this.translate('SI')){
            filtervalue = true;
          }else if(element.value === this.translate('NO')){
            filtervalue = false;
          }

          if(filtervalue != undefined){
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.gridJobs.addfilter(column, filtergroup);
            this.gridJobs.applyfilters();
          }
        });
      }
    }
  }

  async updateTarea(tarea: TareaModel) {
    let actualizado = await this.administradorService.updateTarea(tarea);

    if(actualizado !== null) {
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );
    }else{
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }

  onExportar(){
    const json = JSON.parse(JSON.stringify(this.gridJobs.getdisplayrows()));
    /*
      Elimino los campos que no se quieren mostrar en el excel
    */
    json.forEach(element => {
      delete element.id;
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;

      if(element.activado){
        element.activado = AppComponent.translate('SI');
      }else{
        element.activado = AppComponent.translate('NO');
      }
    });

    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_Trabajos.xlsx');
  }
}
