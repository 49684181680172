import { CerraduraModel } from "./cerradura.model";

export class ConfiguracionCerraduraModel {
  id: number = 0;
  empresa: number = 0;
  nombre: string = '';
  host: string = '';
  puerto: number = 1;
  apn: string = '';
  apnUsuario: string = '';
  apnPassw: string = '';
  pin: string = '';
  tecnologia: number = 2;
  intervaloEnvio: number = 30;
  horaEnvio: number = 0;
  alarmas: number = 0;
  bateriaBaja: number = 500;
  bateriaCritica: number = 500;
  temperatura: number = 750;
  tiempoAbrir: number = 6;
  tiempoAporte: number = 10;
  tiempoCerrar: number = 10;
  tipoContenedor: number = 0;
  tipoCerradura: number = 0;

  cerraduras: CerraduraModel[] = [];

  constructor() { }
}
