import { ElementoCerraduraModel } from "./elementoCerradura.model";
import { OperadorModel } from "./operador.model";

export class CerraduraModel {
    id: number;
    nsMovisat: string;
    nsFabricante: string;
    imei: string;
    tag: string;
    sim: string;
    lat: number;
    lng: number;
    nombreOperadorTelefonia: number;
    operadorTelefonia: OperadorModel;
    calendarios: number[];
    idElemento: number;
    empresa: number;
    fechaCreacion: Date;
    fechaModificacion: Date;
    fechaBaja: Date;
    observaciones: string;
    estado: number;
    modoOperacion: number;
    tipoOperacionStr: string;
    mcc: string;
    mnc: string;
    icc: string;
    imsi: string;
    elemento: ElementoCerraduraModel = new ElementoCerraduraModel();
    versoft: string;

    //agregados
    fechaModificacionStr: string;
}
