<jqxWindow #windowIncidences [theme]="environment.tema" [width]="1000" [height]="490" [zIndex]="90" [autoOpen]="true" [showCloseButton]="true"
    [showCollapseButton]="true" [resizable]="false" [position]="getFormPos(windowIncidences)" (onClose)="onClose()">
    <div class="formHeader">
        {{translate('Incidencias')}}
    </div>
    <div #gridIncidencesContainer class="form">
        <div class="selects-container">
            <div class="column">
                <p class="text-column"> {{translate('Periodo')}}</p>
                <app-periodo-select #periodoSelect [noAnno]="true" [noTrimestre]="true" [noSemestre]="true"
                    [noLibre]="true" [height]="24">
                </app-periodo-select>
            </div>
            <div class="column">
                <p class="text-column"> {{translate('Estado')}}</p>
                <jqxDropDownList #listEstados [theme]="environment.tema" [width]="200" [height]="25" [checkboxes]="true"
                    [displayMember]="'Nombre'" [valueMember]="'Id'" [autoDropDownHeight]="true" [disabled]="false"
                    (onClose)="onCloseCombo()" (onBindingComplete)="onLoadList()"
                    (onCheckChange)="onCheckChange($event)">
                </jqxDropDownList>
            </div>
            <div class="column-buttons">
                <jqxButton style="float:left; margin-left: 18px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"'
                    [value]="translate('ACEPTAR')" (onClick)="onClickBuscar()">
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/exportar.png"' [value]="translate('Exportar')"
                    (onClick)="onExportar($event)">
                </jqxButton>
                <!-- <div class="button">
                    <jqxButton [height]="24" [width]="60" [value]="translate('Buscar')" (onClick)="onClickBuscar()">
                    </jqxButton>
                </div> -->
            </div>
        </div>

        <div class="grid-incidences-container">
            <jqxGrid #incidencesGrid [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
                [columnsresize]="true" [source]="dataAdapter" [sortable]="true" [showrowlines]="false"
                [rendertoolbar]="createToolBar" [showcolumnlines]="true" [columns]="columns" [altrows]="true"
                [sortmode]="'many'" [enabletooltips]="true" [filterable]="false" [showstatusbar]="true"
                [statusbarheight]="20" (onBindingcomplete)="onBindingComplete()" [showaggregates]="true"
                [rowsheight]="30" [columnsheight]="20" [localization]="langGrid" [groupable]="true" [showtoolbar]="true"
                [autoloadstate]="true" (onRowselect)="onRowSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>
            <jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
            </jqxLoader>
        </div>
        <div class="buttons-container">
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' (onClick)="onClickVer()"
                [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]='"/assets/images/ver.png"'
                [value]="translate('Ver')">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]="'imageBeforeText'" (onClick)="onClickCrear()" [textPosition]="'left'"
                [imgSrc]='"/assets/images/mas.png"' [value]="translate('Crear')">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' (onClick)="onClickEditar()"
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
                [value]="translate('Editar')">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='150' [height]='26'
                [textImageRelation]="'imageBeforeText'" (onClick)="onClickCambiar()" [textPosition]="'left'"
                [imgSrc]='"/assets/images/change.png"' [value]="translate('Cambiar_estado')">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26' (onClick)="onClickBorrar()"
                [hidden]="true" [textImageRelation]="'imageBeforeText'" [textPosition]="'left'"
                [imgSrc]='"/assets/images/borrar.png"' [value]="translate('Borrar')">
            </jqxButton>
            <div class="url-container">
                <a class="url-text" (click)="onClickHref()">{{translate('Historico_estados')}}</a>
            </div>
        </div>

    </div>
</jqxWindow>
<app-modal></app-modal>
