import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EventEmitter, Injectable, Output } from '@angular/core';
import { BdtService } from '../bdt/bdt.service';
import { BdtEquipamientoModel } from '../bdt/models/bdt-equipamiento.model';
import { SsoService } from '../sso/sso.service';
import { PuModel } from './models/pu.model';
import { Accion } from '../auditoria/models/accion.model';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { MainComponent } from 'src/app/components/main/main.component';

@Injectable({
  providedIn: 'root'
})
export class PuService {
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  private REG_PAG = 50000; // Número de registros por página

  // Evento para añadir PU
  @Output() addPuEmiter: EventEmitter<PuModel[]> = new EventEmitter();
  // Evento para indicar que se han terminado de cargar los PU
  @Output() endLoadPuEmiter: EventEmitter<void> = new EventEmitter();
  // Evento para crear PU
  @Output() newPuEmiter: EventEmitter<PuModel> = new EventEmitter();
  // Evento para modificar PU
  @Output() modifyPuEmiter: EventEmitter<PuModel> = new EventEmitter();
  // Evento para eliminar PU
  @Output() deletePuEmiter: EventEmitter<PuModel> = new EventEmitter();

  constructor(private http: HttpClient,
    private ssoService: SsoService,
    private bdtService: BdtService,
    private auditoriaService: AuditoriaService) {
  }

  // Recupera los modelos de elementos
  async getElementsEquip(): Promise<BdtEquipamientoModel[]> {
    let result: BdtEquipamientoModel[] = [];
    try {
      if (BdtService.equipamiento.size < 1) {
        await this.bdtService.getCatalogoEquipamiento();
      }
      return new Promise<BdtEquipamientoModel[]>((resolve, reject) => {
        for (const [key, value] of BdtService.equipamiento) {
          result.push(value);
        }
        resolve(result);
      });
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // Recupero los PU
  async getPuntosUbicacion(): Promise<void> {
    try {
      for (let pag = 1, result = null; (result = await this.getPuntosUbicacionPag(pag)) && result.length > 0; pag++) {
        this.addPuEmiter.emit(result);
      }
      this.endLoadPuEmiter.emit();
    } catch (e) {
      console.log(e);
    }
  }

  // Recupera una página de PU
  async getPuntosUbicacionPag(pagina: number): Promise<PuModel[]> {
    try {
      return await this.http.get<PuModel[]>(this.ssoService.getTicket().UrlApi +
        '/api/puntosubicacion?empresaId=' + this.ssoService.getTicket().Empresa.IdGestion +
        "&pag=" + pagina + "&reg=" + this.REG_PAG).toPromise();
    } catch (e) {
      console.log(e);
    }
  }

  // Crea o modifica un PU
  async savePU(pu: PuModel): Promise<PuModel> {
    let response: PuModel = null;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      const puSave = { ...pu }; // Para no modificar el pu original
      let newReg = puSave.Id < 1;
      // Quito los campos "Marker" y "Elemento" porque probocan referencia circular al serializar el 77JSON
      delete puSave.Elemento;
      delete puSave.Marker;
      response = await this.http.post<PuModel>(this.ssoService.getTicket().UrlApi + '/api/puntoubicacion',
        JSON.stringify(puSave), httpOptions).toPromise();
      if (response) {
        // Si se trata de un pu nuevo lo añado a la lista de pu's
        if (newReg) {
          this.newPuEmiter.emit(response);
        } else { // Notifico que se ha modificado un PU
          this.modifyPuEmiter.emit(pu);
        }
      }
    } catch (e) {
      console.log(e);
    }
    if (pu.Id && response) {
      this.auditoria.AccionId = Accion.EDITAR_PUNTO_UBICACION;
    } else if (response && !pu.Id) {
      this.auditoria.AccionId = Accion.CREAR_PUNTO_DE_UBICACION;
    }
    this.msgChangeResponse(response);
    this.auditoriaService.addAuditoria(this.auditoria);

    return response;
  }

  // Borra un PU
  async deletePU(pu: PuModel): Promise<boolean> {
    let response = true;
    try {
      response = await this.http.delete<boolean>(this.ssoService.getTicket().UrlApi +
        '/api/puntoubicacion?puId=' + pu.Id).toPromise();
      if (response) {
        this.deletePuEmiter.emit(pu);
        this.auditoria.AccionId = Accion.ELIMINAR_PUNTO_DE_UBICACION;
        this.auditoria.Info = '<div style="margin-left: 3px; margin-top: 2px"> GUID: ' + pu.Id + '</div>';
        this.auditoriaService.addAuditoria(this.auditoria);
      }



    } catch (e) {
      console.log(e);
    }


    return response;
  }

  // Devuelve el punto de ubicación de un elemento
  async getPuntoUbicacionByElemento(elementoId: number): Promise<PuModel> {
    try {
      let res: PuModel;
      res = await this.http.get<PuModel>(this.ssoService.getTicket().UrlApi +
        '/api/puntoubicacion/elemento?elementoId=' + elementoId).toPromise();
      if (res) {
        res.Equipamiento = BdtService.equipamiento.get(res.IdEquipamiento);
        if (!res.Nombre) { // Los PU que se crean al crear un elemento no tienen nombre
          res.Nombre = 'PU-' + res.Id;
        }
      }
      return res;
    } catch (e) {
      console.log(e);
    }
  }

  msgChangeResponse(response: any): string {
    return this.auditoria.Info = 'ID: ' + response.Id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + response.Nombre;
  }

}
