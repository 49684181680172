import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonComponent } from 'jqwidgets-ng/jqxbuttons';
import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ZonesService } from 'src/app/services/zones/zones.service';
import { ZonaModel } from 'src/app/services/zones/models/zona.model';
import { EntradaModel } from 'src/app/services/zones/models/zona-entrada.model';
import { ZonesComponent } from '../zones.component';
import { get } from 'http';

@Component({
  selector: 'app-entrada-salida',
  templateUrl: './entrada-salida.component.html',
  styleUrls: ['./entrada-salida.component.css']
})
export class EntradaSalidaComponent extends CustomForms implements OnInit {
  // variables recuperadas del html
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('gridEntrLocal') gridEntrLocal: jqxGridComponent;
  @ViewChild('formEntradaSalida') formEntradaSalida: jqxWindowComponent;
  @ViewChild('create') create: jqxButtonComponent;
  @ViewChild('edit') edit: jqxButtonComponent;
  @ViewChild('delete') delete: jqxButtonComponent;
  // variables para el mapa
  public theme = environment.tema;
  private map: MapComponent;
  // variables para el grid
  dataSource: any;
  public dataAdapter: any;
  dataSourceLocal: any;
  dataAdapterLocal: any;
  public langGrid = JqWidgets.getLocalization('es');
  @Input() editable: boolean;
  row: number;
  public static _this: any;
  // variables para el crud
  public editEntrada: boolean = false;
  public entrada: EntradaModel = new EntradaModel();
  public entradas: EntradaModel[] = [];
  entradasLocal: EntradaModel[] = [];
  entradaLocal: EntradaModel = new EntradaModel();
  public tipoEntrada: boolean = false;
  public tipoSalida: boolean = false;
  viewFormEntrada: boolean = false;
  // marcadores
  markers: MapMarker[] = [];
  auxMarker: MapMarker[] = [];
  marker: MapMarker;
  public zonaSelect: ZonaModel = new ZonaModel();
  // suscripción a los eventos del mapa
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerMove = null;
  private subscriptionOnCircleClick = null;
  private subscriptionOnPolygonClick = null;

  public columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', filtertype: 'input', datafield: 'nombre',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Direccion'), columntype: 'textbox', filtertype: 'input', datafield: 'direccion', align: 'center', },
    { text: AppComponent.translate('Poblacion'), columntype: 'textbox', filtertype: 'input', datafield: 'poblacion', },
    { text: AppComponent.translate('Municipio'), columntype: 'textbox', filtertype: 'input', datafield: 'municipio', },
    {
      text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'checkedlist', filteritems: [this.translate("Entrada"), this.translate("Salida"), this.translate("Entrada") + "/" + this.translate("Salida")],
      datafield: 'tipo', align: 'center', cellsrenderer: this.renderRow
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  public columnsLocal = [
    {
      text: AppComponent.translate('Nombre'), columntype: 'textbox', width: 140, filtertype: 'textbox', datafield: 'nombre',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">Total: ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Tipo'), columntype: 'textbox', datafield: 'tipo', width: 90, cellsrenderer: this.renderRow },
    { text: AppComponent.translate('Direccion'), columntype: 'textbox', datafield: 'direccion', },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  constructor(private zonesService: ZonesService, private modal: NzModalService, private ssoService: SsoService) {
    super();
    EntradaSalidaComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.map = MainComponent.getInstance().getActiveMap();
    this.getEntradas(this.editable);
    this.initGridLocal();
  }

  ngAfterViewInit() {
    this.setGridLocal(this.grid);
  }

  async getDireccion(element: any, lat: number, lng: number): Promise<void> {
    try {
      const data = await this.zonesService.getDireccion(lat, lng);
      if (data.District !== null && data.District !== undefined) {
        element.Municipio = data.Locality
      } else if (data.District == null && data.Locality == undefined) {
        element.Poblacion = data.Locality
      }
      element.Poblacion = data.District;

    } catch (error) {
      console.error(error);
    }
  }

  //obtengo todas las entradas
  async getEntradas(edit: boolean) {
    if (edit) {
      this.zonaSelect = ZonesComponent._this.zonaEdit;
      this.entradas = await this.zonesService.getEntradas(this.zonaSelect.Id);
      await Promise.all(
        this.entradas.map(element => this.getDireccion(element, element.Lat, element.Lng))
      );

    } else {
      this.entradas = [];
      this.entradasLocal = [];
    }
    if (this.entradas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'int', map: 'Id' },
          { name: 'nombre', type: 'string', map: 'Nombre' },
          { name: 'direccion', type: 'string', map: 'Direccion' },
          { name: 'poblacion', type: 'string', map: 'Poblacion' },
          { name: 'municipio', type: 'string', map: 'Municipio' },
          { name: 'tipo', type: 'string', map: 'Tipo' },
        ],
        localdata: this.entradas,
      }
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
      this.cargarMarkers();
    }
    return this.entradas;
  }

  initGridLocal() {
    this.dataSourceLocal = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'int', map: 'Id' },
        { name: 'nombre', type: 'string', map: 'Nombre' },
        { name: 'tipo', type: 'string', map: 'Tipo' },
        { name: 'direccion', type: 'string', map: 'Direccion' },
        { name: 'selec', type: 'string' },
      ],
      localdata: this.entradasLocal,
    }
    this.dataAdapterLocal = new jqx.dataAdapter(this.dataSourceLocal);
  }

  cargarMarkers() {
    this.entradas = Utils.onChangeArray(this.entradas);
    this.entradas.forEach(entrada => {
      this.paintMarkers(entrada, new MapLatLng(entrada.Lat, entrada.Lng));
    });
  }

  // añado titulo al formulario
  addTittleForm() {
    setTimeout(() => {
      this.formEntradaSalida.setTitle(AppComponent.translate('Titulo_entr_salidas') + ' ' + this.zonaSelect.Nombre);
      this.addCustomForm(this.formEntradaSalida);
    }, 100);
  }

  // Suscripción a los eventos del mapa
  subscribeToMapEvents() {
    // si no hay subscripción, la creo
    if (!this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onCreateEntrada);
    }
    if (!this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove = this.map.subscribeOnMarkerMove(this, this.onMarkerMove);
    }
    if (!this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick = this.map.subscribeOnCircleClick(this, this.onCreateEntrada);
    }
    if (!this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick = this.map.subscribeOnPolygonClick(this, this.onCreateEntrada);
    }
  }

  onCreateEntrada(_this: EntradaSalidaComponent, position: MapLatLng) {
    _this.createMarker(position);
  }

  onMarkerMove(_this: EntradaSalidaComponent, marker: MapMarker) {
    if (_this.marker == marker) {
      _this.marker.setPosition(new MapLatLng(marker.position.lat, marker.position.lng));
    }
  }

  //para crear un marcador y pinto su icono correspondiente
  async createMarker(position: any) {
    if (this.controlForm() == true) {

      if (position.lat == undefined && position.lng == undefined) {
        position.lat = position.clickPoint.lat;
        position.lng = position.clickPoint.lng;
      }

      this.marker = this.map.addMarker({
        dataModel: this.entrada,
        title: this.entrada.Nombre,
        content: this.zonaSelect.Observaciones,
        position: position.id ? position.clickPoint : position,
        icon: this.iconMarker(this.entrada),
        zIndex: 999,
        drag: this.editEntrada || true,
        visible: true
      });
      this.markers.push(this.marker);
    }

    let data = await this.zonesService.getDireccion(position.lat, position.lng);
    this.entrada.Direccion = data.Street;
    this.entradasLocal.push(this.entrada);
    this.dataSourceLocal.localdata = this.entradasLocal;
    this.gridEntrLocal.updatebounddata();
    if (this.entradasLocal.length == 1) {
      // mensaje de que puede añadir mas entradas
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Puede_anadir_mas_entradas',
        2000);
    }
    // ordenar en ascendente cada vez que se añade un marcador
    setTimeout(() => {
      this.gridEntrLocal.sortby('nombre', 'asc');
      this.resetForm();
    }, 220);
  }

  setGridLocal(grid: jqxGridComponent) {
    grid.setOptions({
      statusbarheight: 15,
      columnsresize: true,
      rowsheight: 20,
      columnsheight: 20
    });
  }

  onRowclickEdit(event) {
    let data = this.gridEntrLocal.getrowdata(event.args.rowindex);
    if (data) {
      this.recoverTypeEntrada(this.entrada.Tipo);
      this.gridEntrLocal.setcellvalue(event.args.rowindex, 'selec', this.markers[event.args.rowindex].id);
      // animo el marcador
      let markAnimate = this.markers.find(marker => marker.id === data.selec);
      if (markAnimate) {
        markAnimate.animate(1000);
      }
    }
  }

  paintMarkers(entrada: EntradaModel, position: MapLatLng) {
    this.marker = this.map.addMarker({
      dataModel: entrada,
      title: entrada.Nombre,
      content: this.zonaSelect?.Observaciones,
      position: position,
      icon: this.iconMarker(entrada),
      zIndex: 999,
      drag: false,
      visible: true
    });
    this.markers.push(this.marker);
  }

  async createEntrada(lat: number, lng: number, marker: MapMarker) {
    this.entrada.Lat = lat;
    this.entrada.Lng = lng;
    this.entrada.ZonaId = ZonesComponent._this.creandoZonaMasEntradas ? ZonesComponent._this.idZonaEntrada : this.zonaSelect.Id;
    let nuevaEntrada = new EntradaModel();
    nuevaEntrada = await this.zonesService.addEntrada(this.entrada);
    if (nuevaEntrada) {
      this.entrada = nuevaEntrada;
      marker.setData(this.entrada);
      this.entradas.push(nuevaEntrada);
    }
  }

  //control de formulario nombre y check obligatorios
  controlForm(): boolean {
    if (this.entrada.Nombre === null || this.entrada.Nombre === undefined || this.entrada.Nombre.length < 1) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
    } else if (this.tipoEntrada == false && this.tipoSalida == false) {
      MainComponent.getInstance().showError('ATENCION', 'Chequee_entrada_salida', 2000);
      return false;
    } else {
      return true;
    }
  }

  //asigna el tipo de entrada
  typeEntrada() {
    if (this.tipoEntrada == true && this.tipoSalida == true) {
      this.entrada.Tipo = 3;
    } else if (this.tipoEntrada == true && this.tipoSalida == false) {
      this.entrada.Tipo = 1;
    } else if (this.tipoEntrada == false && this.tipoSalida == true) {
      this.entrada.Tipo = 2;
    }
  }

  check() {
    // //si existe el marcador solo le asigno el tipo de entrada y cambio su icono
    if (this.marker && this.editEntrada == true) {
      this.typeEntrada();
      this.iconMarker(this.entrada);
      //si no existe el marcador solo le asigno el tipo de entrada y añado su icono
    } else {
      this.typeEntrada();
    }
  }

  //pinto el icono del marcador segun el tipo de entrada y si existe el marcador lo pinto de una manera u otra
  iconMarker(entrada): string {
    let iconUrl: string = '';
    switch (entrada.Tipo) {
      case 1:
        iconUrl = '/assets/images/entrada.png';
        if (this.marker && this.editEntrada == true) {
          this.marker.setIcon('/assets/images/entrada.png')
        }
        break;
      case 2:
        iconUrl = '/assets/images/salida.png';
        if (this.marker && this.editEntrada == true) {
          this.marker.setIcon('/assets/images/salida.png')
        }
        break
      case 3:
        iconUrl = '/assets/images/entrada_salida.png';
        if (this.marker && this.editEntrada == true) {
          this.marker.setIcon('/assets/images/entrada_salida.png')
        }
        break;
    }
    return iconUrl
  }

  //select grid
  onRowclick(event) {
    this.row = event.args.row.bounddata.id;
    let markAnimate = this.markers.find(marker => marker.dataModel.Id === this.row);
    if (markAnimate) {
      markAnimate.animate(1000);
    }
  }

  onRowdoubleclick(event) {
    this.onEditarES(event);
  }

  //cambio el formato de celda a string, segun el tip de entrada
  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    switch (columnfield) {
      case 'tipo':
        switch (value) {
          case 1:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate("Entrada") + '</div>';
          case 2:
            return '<div style="margin-left: 4px; margin-top: 2px;" ' + columnproperties.cellsalign + ';">' + AppComponent.translate("Salida") + '</div>';
          case 3:
            return '<div style="margin-left: 4px; margin-top: 2px;"' + columnproperties.cellsalign + ';">' + AppComponent.translate("Entrada") + "/" + AppComponent.translate("Salida") + '</div>';
        }
    }
  }

  //FUNCIONALIDADES DE ENTRADAS Y SALIDAS
  // crear entrada
  async onCrearES(event: any) {
    if (ZonesComponent._this.circle != null || ZonesComponent._this.polygon != undefined
      || ZonesComponent._this.polygonGeoJson != null) {
      setTimeout(() => {
        this.setGridLocal(this.gridEntrLocal);
      }, 200);
      this.viewFormEntrada = true;
      this.auxMarker = this.markers;
      this.markers = [];
      this.marker = null;
      if (ZonesComponent._this.subscriptionOnMapClick) {
        ZonesComponent._this.subscriptionOnMapClick.unsubscribe();
        ZonesComponent._this.subscriptionOnMapClick = null;
      }
    } else {
      MainComponent.getInstance().showError(
        'ATENCION', 'Seleccione_punto_cartografia', 2000);
    }
  }

  async onGuardarES(event: any) {
    if (this.editEntrada && this.zonaSelect.Id == 0) {
      this.editEntrada = false;
      this.zonaSelect = ZonesComponent._this.zonaEdit;
    }
    
    if (this.zonaSelect.Id == 0) {
      await ZonesComponent._this.onGuardarZona();
      this.entrada.ZonaId = ZonesComponent._this.idZonaEntrada;
      this.zonaSelect = ZonesComponent._this.zonaSelec;
    }

    if (this.markers.length > 0) {
      for (let marker in this.markers) {
        let markerEntrada = this.markers[marker];
        this.entrada = this.markers[marker].dataModel;
        await this.createEntrada(markerEntrada.position.lat, markerEntrada.position.lng, markerEntrada);
      }
      await Promise.all(
        this.entradas.map(element => this.getDireccion(element, element.Lat, element.Lng))
      );
      if (this.auxMarker.length > 0) {
        let marksFusionados = this.auxMarker.concat(this.markers);
        this.markers = marksFusionados;
      }
      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000);
      this.formEntradaSalida.close();
      if (ZonesComponent._this.creandoZonaMasEntradas) {
        ZonesComponent._this.form.collapse();
        ZonesComponent._this.deleteElementsMaps();
        ZonesComponent._this.creandoZonaMasEntradas = false;
      }
      this.entradas = Utils.onChangeArray(this.entradas);
      this.dataSource.localdata = this.entradas;
      this.updateColumnEntradaZonas(this.entradas);
      ZonesComponent._this.gridZonas.clearselection();
      ZonesComponent._this.gridZonas.selectrow(ZonesComponent._this.row);
      this.grid.updatebounddata();
      Utils.renderSizeGrid(this.grid);
    } else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }

  updateColumnEntradaZonas(entradas: EntradaModel[]) {
    this.zonaSelect = ZonesComponent._this.zonaEdit;
    ZonesComponent._this.zonas.forEach(zona => {
      if (zona.Id == this.zonaSelect.Id) {
        zona.Entradas = entradas;
        ZonesComponent._this.countEntrSal(zona);
      }
    });

  }

  // editar entrada
  async onEditarES(event: any) {
    // pinto el marcador en el mapa y lo centro
    let elem = this.entradas.find(elem => elem.Id == this.row);
    if (elem) {
      this.viewFormEntrada = true;
      this.editEntrada = true;
      this.entrada = elem;
      this.recoverTypeEntrada(this.entrada.Tipo);
      this.marker = this.markers.find(marker => marker.dataModel.Id === this.row);
      this.marker.setDraggable(true);
      this.marker.animate(1000);
      this.map.setCenter(this.marker.position);
      this.map.setZoom(18);
      // no dejo mover los demas marcadores
      this.markers.forEach(marker => {
        if (marker.dataModel.Id !== this.row) {
          marker.setDraggable(false);
        }
      });
      this.auxMarker = this.markers.filter(marker => marker.dataModel.Id !== this.row);
      this.markers = [];
      this.markers.push(this.marker);
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // borrar entrada
  async onBorrarES() {
    let elem = this.entradas.find(elem => elem.Id == this.row);
    if (elem) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_elemento') + ': ' + elem.Nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzZIndex: 1,
        nzOnOk: async () => {
          await this.zonesService.deleteEntrada(elem.Id);
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          let markerEntrada = this.markers.find(marker => marker.dataModel.Id === this.row);
          if (markerEntrada) {
            this.map.removeMarker(markerEntrada);
            this.markers.splice(this.markers.indexOf(markerEntrada), 1);
            this.entradas.splice(this.entradas.indexOf(elem), 1);
            this.dataSource.localdata = this.entradas;
            this.grid.updatebounddata();
            this.updateColumnEntradaZonas(this.entradas);
            ZonesComponent._this.gridZonas.clearselection();
            ZonesComponent._this.gridZonas.selectrow(ZonesComponent._this.row);
            this.grid.unselectrow(this.grid.getselectedrowindex());
          }
        }
      });
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  // filtro de tipo de producto
  filter = (cellValue, rowData, dataField, filterGroup, defaultFilterResult): boolean => {

    if (dataField == 'tipo') {
      let filterValue = filterGroup.getfilters()[0].value;

      switch (filterValue) {
        case this.translate('Entrada'):
          if (cellValue == 1) {
            return true;
          }
          break;
        case this.translate('Salida'):
          if (cellValue == 2) {
            return true;
          }
          break;
        case this.translate('Entrada') + "/" + this.translate('Salida'):
          if (cellValue == 3) {
            return true;
          }
        default:
          return false;
      }
    }
  }

  // abrir el formulario
  onOpen() {
    this.viewFormEntrada = true;
    this.addTittleForm();
    this.subscribeToMapEvents();
    ZonesComponent._this.formEditZona.collapse();
  }

  // cierro el formulario
  async onClose() {
    this.viewFormEntrada = false;
    this.unsubscribeMapEvents();
    this.resetForm();
    this.editEntrada = false;
    this.removeMarker();
    this.cargarMarkers();
    ZonesComponent._this.formEditZona.expand();
    this.entradaLocal = new EntradaModel();
    this.entradasLocal = [];
    this.dataSourceLocal.localdata = [];
    this.gridEntrLocal.updatebounddata();
  }

  // Desuscripción a los eventos del mapa
  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
    if (this.subscriptionOnMarkerMove) {
      this.subscriptionOnMarkerMove.unsubscribe();
      this.subscriptionOnMarkerMove = null;
    }

    if (this.subscriptionOnCircleClick) {
      this.subscriptionOnCircleClick.unsubscribe();
      this.subscriptionOnCircleClick = null;
    }

    if (this.subscriptionOnPolygonClick) {
      this.subscriptionOnPolygonClick.unsubscribe();
      this.subscriptionOnPolygonClick = null;
    }

  }

  // reseteo el formulario
  resetForm() {
    this.entrada = new EntradaModel();
    this.tipoEntrada = false;
    this.tipoSalida = false;
  }

  // elimino los marcadores
  removeMarker() {
    if (this.markers.length > 0) {
      this.markers.forEach(marker => {
        this.map.removeMarker(marker);
      });
      this.marker = null;
      this.markers = [];
    }

    if (this.auxMarker.length > 0) {
      this.auxMarker.forEach(marker => {
        this.map.removeMarker(marker);
      });
      this.marker = null;
      this.auxMarker = [];
    }
  }

  // recupero el tipo de entrada para pintar el check
  recoverTypeEntrada(tipo: number) {
    switch (tipo) {
      case 1:
        this.tipoEntrada = true;
        this.tipoSalida = false;
        break;

      case 2:
        this.tipoEntrada = false;
        this.tipoSalida = true;
        break;

      case 3:
        this.tipoEntrada = true;
        this.tipoSalida = true;
        break;
    }
  }

  //habilito o deshabilito los botones
  disabledBotton(value: boolean) {
    if (this.zonaSelect.Tipo === 3) {
      this.grid.disabled(true);
      this.create.disabled(true);
      this.edit.disabled(true);
      this.delete.disabled(true);
    } else {
      this.grid.disabled(value);
      this.create.disabled(value);
      this.edit.disabled(value);
      this.delete.disabled(value);
    }
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
