import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { environment } from 'src/environments/environment';
import { CiudadanosDireccionEditComponent } from '../../ciudadanos-direccion-edit/ciudadanos-direccion-edit.component';
import { MainComponent } from 'src/app/components/main/main.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { CiudadanosEditComponent } from '../../ciudadanos-edit/ciudadanos-edit.component';
import { MapLatLng } from 'movisat-maps';
import { DireccionService } from 'src/app/services/direccion/direccion.service';
import { MapsService } from 'src/app/services/maps/maps.service';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

@Component({
  selector: 'app-direccion-listado',
  templateUrl: './direccion-listado.component.html',
  styleUrls: ['./direccion-listado.component.css']
})
export class DireccionListadoComponent implements OnInit {
  // Componentes del html
  @ViewChild('formDireccion', { read: ViewContainerRef }) editDireccionComponent;
  @ViewChild('gridDirecciones') gridDirecciones: jqxGridComponent;

  // Entradas
  @Input() ciudadano: CiudadanoModel;

  // Funcionalidad
  public static _this: DireccionListadoComponent;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  formDireccion: any;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private direccionService: DireccionService,
    private mapService: MapsService,
    private modal: NzModalService,
  ) {
    DireccionListadoComponent._this = this;
  }

  ngOnInit(): void {
    this.initGridDirecciones();
  }

  // Variables grid direcciones
  public columnsDirecciones;
  public columnsGroupDirecciones
  public sourceDirecciones;
  public adapterDirecciones;

  // Inicializa grid de direcciones
  initGridDirecciones() {
    // Agrupaciones de columnas
    this.columnsGroupDirecciones = [
      { text: AppComponent.translate('Acciones'), align: 'center', name: 'accionesGroup' },
      { text: AppComponent.translate('Generales'), align: 'center', name: 'generales' },
      { text: AppComponent.translate('Catastro'), align: 'center', name: 'catastro' },
      { text: AppComponent.translate('Direccion_cartografica'), align: 'center', name: 'direccionCartografica' }
    ];

    this.columnsDirecciones = [
      { text: '', datafield: 'id', hidden: true },
      {
        text: '',
        width: 80,
        columntype: 'text',
        sortable: false,
        editable: false,
        datafield: 'acciones',
        groupable: false,
        menu: false,
        columngroup: 'accionesGroup',
        rendered: (columnHeaderElement) => {
          let time = new Date().getTime();
          const buttonContainer = document.createElement('div');

          const options = {
            width: '100%',
            value: '<i class="fa fa-plus-circle fa-lg" aria-hidden="true"></i>',
            textImageRelation: 'imageAboveText',
          };
          buttonContainer.id = `buttonContainerColumn_jqxButton` + time;
          columnHeaderElement[0].appendChild(buttonContainer);
          let myButton;

          setTimeout(() => {
            myButton = jqwidgets.createInstance(
              `#buttonContainerColumn_jqxButton` + time,
              'jqxButton',
              options
            );
            myButton.addEventHandler('click', () => {
              let direccion = new DireccionModel();
              direccion.direccionPrincipal = false;
              direccion.idCiudadano = this.ciudadano.id
              this.formDireccion = this.editDireccionComponent.createComponent(CiudadanosDireccionEditComponent);
              this.formDireccion.instance.init(this.formDireccion, direccion, this.ciudadano);

              CiudadanosEditComponent._this.windowCiudadanosForm.collapse();
            });
            let btn = <HTMLElement>buttonContainer.parentElement.children[2];
            btn.title = 'Añadir';
            btn.classList.add('button');
            btn.style.cursor = 'pointer';
            btn.style.height = '25px';
            btn.style.marginTop = '2px';

            let icon = <HTMLElement>btn.children[1];
            icon.style.position = 'unset';
          }, 50);
          return columnHeaderElement[0];
        },
        createwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ): void => {
          this.initBtnColumn(row, column, value, htmlElement);
        },
        initwidget: (
          row: any,
          column: any,
          value: string,
          htmlElement: HTMLElement
        ) => {
          this.initBtnColumn(row, column, value, htmlElement);
        }
      },
      { text: AppComponent.translate('Tipo_direccion'), columntype: 'textbox', datafield: 'direccionPrincipal', columngroup: 'generales', cellsrenderer: this.renderTipoDireccion },
      { text: AppComponent.translate('Numero_de_catastro'), columntype: 'textbox', datafield: 'referenciaCatastral', columngroup: 'catastro' },
      { text: AppComponent.translate('Direccion_catastro'), columntype: 'textbox', datafield: 'direccionCatastral', columngroup: 'catastro' },
      { text: AppComponent.translate('Titular_catastro'), columntype: 'textbox', datafield: 'titularCatastro', columngroup: 'catastro' },
      { text: AppComponent.translate('Domicilio'), columntype: 'textbox', datafield: 'direccion', columngroup: 'direccionCartografica' },
      { text: AppComponent.translate('Numero'), columntype: 'textbox', datafield: 'numero', columngroup: 'direccionCartografica', align: 'right' },
      { text: AppComponent.translate('Codigo_postal'), columntype: 'textbox', datafield: 'codPostal', columngroup: 'direccionCartografica', align: 'right' },
      { text: AppComponent.translate('Poblacion'), columntype: 'textbox', datafield: 'poblacion', columngroup: 'direccionCartografica' },
      { text: AppComponent.translate('Municipio'), columntype: 'textbox', datafield: 'municipio', columngroup: 'direccionCartografica' },
      { text: AppComponent.translate('Provincia'), columntype: 'textbox', datafield: 'provincia', columngroup: 'direccionCartografica' },
      { text: AppComponent.translate('Comunidad_autonoma'), columntype: 'textbox', datafield: 'comunidadAutonoma', columngroup: 'direccionCartografica' },
      { text: AppComponent.translate('Planta'), columntype: 'textbox', datafield: 'planta', columngroup: 'direccionCartografica', align: 'right' },
      { text: AppComponent.translate('Puerta'), columntype: 'textbox', datafield: 'puerta', columngroup: 'direccionCartografica', align: 'right' },
    ];

    this.sourceDirecciones = {
      datatype: 'json',
      datafields: [
        {name: 'id', type: 'number', map: 'id'},
        {name: 'acciones', type:'string'},
        {name: 'direccionPrincipal', type:'string', map: 'direccionPrincipal'},
        {name: 'referenciaCatastral', type:'string', map: 'referenciaCatastral'},
        {name: 'direccionCatastral', type:'string', map: 'catastro>direccion'},
        {name: 'titularCatastro', type:'string', map: 'catastro>titular'},
        {name: 'direccion', type:'string', map: 'direccion'},
        {name: 'numero', type:'string', map: 'numero'},
        {name: 'codPostal', type:'string', map: 'codigoPostal'},
        {name: 'poblacion', type:'string', map: 'poblacion'},
        {name: 'municipio', type:'string', map: 'municipio'},
        {name: 'provincia', type:'string', map: 'provincia'},
        {name: 'comunidadAutonoma', type:'string', map: 'comunidadAutonoma'},
        {name: 'planta', type:'string', map: 'planta'},
        {name: 'puerta', type:'string', map: 'puerta'},
      ],
      localdata: this.ciudadano.direcciones,
    }
    this.adapterDirecciones = new jqx.dataAdapter(this.sourceDirecciones);
  }

  // Incializa la columna de botones
  async initBtnColumn(
    row: any,
    column: any,
    value: string,
    htmlElement: HTMLElement
  ) {
    htmlElement.innerHTML = '';
    // Crea un contenedor para los botones
    const btnContainer = document.createElement('div');
    btnContainer.style.display = 'flex';
    btnContainer.style.justifyContent = 'space-around';
    btnContainer.style.gap = '2px';
    btnContainer.style.padding = '2px';
    let direccion = this.ciudadano.direcciones.find(elem => elem.id == this.gridDirecciones.getrowdata(row.boundindex).id);

    const btnViewMap = document.createElement('div');
    btnViewMap.innerHTML =
      '<button class="button" style=" height: 20px; width: 20px;  padding: 0; margin: 0; cursor: pointer !important;">' +
      '<i style="font-size: 15px;" class="fa-sharp fa-solid fa-location-dot"></i></button>';
      btnViewMap.id = `buttonViewMap_jqxButton`;
    btnContainer.appendChild(btnViewMap);

    btnViewMap.addEventListener('click',async (event: any) => {
      this.onCentrar(direccion);
    });

    const btnEdit = document.createElement('div');
    btnEdit.innerHTML =
      '<button class="button" style="height: 20px; width: 20px; padding: 0; margin: 0; cursor: pointer !important;">' +
      '<i style="font-size: 15px;" class="fas fa-edit"></i></button>';
      btnEdit.id = `buttonInfoMap_jqxButton`;
    btnContainer.appendChild(btnEdit);

    btnEdit.addEventListener('click',async (event: any) => {
      this.formDireccion = this.editDireccionComponent.createComponent(CiudadanosDireccionEditComponent);
      this.formDireccion.instance.init(this.formDireccion, direccion, this.ciudadano);

      CiudadanosEditComponent._this.windowCiudadanosForm.collapse();
    });

    let rowdata;
    if(isNaN(row)) {
      rowdata = row.bounddata;
    }else{
      rowdata = this.gridDirecciones.getrowdata(row);
    }

    // Muestra el boton de borrar si la direccion no es principal
    if(rowdata && !rowdata.direccionPrincipal){
      const btnDelete = document.createElement('div');
      btnDelete.innerHTML =
        '<button class="button" style=" height: 20px; width: 20px;  padding: 0; margin: 0; cursor: pointer !important;">' +
        '<i style="font-size: 15px;" class="fa-sharp fa-solid fa-trash"></i></button>';
        btnDelete.id = `buttonDelete_jqxButton`;
      btnContainer.appendChild(btnDelete);

      btnDelete.addEventListener('click',async (event: any) => {
        this.onBorrarDireccion(direccion);
      });
    }

    htmlElement.appendChild(btnContainer);
  }

  // Renderiza la columna de direcciones
  renderTipoDireccion(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-left: 4px; margin-top: 4px;">'+AppComponent.translate('Principal')+'</div>';
    }else {
      return '<div class="jqx-grid-cell-left-align" style="margin-left: 4px; margin-top: 4px;">'+AppComponent.translate('Secundaria')+'</div>';
    }
  }

  // Centra el mapa y genera un marcador en el punto
  async onCentrar(data: DireccionModel) {
    CiudadanosEditComponent._this.removeMarker();

    //Obtiene las coordenadas
    let coordenadas = await this.mapService.getCoordinatesByDirection(
      data.numero,
      data.direccion,
      data.municipio,
      data.provincia,
      data.comunidadAutonoma,
      data.codigoPostal,
      data.poblacion
    );

    // Acerca y centra el mapa
    CiudadanosEditComponent._this.map.setZoom(18);
    CiudadanosEditComponent._this.map.setCenter(new MapLatLng(coordenadas.lat, coordenadas.lng));

    // Añade el marcador
    CiudadanosEditComponent._this.marker = CiudadanosEditComponent._this.map.addMarker({
      dataModel: data.id,
      title: 'Direccion',
      content: '',
      icon: '/assets/images/elemento.png',
      zIndex: 998,
      visible: true,
      position: new MapLatLng(coordenadas.lat, coordenadas.lng)
    });
    CiudadanosEditComponent._this.marker.animate(2900);
    CiudadanosEditComponent._this.windowCiudadanosForm.collapse();
  }

  // Muestra la confirmación del borrado
  public onBorrarDireccion(direccion: DireccionModel) {
    if(direccion.direccionPrincipal){
      MainComponent.getInstance().showWarning(
        'ATENCION',
        'Direccion_es_principal',
        5000
      );
    }else {
      this.modal.confirm({
        nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
        nzContent: this.translate('Quiere_borrar_direccion'),
        nzCentered: true,
        nzCancelText: this.translate('CANCELAR'),
        nzOkText: this.translate('SI'),
        nzOnOk: async () => {
          this.removeDireccion(direccion);
          this.modal.closeAll();
        }
      });
    }
  }

  // Metodo que se llama al confirmar el borrado
  async removeDireccion(direccion: DireccionModel) {
    if (await this.direccionService.deleteDireccion(direccion)) {
      if(CiudadanosEditComponent._this.marker.dataModel == direccion.id) {
        CiudadanosEditComponent._this.map.removeMarker(CiudadanosEditComponent._this.marker);
      }

      let index = this.ciudadano.direcciones.findIndex(elem => elem.id == direccion.id);
      this.ciudadano.direcciones.splice(index, 1);
      this.gridDirecciones.updatebounddata();

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_borrado',
        2000
      );
    }else {
      MainComponent.getInstance().showWarning(
        'Error',
        'Error_api',
        2000
      );
    }
  }

  public savedDirection(direccion: DireccionModel) {
    let index = this.ciudadano.direcciones.findIndex(elem => elem.id == direccion.id);
    if(index != -1) {
      this.ciudadano.direcciones[index] = direccion;
      if(direccion.direccionPrincipal){
        for(const prop in direccion){
          this.ciudadano[prop] = direccion[prop];
        }
      }
    }else {
      this.ciudadano.direcciones.push(direccion);
    }
    this.gridDirecciones.updatebounddata();
    CiudadanosEditComponent._this.windowCiudadanosForm.expand();
  }
}
