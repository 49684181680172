import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';

import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';
import { MainComponent } from 'src/app/components/main/main.component';

import { MapLatLng } from 'src/app/imports';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxSplitterComponent } from 'jqwidgets-ng/jqxsplitter';

import { SsoService } from 'src/app/services/sso/sso.service';
import { SensorsService } from 'src/app/services/sensors/sensors.service';
import { AuditoriaService } from 'src/app/services/auditoria/auditoria.service';

import { Accion } from 'src/app/services/auditoria/models/accion.model';
import { MovilModel } from 'src/app/services/resources/models/movil.model';
import { AuditoriaModel } from 'src/app/services/auditoria/models/auditoria.model';
import { SensorDatosModel } from 'src/app/services/sensors/models/sensor-datos.model';
import { InformeMovilidadModel } from 'src/app/services/reports/models/informe-movilidad-model';
import { EDigitalMovilidadModel } from 'src/app/services/reports/models/edigital-movilidad-model';

import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-listado-sensor',
  templateUrl: './listado-sensor.component.html',
  styleUrls: ['./listado-sensor.component.css']
})
export class ListadoSensorComponent extends CustomForms implements OnInit, AfterViewInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('loader') loader: jqxLoaderComponent;
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('mainSplitter') mainSplitter: jqxSplitterComponent;

  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);
  // Variables para generar el listado
  private movilSelect: MovilModel[] = []
  private informe: InformeMovilidadModel[] = [];
  private entradasDigitales: EDigitalMovilidadModel[] = [];
  private sensores: SensorDatosModel[] = [];
  private sensorSelec: SensorDatosModel;
  public dataSource;
  public dataAdapter;

  //Variables para mapa
  private map;
  private marker;

  private componentRef = null;
  public environment = environment;

  // Variables para filtrado
  public fechaIni: Date;
  public fechaFin: Date;


  // Preparo las columnas del grid
  public columns = [

    { text: AppComponent.translate('Fecha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fecha', cellsformat: 'dd/MM/yy HH:mm:ss' },
    {
      text: AppComponent.translate('Movil'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'movil',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' +
            AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + ' </div>';
        }
        return renderstring;
      }
    },
    { text: AppComponent.translate('Sensor'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'sensor', },
    { text: AppComponent.translate('Lectura'), columntype: 'textbox', filtertype: 'textbox', datafield: 'valor', cellsalign: 'center' }
  ];

  //  Esto es para que los textos en los controles del grid salgan en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private sensorService: SensorsService,
    private auditoriaService: AuditoriaService
  ) {
    super();
  }

  ngOnInit(): void {
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(this.translate('Listado_sensores'));
    Utils.renderSizeGrid(this.grid);
    this.mainSplitter;
  }

  // Trae los moviles seleccionados del hijo
  selected(event) {
    this.movilSelect = event;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  // Cierro el formulario y destruyo el componente
  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.delMarker();
  }

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  // Obtiene los sensores
  private async getSensors() {
    if (this.movilSelect) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'fecha', map: 'Fecha' },
          { name: 'movil', type: 'string', map: 'movil>Nombre' },
          // { name: 'sensor', type: 'string', map: 'sensor>Nombre' },
          { name: 'sensor', type: 'string', map: 'NombreSensor' },
          { name: 'valor', type: 'string', map: 'Valor' },
          { name: 'tipoValor', type: 'string', map: 'sensor>TipoValor' }
        ],
        localdata: this.sensores,
        sortcolumn: 'fecha',
        sortdirection: 'asc'
      };
      //Antes de completar la carga modifica el registro según el tipo de valor
      this.dataAdapter = new jqx.dataAdapter(this.dataSource, {
        beforeLoadComplete: (records: any[]): void => (
          records.forEach(registro => {
            if (registro.tipoValor === 'int' && Number(registro.valor) !== 0) {
              registro.valor = NumberUtils.format(Number(registro.valor), 0);
            }
            if (registro['tipoValor'] === 'float' && Number(registro.valor) !== 0) {
              registro.valor = NumberUtils.format(Number(registro.valor), 2);
            }
          })
        )
      });
    } else {
      this.sensores = [];
    }

    const t = setTimeout(() => {
      clearTimeout(t);
      this.grid.autoresizecolumns();
    }, 500);
  }

  imagerenderer(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    return value ? '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="data:image/jpg;base64,' + value + '"/>' :
      '<img style="margin-left: 4px; margin-top: 2px;" height="16" width="16" src="assets/images/car.png" />';
  }

  async onAceptar() {
    this.fechaIni = this.header.periodoSelect.getFechaIni();
    this.fechaFin = this.header.periodoSelect.getFechaFin();
    let periodo = 0;
    switch (this.header.periodoSelect.getPeriodo()) {
      case 0:
        periodo = 1; // Dia
        break;
      case 1:
        periodo = 2; // Semana
        break;
      case 2:
        periodo = 3; // Mes
        break;
      default:
        periodo = 4; // Año
        break;
    }

    this.loader.open();
    const result = await this.sensorService.getSensoresMoviles(this.movilSelect, this.fechaIni, this.fechaFin);
    this.sensores = result;
    this.getSensors();
    this.generateEventSplitter();
    this.loader.close();

  }
  // Metodo para cerrar el splitter cuando le damos clic al boton aceptar
  generateEventSplitter() {
    this.mainSplitter.collapse();
  }

  onRowClick(event: any) {
    this.sensorSelec = this.sensores[event.args.rowindex];
  }

  getMap() {
    this.map = MainComponent.getActiveMap();
    return this.map;
  }

  // Elimina el marcador del mapa
  delMarker() {
    if (this.marker && this.map) {
      this.map.removeMarker(this.marker);
    }
  }

  onCentrar(event: any) {
    // Crea y posiciona el marcador
    if (this.sensorSelec) {
      this.getMap();
      this.delMarker();
      this.map = this.crearMarker(this.map);
      this.form.collapse();
      this.map.setCenter(this.marker.position);
      this.map.setZoom(18);
      this.marker.animate(3000);
    } else {
      MainComponent.showWarning('ATENCION', 'Seleccione_registro', 3000);
    }
  }

  crearMarker(map) {
    let valor = this.sensorSelec.Valor;
    if (this.sensorSelec.sensor.TipoValor === 'int' && Number(this.sensorSelec.Valor) !== 0) {
      valor = NumberUtils.format(Number(this.sensorSelec.Valor), 0);
    }
    if (this.sensorSelec.sensor.TipoValor === 'float' && Number(this.sensorSelec.Valor) !== 0) {
      valor = NumberUtils.format(Number(this.sensorSelec.Valor), 2);
    }
    this.marker = map.addMarker({
      dataModel: { codigo: 1, nombre: 'Marcador 1' },
      title: `Sensor ${this.sensorSelec.sensor.Nombre}`, // Texto que aparece al pasar el cursor por encima
      content: `<h3>${this.sensorSelec.sensor.Nombre}</h3>
                      <hr>
                      <b>Móvil: ${this.sensorSelec.movil.Nombre}</b>
                      <br>
                      Fecha: ${DateUtils.formatDateTimeShort(this.sensorSelec.Fecha, true)}
                      <br>
                      Lectura: ${valor}`, // Contenido html de la ventana que aparece al pinchar
      position: new MapLatLng(this.sensorSelec.Lat, this.sensorSelec.Lng),
      icon: '',
      zIndex: 0,
      drag: false
    });
    return map;
  }

  onExportar() {
    var rows = this.grid.getrows();
    let gridContent = this.grid.exportdata('json');
    let tabla = JSON.parse(gridContent);
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(tabla);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_ListadoSensor.xlsx');
    this.auditoria.AccionId = Accion.EXPORTAR_INFORME_SENSORES;
    this.auditoriaService.addAuditoria(this.auditoria);
  }

}
