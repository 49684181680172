import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { IdiomaModel } from '../sso/models/idioma.model';
import { TextoModel } from '../sso/models/texto.model';

@Injectable({
  providedIn: 'root'
})
export class LangService {
  private textos = new Map<string, string>();

  constructor(private http: HttpClient) { }

  // Traduce un texto
  translate(textId: string): string {
    let res = this.textos.get(textId);
    if (!res) {
      res = '@#!';
    }
    return res;
  }

  // Recupera todos los textos de un idioma
  async getText(idiomaId: number): Promise<void> {
    try {
      const response = await this.http.get<TextoModel[]>(environment.apiSSO + '/sso/textos/' + idiomaId).toPromise();
      if (response) {
        response.forEach(texto => {
          this.textos.set(texto.Id, texto.Texto);
        });
      }
    } catch (e) {
      console.log(e);
    }
  }

  // Recupera todos los idiomas
  async getIdiomas(): Promise<IdiomaModel[]> {
    try {
      return await this.http.get<IdiomaModel[]>(environment.apiSSO + '/sso/idiomas').toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  getCurrentTexts() {
    return this.textos;
  }

}
