<div class="row" style="height: 100%; width: 100%;">
  <div style="width: 45%;">
    <h3>{{ translate('Tarjetas_de') }} {{ciudadano?.nombre}} {{ciudadano?.apellidos}}</h3>
    <jqxGrid #gridTarjetasAsignadas [theme]="environment.tema" [width]="'99%'" [height]="'100%'"
      [source]="adapterTarjetasAsignadas" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
      [showfilterrow]="false" [columns]="columnsTarjetasAsignadas" [showrowlines]="false" [showcolumnlines]="true"
      [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="0"
      [showaggregates]="false" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="false" [editable]="true"
      [groupable]="true" [selectionmode]="'singlerow'" (onRowclick)="onRowSelectAsignada($event)"
      (onCellendedit)="onCellEdit($event)">
    </jqxGrid>
  </div>
  <div style="width: 10%;">
    <div class="rowC" style="height: 88%;">
      <jqxButton class="button" style="margin-bottom: 25%; cursor: pointer;" [width]="50" [height]="50" [imgHeight]="40"
        [imgWidth]="40" [imgSrc]='"/assets/images/izquierda.png"' [imgPosition]="'center'"
        (onClick)="associateTarjetasToCiudadano()" [disabled]="!(tarjetaLibreSelect)">
      </jqxButton>
      <jqxButton class="button" style="margin-top: 25%; cursor: pointer;" [width]="50" [height]="50" [imgHeight]="40"
        [imgWidth]="40" [imgSrc]='"/assets/images/derecha.png"' [imgPosition]="'center'"
        (onClick)="disassociateTarjetasFromCiudadano()" [disabled]="!(tarjetaAsignadaSelect)">
      </jqxButton>
    </div>
    <div style="height: 20%;">
      <jqxButton style="float: left;" [width]="'95%'" [height]="36" [imgHeight]="26" [imgWidth]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]='"/assets/images/nfc.png"'
        [imgPosition]="'left'" [value]="translate('Grabar_tarjeta')" (onClick)="onNewCard()">
      </jqxButton>
    </div>
  </div>
  <div style="width: 45%;">
    <h3>{{ translate('Tarjetas_libres') }}</h3>
    <jqxGrid #gridTarjetasLibres [theme]="environment.tema" [width]="'99%'" [height]="'100%'"
      [source]="adapterTarjetasLibres" [columnsresize]="true" [localization]="langGrid" [filterable]="true"
      [showfilterrow]="false" [columns]="columnsTarjetasLibres" [showrowlines]="false" [showcolumnlines]="true"
      [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="0"
      [showaggregates]="false" [rowsheight]="20" [columnsheight]="20" [enabletooltips]="false" [editable]="false"
      [groupable]="true" [selectionmode]="'singlerow'" (onRowclick)="onRowSelectLibre($event)">
    </jqxGrid>
  </div>
</div>