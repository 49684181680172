import { SpecialDaysModel } from "./special-days.model";

export class CalendarModel {
    id: number;
    empresa: number;
    nombre: string;
    observaciones: string;
    fechaInicio: Date;
    fechaFin: Date;
    todosLosDias: boolean;
    horaInicio1: Date;
    horaFin1: Date;
    horaInicio2: Date;
    horaFin2: Date;
    horaInicio3: Date;
    horaFin3: Date;
    horaInicio4: Date;
    horaFin4: Date;
    horaInicio5: Date
    horaFin5: Date;
    horaInicio6: Date;
    horaFin6: Date;
    horaInicio7: Date;
    horaFin7: Date;
    calendario: [] = [];
    diasEspecialesConServicio: SpecialDaysModel[] = [];
    diasEspecialesSinServicio: SpecialDaysModel[] = [];

    //Agregado
    diasSemana: string;
    calendarId: string;
    excepto: string;

}