import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';
import { IdentificacionCerraduraModel } from 'src/app/services/cerraduras/models/identificacionCerradura';

import { ModeloSelectComponent } from 'src/app/components/modelo-select/modelo-select.component';
import { PeriodoSelectComponent } from 'src/app/components/periodo-select/periodo-select.component';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-listado-identificacion',
  templateUrl: './listado-identificacion.component.html',
  styleUrls: ['./listado-identificacion.component.css']
})
export class ListadoIdentificacionComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') grid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  cerradura: boolean;
  unSelect: boolean;
  elementosSelect: ElementoModel[] = [];
  identificacion: IdentificacionCerraduraModel = new IdentificacionCerraduraModel;
  identificaciones: IdentificacionCerraduraModel[] = [];
  dateFin = new Date();
  dateInicio = new Date();
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();
  // grid
  dataAdapter: any;
  source: any;
  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');

  mapHeight;
  mapWidth;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private ssoService: SsoService,
    private serviceCerraduras: CerraduraService) {
    super();
  }

  // Inicializa el componente
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.getIdentificaciones(this.dateIdentificacion);

    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Historicos_identificaciones'));

    this.onAceptar();
  }

  public columns = [
    { text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: this.translate('Fecha'),
      cellsrenderer: this.renderRow, aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-right: 4px;">' +
            AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ns_movisat') },
    { text: this.translate('Ciudadano'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ciudadano') },

    {
      text: this.translate('Aporte_residuo'), columntype: 'checkbox', datafield: this.translate('Aporte_residuo'), cellsrenderer: this.renderCheck,
      width: 110,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO'),
      ],
    },
    {
      text: this.translate('Apertura_tapa'), columntype: 'checkbox', datafield: this.translate('Apertura_tapa'), cellsrenderer: this.renderCheck,
      width: 100,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO'),
      ],
    },
    {
      text: this.translate('No_cerro_tapa'), columntype: 'checkbox', datafield: this.translate('No_cerro_tapa'), cellsrenderer: this.renderCheck,
      width: 85,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('SI'),
        this.translate('NO'),
      ],
    },

    {
      text: this.translate('Tipo_identificacion'), columntype: 'textbox', datafield: this.translate('Tipo_identificacion'), cellsrenderer: this.renderPermiso, sortable: false,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('Tarjeta_nfc'),
        'Bluetooth',
      ],
    },
    {
      text: this.translate('Tipo_permiso'), columntype: 'textbox', datafield: this.translate('Tipo_permiso'), cellsrenderer: this.renderTarjeta,
      filtertype: 'checkedlist',
      filteritems: [
        this.translate('Master'),
        this.translate('Blanca')
      ],
    },

    { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Nombre_elemento') },
    { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Marca') },
    { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Modelo') },
    { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Matricula') },
    {
      text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Observaciones'),
      sortable: false, filterable: false
    },
    { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Direccion') },
    { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Municipio') },
    { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Poblacion') },
  ];

  async getIdentificaciones(date: DateIdentificacionModel) {
    this.source = {
      datatype: 'json',
      dataFields: [
        { name: this.translate('Fecha'), type: 'date', map: 'fecha' },
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsCerradura' },
        { name: this.translate('Ciudadano'), type: 'string', map: 'nombreCiudadano' },

        { name: this.translate('Aporte_residuo'), type: 'bool', map: 'aporte' },
        { name: this.translate('Apertura_tapa'), type: 'bool', map: 'aperturaTapa' },
        { name: this.translate('No_cerro_tapa'), type: 'bool', map: 'noCerroTapa' },

        { name: this.translate('Tipo_identificacion'), type: 'string', map: 'medio' },
        { name: this.translate('Tipo_permiso'), type: 'string', map: 'tarjetaMaster' },

        { name: this.translate('Nombre_elemento'), type: 'string', map: 'nombreElemento' },
        { name: this.translate('Marca'), type: 'string', map: 'marcaElemento' },
        { name: this.translate('Modelo'), type: 'string', map: 'modeloElemento' },
        { name: this.translate('Matricula'), type: 'string', map: 'matriculaElemento' },
        { name: this.translate('Observaciones'), type: 'string', map: 'observacionesElemento' },

        { name: this.translate('Direccion'), type: 'string', map: 'direccionElemento' },
        { name: this.translate('Municipio'), type: 'string', map: 'municipioElemento' },
        { name: this.translate('Poblacion'), type: 'string', map: 'poblacionElemento' },
      ],
      localdata: this.identificaciones,
      sortcolumn: 'fecha',
      sortdirection: 'desc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);
  }

  // Renderiza los textos al agrupar por ciertos campos
  groupsrenderer(text?: string, group?: any, expanded?: boolean, data?: any): string {
    // El cierre del div se hace en el return del texto a mostrar
    let showText = `
      <div style="top: 50%; margin-top: -8px; position: relative; margin-left: 4px">
        <b>`+ data.groupcolumn.datafield +`: </b>
      `;

    if(
      data.groupcolumn.datafield === AppComponent.translate('Aporte_residuo') ||
      data.groupcolumn.datafield === AppComponent.translate('Apertura_tapa') ||
      data.groupcolumn.datafield === AppComponent.translate('No_cerro_tapa')
    ){
      if(group === true){
        showText += AppComponent.translate('SI');
        if(data.subGroups.length == 0){
          showText += '(' + data?.subItems.length + ')';
        }else{
          showText += '(' + data?.subGroups.length + ')';
        }
      }else if(group === false){
        showText += AppComponent.translate('NO');
        if(data.subGroups.length == 0){
          showText += '(' + data?.subItems.length + ')';
        }else{
          showText += '(' + data?.subGroups.length + ')';
        }
      }
      return showText + "</div>";
    }else if(data.groupcolumn.datafield === AppComponent.translate('Tipo_identificacion')){
      if(group === 1){
        showText += 'Bluetooth';
        if(data.subGroups.length == 0){
          showText += '(' + data?.subItems.length + ')';
        }else{
          showText += '(' + data?.subGroups.length + ')';
        }
      }else if(group === 0){
        showText += AppComponent.translate('Tarjeta_nfc');
        if(data.subGroups.length == 0){
          showText += '(' + data?.subItems.length + ')';
        }else{
          showText += '(' + data?.subGroups.length + ')';
        }
      }
      return showText + "</div>";
    }else if(data.groupcolumn.datafield === AppComponent.translate('Tipo_permiso')){
      if(group){
        showText += AppComponent.translate('Master');
        if(data.subGroups.length == 0){
          showText += '(' + data?.subItems.length + ')';
        }else{
          showText += '(' + data?.subGroups.length + ')';
        }
      }else{
        showText += AppComponent.translate('Blanca');
        if(data.subGroups.length == 0){
          showText += '(' + data?.subItems.length + ')';
        }else{
          showText += '(' + data?.subGroups.length + ')';
        }
      }
      return showText + "</div>";
    }
  }

  // Cambia el color de los checkbox para que aparezcan como deshabilitados
  renderCheck(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if(value){
      return(
      `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgete18472e433a4" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material" checked="true">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;" class="jqx-checkbox-check-checked jqx-checkbox-check-checked-material"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }else{
      return(
      `
        <div style="position: absolute; top: 50%; left: 50%; margin-top: -9px; margin-left: -12px; overflow: visible; cursor: auto;"
        id="jqxWidgetd0791853769a" tabindex="0" class="jqx-widget jqx-widget-material jqx-checkbox jqx-checkbox-material">
          <div class="jqx-checkbox-default jqx-checkbox-default-material jqx-fill-state-normal jqx-fill-state-normal-material jqx-rc-all jqx-rc-all-material ripple" style="filter: grayscale(1)">
            <div style="width: 16px; height: 16px;">
              <span style="width: 16px; height: 16px;"></span>
            </div>
            <span style="height: 18px; width: 18px; top: -1px; left: -1px;"></span>
          </div>
          <div style="clear: both;"></div>
        </div>
      `)
    }
  }

  renderRow(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 8px">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  renderPermiso(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if(value == 0) {
      return '<div style="margin-left: 4px; margin-top: 8px;">'+AppComponent.translate('Tarjeta_nfc')+'</div>';
    }else if(value == 1) {
      return '<div style="margin-left: 4px; margin-top: 8px;">Bluetooth</div>';
    }
  }

  renderTarjeta(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return '<div class="jqx-grid-cell-left-align" style="margin-left: 4px; margin-top: 4px;">'+AppComponent.translate('Master')+'</div>';
    }
    else {
      return '<div class="jqx-grid-cell-left-align" style="margin-left: 4px; margin-top: 4px;">'+AppComponent.translate('Blanca')+'</div>';
    }
  }

  selecElement(listElement: any) {
    this.elementosSelect = listElement;
    if (this.elementosSelect.length <= 0 && this.source) {
      this.identificaciones = [];
      this.source.localdata = this.identificaciones;
      this.grid.updatebounddata();
    }
  }

  onfilter(event) {
    // Itero a través de todos los filtros aplicados
    for (let filterObj of event.args.filters) {
      let filter1;
      let column = filterObj.datafield;
      let filter = filterObj.filter.getfilters();

      if (column === this.translate('Tipo_identificacion')) {   // 0 BT, 1 nfc,
        let filter1;
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if(element.value === this.translate('Tarjeta_nfc') && filtercondition !== 'NOT_EQUAL'){
            filtervalue = 0;
          }else if(element.value === 'Bluetooth' && filtercondition !== 'NOT_EQUAL'){
            filtervalue = 1;
          }

          if(filtervalue != undefined){
            filter1 = filtergroup.createfilter('numericfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }else{

          }
        });
      } else if(column === this.translate('Tipo_permiso')){
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if(element.value === this.translate('Master') && filtercondition !== 'NOT_EQUAL'){
            filtervalue = true;
          }else if(element.value === this.translate('Blanca') && filtercondition !== 'NOT_EQUAL'){
            filtervalue = false;
          }

          if(filtervalue != undefined){
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }
        });
      } else if(
        column === AppComponent.translate('Aporte_residuo') ||
        column === AppComponent.translate('Apertura_tapa') ||
        column === AppComponent.translate('No_cerro_tapa')
      ){
        filter.forEach(element => {
          let filtergroup = new jqx.filter();
          let filter_operator = element.operator;
          let filtercondition = element.condition;
          let filtervalue;

          if(element.value === this.translate('SI') && filtercondition !== 'NOT_EQUAL'){
            filtervalue = true;
          }else if(element.value === this.translate('NO') && filtercondition !== 'NOT_EQUAL'){
            filtervalue = false;
          }

          if(filtervalue != undefined){
            filter1 = filtergroup.createfilter('booleanfilter', filtervalue, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
            this.grid.applyfilters();

            filter1 = filtergroup.createfilter('stringfilter', element.value, filtercondition);
            filtergroup.addfilter(filter_operator, filter1);
            this.grid.addfilter(column, filtergroup);
          }
        });
      }
    }
  }

  // asigno las fechas del periodo seleccionado
  onAceptar() {
    this.dateInicio = this.header.periodoSelect.getFechaIni();
    this.dateFin = this.header.periodoSelect.getFechaFin();
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;
    this.onSearch();
  }

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.fecha1.value(new Date());
    this.header.periodoSelect.fecha2.value(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    this.onAceptar();
  }

  // recojo las identificaciones por cerradura y por fechas
  async onSearch() {
    this.identificaciones = await this.serviceCerraduras.getIndentificacionesDate(this.dateIdentificacion);
    this.source.localdata = this.identificaciones;
    this.source.sortcolumn = 'fecha';
    this.source.sortdirection = 'desc';
    this.grid.updatebounddata(this.identificaciones);

    let filtervalue = this.header.searchInput['nativeElement'].value != null ? this.header.searchInput['nativeElement'].value : '';

    if(filtervalue != ''){
      this.grid.columns()['records'].forEach(column => {
        if(column.filtertype === 'textbox'){
          let filtergroup = new jqx.filter();
          filtergroup.operator = 'or'
          let filter_or_operator = 1;
          let filtercondition = 'contains';
          let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

          filtergroup.addfilter(filter_or_operator, filter1);
          this.grid.addfilter(column.datafield, filtergroup);
          this.grid.applyfilters();
        }
      });
    }

    this.resizeColumns();
  }

  resizeColumns() {
    if(this.identificaciones.length != 0){
      setTimeout(() => {
        this.grid.attrColumns.forEach((column: any) => {
          try{
            if(
              column.datafield !== AppComponent.translate('Aporte_residuo') &&
              column.datafield !== AppComponent.translate('Apertura_tapa') &&
              column.datafield !== AppComponent.translate('No_cerro_tapa')
            ){
              this.grid.autoresizecolumn(column.datafield, column.columntype);
            }
          }catch(e){

          }
        })
      }, 300);
    }
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.grid.getdisplayrows()));
    /*
      Elimino los campos que no se quieren mostrar en el excel y
      cambio el texto de los campos que vienen como booleano o numero
    */
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;

      if(element[this.translate('Fecha')]){
        element[this.translate('Fecha')] = DateUtils.formatDateTimeShort(new Date(element[this.translate('Fecha')]), true)
      }

      if(element[this.translate('Aporte_residuo')]){
        element[this.translate('Aporte_residuo')] = this.translate('SI');
      }else{
        element[this.translate('Aporte_residuo')] = this.translate('NO');
      }

      if(element[this.translate('Apertura_tapa')]){
        element[this.translate('Apertura_tapa')] = this.translate('SI');
      }else{
        element[this.translate('Apertura_tapa')] = this.translate('NO');
      }

      if(element[this.translate('No_cerro_tapa')]){
        element[this.translate('No_cerro_tapa')] = this.translate('SI');
      }else{
        element[this.translate('No_cerro_tapa')] = this.translate('NO');
      }

      if(element[this.translate('Tipo_identificacion')] == 0){
        element[this.translate('Tipo_identificacion')] = this.translate('Tarjeta_nfc');
      }else if(element[this.translate('Tipo_identificacion')] == 1){
        element[this.translate('Tipo_identificacion')] = 'Bluetooth';
      }

      if(element[this.translate('Tipo_permiso')]){
        element[this.translate('Tipo_permiso')] = this.translate('Master');
      }else{
        element[this.translate('Tipo_permiso')] = this.translate('Blanca');
      }
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_'+ this.translate('Historicos_identificaciones') +'.xlsx');
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  onCollapse(event) {
    ModeloSelectComponent._this.onCollapse();
  }

  onExpand(event) {
    ModeloSelectComponent._this.onExpand();
  }
}
