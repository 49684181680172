<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #form [theme]="environment.tema" [width]="1000" [height]="492" [zIndex]="50" [position]="getFormPos(form)"
  [autoOpen]="true" [zIndex]="90" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Listado_sensores')}}
  </div>
  <div class="form" style="height: 100%; overflow: hidden;">
    <div style="float: left; margin-top: 2px; height: '100%'; width: '266px';">
      <jqxSplitter #mainSplitter [panels]="[{ min: 150, size: 230 }, { min: 715 }]" [height]="450" [width]="985">
        <div>
            <div class="form">
              <span style="margin-left: 4px;">{{translate('Subflotas')}}</span>
            </div>
            <div style="height: 440px;">
              <app-moviles-select (passArray)="selected($event)"></app-moviles-select>
            </div>
        </div>
        <div>
          <div style="height: 400px;">
            <div>
              <app-header #header
                [exportar]="true" [periodo]="true" [filtro]="true"
                [noTrimestre]="true" [noSemestre]="true" [noLibre]="true"
                (eventExportar)="onExportar()" (eventFilter)="onAceptar()">
              </app-header>
            </div>
            <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'380px'"
              [source]="dataAdapter" [columnsresize]="true" [filterable]="true" [showfilterrow]="false"
              [columns]="columns" [sortable]="true" [altrows]="true" [showrowlines]="false"
              [filterrowheight]="30" [rowsheight]="20" [columnsheight]="20" [showaggregates]="true"
              [enabletooltips]="true" [groupable]="true" [showstatusbar]="true" [statusbarheight]="20"
              [localization]="langGrid" (onRowselect)="onRowClick($event)">
            </jqxGrid>
            <div class="form" style="float: left; width: 100%; height: 100%;">
              <jqxButton style="float:left;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                [imgSrc]='"/assets/images/center.png"' [value]="translate('Centrar')"
                (onClick)="onCentrar($event)">
              </jqxButton>
            </div>
          </div>
        </div>
      </jqxSplitter>
    </div>
  </div>
</jqxWindow>
