import { Component, Injectable, ViewChild, ViewContainerRef } from '@angular/core';
import { CanActivate, CanActivateChild, NavigationError, NavigationStart, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { MainComponent } from './components/main/main.component';
import { ApiService } from './services/api/api.service';
import { LangService } from './services/lang/lang.service';
import { SsoService } from './services/sso/sso.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  @ViewChild('mainContainer', { read: ViewContainerRef }) mainContainer;
  private static instance: AppComponent;
  public idioma = 'es-ES';
  title = 'newp';

  // NOTA: El servicio del SSO pide el ticket nada más crearse
  constructor(private ssoService: SsoService,
    private router: Router, private apiService: ApiService,
    private langService: LangService) {
    AppComponent.instance = this;
    const t = setTimeout(async () => {
      clearTimeout(t);
      // Antes de nada pido el token del SSO para que se almacene en el localstorage
      await this.ssoService.getToken();
      // Control de eventos de enrutamiento
      this.router.events.subscribe(async (event) => {
        // Cada vez que se carga una página
        if (event instanceof NavigationStart) {
          if (!event.url.startsWith('/?ticketId=')) {
            // Se cormprueba que hay ticket SSO válido
            const ticket = this.ssoService.getTicket();
            if (ticket === null || !await this.ssoService.isUsuarioValidado(ticket.Usuario.Id)) {
              this.logout();
            }
          }
          // Si se accede a una página que no existe
          if (event instanceof NavigationError) {
            this.logout();
          }
        }
      });
      // Si no tengo el ticket del SSO intento recuperarlo
      const ticketId = new URLSearchParams(window.location.search).get('ticketId');
      if (ticketId || !this.ssoService.getTicket()) {
        const t = setTimeout(async () => {
          clearTimeout(t);
          // Recupero el ticket del SSO con el id recibido
          if (await this.ssoService.initSession(ticketId)) {
            // Para que vaya a la pagina principal y no se quede el ticketId visible en la URL
            window.location.href = "/";
          } else {
            this.logout();
          }
        }, 0);
      } else {
        if (window.location.pathname === '/') {
          this.login();
        }
      }
    }, 0);
  }

  public static getInstance(): AppComponent {
    return AppComponent.instance;
  }

  // Redirijo a la página principal
  private async login(): Promise<void> {
    // Recupero el ticket del SSO
    const ticketSSO = this.ssoService.getTicket();
    // Obtengo un token de la API
    if (ticketSSO.UrlApi && ticketSSO.UrlApi.length > 0) {
      if (!await this.apiService.initSessionApi()) {
        alert('No se ha obtenido token de ' + ticketSSO.UrlApi);
        this.logout();
      }
    }

    // Obtengo un token de la api de la BDT si se ha configurado
    if (environment.urlApiBdt && environment.urlApiBdt.length > 0) {
      // if (!await this.apiService.initSessionApiBdt()) {
      if (!this.apiService.initSessionApiBdt()) {
        alert('No se ha obtenido token de ' + environment.urlApiBdt);
        this.logout();
      }
    }

    // Obtengo un token de la api de IA si se ha configurado
    if (ticketSSO.UrlApiIA && ticketSSO.UrlApiIA.length > 0) {
      // if (!await this.apiService.initSessionApiIA()) {
      if (!this.apiService.initSessionApiIA()) {
        alert('No se ha obtenido token de ' + ticketSSO.UrlApiIA);
        //this.logout();
      }
    }

    // Obtengo un token de la api de Cartografía si se ha configurado
    if (environment.carto.baseUrl && environment.carto.baseUrl.length > 0) {
      // if (!await this.apiService.initSessionApiCarto()) {
      if (!this.apiService.initSessionApiCarto()) {
        alert('No se ha obtenido token de ' + environment.carto.baseUrl);
        //this.logout();
      }
    }

    // Almaceno el idioma del usuario
    this.idioma = ticketSSO.Usuario.Idioma.Codigo;
    // Recupero los textos y cargo los del idioma del usuario
    await this.langService.getText(ticketSSO.Usuario.Idioma.Id);
    // Una vez cargado el idioma abro el componente principal de la aplicación
    this.mainContainer.createComponent(MainComponent);
  }

  // Redirijo a la página de login del SSO
  private logout(): void {
    this.ssoService.endAppSession();
  }

  // Método etático para traducciones
  public static translate(text): string {
    return AppComponent.getInstance().langService.translate(text);
  }
}

// Clase que sirve para comtrolar todas las redirecciones
// Si no hay ticket del SSO no dejo redireccionar a ninguna página
@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(private ssoService: SsoService) { }

  canActivate() {
    return this.ssoService.getTicket() !== null;
  }

  canActivateChild() {
    return this.ssoService.getTicket() !== null;
  }

}
