import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';

import { AlarmsgeoComponent } from './alarmsgeo/alarmsgeo.component';
import { HeaderModule } from '../header/header.module';

@NgModule({
  declarations: [
    AlarmsgeoComponent
  ],
  imports: [
    FormsModule,
    HeaderModule,
    CommonModule,
    BrowserModule,
    jqxGridModule,
    jqxInputModule,
    jqxWindowModule,
    jqxButtonModule,
    jqxLoaderModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule
  ]
})
export class AlarmsModule { }
