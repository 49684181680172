import { Component, Input, OnInit, QueryList, ViewChildren } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';

@Component({
  selector: 'app-elements-edit-gestion',
  templateUrl: './elements-edit-gestion.component.html',
  styleUrls: ['./elements-edit-gestion.component.css', '../elements-edit.component.css']
})
export class ElementsEditGestionComponent implements OnInit {
  @ViewChildren('gridOperRecientes') gridOperRecientes: jqxGridComponent;
  @ViewChildren('gridHistIncidencias') gridHistIncidencias: jqxGridComponent;

  @Input() elemEdit: ElementoModel;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;

  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  ejecutado: boolean = false;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  constructor() { }

  ngOnInit(): void {
  }

  onSearch() {

  }

  /* APARTADO OPERACIONES RECIENTES */
  public columOperRecientes;

  private sourceOperRecientes;
  public dataAdapterOperRecientes;

  initGridOperRecientes() {
    this.columOperRecientes = [
      { text: this.translate('Fecha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fecha', width: 150 },
      { text: this.translate('Accion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'operador', autowidth: 'true' },
    ];

    this.sourceOperRecientes = {
      datatype: 'json',
      datafields: [
        {name: 'fecha', type: 'string' },
        {name: 'operador', type: 'string' },
      ]
    };

    this.dataAdapterOperRecientes = new jqx.dataAdapter(this.sourceOperRecientes);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridOperRecientes.autoresizecolumns();
    }, 500);
  }
  /* FIN APARTADO OPERACIONES RECIENTES */

  /* APARTADO HISTORICO INCIDENCIAS */
  public columHistIncidencias;

  private sourceHistIncidencias;
  public dataAdapterHistIncidencias;

  initGridHistIncidencias() {
    this.columHistIncidencias = [
      { text: this.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', autowidth: 'true' },
      { text: this.translate('Incidencia'), columntype: 'textbox', filtertype: 'textbox', datafield: 'incidencia', autowidth: 'true' },
      { text: this.translate('Motivo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'motivo', autowidth: 'true' },
      { text: this.translate('Estado'), columntype: 'textbox', filtertype: 'textbox', datafield: 'estado', autowidth: 'true' },
      { text: this.translate('Fecha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fecha', width: 150 }
    ];

    this.sourceHistIncidencias = {};

    this.dataAdapterHistIncidencias = new jqx.dataAdapter(this.sourceHistIncidencias);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridHistIncidencias.autoresizecolumns();
    }, 500);
  }
  /* FIN APARTADO HISTORICO INCIDENCIAS */
}
