import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { TarjetasService } from 'src/app/services/ciudadanos/tarjetas/tarjetas.service';

import { TarjetaModel } from 'src/app/services/ciudadanos/models/tarjeta.model';

@Component({
  selector: 'app-tarjetas-ciudadanos',
  templateUrl: './tarjetas-ciudadanos.component.html',
  styleUrls: ['./tarjetas-ciudadanos.component.css']
})
export class TarjetasCiudadanosComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridTarjetas') gridTarjetas: jqxGridComponent;

  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  tarjetas: TarjetaModel[] = [];

  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private tarjetasService: TarjetasService,
    private ssoService: SsoService
  ) {
    super();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.initGrid();
  }

  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit(): Promise<void> {
    this.form.setTitle(this.translate('Tarjetas_ciudadanos'));
    this.addCustomForm(this.form);

    this.getTarjetas();
  }

  public onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  public columnsTarjetas;

  public sourceTarjetas;
  public adapterTarjetas;

  initGrid() {
    this.columnsTarjetas = [
      { text: this.translate('Ns_movisat'), columntype: 'textbox', datafield: this.translate('Ns_movisat') },
      { text: this.translate('Ciudadano'), columntype: 'textbox', datafield: this.translate('Ciudadano') }
    ];

    this.sourceTarjetas = {
      datatype: 'json',
      datafields: [
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsMovisat' },
        { name: this.translate('Ciudadano'), type: 'string', map: 'nombreCiudadano' }
      ],
      localdata: this.tarjetas,
    };
    this.adapterTarjetas = new jqx.dataAdapter(this.sourceTarjetas);
  }

  async getTarjetas() {
    this.tarjetas = await this.tarjetasService.getTarjetasWithCiudadanos();

    this.tarjetas.forEach(element => {
      if(element.ciudadano) {
        element['nombreCiudadano'] = element.ciudadano.nombre + ' ' + element.ciudadano.apellidos;
      }else {
        element['nombreCiudadano'] = '';
      }
    });

    if(!this.tarjetas) {
      this.tarjetas = [];
    }

    this.sourceTarjetas.localdata = this.tarjetas;
    this.gridTarjetas.updatebounddata(this.tarjetas);
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.gridTarjetas.getrows()));
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_'+AppComponent.translate('Tarjetas_ciudadanos')+'.xlsx');
  }
}
