<div style="float:left; overflow: hidden;">
    <jqxDropDownList #cbPeriodo style="float:left; overflow-x: hidden;" [width]="110" [height]="height"
        [selectedIndex]="0" [autoDropDownHeight]="true" (onSelect)="onSelectPeriodo($event)">
    </jqxDropDownList>
    <jqxDateTimeInput #fecha1 style="float:left; margin-left: 2px; overflow-x: hidden;" [width]="108" [height]="height"
        [culture]="localization" [formatString]="'dd-MM-yyyy'" [showTimeButton]="false" (onValueChanged)="onChangeFecha()">
    </jqxDateTimeInput>
    <jqxDateTimeInput #fecha2 style="float:left; margin-left: 2px; overflow-x: hidden;" [width]="108" [height]="height"
        [culture]="localization" [formatString]="'dd-MM-yyyy'" [showTimeButton]=" false" (onValueChanged)="onChangeFecha2()"
        [disabled]="periodoSelec !== 6">
    </jqxDateTimeInput>
</div>