import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SsoService } from '../sso/sso.service';
import { CerraduraModel } from './models/cerradura.model';
import { DateIdentificacionModel } from './models/dateIdentificacion';
import { IdentificacionCerraduraModel } from './models/identificacionCerradura';
import { AlarmaCerraduraModel } from './models/alarmaCerradura';
import { AuditoriaModel } from '../auditoria/models/auditoria.model';
import { Accion } from '../auditoria/models/accion.model';
import { MainComponent } from 'src/app/components/main/main.component';
import { AuditoriaService } from '../auditoria/auditoria.service';
import { PosicionCerraduraModel } from './models/posicionCerradura.model';
import { AppComponent } from 'src/app/app.component';
import { DateUtils } from 'src/app/utils/date-utils';

@Injectable({
  providedIn: 'root'
})
export class CerraduraService {
  private usuario = this.ssoService.getTicket().Usuario.Email;
  private auditoria: AuditoriaModel = new AuditoriaModel(this.usuario, 0);

  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient, private auditoriaService: AuditoriaService) { }

  msgChangeResponse(result: any): string {
    return this.auditoria.Info = 'ID: ' + result.id + ', ' + MainComponent.getInstance().translate('Nombre') + ': ' + result.nombre;
  }

  // crear cerradura y editar cerradura
  async createCerradura(cerradura: CerraduraModel): Promise<CerraduraModel> {
    let response = null;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      response = await this.http.post<CerraduraModel>(
        this.urlApi + '/api/cerraduras/crear/',
        JSON.stringify(cerradura), httpOptions
      ).toPromise();

      if (cerradura.id > 0) {
        this.auditoria.AccionId = Accion.EDITAR_CERRADURA;
      } else {
        this.auditoria.AccionId = Accion.CREAR_CERRADURA;
      }

      this.msgChangeResponse(response);
      this.auditoriaService.addAuditoria(this.auditoria);
    }
    catch (e) {
      console.log(e);
    }

    return response;
  }

  // obtener cerraduras
  async getCerraduras() {
    let result: CerraduraModel[] = [];
    try {
      result = await this.http.get<CerraduraModel[]>(this.urlApi + '/api/cerraduras/?enterprise=' + this.empresaId + '&include=all').toPromise();
      if (result) {
        result.forEach(element => {
          element.fechaModificacion = new Date(element.fechaModificacion);
          element.fechaModificacionStr = DateUtils.formatDateTimeShort(element.fechaModificacion, true)
          if (element.modoOperacion == 0 || element.modoOperacion == 1) {
            element.tipoOperacionStr = AppComponent.translate('Cerradura');
          } else if (element.modoOperacion == 3) {
            element.tipoOperacionStr = AppComponent.translate('Identificador');
          } else {
            element.tipoOperacionStr = '';
          }
        });
      }
    } catch (e) {
      console.log(e);
    }
    return result;
  }


  // obtener cerraduras sin elemento
  async getCerradurasWithoutElement(): Promise<CerraduraModel[]> {
    let result: CerraduraModel[] = [];
    try {
      await this.http.get<any[]>(
        this.urlApi + '/api/cerraduras/libres?enterprise=' + this.empresaId
      ).toPromise().then(
        res => {
          result = res;
        }, err => {
          result = null;
        }
      );
    } catch (e) {
      result = null;
    }

    return result;
  }

  // obtener cerradura por elemento
  async getCerraduraByElemento(elementoId: number): Promise<CerraduraModel> {
    let result: CerraduraModel = null;
    try {
      result = await this.http.get<CerraduraModel>(this.urlApi + '/api/cerraduras/elemento/' + elementoId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // obtener cerradura por id
  async getCerraduraById(id: number): Promise<CerraduraModel> {
    let result: CerraduraModel = null;
    try {
      result = await this.http.get<CerraduraModel>(this.urlApi + '/api/cerraduras/' + id).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // borrar cerradura
  async deleteCerradura(id: number): Promise<boolean> {
    let response: boolean = false;

    try {
      await this.http.delete(this.urlApi + '/api/cerraduras/' + id).toPromise().then(
        res => {
          this.auditoria.AccionId = Accion.ELIMINAR_CERRADURA;
          this.auditoria.Info = 'ID: ' + id;
          this.auditoriaService.addAuditoria(this.auditoria);

          response = true;
        }, err => {
          response = false;
        }
      );
    } catch (e) {
      response = false;
    }

    return response;
  }

  // devuelve todas las identificadciones de una cerradura en un rango de fechas
  async getIndentificacionesDate(date: DateIdentificacionModel): Promise<IdentificacionCerraduraModel[]> {
    let result: IdentificacionCerraduraModel[] = [];
    let desde = date.Desde.toISOString();
    let hasta = date.Hasta.toISOString();
    try {
      result = await this.http.get<IdentificacionCerraduraModel[]>(this.urlApi + '/api/cerraduras/identificacion/between?enterprise='
        + this.empresaId + '&from=' + desde + '&to=' + hasta).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // asocia una cerradura a un elemento
  async associateCerraduraToElement(asociacion: any): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const aso = { ...asociacion };

    aso.empresa = this.empresaId;

    try {
      await this.http.post<any>(this.urlApi + '/api/cerradura/asociar/elemento',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;
          }, err => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  // desasocia una cerradura de un elemento
  async disassociateCerraduraFromElement(asociacion: any): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    const aso = { ...asociacion };

    aso.empresa = this.empresaId;

    try {
      await this.http.post<any>(this.urlApi + '/api/cerradura/desasociar/elemento',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;
          }, err => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  // obtener las alamras de una cerradura en un rango de fechas
  async getAlarmas(date: DateIdentificacionModel) {
    let result: AlarmaCerraduraModel[] = [];
    let desde = date.Desde.toISOString();
    let hasta = date.Hasta.toISOString();
    try {
      result = await this.http.get<AlarmaCerraduraModel[]>(this.urlApi + '/api/cerraduras/alarmas/tarjeta/between?enterprise='
        + this.empresaId + '&from=' + desde + '&to=' + hasta).toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }


  // obtener las cerraduras que no tienen calendario
  async getCerradurasSinCalendario() {
    let result: CerraduraModel[] = [];
    try {
      result = await this.http.get<CerraduraModel[]>(this.ssoService.getTicket().UrlApi + '/api/cerraduras/sin/calendario?enterprise=' + this.empresaId + '&include=all').toPromise();
    } catch (e) {
      console.log(e);
    }
    return result;
  }

  // obtiene cerraduras sin configuracion
  async getCerradurasSinConfiguracion() {
    let result: CerraduraModel[] = [];
    try {
      await this.http.get<CerraduraModel[]>(this.urlApi + '/api/cerraduras/sin/configuracion?enterprise=' + this.empresaId).toPromise().then(
        res => {
          result = res;
        },
        err => {
          return null;
        }
      );
    } catch (e) {
      return null;
    }
    return result;
  }

  // obtiene cerraduras por configuracion
  async getCerradurasPorConfiguracion(id: number) {
    let result: CerraduraModel[] = [];

    try {
      await this.http.get<CerraduraModel[]>(this.urlApi + '/api/cerraduras/configuracion/id?idConfiguracion=' + id + '&enterprise=' + this.empresaId).toPromise().then(
        res => {
          result = res;
        },
        err => {
          result = [];
        }
      );
    }
    catch (e) {
      result = [];
    }

    return result;
  }

  // pedir posicion de una cerradura
  async pedirPosicion(list: PosicionCerraduraModel) {
    let result: PosicionCerraduraModel = new PosicionCerraduraModel(list.idsCerraduras, this.empresaId);
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    try {
      result = await this.http.post<PosicionCerraduraModel>(this.urlApi + '/api/cerradura/update/pedir/posicion/',
        JSON.stringify(list), httpOptions).toPromise();
    }
    catch (e) {
      console.log(e);
    }
    return result;
  }
}
