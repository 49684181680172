<div style="width: 100%; height: 262px;">
    <jqxGrid #grid [theme]="theme" [width]="'100%'" [height]="'100%'" [source]="dataAdapter" [columnsresize]="true"
        [localization]="langGrid" [columns]="columns" [showrowlines]="false" [filterable]="true" [filter]="filter"
        [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true" [groupable]="true"
        [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20" [columnsheight]="20"
        [editable]="false" (onRowclick)="onRowclick($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
    </jqxGrid>
    <div class="bottons" style="float: left; margin-top: 6px;">
        <jqxButton #create style="float:left; margin-left: 4px;" [width]='100' [height]='26'
            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
            [value]="translate('Crear')" (onClick)="onCrearES($event)">
        </jqxButton>
        <jqxButton #edit style="float:left; margin-left: 4px;" [width]='100' [height]='26'
            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
            [value]="translate('Editar')" (onClick)="onEditarES($event)">
        </jqxButton>
        <jqxButton #delete style="float:left; margin-left: 4px;" [width]='100' [height]='26'
            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"'
            [value]="translate('Borrar')" (onClick)="onBorrarES()">
        </jqxButton>
    </div>
</div>

<!-- formulario de entrada-salida -->
<div *ngIf="viewFormEntrada">
    <jqxWindow #formEntradaSalida [theme]="theme" [autoOpen]="true" [position]="getFormPos(formEntradaSalida)"
        [zIndex]="90" [width]="610" [height]="240" [showCloseButton]="true" [showCollapseButton]="true"
        (onOpen)="onOpen()" [resizable]="false" (onClose)="onClose()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Entradas_salidas')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden; ">
            <div class="columnGrid">
                <div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Nombre')}}
                    </div>
                    <div style="float:left; clear:both;">
                        <jqxInput [width]="250" [height]="25" [(ngModel)]="entrada.Nombre">
                        </jqxInput>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Observaciones')}}
                    </div>
                    <div style="float:left; clear:both;">
                        <jqxInput [width]="250" [height]="25" [(ngModel)]="entrada.Observaciones">
                        </jqxInput>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Direccion')}}
                    </div>
                    <div style="float:left; clear: both;">
                        <jqxInput [width]="250" [height]="25" [(ngModel)]="entrada.Direccion" disabled="true">
                        </jqxInput>
                    </div>
                    <div id="checkbox" style="margin-top: 5px; float: left; margin-right: 20%; margin-bottom: 5px;">
                        <div style="float:left;">
                            {{translate('Entrada')}}&nbsp;&nbsp;
                            <input #CheckBoxEntrada id="checkEntrada" [(ngModel)]="tipoEntrada" type="checkbox"
                                style="float: left;" (change)="check()" />
                        </div>
                        <div style="float:left;">
                            {{translate('Salida')}}&nbsp;&nbsp;
                            <input #CheckBoxSalida [(ngModel)]="tipoSalida" id="checkSalida" type="checkbox"
                                style="float:left; margin-left:5px;" (change)="check()" />
                        </div>
                    </div>
                    <div style="float: left;">
                        <jqxButton style="float:left; margin-left: 0px;" [width]='100' [height]='26'
                            [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                            [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                            (onClick)="onGuardarES($event)">
                        </jqxButton>
                    </div>
                </div>
                <div>
                    <jqxGrid #gridEntrLocal [theme]="theme" [width]="350" [height]="200" [source]="dataAdapterLocal"
                        [columnsresize]="true" [localization]="langGrid" [columns]="columnsLocal" [filtermode]="'excel'"
                        [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                        [altrows]="true" [showstatusbar]="true" [showaggregates]="true" [editable]="false"
                        (onRowclick)="onRowclickEdit($event)">
                    </jqxGrid>
                </div>
            </div>

        </div>
    </jqxWindow>
</div>