<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [theme]="theme" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #form [theme]="theme" [position]="getFormPos(form, 0)" [width]="mapWidth" [height]="mapHeight" [zIndex]="999"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Calendarios')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div>
          <app-header #header
            [exportar]="true" [imprimir]="true"
            (eventExportar)="onExportar()" (eventImprimir)="onPrint()">
          </app-header>
        </div>
        <div style="width: 100%; height: 410px;">
            <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="'100%'" [source]="dataAdapter"
                [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showaggregates]="true"
                [columns]="columns" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                [showstatusbar]="true" [altrows]="true" [statusbarheight]="20" [rowsheight]="20" [columnsheight]="20"
                [editable]="false" [columnsresize]="true" (onRowclick)="onRowClick($event)"
                (onRowdoubleclick)="onRowdoubleclick($event)" [filter]="filter">
            </jqxGrid>

            <div class="bottons" style="float: left; margin-top: 6px;">
                <jqxButton #create style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
                    [value]="translate('Crear')" (onClick)="onCrear($event)">
                </jqxButton>
                <jqxButton #edit style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/editar.png"' [value]="translate('Editar')" (onClick)="onEditar($event)">
                </jqxButton>
                <jqxButton #delete style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/borrar.png"' [value]="translate('Borrar')" (onClick)="onBorrar()">
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"'
                    [value]="translate('Ver')" (onClick)="onVerCalendario()">
                </jqxButton>
            </div>
        </div>
    </div>
</jqxWindow>

<div #editCalendar></div>
<div #viewCalendar></div>
