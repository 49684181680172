<div style="display: flex; gap: 2px">
  <div>
    <div style="margin-top: 2px;">
      {{ translate("Historico_coordenadas") }}
    </div>
    <jqxGrid #gridHistCoordenadas [theme]="environment.tema" [width]="880" [height]="453"
      [autoheight]="false" [source]="dataAdapterHistCoordenadas" [columnsresize]="true" [selectionmode]="'singlerow'"
      [columns]="columHistCoordenadas" [sortable]="true" [altrows]="true" [showrowlines]="true"
      [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [localization]="langGrid">
    </jqxGrid>
  </div>
</div>
