import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxLoaderComponent } from 'jqwidgets-ng/jqxloader';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { IncidencesService } from 'src/app/services/incidences/incidences.service';

import { UsuarioModel } from 'src/app/services/sso/models/usuario.model';
import { EstadoModel } from 'src/app/services/incidences/models/estado.model';
import { CambioEstadoModel } from 'src/app/services/incidences/models/cambioEstado.model';

import { PeriodoSelectComponent } from '../../periodo-select/periodo-select.component';

@Component({
  selector: 'app-status-history',
  templateUrl: './status-history.component.html',
  styleUrls: ['./status-history.component.css']
})
export class StatusHistoryComponent extends CustomForms implements OnInit {
  @ViewChild('windowStates') windowStates: jqxWindowComponent;
  @ViewChild('periodoSelect') periodoSelect: PeriodoSelectComponent;
  @ViewChild('statesGrid') statesGrid: jqxGridComponent;
  @ViewChild('loader') loaderComponent: jqxLoaderComponent;
  @Output()
  cerrar = new EventEmitter<boolean>();
  //referencia de componente
  private componentRef = null;
  //variables de entorno
  public environment = environment;
  //instancia del componente
  private static instance: StatusHistoryComponent;
  fechaIni: Date;
  fechaFin: Date;
  //grid
  dataAdapter: any;
  dataSource: any;
  columns;
  private static toolbarContainer = null;
  private static searchControl = null;
  searchControl;
  private searchText = '';
  //variables
  cambiosEstado: CambioEstadoModel[] = [];
  estados;
  usuarios;

  constructor(private ssoService: SsoService, private incidencesService: IncidencesService) {
    super();
    StatusHistoryComponent.instance = this;
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any, estados: EstadoModel[], usuarios: UsuarioModel[]) {
    this.componentRef = componentRef;
    this.estados = estados;
    this.usuarios = usuarios;
  }

  public static getInstance(): StatusHistoryComponent {
    return StatusHistoryComponent.instance;
  }

  ngOnInit(): void {
    this.initGrid();


  }
  // textos del grid en el idioma correspondiente
  langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

  ngAfterViewInit() {
    this.addCustomForm(this.windowStates);
    //cambia el titulo a la ventana
    this.windowStates.setTitle(this.translate('Historico_estados'));
    setTimeout(() => {
      //extrae las fechas del selector de periodo
      this.fechaIni = new Date(this.periodoSelect.getFechaIni());
      this.fechaFin = new Date(this.periodoSelect.getFechaFin());
      //actualiza grid
      this.refreshGrid();

    }, 500);
  }

  //recuperar datos de cambios de estado
  async refreshGrid() {
    this.loaderComponent.open();
    this.cambiosEstado = await this.incidencesService.getHistoricStates(this.fechaIni, this.fechaFin);
    //asignar usuarios y estados
    this.cambiosEstado.forEach(cambioEstado => {
      cambioEstado.UsuarioEmail = this.usuarios.find(usuario => cambioEstado.UsuarioId == usuario.Id).Email;
      cambioEstado.EstadoNombre = this.estados.find(estado => cambioEstado.EstadoId == estado.Id).Nombre;
      cambioEstado.selec = 'selec';
    });
    this.dataSource.localdata = this.cambiosEstado;
    if (this.searchText == '') {
      this.statesGrid.updatebounddata('data');
      this.statesGrid.sortby('Fecha', 'asc');

      const t = setTimeout(() => {
        clearTimeout(t);
        this.statesGrid.autoresizecolumns();
      }, 500);
    } else {
      this.initSearchControl();
    }

    // this.loaderComponent.close();
  }

  //iniciar grid
  initGrid() {
    this.columns = [
      {
        text: 'Id', datafield: 'Id', align: 'center', cellsalign: 'center', width: '8%',
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return currentValue ? aggregatedValue + 1 : aggregatedValue;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Estado'), datafield: 'Estado', cellsalign: 'left', align: 'left', width: '12%' },
      { text: this.translate('USUARIO'), datafield: 'UsuarioEmail', cellsalign: 'left', align: 'left', width: '27%' },
      { text: this.translate('Observaciones'), datafield: 'Observaciones', cellsalign: 'left', align: 'left', width: '23%' },
      { text: this.translate('Fecha'), datafield: 'Fecha', align: 'center', cellsalign: 'center', width: '30%', cellsformat: 'dd/MM/yy HH:mm:ss' },
      { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true }


    ];

    this.dataSource = {
      datatype: 'json',
      datafields: [
        { name: 'Id', map: 'IncidenciaId', type: 'number' },
        { name: 'Estado', map: 'EstadoNombre', type: 'string' },
        { name: 'UsuarioEmail', map: 'UsuarioEmail', type: 'string' },
        { name: 'Observaciones', map: 'Observaciones', type: 'string' },
        { name: 'Fecha', map: 'Fecha', type: 'date' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: [],
      sortcolumn: 'Fecha',
      sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.dataSource);
  }

  // Crea los componentes de la cabecera
  createToolBar(statusbar: any) {
    if (statusbar[0] !== undefined) {
      // Añado el control de búsqueda a la cabecera
      StatusHistoryComponent.toolbarContainer = document.createElement('div');
      StatusHistoryComponent.toolbarContainer.style.cssText = 'overflow: hidden; position: relative; margin-left: 4px; margin-top: 0px';
      StatusHistoryComponent.searchControl = document.createElement('div');
      StatusHistoryComponent.searchControl.id = 'searchControlElementsStates';
      StatusHistoryComponent.searchControl.style.cssText = `
        float: left;
        background-color: white;
        background-image: url('../assets/images/search.png');
        background-repeat: no-repeat;
        background-position: 4px center;
        background-size: 18px;
        display: flex;
        align-items: center;
        width: 206px;
        margin-top: 2px;
        padding-top: 2px;
        padding-left: 28px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        overflow: hidden;
      `;
      StatusHistoryComponent.searchControl.innerHTML = '<input type="text" style="border: 0;width: 100%; outline: none;" (keydown.enter)="$event.preventDefault()" ' +
        'placeholder="' + AppComponent.translate('Buscar') + '..." autocorrect = "off" autocapitalize = "off" spellcheck = "off">';
      StatusHistoryComponent.toolbarContainer.appendChild(StatusHistoryComponent.searchControl);

      statusbar[0].appendChild(StatusHistoryComponent.toolbarContainer);
      // Creo los botones y el componente de búsqueda
      StatusHistoryComponent.getInstance().initSearchControl();
    }
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    this.searchControl = document.getElementById('searchControlElementsStates');
    if (this.searchControl) {
      this.searchControl.addEventListener('input', (event: any) => {
        this.searchText = event.target.value.toUpperCase();

      });
      let lastSearch = '';
      let timer = null;
      // Cada segundo compruebo si se ha filtrado la información
      setInterval(() => {
        if (this.searchText !== lastSearch) {
          if (timer) {
            clearTimeout(timer);
          }
          lastSearch = this.searchText;
          timer = setTimeout(() => {
            // Marco los registros que cumplen la condición de búsqueda y pongo el campo
            // oculto a "selec"
            if (this.cambiosEstado) {
              this.cambiosEstado.forEach(cambioEstado => {
                if ((cambioEstado.IncidenciaId && cambioEstado.IncidenciaId.toString().toUpperCase().indexOf(this.searchText) > -1) ||
                  (cambioEstado.EstadoNombre && cambioEstado.EstadoNombre.toUpperCase().indexOf(this.searchText) > -1) ||
                  (cambioEstado.UsuarioEmail && cambioEstado.UsuarioEmail.toUpperCase().indexOf(this.searchText) > -1)
                ) {
                  cambioEstado.selec = 'selec';
                } else {
                  cambioEstado.selec = '';
                }
              });
            }
            // Compruebo si ya he creado el filtro "selec" anteriormente
            const filters = this.statesGrid.getfilterinformation();
            if (filters.find(s => s.datafield === 'selec') === undefined) {
              const filtergroup = new jqx.filter();
              filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
              this.statesGrid.addfilter('selec', filtergroup);
            }
            this.statesGrid.applyfilters();
            this.dataSource.localdata = this.cambiosEstado;
            this.statesGrid.updatebounddata('data');

            const t = setTimeout(() => {
              clearTimeout(t);
              this.statesGrid.autoresizecolumns();
            }, 500);
          }, 500);
        }
      }, 500);
    }
  }

  ngOnDestroy() {
    StatusHistoryComponent.searchControl.remove();
    StatusHistoryComponent.toolbarContainer.remove();
  }

  // Cierro el formulario y destruyo el componente
  onClose() {
    //se emite evento para que se muestre la ventana del grid de incidencias
    this.cerrar.emit(true);
    // Destruyo el componente
    if (this.componentRef) {
      this.componentRef.destroy();
    }

  }

  //eventos botones
  onClickBuscar() {
    //extrae las fechas del selector de periodo
    this.fechaIni = new Date(this.periodoSelect.getFechaIni());
    this.fechaFin = new Date(this.periodoSelect.getFechaFin());
    this.refreshGrid();
  }

  onExportar(event) {
    const json = JSON.parse(JSON.stringify(this.statesGrid.getrows()));
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_ListadoCambiosEstado.xlsx');
  }

  onBindingComplete() {
    this.loaderComponent.close();
  }

  onRowSelect(event) {

  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }




}
