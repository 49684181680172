import { Injectable } from '@angular/core';
import { SsoService } from '../../sso/sso.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { TarjetaModel } from '../models/tarjeta.model';
import { Socket } from 'socket.io';

@Injectable({
  providedIn: 'root'
})
export class TarjetasService {

  private empresaId: number = this.ssoService.getTicket().Empresa.IdGestion;
  private urlApi = this.ssoService.getTicket().UrlApi;

  constructor(private ssoService: SsoService, private http: HttpClient) { }

  async getTarjetasWithCiudadanos(): Promise<TarjetaModel[]> {
    let result: TarjetaModel[] = [];

    try {
      await this.http.get<TarjetaModel[]>(this.urlApi + '/api/tarjetas?enterprise=' + this.empresaId +
        '&include=ciudadano').toPromise().then(
          res => {
            result = res;
          }, error => {
            result = [];
          }
        )
    } catch (e) {
      result = [];
    }

    return result;
  }

  async getTarjetasLibres(): Promise<TarjetaModel[]> {
    let result: TarjetaModel[] = [];

    try {
      await this.http.get<TarjetaModel[]>(this.urlApi + '/api/tarjetas/libres?enterprise=' +
        this.empresaId).toPromise().then(
          res => {
            result = res;
          }, error => {
            result = [];
          }
        );
    } catch (e) {
      result = [];
    }

    return result;
  }

  async associateTarjetaToCiudadano(asociacion: any): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let aso = { ...asociacion };

    aso.empresa = this.empresaId;

    try {
      await this.http.post<any>(this.urlApi + '/api/tarjeta/asociar/ciudadano',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;
          }, error => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  async associateTarjetasToCiudadano(tarjetas: any, idCiudadano: number): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let aso = {
      "empresa": this.empresaId,
      "tarjetas": tarjetas,
      "idCiudadano": idCiudadano,
      "fecha": new Date()
    };

    try {
      await this.http.post<any>(this.urlApi + '/api/tarjeta/asociar/ciudadano/list',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;
          }, error => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }


  async disassociateTarjetaFromCiudadano(asociacion: any): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let aso = { ...asociacion };

    aso.empresa = this.empresaId;

    try {
      await this.http.post<any>(this.urlApi + '/api/tarjeta/desasociar/ciudadano',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;
          }, error => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  async disassociateTarjetasFromCiudadano(tarjetas: any, idCiudadano: number): Promise<boolean> {
    let response: boolean = false;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    let aso = {
      "empresa": this.empresaId,
      "tarjetas": tarjetas,
      "idCiudadano": idCiudadano,
      "fecha": new Date()
    };

    try {
      await this.http.post<any>(this.urlApi + '/api/tarjeta/desasociar/ciudadano/list',
        JSON.stringify(aso), httpOptions).toPromise().then(
          res => {
            response = true;
          }, error => {
            response = false;
          }
        )
    } catch (e) {
      response = false;
    }

    return response;
  }

  async creaTarjeta(tarjeta: TarjetaModel): Promise<TarjetaModel> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      };
      return await this.http.post<TarjetaModel>(this.urlApi + '/api/tarjeta', JSON.stringify(tarjeta), httpOptions).toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

  async grabaTarjeta(tarjeta: TarjetaModel): Promise<TarjetaModel> {
    try {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      }
      tarjeta.empresa = this.empresaId;
      return await this.http.post<TarjetaModel>('http://localhost:8444', JSON.stringify(tarjeta), httpOptions).toPromise();
    } catch (e) {
      try {
        const httpOptions = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json'
          })
        }
        tarjeta.empresa = this.empresaId;
        return await this.http.post<TarjetaModel>('https://localhost:8443', JSON.stringify(tarjeta), httpOptions).toPromise();
      } catch (e) {
        console.log(e);
      }
    }
    return null;
  }

  public async getUltimaTarjeta(zonaId: number): Promise<TarjetaModel> {
    try {
      return await this.http.get<TarjetaModel>(this.urlApi + '/api/tarjeta/ultima?zoneId=' + zonaId).toPromise();
    } catch (e) {
      console.log(e);
    }
    return null;
  }

}
