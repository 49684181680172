export class HistoricoEnvioIdentiiicadorModel {
    public id: number;
    public fechaEnvio: Date;
    public nsMovisat: string;
    public cantidad: number;
    public bateriaVoltaje: number;
    public bateriaPorcentaje: number;
    public temperatura: number;
    public lat: number;
    public lng: number;
    public latElemento: number;
    public lngElemento: number;
    public idElemento: number;
    public metros: number;
    public nombreElemento: string;
    public marcaElemento: string;
    public modeloElemento: string;
    public observacionesElemento: string;
    public direccionElemento: string;
    public municipioElemento: string;
    public poblacionElemento: string;
    public Detalle: Detalle[];
}

export class Detalle {
    public id: number;
    public idCerradura: number;
    public idTarjeta: number;
    public fecha: Date;
    public medio: number;
    public tipoTag: number;
    public aporte: boolean;
    public tarjetaMaster: boolean;
    public aperturaTapa: boolean;
    public noCerroTapa: boolean;
    public fechaRecibido: Date;
    public empresa: number;
    public nsCerradura: string;
    public nombreElemento: string;
    public idElemento: number;
    public nombreCiudadano: string;
    public idEnvio: number;
    public marcaElemento: string;
    public modeloElemento: string;
    public observacionesElemento: string;
    public matriculaElemento: string;
    public direccionElemento: string;
    public municipioElemento: string;
    public poblacionElemento: string;
    public tipoPermiso: string;
}
