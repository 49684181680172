import { ZonaModel } from "../../zones/models/zona.model";

export class AlarmModel {
    Id: number = 0;
    Zona: ZonaModel = null;
    EmpresaId: number = 0;
    Nombre: string = '';
    Observaciones: string = '';
    Entrada: boolean = false;         // Si es de entrada
    Salida: boolean = false;          // Si es de salida
    HayHoraEntrada: boolean = false;  // Si hay que controlar la hora de entrada
    HayHoraSalida: boolean = false;   // Si hay que controlar la hora de salida
    HoraEntrada: Date = null;         // Hora máxima de entrada
    HoraSalida: Date = null;          // Hora máxima de salida
    Repetir: boolean = false;         // Si hay que repetir diariamente o sólo una vez y se borra
    Dias: string = '';                // Los días de la semana que está activa -> LMXJVSD
    Emails: string = '';              // Lista de e-mails separados por punto y coma
}
