<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth"
  [height]="mapHeight" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
  (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
      {{translate('Vehiculos')}}
  </div>
  <div class="form" style="float:left; overflow-x: hidden;">
    <div>
      <app-header #header [exportar]="true" [imprimir]="true"
        (eventExportar)="onExportar()" (eventImprimir)="onPrint()">
      </app-header>
    </div>
    <div style="float:left; width: 100%; height: 100%;">
      <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'85%'" [source]="dataAdapter"
        [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false"
        [selectionmode]="'checkbox'" [columns]="columns" [showrowlines]="false" [showcolumnlines]="true"
        [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
        [showaggregates]="true" [rowsheight]="30" [filterrowheight]="25" [columnsheight]="25"
        [enabletooltips]="true" [editable]="false" (onRowclick)="onRowClick($event)"
        (onBindingcomplete)="onBindingComplete()" [filtermode]="'excel'"
        (onRowdoubleclick)="onRowDoubleClick($event)">
      </jqxGrid>

      <div style="float: left; margin-top: 4px; display: flex; gap: 2px;">
        <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
          [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
          (onClick)="onGuardar()">
        </jqxButton>
        <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
          [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"' [value]="translate('Detalle')"
          (onClick)="onEditar()" [disabled]="movilSelect == null">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formMovil></div>
