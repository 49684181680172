<jqxWindow #windowForm [width]="1400" [height]="400" [zIndex]="999" [theme]="environment.tema"
  [isModal]="false" [position]="getFormPos(windowForm, 0)" [showCloseButton]="true" [autoOpen]="true"
  [showCollapseButton]="true" [resizable]="true" (onClose)="onClose()" [title]="translate('Ficha_direccion')">
  <div class="formHeader">
  </div>

  <div class="form" style="display: grid;">
    <div #fieldsetCartografica id="fieldsetCartografica">
      <fieldset>
        <legend>{{ translate('Direccion_cartografica') }}</legend>

        <div class="row">
          <span style="width: 25%;">
            <div style="clear: both">
              {{ translate('Buscar') }}
            </div>
            <span #mapSearch>
              <movisat-map-search style="float: left; width: 100%;" (mapSearch)="onMapSearch($event)">
              </movisat-map-search>
            </span>
          </span>

          <span style="width: 20%;">
            <div style="clear: both">
              {{ translate('Domicilio') }}
            </div>
            <jqxInput [value]="direccion.direccion" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>

          <span style="width: 10%;">
            <div style="clear: both">
              {{ translate('Numero') }}
            </div>
            <jqxInput [value]="direccion.numero" [rtl]="true" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>

          <span style="width: 10%;">
            <div style="clear: both">
              {{ translate('Codigo_postal') }}
            </div>
            <jqxInput [value]="direccion.codigoPostal" [rtl]="true" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>

          <span style="width: 5%;">
            <div style="clear: both">
              {{ translate('Planta') }}
            </div>
            <jqxInput [(ngModel)]="direccion.planta" [rtl]="true" [width]="'100%'">
            </jqxInput>
          </span>

          <span style="width: 5%;">
            <div style="clear: both">
              {{ translate('Puerta') }}
            </div>
            <jqxInput [(ngModel)]="direccion.puerta" [rtl]="true" [width]="'100%'">
            </jqxInput>
          </span>

          <span style="width: 25%;">
            <div style="clear: both">
              {{ translate('Poblacion') }}
            </div>
            <jqxInput [value]="direccion.poblacion" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>
        </div>
        <div class="row">
          <span style="width: 25%;">
            <div style="clear: both">
              {{ translate('Municipio') }}
            </div>
            <jqxInput [value]="direccion.municipio" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>
          <span style="width: 25%;">
            <div style="clear: both;">
              {{ translate('Provincia') }}
            </div>
            <jqxInput [value]="direccion.provincia" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>
          <span style="width: 25%;">
            <div style="clear: both;">
              {{ translate('Comunidad_autonoma') }}
            </div>
            <jqxInput [value]="direccion.comunidadAutonoma" [width]="'100%'" [disabled]="true">
            </jqxInput>
          </span>
        </div>
      </fieldset>
    </div>

    <div #fieldsetCatastro id="fieldsetCatastro">
      <fieldset>
        <legend>{{ translate('Catastro') }}</legend>
        <app-selector-catastro [direccion]="direccion"></app-selector-catastro>
      </fieldset>
    </div>
    <div class="row-buttons" style="display: grid; align-items: end; align-content: space-around">
      <jqxButton style="float:left; margin-bottom: 10px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"'
        [value]="translate('Guardar')" (onClick)="onGuardar()">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
