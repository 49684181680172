import { Component, Input, OnInit, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

import { AppComponent } from 'src/app/app.component';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { InputNumberComponent } from 'src/app/components/input-number/input-number.component';

@Component({
  selector: 'app-elements-edit-detalle',
  templateUrl: './elements-edit-detalle.component.html',
  styleUrls: ['./elements-edit-detalle.component.css', '../elements-edit.component.css']
})
export class ElementsEditDetalleComponent implements OnInit {
  @Input() elemEdit: ElementoModel;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;
  @ViewChildren(InputNumberComponent) inputNumbers: QueryList<InputNumberComponent>;

  ejecutado: boolean = false;

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderer() {
    if (!this.ejecutado) {
      setTimeout(() => {
        this.inputNumbers.forEach(inputNumber => {
          const value = inputNumber.value;
          inputNumber.rendererDecimal('', '', value);
        });
      }, 100);
      this.ejecutado = true;
    }
  }

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'elemEdit': {
            this.elemEdit = changes[propName].currentValue;
            break;
          }
          default: {
            break;
          }
        }
      }
    }
  }

  /* Opciones dropdown ruedas */
  sourceRuedas: string[] =
  [
    'No',
    'Si'
  ];
}
