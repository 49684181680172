import { Component, Input, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

@Component({
  selector: 'app-elements-edit-historico-coordenadas',
  templateUrl: './elements-edit-historico-coordenadas.component.html',
  styleUrls: ['./elements-edit-historico-coordenadas.component.css', '../elements-edit.component.css']
})
export class ElementsEditHistoricoCoordenadasComponent implements OnInit {
  @ViewChild('gridHistCoordenadas') gridHistCoordenadas: jqxGridComponent;

  @Input() elemEdit: ElementoModel;
  @Input() expanded: boolean = true;
  @Input() closed: boolean = false;

  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor() { }

  ngOnInit(): void {
  }

  // Columnas para el grid historico de coordenadas
  columHistCoordenadas;
  sourceHistCoordenadas;
  dataAdapterHistCoordenadas: any;

  initGridHistCoordenadas() {
    this.columHistCoordenadas = [
      { text: this.translate('Fecha'), columntype: 'textbox', filtertype: 'textbox', datafield: 'fecha', width: 150 },
      { text: this.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'gestion', autowidth: 'true' },
      { text: this.translate('Calle'), columntype: 'textbox', filtertype: 'textbox', datafield: 'calle', autowidth: 'true' },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'poblacion', autowidth: 'true' },
    ];

    this.sourceHistCoordenadas =
    {
      datatype: 'json',
      datafields: [
        { name: 'fecha', type: 'date', map: 'fecha', format: 'dd/MM/yyyy HH:mm:ss' },
        { name: 'operacion', type: 'string', map: 'operacionNombre' },
        { name: 'latitud', type: 'number', map: 'lat' },
        { name: 'longitud', type: 'number', map: 'lang' }
      ],
      id: 'id',
      sortcolumn: 'fecha',
      sortdirection: 'desc'
    };

    this.dataAdapterHistCoordenadas = new jqx.dataAdapter(this.sourceHistCoordenadas);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridHistCoordenadas.autoresizecolumns();
    }, 500);
  }
}
