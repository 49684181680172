import {
  AfterViewInit, Component, OnDestroy, OnInit,
  ViewChild, ViewContainerRef
} from '@angular/core';

import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { MapComponent, MapLatLng, MapMarker } from 'movisat-maps';
import { MapClusterV2 } from 'movisat-maps/lib/movisat/map-cluster-v2';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';

import { NzModalService } from 'ng-zorro-antd/modal';
import { PuService } from 'src/app/services/pu/pu.service';
import { BdtService } from 'src/app/services/bdt/bdt.service';
import { SsoService } from 'src/app/services/sso/sso.service';
import { ConfigService } from 'src/app/services/config/config.service';
import { ElementsService } from 'src/app/services/elements/elements.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';

import { ElementsEditComponent } from './elements-edit/elements-edit.component';
import { ElementsCercaComponent } from './elements-cerca/elements-cerca.component';
import { Utils } from 'src/app/utils/utils';
import * as xlsx from 'xlsx';

@Component({
  selector: 'app-elements',
  templateUrl: './elements.component.html',
  styleUrls: ['./elements.component.css']
})
export class ElementsComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('elementsContainer', { read: ViewContainerRef }) elementsContainer;
  @ViewChild('gridElements') gridElements: jqxGridComponent;

  private static instance: ElementsComponent;
  public environment = environment;
  private elemSelec: ElementoModel = null;
  private searchText = '';
  private timerSearch = null;
  private initialFilter: any = null;
  public elementListTotal: ElementoModel[] = [];
  public elementList: ElementoModel[] = [];
  private map: MapComponent;
  private orderBy = 'marca,asc';
  private equipModelFilter: any[] = [];
  private oldFilter: any = null;
  private equipModelFilterIA: any[] = [];
  public canEdit = true;
  private toolbarOk = false;
  private readonly MARKERS_ZOOM = 18; // Nivel de zoom al que se ven los marcadores
  public elemCluster: MapClusterV2;
  private canMove = false;

  // Subscripciones
  private subscriptionOnAddElements: any = null;
  private subscriptionOnModifyElements: any = null;
  private subscriptionOnDeleteElements: any = null;
  private subscriptionOnChangeFilterElements: any = null;
  private subscriptionOnEndLoadElements: any = null;
  private subscriptionOnNewElements: any = null;
  private subscriptionOnModifyPU: any = null;

  // Varibles para el datagrid
  public source: any = [];
  public dataAdapter = new jqx.dataAdapter(this.source);

  public columns = [
    {
      text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', width: 1, hidden: true
    },
    {
      text: 'EqId', columntype: 'textbox', filtertype: 'textbox', datafield: 'eqId', width: 1, hidden: true
    },
    {
      text: AppComponent.translate('Elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'elemento', width: 150,
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return currentValue ? aggregatedValue + 1 : aggregatedValue;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: center;">' + aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: AppComponent.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', datafield: 'marca', width: 120,
      hidden: true,
    },
    {
      text: AppComponent.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'modelo', width: 150,
      hidden: true
    },
    {
      text: AppComponent.translate('Tipo'), columntype: 'textbox', filtertype: 'textbox', datafield: 'tipo', width: 80,
      hidden: true
    },
    { text: AppComponent.translate('Descripcion'), columntype: 'textbox', menu: false, datafield: 'descripcion', width: 250 },
    { text: AppComponent.translate('Estado'), columntype: 'textbox', filtertype: 'textbox', datafield: 'estado', width: 100 },
    { text: AppComponent.translate('Informacion_geografica'), columntype: 'textbox', menu: false, datafield: 'infogeo', width: 300 },
    {
      text: AppComponent.translate('Capacidad'), columntype: 'textbox', filtertype: 'textbox', datafield: 'capacidad', width: 80,
      align: 'center', cellsalign: 'center', hidden: true, cellsrenderer: this.numberrenderer
    },
    {
      text: AppComponent.translate('Nivel') + ' %', columntype: 'textbox', filtertype: 'textbox', datafield: 'nivel', width: 80,
      align: 'center', cellsalign: 'center', hidden: true
    },
    {
      text: AppComponent.translate('Fecha_lectura'), columntype: 'date', filtertype: 'date', datafield: 'fechaNivel',
      cellsformat: 'dd/MM/yy HH:mm:ss', width: 140, align: 'center', cellsalign: 'center', hidden: true,
      cellsrenderer: (row: number, columnField: string, value: any,
        defaulHhtml: string, columnProperties: any, rowData: any) => {
        if (value.getFullYear() < 2000) {
          return '<div style="text-align: center; margin-top:3px;"></div>';
        }
        return defaulHhtml;
      }
    },
    {
      text: AppComponent.translate('Distancia'), columntype: 'textbox', filtertype: 'textbox', datafield: 'distancia', width: 100,
      hidden: true, align: 'center', cellsalign: 'center',
      aggregates: [{
        'Cerca': function (aggregatedValue, currentValue: number) {
          return currentValue ? aggregatedValue + 1 : aggregatedValue;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Cerca"] !== undefined) {
          renderstring += '<div style="text-align: center;">' + aggregates["Cerca"];
        }
        return renderstring;
      }
    },
    { text: 'Selec', columntype: 'textbox', filtertype: 'textbox', datafield: 'selec', hidden: true, width: 1 }
  ];

  // Pongo por defecto los textos en los controles del grid en español
  public langGrid = JqWidgets.getLocalization('es');

  constructor(
    private ssoService: SsoService,
    private configService: ConfigService,
    private elementsService: ElementsService,
    private modal: NzModalService,
    private puService: PuService
  ) {
    ElementsComponent.instance = this;
  }

  public static getInstance(): ElementsComponent {
    return ElementsComponent.instance;
  }

  ngOnInit(): void {
    this.canEdit = true; // TODO: por hacer...
    // Cargo el idioma para los componentes jqwidgets
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    // Me subscribo a eventos
    this.subscribeOnAddElements();
    this.subscribeOnEndLoadElements();
    this.subscribeOnNewElements();
    this.subscribeModifyElements();
    this.subscribeDeleteElements();
    this.subscribeChangeFilterElements();
    this.subscribeModifyPU();
    // Recupero el filtro almacenado si lo hay
    this.getInitFilter();
  }

  // Inicializo el toolbar y preparo el grid
  async ngAfterViewInit(): Promise<void> {
    // Espera a que el mapa esté listo para empezar a descargar los elementos
    const timer1 = setInterval(async () => {
      this.map = MainComponent.getInstance().getMap();
      if (this.map) {
        clearInterval(timer1);
        // Me subscribo a eventos del mapa
        this.map.subscribeOnMarkerClick(this, this.onMarkerClick);
        this.map.subscribeOnMarkerDragEnd(this, this.onMarkerDragEnd);
        // Creo el cluster para los elementos
        this.elemCluster = this.map.addClusterV2(this.MARKERS_ZOOM);
        // Recupero los equipamientos
        await this.elementsService.getElementsEquip();
        if (!this.elementsService.elemGenericos) {
          this.showHideColums();
        }
        // Recupero el filtro de modelos de elementos
        await this.elementsService.getFilterModel();
        // Recupero los elementos;
        this.elementsService.getElements();
      }
    }, 1000);
    Utils.renderSizeGrid(this.gridElements, 500);
  }

  // Este método es llamado por el creador del componente
  public init(componentRef: any) {
  }

  // Para traducir los textos del template
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  showHideColums() {
    this.gridElements.showcolumn('marca');
    this.gridElements.showcolumn('modelo');
    this.gridElements.showcolumn('tipo');
    this.gridElements.showcolumn('capacidad');
  }

  // Cuando se pincha sobre un elemento
  onMarkerClick(_this: any, marker: MapMarker) {
    // Compruebo que se trata de un elemento
    if (marker.dataModel.hasOwnProperty('IdPU')) {
      // Busco el elemento en el grid y lo selecciono
      const rows = _this.gridElements.getrows();
      if (rows) {
        rows.forEach(async (row, i) => {
          if (row.id === marker.dataModel.Id) {
            _this.gridElements.ensurerowvisible(i);
            const event = {
              args: {
                rowindex: row.dataindex ? row.dataindex : row.boundindex,
                fromCarto: true
              }
            }
            _this.onRowClick(event);
            // Añado la información del punto de ubicación si todavía no está
            if (marker.content.indexOf('#PU') < 0) {
              const elem: ElementoModel = await _this.elementsService.elementos.get(marker.dataModel.Id);
              if (elem && elem.Pu) {
                let content = marker.content + '<br>' + elem.Pu.Nombre +
                  '<div style="display: none;">#PU</div>';
                marker.setContent(content);
              }
            }
          }
        });
      }
    } else {
      if (marker.dataModel.hasOwnProperty('numElements')) { // Un cluster
        _this.map.setCenter(marker.position);
        _this.map.setZoom(_this.map.zoom < _this.MARKERS_ZOOM - 2 ? _this.map.zoom + 2 : _this.MARKERS_ZOOM);
      }
    }
  }

  // Cuando se arrastra un elemento
  onMarkerDragEnd(_this: any, marker: MapMarker) {
    // Compruebo que se trata de un elemento
    if (marker.dataModel.hasOwnProperty('IdPU')) {
      // Sólo me interesan los elementos que ya existen, los nuevos no
      if (marker.dataModel.Id > 0) {
        if (!MainComponent.getInstance().controlAmbitoActividad(marker.position)) {
          MainComponent.getInstance().showError('ATENCION', 'Fuera_ambito', 2000);
          for (let i = 0; i < _this.elementList.length; i++) {
            if (_this.elementList[i].Id === marker.dataModel.Id) {
              marker.setPosition(new MapLatLng(_this.elementList[i].Lat, _this.elementList[i].Lng));
              break;
            }
          }
        } else {
          _this.modal.confirm({
            nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
            nzContent: AppComponent.translate('Quiere_mover_elemento') + ': ' + marker.dataModel.Nombre + ' ?',
            nzCentered: true,
            nzCancelText: AppComponent.translate('CANCELAR'),
            nzOkText: AppComponent.translate('SI'),
            nzOnOk: async () => {
              let i = 0;
              for (; i < _this.elementList.length; i++) {
                if (_this.elementList[i].Id === marker.dataModel.Id) {
                  _this.elementList[i].Lat = marker.position.lat;
                  _this.elementList[i].Lng = marker.position.lng;
                  marker.setZIndex(999);
                  break;
                }
              }
              if (await _this.elementsService.saveElemento(_this.elementList[i])) {
                MainComponent.getInstance().showSuccess('ATENCION', 'Registro_almacenado', 2000);
              } else {
                MainComponent.getInstance().showError('ATENCION', 'Fallo_almacenar_info', 2000);
              }
            },
            nzOnCancel: async () => {
              for (let i = 0; i < _this.elementList.length; i++) {
                if (_this.elementList[i].Id === marker.dataModel.Id) {
                  marker.setPosition(new MapLatLng(_this.elementList[i].Lat, _this.elementList[i].Lng));
                  break;
                }
              }
            }
          });
        }
      }
    }
  }

  // Añade un marcador al mapa
  addMarker(elem: ElementoModel, refresh = true): MapMarker {
    let icono = 'assets/images/elemento.png';
    if (!this.elementsService.elemGenericos && elem.Equipamiento.Icono && elem.Equipamiento.Icono.length > 50) {
      icono = 'data:image/png;base64,' + elem.Equipamiento.Icono;
    }
    return this.map.addMarkerClusterV2(this.elemCluster, {
      dataModel: elem,
      label: (elem.ImeiVolum || elem.IdVolumetrico) ? elem.UltPctjeVolum + '%' : '',
      title: this.getMarkerTitle(elem),
      content: this.getMarkerContent(elem),
      position: new MapLatLng(elem.Lat, elem.Lng),
      icon: icono,
      zIndex: 10,
      drag: (this.canMove && this.canEdit),
      visible: true
    }, refresh);
  }

  // Crea los componentes de la cabecera
  createToolBar(statusbar: any) {
    if (statusbar[0] !== undefined && !ElementsComponent.getInstance().toolbarOk) {
      ElementsComponent.getInstance().toolbarOk = true;
      // Añado el control de búsqueda a la cabecera
      const toolbarContainer = document.createElement('div');
      toolbarContainer.style.cssText = 'overflow: hidden; position: relative; margin-left: 4px; margin-top: 0px';
      const searchControl: any = document.createElement('div');
      searchControl.id = 'searchControlElements';
      searchControl.style.cssText = `
        float: left;
        background-color: white;
        background-image: url('../assets/images/search.png');
        background-repeat: no-repeat;
        background-position: 4px center;
        background-size: 18px;
        display: flex;
        align-items: center;
        width: 206px;
        margin-top: 2px;
        padding-top: 2px;
        padding-left: 28px;
        height: 25px;
        border: 1px solid rgba(0, 0, 0, 0.5);
        border-radius: 3px;
        overflow: hidden;
      `;
      searchControl.innerHTML = '<input type="text" style="border: 0;width: 100%; outline: none;" (keydown.enter)="$event.preventDefault()" ' +
        'placeholder="' + AppComponent.translate('Buscar') + '..." autocorrect = "off" autocapitalize = "off" spellcheck = "off">';
      toolbarContainer.appendChild(searchControl);
      // Creo el botón para crear
      const btnCrear: any = document.createElement('div');
      btnCrear.id = 'btnCrear';
      btnCrear.style.cssText = 'float: left; height: 25px; width: 85px; padding-left: 3px; padding-top: 2px;';
      btnCrear.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Crear') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/mas.png" /></button>
      `;
      toolbarContainer.appendChild(btnCrear);
      // Creo el botón para editar
      const btnEditar: any = document.createElement('div');
      btnEditar.id = 'btnEditar';
      btnEditar.style.cssText = 'float: left; height: 25px; width: 85px; padding: 2px;';
      btnEditar.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Editar') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/editar.png" /></button>
      `;
      toolbarContainer.appendChild(btnEditar);
      // Creo el botón para borrar
      const btnBorrar: any = document.createElement('div');
      btnBorrar.id = 'btnBorrar';
      btnBorrar.style.cssText = 'float: left; height: 25px; width: 85px; padding: 2px;';
      btnBorrar.innerHTML = `
        <button type="submit" style="width: 80px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Borrar') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/borrar.png" /></button>
      `;
      toolbarContainer.appendChild(btnBorrar);
      // Creo el check para activar el movimiento de elementos
      const btnMover: any = document.createElement('div');
      btnMover.id = 'btnMover';
      btnMover.style.cssText = 'float: left; height: 25px; width: 85px; padding: 2px;';
      btnMover.innerHTML = `
      <div style="float: left; margin-left: 4px; padding-top: 5px;">`+ AppComponent.translate('Mover') + `</div>
      <input type="checkbox" style="float:left; margin-left: 5px; margin-top: 5px; width: 16px; height: 16px;" />
      `;
      toolbarContainer.appendChild(btnMover);

      // Creo el botón para móviles cercanos
      const btnElementosCerca: any = document.createElement('div');
      btnElementosCerca.id = 'btnElementosCerca';
      btnElementosCerca.style.cssText = 'float: left; height: 25px; width: 110px; margin-left: 4px; padding: 2px;';
      btnElementosCerca.innerHTML = `
        <button type="submit" style="width: 75px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;">`+ AppComponent.translate('Cerca') + `</div>
        <img style="float: left; height: 18px; width: 18px;" src="../assets/images/radar.png" /></button>
      `;
      toolbarContainer.appendChild(btnElementosCerca);
      statusbar[0].appendChild(toolbarContainer);

      const btnImprimir: any = document.createElement('div');
      btnImprimir.id = 'btnImprimir';
      btnImprimir.style.cssText = 'float: right; height: 25px;padding: 2px; margin-right: 2px;';
      btnImprimir.innerHTML = `
        <button type="submit" class='button' title='`+ AppComponent.translate('Imprimir') + `' style="width: 35px; height: 25px; align-items: center;cursor: pointer;">
        <div style="float: right; padding-top: 2px;"></div>
        <i class="fa fa-print fa-lg"></i></button>
      `;
      toolbarContainer.appendChild(btnImprimir);
      // añado el boton exportar a excel e imprimir
      const btnExportar: any = document.createElement('div');
      btnExportar.id = 'btnExportar';
      btnExportar.style.cssText = 'float: right; height: 25px;  padding: 2px; margin-left: 5px;';
      btnExportar.innerHTML = `
        <button type="submit" class='button' title='`+ AppComponent.translate('Exportar') + `' style="width: 35px; height: 25px; align-items: center; cursor: pointer;">
        <div style="float: right; padding-top: 2px;"></div>
        <i class="fa fa-file-excel fa-lg"></i></button>
      `;
      toolbarContainer.appendChild(btnExportar);





      // Creo los botones y el componente de búsqueda
      ElementsComponent.getInstance().initSearchControl();
      ElementsComponent.getInstance().initToolbarButtons();
    }
  }
  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; text-align: right; margin-top: 5px">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  // Inicializa los botones de la cabecera
  initToolbarButtons(): void {
    const btnCrear = document.getElementById('btnCrear');
    if (btnCrear) {
      btnCrear.addEventListener('click', (event: any) => {
        const component = ElementsComponent.getInstance().elementsContainer.createComponent(ElementsEditComponent);
        component.instance.init(component, null);
      });
    }
    const btnEditar = document.getElementById('btnEditar');
    if (btnEditar) {
      btnEditar.addEventListener('click', (event: any) => {
        const rowsSelec = this.gridElements.getselectedrowindexes();
        if (rowsSelec && rowsSelec.length > 0) {
          if (rowsSelec.length < 11) {
            rowsSelec.forEach(rowIndex => {
              const component = ElementsComponent.getInstance().elementsContainer.createComponent(ElementsEditComponent);
              component.instance.init(component, this.elementList[rowIndex]);
            });
          } else {
            MainComponent.getInstance().showWarning('ATENCION', 'Diez_registros_maximo', 2000);
          }
        } else {
          MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
        }
      });
    }
    const btnBorrar = document.getElementById('btnBorrar');
    if (btnBorrar) {
      btnBorrar.addEventListener('click', (event: any) => {
        const rowsSelec = this.gridElements.getselectedrowindexes();
        if (rowsSelec && rowsSelec.length > 0) {
          if (rowsSelec.length < 11) {
            rowsSelec.forEach(async (rowIndex) => {
              let result = await this.elementsService.getResiduoPendiente(this.elementList[rowIndex].Id);
              if (result.id) {
                MainComponent.getInstance().showWarning('ATENCION', 'Elemento_con_residuo', 3000, ' ' + this.elementList[rowIndex].Nombre);
              } else {
                this.modal.confirm({
                  nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
                  nzContent: AppComponent.translate('Quiere_borrar_elemento') + ' ' + this.elementList[rowIndex].Nombre + ' ?',
                  nzCentered: true,
                  nzCancelText: AppComponent.translate('CANCELAR'),
                  nzOkText: AppComponent.translate('SI'),
                  nzOnOk: async () => {
                    await this.elementsService.deleteElemento(this.elementList[rowIndex]);
                    MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
                    this.gridElements.clearselection();
                  }
                });
              }
            });
          } else {
            MainComponent.getInstance().showWarning('ATENCION', 'Diez_registros_maximo', 2000);
          }
        } else {
          MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
        }
      });
    }
    const btnMover = document.getElementById('btnMover');
    if (btnMover) {
      btnMover.addEventListener('click', (event: any) => {
        this.canMove = event.target.checked;
        if (this.elementList) {
          this.elementList.forEach(elem => {
            if (elem.Marker) {
              elem.Marker.setDraggable(this.canMove);
            }
          });
        }
      });
    }
    const btnMovilesCerca = document.getElementById('btnElementosCerca');
    if (btnMovilesCerca) {
      btnMovilesCerca.addEventListener('click', (event: any) => {
        const component = this.elementsContainer.createComponent(ElementsCercaComponent);
        component.instance.init(component);
      });
    }


    //boton exportar
    const btnExportar = document.getElementById('btnExportar');
    if (btnExportar) {
      btnExportar.addEventListener('click', (event: any) => {
        if (this.gridElements.getrows().length === 0) {
          return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
        } else {
          const json = this.gridElements.exportdata('json');
          let datos = JSON.parse(json);
          const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
          this.generateAutofilterHeader(ws);
          const wb: xlsx.WorkBook = xlsx.utils.book_new();
          xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
          xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Elementos') + '.xlsx');
        }
      });
    }

    //boton imprimir
    const btnImprimir = document.getElementById('btnImprimir');
    if (btnImprimir) {
      btnImprimir.addEventListener('click', (event: any) => {
        if (this.gridElements.getrows().length === 0) {
          return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
        } else {
          let gridContent = this.gridElements.exportdata('html');
          let newWindow = window.open('', '', 'width=800, height=500'),
            document = newWindow.document.open(),
            pageContent =
              '<!DOCTYPE html>\n' +
              '<html>\n' +
              '<head>\n' +
              '<meta charset="utf-8" />\n' +
              '<title>jQWidgets Grid</title>\n' +
              '</head>\n' +
              '<body>\n' +
              gridContent +
              '\n</body>\n</html>';
          document.write(pageContent);
          document.close();
          newWindow.onafterprint = function () {
            newWindow.close();
          };
          newWindow.print();
        }
      });
    }
  }


  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Inicializa el control de búsqueda de la cabecera
  initSearchControl(): void {
    const searchControl = document.getElementById('searchControlElements');
    if (searchControl) {
      searchControl.addEventListener('change', (event: any) => {
        this.searchText = event.target.value.toUpperCase();
        // Marco los registros que cumplen la condición de búsqueda y pongo el campo oculto a "selec"
        if (this.elementList) {
          this.elementList.forEach(elem => {
            if ((elem.Equipamiento.Marca.Nombre && elem.Equipamiento.Marca.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (elem.Equipamiento.Modelo.Nombre && elem.Equipamiento.Modelo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (elem.Equipamiento.Elemento.Nombre && elem.Equipamiento.Elemento.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (elem.Equipamiento.Elemento.Tipo.Nombre && elem.Equipamiento.Elemento.Tipo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
              (elem.Nombre && elem.Nombre.toUpperCase().indexOf(this.searchText) > -1)) {
              elem.selec = 'selec';
            } else {
              elem.selec = '';
            }
          });
        }
        // Compruebo si ya he creado el filtro "selec" anteriormente
        const filters = this.gridElements.getfilterinformation();
        if (filters.find(s => s.datafield === 'selec') === undefined) {
          const filtergroup = new jqx.filter();
          filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
          this.gridElements.addfilter('selec', filtergroup);
        }
        this.gridElements.applyfilters();
        this.gridElements.updatebounddata('data');
      });
      // searchControl.addEventListener('input', (event: any) => {
      //   this.searchText = event.target.value.toUpperCase();
      // });
      // let lastSearch = '';
      // let timer = null;
      // // Cada segundo compruebo si se ha filtrado la información
      // setInterval(() => {
      //   if (this.searchText !== lastSearch) {
      //     if (timer) {
      //       clearTimeout(timer);
      //     }
      //     lastSearch = this.searchText;
      //     timer = setTimeout(() => {
      //       // Marco los registros que cumplen la condición de búsqueda y pongo el campo
      //       // oculto a "selec"
      //       if (this.elementList) {
      //         this.elementList.forEach(elem => {
      //           if ((elem.Equipamiento.Marca.Nombre && elem.Equipamiento.Marca.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
      //             (elem.Equipamiento.Modelo.Nombre && elem.Equipamiento.Modelo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
      //             (elem.Equipamiento.Elemento.Nombre && elem.Equipamiento.Elemento.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
      //             (elem.Equipamiento.Elemento.Tipo.Nombre && elem.Equipamiento.Elemento.Tipo.Nombre.toUpperCase().indexOf(this.searchText) > -1) ||
      //             (elem.Nombre && elem.Nombre.toUpperCase().indexOf(this.searchText) > -1)) {
      //             elem.selec = 'selec';
      //           } else {
      //             elem.selec = '';
      //           }
      //         });
      //       }
      //       // Compruebo si ya he creado el filtro "selec" anteriormente
      //       const filters = this.gridElements.getfilterinformation();
      //       if (filters.find(s => s.datafield === 'selec') === undefined) {
      //         const filtergroup = new jqx.filter();
      //         filtergroup.addfilter(1, filtergroup.createfilter('stringfilter', 'selec', 'equal'));
      //         this.gridElements.addfilter('selec', filtergroup);
      //       }
      //       this.gridElements.applyfilters();
      //       this.gridElements.updatebounddata('data');
      //     }, 500);
      //   }
      // }, 500);
    }
  }

  // Cuando se destruye el componente borro las subscripciones y los temporizadores
  ngOnDestroy() {
    if (this.subscriptionOnAddElements) {
      this.subscriptionOnAddElements.unsubscribe();
    }
    if (this.subscriptionOnModifyElements) {
      this.subscriptionOnModifyElements.unsubscribe();
    }
    if (this.subscriptionOnDeleteElements) {
      this.subscriptionOnDeleteElements.unsubscribe();
    }
    if (this.subscriptionOnChangeFilterElements) {
      this.subscriptionOnChangeFilterElements.unsubscribe();
    }
    if (this.subscriptionOnNewElements) {
      this.subscriptionOnNewElements.unsubscribe();
    }
    if (this.subscriptionOnEndLoadElements) {
      this.subscriptionOnEndLoadElements.unsubscribe();
    }
    if (this.subscriptionOnModifyPU) {
      this.subscriptionOnModifyPU.unsubscribe();
    }
    if (this.timerSearch === null) {
      clearInterval(this.timerSearch);
    }
  }

  // Cuando se selecciona una fila
  onRowClick(event: any) {
    const rowSel = this.gridElements.getselectedrowindexes();
    if (rowSel && rowSel.length < 2) {
      this.gridElements.clearselection();
      this.gridElements.selectrow(event.args.rowindex);
    }
    this.elemSelec = this.elementList[event.args.rowindex];
    if (!event.args.fromCarto) {
      if (!this.elemSelec.Marker) {
        this.elemSelec.Marker = this.addMarker(this.elemSelec);
      }
      this.map.setCenter(this.elemSelec.Marker.position);
      this.map.setZoom(this.MARKERS_ZOOM);
      this.elemSelec.Marker.setZIndex(999);
      this.elemSelec.Marker.animate(2000);
    }
  }

  onRowdoubleclick(event: any) {
    this.onRowClick(event);

    const btnEditar = document.getElementById('btnEditar');
    btnEditar.click();
  }

  // Recupera los elementos de la empresa
  public initGrid(): void {
    this.source = {
      datatype: 'json',
      datafields: [
        { name: 'id', type: 'number', map: 'Id' },
        { name: 'eqId', type: 'number', map: 'Equipamiento>Modelo>Id' },
        { name: 'marca', type: 'string', map: 'Equipamiento>Marca>Nombre' },
        { name: 'modelo', type: 'string', map: 'Equipamiento>Modelo>Nombre' },
        { name: 'elemento', type: 'string', map: 'Equipamiento>Elemento>Nombre' },
        { name: 'infogeo', type: 'string', map: 'Infogeo' },
        { name: 'estado', type: 'string', map: 'estadoTexto' },
        { name: 'tipo', type: 'string', map: 'Equipamiento>Elemento>Tipo>Nombre' },
        { name: 'capacidad', type: 'int', map: 'Equipamiento>Capacidad' },
        { name: 'descripcion', type: 'string', map: 'Nombre' },
        { name: 'nivel', type: '', map: 'UltPctjeVolum' },
        { name: 'fechaNivel', map: 'UltFechaVolum' },
        { name: 'distancia', type: 'string', map: 'distancia' },
        { name: 'selec', map: 'selec' }
      ],
      localdata: this.elementList,
      // sortcolumn: 'marca',
      // sortdirection: 'asc'
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);

    this.gridElements.columnmenuopening(this.columnmenuopening);
  }

  /*
  Ajusto el menu del filtro en funcion de la posicion
  */
  public columnmenuopening (menu: any, datafield: any, height: any): void {
    let menuElement = <HTMLElement>document.getElementById(menu[0].id);
    let areaGestion = <HTMLElement>document.getElementById('elemGrid').children[0];
    let column = ElementsComponent.instance.gridElements.getcolumn(datafield)['element'];

    // En caso de que vaya a sobresalir por debajo de la pantalla lo ajusto hacia arriba
    if(height > areaGestion.offsetHeight){
      let top = height - areaGestion.offsetHeight + 80;

      menuElement.parentElement.style.top = '-'+top+'px';
    }

    // Ajuste del menu para que se vea el boton de filtro en el grid
    setTimeout(() => {
      let columnPos = column.offsetLeft + column.offsetWidth;
      let menuWidth = menuElement.offsetWidth;

      if(columnPos + menuWidth >= areaGestion.offsetWidth){
        menuElement.parentElement.style.left = '-20px';
      }else{
        menuElement.parentElement.style.left = '20px';
      }
    }, 0)
  };

  // Cuando se refrescan los datos del grid
  onBindingComplete(): void {
    if (this.gridElements && this.elementList && this.elementList.length > 0) {
      this.gridElements.sortby(this.orderBy.split(',')[0], this.orderBy.split(',')[1]);
    }
    if (this.initialFilter) {
      this.setStoredFilter();
    }
    const elemVisibles = new Map<number, number>();
    const rowsVisibles = this.gridElements.getrows();
    if (rowsVisibles) {
      rowsVisibles.forEach(row => {
        elemVisibles.set(row.id, row.id);
      });
    }
    if (this.elementList) {
      this.elementList.forEach(elem => {
        if (elem.Marker) {
          elem.Marker.setVisible(elemVisibles.get(elem.Id) !== undefined);
        }
      });
    }
  }

  // Permite añadir elementos al mapa y al grid
  async subscribeOnAddElements(): Promise<void> {
    this.subscriptionOnAddElements = this.elementsService.addElementEmiter.subscribe(async elementos => {
      elementos.forEach(elemento => {
        elemento.UltFechaVolum = new Date(elemento.UltFechaVolum);
        this.elementListTotal.push(elemento);
      });
    });
  }

  // Permite añadir un nuevo elemento al mapa y al grid
  async subscribeOnNewElements(): Promise<void> {
    this.subscriptionOnNewElements = this.elementsService.newElementEmiter.subscribe(async elemento => {
      elemento.UltFechaVolum = new Date(elemento.UltFechaVolum);
      elemento.Equipamiento = BdtService.equipamiento.get(elemento.IdEquipamiento);
      // Añado los agregados
      this.addAgregates(elemento);
      // Añado el marcador refrescando el cluster
      elemento.Marker = this.addMarker(elemento, true);
      this.elementListTotal.push(elemento);
      this.elementList.push(elemento);
      this.gridElements.updatebounddata('data');

      // Actualizo la hash table
      this.elementsService.elementos.set(elemento.Id, elemento);
    });
  }

  // Cuando se ha terminado de descargar los elementos
  async subscribeOnEndLoadElements(): Promise<void> {
    this.subscriptionOnEndLoadElements = this.elementsService.endLoadElementsEmiter.subscribe(async () => {

      // Prueba de carga con 400.000 elementos ---------------------------
      // try {
      //   let tot = this.elementListTotal.length;
      //   while (this.elementListTotal.length < 400000) {
      //     let i = Math.trunc((Math.random() * tot) % tot);
      //     const ele = { ...this.elementListTotal[i] };
      //     ele.Nombre += (" " + i);
      //     let j = Math.trunc((Math.random() * 10) % 10);
      //     ele.Lat += (0.0001 * j);
      //     ele.Lng += (0.0001 * j);
      //     this.elementListTotal.push(ele);
      //   }
      // } catch (e) {
      //   console.log(e);
      // }
      //-------------------------------------------------------------------

      this.equipModelFilter = await this.elementsService.getFilterModel();
      if (MainComponent.getInstance().isEcoEvolution) {
        this.equipModelFilterIA = await this.elementsService.getFilterModelIA();
      } else {
        this.equipModelFilterIA = null;
      }
      // Creo la lista de elementos filtrados
      this.elementList = [];
      let hayVolum = false;
      if (this.elementListTotal) {
        this.elementListTotal.forEach(elemento => {
          elemento.UltFechaVolum = new Date(elemento.UltFechaVolum);
          elemento.Equipamiento = BdtService.equipamiento.get(elemento.IdEquipamiento);
          if (elemento.Equipamiento) {
            if (!this.equipModelFilterIA || this.equipModelFilterIA.find(s => s.id === elemento.Equipamiento.Id) !== undefined) {
              if (this.equipModelFilter.find(s => s.id === elemento.Equipamiento.Id) !== undefined) {
                elemento.selec = '';
                // Añado los agregados
                this.addAgregates(elemento);
                // Añado el marcador sin refrescar el cluster
                elemento.Marker = this.addMarker(elemento, false);
                this.elementList.push(elemento);
                if (elemento.ImeiVolum || elemento.IdVolumetrico) {
                  hayVolum = true;
                }
              }
            }
          }
        });
      }
      if (hayVolum) {
        this.gridElements.showcolumn('nivel');
        this.gridElements.showcolumn('fechaNivel');
      }
      try {
        const t = setTimeout(() => {
          clearTimeout(t);
          this.elemCluster.refresh(false);
        }, 500);
      } catch (e) {
        console.log(e);
      }
      // Asigno la lista de elementos para los módulos externos que los necesiten
      MainComponent.getInstance().elementsList = this.elementList;
      MainComponent.getInstance().endLoadElementsEmiter.emit(this.elementList);
      // Relleno el grid de elementos
      this.initGrid();
    });
  }

  addAgregates(elemento: ElementoModel) {
    if (!elemento.Estado) {
      elemento.Estado = 0;
    }
    switch (elemento.Estado) {
      case 1:
        elemento.estadoTexto = this.translate('Ubicado');
        break;
      case 2:
        elemento.estadoTexto = this.translate('En_transito');
        break;
      default:
        elemento.estadoTexto = '';
    }
    elemento.Infogeo = '';
    if (elemento.Calle) {
      elemento.Infogeo += elemento.Calle;
      if (elemento.NumeroCalle) {
        elemento.Infogeo += ', ' + elemento.NumeroCalle;
      }
    }
    if (elemento.Municipio) {
      elemento.Infogeo += elemento.Infogeo.length > 0 ? (', ' + elemento.Municipio) : elemento.Municipio;
    }
    if (elemento.Provincia) {
      elemento.Infogeo += elemento.Infogeo.length > 0 ? (' (' + elemento.Provincia + ')') : elemento.Provincia;
    }
  }

  // Devuelve el contenido del infowindows del marcador
  getMarkerTitle(elemento: ElementoModel): string {
    return elemento.Nombre;
  }

  // Devuelve el contenido del infowindows del marcador
  getMarkerContent(elemento: ElementoModel): string {
    let res = '';
    if (!this.elementsService.elemGenericos) {
      res = '<b>' + elemento.Nombre + '</b><hr>' + elemento.Equipamiento.Elemento.Nombre + '<br>' +
        (elemento.Equipamiento.Marca.Nombre ? elemento.Equipamiento.Marca.Nombre + '<br>' : '') +
        (elemento.Equipamiento.Modelo.Nombre ? elemento.Equipamiento.Modelo.Nombre : '');
      // Volumétricos
      if (elemento.ImeiVolum || elemento.IdVolumetrico) {
        res += '<br><br>' + this.translate('Nivel') + ': ' + elemento.UltPctjeVolum + '%';
        if (elemento.UltFechaVolum && elemento.UltFechaVolum.getFullYear() > 2000) {
          res += ' a ' + DateUtils.formatDateTimeShort(new Date(elemento.UltFechaVolum), true);
        }
      }
    } else {
      res = '<b>' + elemento.Nombre + '</b><hr>' + elemento.Equipamiento.Elemento.Nombre;
    }
    // Estados
    if (!elemento.Estado) {
      elemento.Estado = 0;
    }
    switch (elemento.Estado) {
      case 1:
        elemento.estadoTexto = this.translate('Ubicado');
        break;
      case 2:
        elemento.estadoTexto = this.translate('En_transito');
        break;
      default:
        elemento.estadoTexto = '';
    }
    res += '<br><b>' + elemento.estadoTexto + '</b>';
    return res;
  }

  // Permite saber que se han modificado elementos
  subscribeModifyElements(): void {
    this.subscriptionOnModifyElements = this.elementsService.modifyElementEmiter.subscribe(async elemento => {
      if (this.elementList) {
        for (let i = 0; i < this.elementList.length; i++) {
          // Actualizo los datos del elemento en la lista
          if (this.elementList[i].Id === elemento.Id) {
            const marker = elemento.Marker ? elemento.Marker : this.elementList[i].Marker;
            this.elementList[i] = elemento;
            elemento.Marker = marker;
            elemento.UltFechaVolum = new Date(elemento.UltFechaVolum);
            elemento.Equipamiento = BdtService.equipamiento.get(elemento.IdEquipamiento);
            if (elemento.Equipamiento) {
              if (!this.equipModelFilterIA || this.equipModelFilterIA.find(s => s.id === elemento.Equipamiento.Id) !== undefined) {
                if (this.equipModelFilter.find(s => s.id === elemento.Equipamiento.Id) !== undefined) {
                  elemento.selec = '';
                }
              }
            }
            // Actualizo los datos en el marcador
            if (marker) {
              this.elementList[i].Marker.setTitle(this.getMarkerTitle(elemento));
              this.elementList[i].Marker.setContent(this.getMarkerContent(elemento));
              this.elementList[i].Marker.setPosition(new MapLatLng(elemento.Lat, elemento.Lng));
            }
            // Añado los agregados
            this.addAgregates(this.elementList[i]);
            if (!elemento.Pu) {
              // Compruebo si el elemento modificado ya tiene asignado el PU
              elemento.Pu = await this.puService.getPuntoUbicacionByElemento(elemento.Id);
            }
            break;
          }
        }
      }
      this.elementsService.elementos.set(elemento.Id, elemento);
      this.elemCluster.refresh(false);
      // Actualizo el grid sólo después de haber asignado el dataadapter
      if (this.dataAdapter) {
        this.gridElements.updatebounddata('data');
      }
      // Actualizo la hash table
      this.elementsService.elementos.set(elemento.Id, elemento);
    });
  }

  // Permite saber que se han borrado elementos
  subscribeDeleteElements(): void {
    this.subscriptionOnDeleteElements = this.elementsService.deleteElementEmiter.subscribe(async elemento => {
      if (this.elementList) {
        for (let i = 0; i < this.elementList.length; i++) {
          if (elemento.Id === this.elementList[i].Id) {
            this.map.removeMarker(this.elementList[i].Marker);
            this.elementList.splice(i, 1);
            this.elemCluster.refresh(false);
            // Actualizo el grid sólo después de haber asignado el dataadapter
            if (this.dataAdapter) {
              this.gridElements.updatebounddata('data');
            }
            break;
          }
        }
      }
      if (this.elementListTotal) {
        for (let i = 0; i < this.elementListTotal.length; i++) {
          if (elemento.Id === this.elementListTotal[i].Id) {
            this.elementListTotal.splice(i, 1);
            break;
          }
        }
      }
      // Actualizo la hash table
      this.elementsService.elementos.delete(elemento.Id);
    });
  }

  // Cuando se cambia el filtro de elementos
  async subscribeChangeFilterElements(): Promise<void> {
    this.subscriptionOnChangeFilterElements = this.elementsService.changeFilterEmiter.subscribe(async () => {
      this.equipModelFilter = await this.elementsService.getFilterModel();
      if (MainComponent.getInstance().isEcoEvolution) {
        this.equipModelFilterIA = await this.elementsService.getFilterModelIA();
      } else {
        this.equipModelFilterIA = null;
      }
      // Creo el cluster para los elementos
      this.map.removeClusterV2(this.elemCluster);
      this.elemCluster = this.map.addClusterV2(this.MARKERS_ZOOM);
      // Creo la lista de elementos filtrados
      this.elementList = [];
      this.elementListTotal.forEach(elemento => {
        if (elemento.Equipamiento) {
          if (!this.equipModelFilterIA || this.equipModelFilterIA.find(s => s.id === elemento.Equipamiento.Id) !== undefined) {
            if (this.equipModelFilter.find(s => s.id === elemento.Equipamiento.Id) !== undefined) {
              elemento.selec = '';
              // Añado el marcador sin refrescar el cluster
              elemento.Marker = this.addMarker(elemento, false);
              this.elementList.push(elemento);
            }
          }
        }
      });
      // Asigno la lista de elementos para los módulos externos que los necesiten
      MainComponent.getInstance().elementsList = this.elementList;
      // MainComponent.getInstance().endLoadElementsEmiter.emit(this.elementList);
      this.initGrid();
      this.elemCluster.refresh(false);
    });
  }

  // Cando se crea un filtro
  async onChangeFilter(event: any) {
    this.initialFilter = null;
    let filter = null;
    // Si el primer filtro es el de la búsqueda busco el siguiente filtro
    let columnFilter = event.args.filters[0] ? event.args.filters[0].datafield : '';
    if (columnFilter === 'selec') {
      if (event.args.filters[1]) {
        // Si hay un segundo filtro es por que se han filtrado por alguna columna
        columnFilter = event.args.filters[1].datafield
        filter = event.args.filters[1].filter.getfilters();
      }
    } else {
      filter = event.args.filters[0] ? event.args.filters[0].filter.getfilters() : null;
    }
    // Si ha cambiado el filtro
    if (this.oldFilter !== filter) {
      this.oldFilter = filter;
      // Si no hay filtro pongo la variable en blanco
      if (!filter) {
        // Guardo la variable de configuración en blanco
        this.configService.setUsuEmpApp('elements-filters', '');
      } else {
        const config = {
          column: columnFilter,
          filters: filter
        }
        // Guardo la variable de configuración con los datos del filtro
        this.configService.setUsuEmpApp('elements-filters', JSON.stringify(config));
      }
      // Para que se repinten los cluster
      this.onBindingComplete();
    }
  }

  // '{"column":"subflota","filters":[{"value":"C.Lateral","condition":"EQUAL","operator":1,"type":"stringfilter"}]}'
  setStoredFilter() {
    // Recupero los datos del filtro guardado y los aplico
    const filtergroup = new jqx.filter();
    if (this.initialFilter && this.initialFilter.filters) {
      this.initialFilter.filters.forEach(elem => {
        const filter = filtergroup.createfilter(elem.type, elem.value, elem.condition);
        filtergroup.addfilter(elem.operator, filter);
      });
      this.gridElements.addfilter(this.initialFilter.column, filtergroup);
      this.gridElements.applyfilters();
      this.initialFilter = null;
    }
  }

  // Recupero el filtro guardado si lo hay
  async getInitFilter() {
    this.initialFilter = await this.configService.getUsuEmpApp('elements-filters', null);
    if (this.initialFilter) {
      this.initialFilter = JSON.parse(this.initialFilter);
    }
  }

  // Permite refrescar el grid de elementos
  public async refreshGrid() {
    setTimeout(() => {
      this.gridElements.updatebounddata('data');

      const t = setTimeout(() => {
        clearTimeout(t);
        this.gridElements.autoresizecolumns();
      }, 500);

      this.gridElements.ensurerowvisible(0);
    }, 500);
  }

  // Permite modificar la columna de ordenación y la dirección
  public async orderByGrid(column: string, order: string) {
    setTimeout(() => {
      this.orderBy = column + ',' + order;
      this.gridElements.updatebounddata('cell');

      const t = setTimeout(() => {
        clearTimeout(t);
        this.gridElements.autoresizecolumns();
      }, 500);

      this.gridElements.ensurerowvisible(0);
    }, 500);
  }

  // Permite visualizar u ocultar columnas del grid
  public showColumnGrid(column: string, show: boolean) {
    if (show) {
      this.gridElements.showcolumn(column);
    } else {
      this.gridElements.hidecolumn(column);
    }
  }

  // Cuando se modifica un punto de ubicación
  subscribeModifyPU(): void {
    this.subscriptionOnModifyPU = this.puService.modifyPuEmiter.subscribe(pu => {
      if (pu.Elemento && this.elementList) {
        for (let i = 0; i < this.elementList.length; i++) {
          if (this.elementList[i].Id === pu.Elemento.Id) {
            if (this.elementList[i].Marker) {
              this.elementList[i].Lat = pu.Lat;
              this.elementList[i].Lng = pu.Lng;
              this.elementList[i].Marker.setPosition(new MapLatLng(pu.Lat, pu.Lng));
            }
            this.elementsService.elementos.set(this.elementList[i].Id, this.elementList[i]);
            break;
          }
        }
      }
    });
  }

}

