import { Component, OnInit, ViewChild } from '@angular/core';

import { Utils } from 'src/app/utils/utils';
import { CustomForms } from '../forms/custom-forms';
import { AppComponent } from 'src/app/app.component';
import { MainComponent } from '../main/main.component';
import { environment } from 'src/environments/environment';

import { GeoUtils } from 'src/app/utils/geo-utils';
import { MapBounds, MapComponent, MapLatLng, MapMarker, MapPolygon } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { NzModalService } from 'ng-zorro-antd/modal';
import { SsoService } from 'src/app/services/sso/sso.service';
import { CuadriculaService } from 'src/app/services/cuadricula/cuadricula.service';

import { CuadriculaModel } from 'src/app/services/cuadricula/models/cuadricula.model';
import { MarcoGeograficoModel } from 'src/app/services/geographics/marco-geografico.model';


@Component({
  selector: 'app-cuadriculas',
  templateUrl: './cuadriculas.component.html',
  styleUrls: ['./cuadriculas.component.css']
})
export class CuadriculasComponent extends CustomForms implements OnInit {

  private componentRef = null;
  public theme = environment.tema;
  public langGrid = JqWidgets.getLocalization('es');
  private subscriptionOnMapClick = null;
  private subscriptionOnMarkerMove = null;

  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('formCuadricula') formCuadricula: jqxWindowComponent;
  // grid
  dataAdapter: any;
  dataSource: any;
  row: number;
  public visible = false;
  // mostrar formulario
  viewForm: boolean = false;
  // instancia de la clase cuadricula
  cuadricula: CuadriculaModel = new CuadriculaModel();
  private cuadriculas: CuadriculaModel[] = [];
  modificando: boolean = false;
  // Medidas por defecto del marco en metros
  private marcoAncho = 10000;
  private marcoAlto = 10000;

  // map
  private map: MapComponent;
  private markerSw: MapMarker;
  private markerNw: MapMarker;
  private markerNe: MapMarker;
  private markerSe: MapMarker;
  private polygon: MapPolygon;
  private polygon2: MapPolygon;
  private polygonPoints: MapLatLng[] = [];
  public marcoCartoPolygon: MapPolygon;
  polygonSquare: MapPolygon[] = [];
  private marco: MarcoGeograficoModel;

  constructor(private modal: NzModalService, private cuadriculaService: CuadriculaService, private ssoService: SsoService) {
    super();
  }
  // grid
  columns: any = [
    {
      text: this.translate('Nombre'), datafield: 'Nombre', width: 'auto', editable: false, type: 'string',
      aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }], //agrego el total al grid
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="margin-left: 4px;">'+AppComponent.translate('Total')+': ' +
            aggregates["Total"] + '</div>';
        }
        return renderstring;
      }
    },
    {
      text: this.translate('Alto'), datafield: 'Alto', width: '30%', editable: false, type: 'string',
    },
    {
      text: this.translate('Ancho'), datafield: 'Ancho', width: '30%', editable: false, type: 'string',
    },
  ];

  /**Inicializa el componente
*/
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.getCuadriculas();
  }

  ngAfterViewInit() {
    this.form.setTitle(AppComponent.translate('Cuadriculas'));
    this.formCuadricula.setTitle(AppComponent.translate('Cuadriculas'));
    this.map = MainComponent.getActiveMap();
    this.addCustomForm(this.form);
    this.addCustomForm(this.formCuadricula);
    Utils.renderSizeGrid(this.myGrid);
  }

  //Carga de datos
  async getCuadriculas() {
    this.cuadriculas = await this.cuadriculaService.getCuadriculas();
    if (this.cuadriculas) {
      this.dataSource = {
        datatype: 'json',
        datafields: [
          { name: 'Id', type: 'string', map: 'id' },
          { name: 'Nombre', type: 'string', map: 'nombre' },
          { name: 'Alto', type: 'string', map: 'alto' },
          { name: 'Ancho', type: 'string', map: 'ancho' },
        ],
        localdata: this.cuadriculas,
      };
      this.dataAdapter = new jqx.dataAdapter(this.dataSource);
    }

    const t = setTimeout(() => {
      clearTimeout(t);
      this.myGrid.autoresizecolumns();
    }, 500);
  }

  // obtener fila seleccionada
  onRowclick(event: any): void {
    this.row = event.args.row.bounddata.Id;
  }

  onRowdoubleclick(event: any) {
    this.onEditarCuadricula(event);
  }

  //Crud form
  onCrearCuadricula(event) {
    this.viewForm = true;
    this.modificando = false;
    this.cuadricula = new CuadriculaModel();
    this.marco = null;
    this.subscribeMapEvents();
    this.formCuadricula.open(); //Abre el formulario
    this.form.collapse();
  }

  async onSaveCuadricula(event) {
    // Comprobamos que los datos del formulario son correctos
    if (this.marco === undefined || !this.controlForm()) {
      MainComponent.getInstance().showError('ATENCION', 'Pinte_cuadricula_mapa', 2000);
      return;
    } else {
      if (this.cuadricula.id) {
        // busco la cuadricula en el array de cuadriculas
        let elem = this.cuadriculas.find(elem => elem.id == this.cuadricula.id);
        this.cuadriculas.splice(this.cuadriculas.indexOf(elem), 1);
        this.cuadricula.marco = this.marco.marco;
        this.cuadricula = await this.cuadriculaService.addCuadricula(this.cuadricula)
        // Si el id de la acción es null, es que estamos creando
      } else {
        this.cuadricula = await this.saveCuadricula();
      }
      if (this.cuadricula) {
        MainComponent.getInstance().showInfo(
          'ATENCION',
          'Registro_almacenado',
          2000
        );
        this.cuadriculas.push(this.cuadricula);
        this.myGrid.updatebounddata();
        this.formCuadricula.close();
        this.form.expand();
      } else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }
  }

  onEditarCuadricula(event) {
    this.viewForm = true;
    this.modificando = true;
    // si no hay ninguna acción seleccionada, salgo
    if (!this.row) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    // busco la acción en el array de acciones
    this.cuadricula = this.onFindCuadricula();
    let positionCenter = this.positionSquareCenter();
    this.subscribeMapEvents();
    this.onCreateMarco(this, positionCenter);
    // Abro el formulario
    this.formCuadricula.open(); //Abre el formulario
    this.form.collapse();
    // Encuadro el mapa en el marco
    this.map.fitTo(this.marco.marco);
  }

  // Ver cuadrícula
  onVerCuadricula(event) {
    if (!this.row) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    else {
      this.modificando = true;
      this.cuadricula = this.onFindCuadricula();
      let positionCenter = this.positionSquareCenter();
      this.onCreateMarco(this, positionCenter);
      this.removeMarkerAtMarco();
      this.map.setCenter(positionCenter);
      this.map.setZoom(14);
    }
  }

  // Borrar cuadrícula
  onBorrarCuadricula(event) {
    this.viewForm = false;
    // si no hay ninguna acción seleccionada, salgo
    if (this.row == null || this.row == undefined) {
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
      return
    }
    let elem = this.cuadriculas.find(elem => elem.id == this.row);
    if (elem) {
      this.modal.confirm({
        nzTitle: '<i>' + AppComponent.translate('ATENCION') + '</i>',
        nzContent: AppComponent.translate('Quiere_borrar_elemento') + ': ' + elem.nombre + ' ?',
        nzCentered: true,
        nzCancelText: AppComponent.translate('CANCELAR'),
        nzOkText: AppComponent.translate('SI'),
        nzOnOk: async () => {
          await this.cuadriculaService.delCuadricula(elem.id);
          MainComponent.getInstance().showSuccess('ATENCION', 'Registro_borrado', 2000);
          this.cuadriculas.splice(this.cuadriculas.indexOf(elem), 1);
          this.myGrid.updatebounddata();
          this.row = null;
        }
      });
    }
  }


  // Cuando se modifica el alto de la cuadrícula se modifica el marco controlando que no sea menor de 50
  changeHigh(event) {
    let back = true;
    if (event.code === 'Backspace') {
      back = false;
    }
    if (event && this.marco && back) {
      let altoInput = event.target.value;
      if (Number.isNaN(parseInt(altoInput))) {
        MainComponent.getInstance().showWarning('ATENCION', 'Solo_numeros', 2000);
        return;
      }
      this.cuadricula.alto = parseInt(altoInput);
      if (this.cuadricula.alto > 50) {
        let position = this.positionFrameCenter();
        // modificamos el alto del marco
        this.onCreateMarco(this, position);
      } else {
        this.controlForm();
        return;
      }
    }
  }

  // Cuando se modifica el ancho de la cuadrícula se modifica el marco controlando que no sea menor de 50
  changeBroad(event) {
    let back = true;
    if (event.code === 'Backspace') {
      back = false;
    }
    if (event && this.marco && back) {
      let anchoInput = event.target.value;
      // Si no es un número, salgo
      if (Number.isNaN(parseInt(anchoInput))) {
        MainComponent.getInstance().showWarning('ATENCION', 'Solo_numeros', 2000);
        return;
      }
      this.cuadricula.ancho = parseInt(anchoInput);
      if (this.cuadricula.ancho > 50) {
        let position = this.positionFrameCenter();
        this.onCreateMarco(this, position);
      } else {
        this.controlForm();
        return;
      }
    }
  }

  // Busca la cuadrícula en el array de cuadrículas
  onFindCuadricula(): CuadriculaModel {
    let cuadricula = this.cuadriculas.find(x => x.id == this.row);
    if (cuadricula) {
      this.cuadricula = cuadricula;
      return cuadricula;
    }
  }
  
  renderSizeGrid() {
    const t = setTimeout(() => {
      clearTimeout(t);
      this.myGrid.autoresizecolumns();
    }, 100);
  }

  // Obtenemos el centro del marco si la cuadricula no está creada
  positionFrameCenter(): MapLatLng {
    if (this.marco) {
      let position: MapLatLng = new MapLatLng(this.marco.marco.swCorner.lat + ((this.marco.marco.neCorner.lat - this.marco.marco.swCorner.lat) / 2),
        this.marco.marco.swCorner.lng + ((this.marco.marco.neCorner.lng - this.marco.marco.swCorner.lng) / 2));
      return position
    }
  }
  // Obtenemos el centro de la cuadrícula si el marco ya está creado
  positionSquareCenter(): MapLatLng {
    if (this.cuadricula) {
      let positionCenter: MapLatLng = new MapLatLng(this.cuadricula.marco.swCorner.lat + ((this.cuadricula.marco.neCorner.lat - this.cuadricula.marco.swCorner.lat) / 2),
        this.cuadricula.marco.swCorner.lng + ((this.cuadricula.marco.neCorner.lng - this.cuadricula.marco.swCorner.lng) / 2));
      return positionCenter
    }
  }

  //Control de los datos del formulario
  controlForm(): boolean {
    if (!this.cuadricula.nombre) {
      MainComponent.getInstance().showError('ATENCION', 'Introduzca_nombre', 2000);
      return false;
    } else if (this.cuadricula.alto <= 50 || this.cuadricula.alto == undefined || this.cuadricula.ancho == undefined || this.cuadricula.ancho <= 50) {
      MainComponent.getInstance().showError('ATENCION', 'Minimo_cuadricula', 2000);
      return false;
    }
    return true;
  }

  // Configura el marco de 10 km²
  configLatLngMarco(position: MapLatLng): MapBounds {
    if (this.marco) {
      this.marcoAncho = GeoUtils.getDistance(this.marco.marco.swCorner.lat, this.marco.marco.swCorner.lng,
        this.marco.marco.swCorner.lat, this.marco.marco.neCorner.lng);
      this.marcoAlto = GeoUtils.getDistance(this.marco.marco.swCorner.lat, this.marco.marco.swCorner.lng,
        this.marco.marco.neCorner.lat, this.marco.marco.swCorner.lng);
    }
    return new MapBounds(GeoUtils.getNewLatLng(position, -(this.marcoAncho / 2), -(this.marcoAlto / 2)),
      GeoUtils.getNewLatLng(position, this.marcoAncho / 2, this.marcoAlto / 2));
  }

  // Elimina los marcadores del marco
  removeMarkerAtMarco() {
    if (this.markerSw) {
      this.map.removeMarker(this.markerSw);
      this.markerSw = null;
    }

    if (this.markerNe) {
      this.map.removeMarker(this.markerNe);
      this.markerNe = null;
    }

    if (this.markerNw) {
      this.map.removeMarker(this.markerNw);
      this.markerNw = null;
    }

    if (this.markerSe) {
      this.map.removeMarker(this.markerSe);
      this.markerSe = null;
    }
  }

  // Elimina los polígonos de la cuadrícula
  removePolygonSquare() {
    if (this.polygon) {
      this.map.removePolygon(this.polygon);
      this.polygon = null;
      this.polygonSquare.forEach(polygon => this.map.removePolygon(polygon));
      this.polygonSquare = [];
      this.polygonPoints = [];
    }

    if (this.polygon2) {
      this.map.removePolygon(this.polygon2);
      this.polygon2 = null;
    }
  }

  // Añade los marcadores en cada esquina del marco de 10 km
  addMarkersAtMarco(position: MapBounds, polygon: MapPolygon) {
    this.removeMarkerAtMarco();
    this.removePolygonSquare();
    let lat1 = position.neCorner.lat;
    let lat2 = position.neCorner.lat;
    let lng1 = position.swCorner.lng;
    let lng2 = position.swCorner.lng;

    if (polygon) {
      this.map.removePolygon(polygon);
      polygon = null;
    }

    this.polygon2 = this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });

    this.marcoCartoPolygon.points.forEach(point => {
      this.map.addPolygonPoint(this.polygon2, {
        dataModel: this,
        content: '',
        position: point.point
      });
      if (point.point.lat < lat1) {
        lat1 = point.point.lat;
      }
      if (point.point.lng < lng1) {
        lng1 = point.point.lng;
      }
      if (point.point.lat > lat2) {
        lat2 = point.point.lat;
      }
      if (point.point.lng > lng2) {
        lng2 = point.point.lng;
      }
    });

    // Crear los marcadores en cada esquina del marco de 10 km
    this.markerSw = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat1, lng1),
      icon: '/assets/images/angulo-sw.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    this.markerNw = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat2, lng1),
      icon: '/assets/images/angulo-nw.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    this.markerNe = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat2, lng2),
      icon: '/assets/images/angulo-ne.png',
      zIndex: 999,
      drag: true,
      visible: true
    });

    this.markerSe = this.map.addMarker({
      dataModel: this,
      title: '',
      content: '',
      position: new MapLatLng(lat1, lng2),
      icon: '/assets/images/angulo-se.png',
      zIndex: 999,
      drag: true,
      visible: true
    });
    this.getSquareBounds(this.markerSw.position, this.markerNe.position);
  }

  // Crea el marco
  async onCreateMarco(_this: CuadriculasComponent, position: MapLatLng) {
    if (!_this.controlForm()) {
      _this.formCuadricula.expand();
      return;
    } else {
      // Si la cuadricula ya existe, modificamos el marco
      if (_this.modificando) {
        _this.marco = new MarcoGeograficoModel(this.cuadricula.marco);
      } else {
        if (!_this.modificando) {
          const bound = _this.configLatLngMarco(position);
          _this.marco = new MarcoGeograficoModel(bound);
        }
      }

      if (_this.marcoCartoPolygon) {
        _this.map.removePolygon(_this.marcoCartoPolygon);
        _this.marcoCartoPolygon = null;
      }

      _this.marcoCartoPolygon = _this.map.addPolygon({
        strokeColor: '#ff0000',
        strokeOpacity: 0.3,
        strokeWeight: 4,
        fillColor: '#000000',
        fillOpacity: 0
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: _this.marco.marco.swCorner
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: new MapLatLng(_this.marco.marco.neCorner.lat, _this.marco.marco.swCorner.lng)
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: _this.marco.marco.neCorner
      });
      _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
        dataModel: _this,
        position: new MapLatLng(_this.marco.marco.swCorner.lat, _this.marco.marco.neCorner.lng)
      });
      _this.addMarkersAtMarco(_this.marco.marco, _this.marcoCartoPolygon);
      _this.formCuadricula.expand();
    }
  }

  async saveCuadricula(): Promise<CuadriculaModel> {
    let cuadricula: any = {
      Nombre: this.cuadricula.nombre,
      Ancho: this.cuadricula.ancho,
      Alto: this.cuadricula.alto,
      Marco: this.marco.marco,
      Empresa: this.ssoService.getTicket().Empresa.IdGestion
    };
    if (!this.cuadricula.id) {
      this.cuadricula = await this.cuadriculaService.addCuadricula(cuadricula);
    }
    return this.cuadricula;
  }

  getSquareBounds(markerSw: MapLatLng, markerNe: MapLatLng): void {
    // Cojo las esquinas del marco
    let swCorner = this.modificando ? markerSw : this.marco.marco.swCorner;
    const neCorner = this.modificando ? markerNe : this.marco.marco.neCorner;
    // Calculo las medidas del marco
    const marcoAncho = GeoUtils.getDistance(swCorner.lat, swCorner.lng, swCorner.lat,
      swCorner.lng + (neCorner.lng - swCorner.lng));
    const marcoAlto = GeoUtils.getDistance(swCorner.lat, swCorner.lng, swCorner.lat +
      (neCorner.lat - swCorner.lat), swCorner.lng);
    // Calculo el número filas que cogen dentro del marco
    const numColumnas = Math.floor(marcoAncho / this.cuadricula.ancho);
    // Calculo el número columnas que cogen dentro del marco
    const numFilas = Math.floor(marcoAlto / this.cuadricula.alto);
    if (numFilas > 0 && numColumnas > 0) {
      // Recorro filas de abajo a arriba
      for (let fila = 0; fila < numFilas; fila++) {
        // Recorro columnas de izquierda a derecha
        let p1 = swCorner; // Pongo el puntero en la primera columna de la fila
        for (let columna = 0; columna < numColumnas; columna++) {
          const p2 = GeoUtils.getNewLatLng(p1, 0, this.cuadricula.alto);
          const p3 = GeoUtils.getNewLatLng(p1, this.cuadricula.ancho, this.cuadricula.alto);
          const p4 = GeoUtils.getNewLatLng(p1, this.cuadricula.ancho, 0);
          this.polygonPoints = [p1, p2, p3, p4];
          // Creao un polígono para cada cuadrícula y lo agrego al mapa
          this.polygon = this.map.addPolygon({
            dataModel: this,
            strokeColor: '#ff0000',
            strokeOpacity: 0.3,
            strokeWeight: 1,
            fillColor: '#0000ff',
            fillOpacity: 0.1,
            zIndex: 100,
          });
          this.polygonPoints.forEach(punto => {
            this.map.addPolygonPoint(this.polygon, {
              position: punto
            });
          });
          this.polygonSquare.push(this.polygon);
          // Adelanto el puntero una columna
          p1 = p4;
        }
        // Subo el puntero una fila
        swCorner = GeoUtils.getNewLatLng(swCorner, 0, this.cuadricula.alto);
      }
    } else {
      MainComponent.getInstance().showWarning('ATENCION', 'Tamaño_cuadricula', 2000);
    }
  }

  // Modifica el marco al mover los marcadores
  modificarMarco(_this: CuadriculasComponent, marker: MapMarker) {
    if (marker === _this.markerSw) {
      _this.markerNw.setPosition(new MapLatLng(_this.markerNw.position.lat, marker.position.lng));
      _this.markerSe.setPosition(new MapLatLng(marker.position.lat, _this.markerSe.position.lng));
    }
    if (marker === _this.markerNw) {
      _this.markerSw.setPosition(new MapLatLng(_this.markerSw.position.lat, marker.position.lng));
      _this.markerNe.setPosition(new MapLatLng(marker.position.lat, _this.markerNe.position.lng));
    }
    if (marker === _this.markerNe) {
      _this.markerNw.setPosition(new MapLatLng(marker.position.lat, _this.markerNw.position.lng));
      _this.markerSe.setPosition(new MapLatLng(_this.markerSe.position.lat, marker.position.lng));
    }
    if (marker === _this.markerSe) {
      _this.markerSw.setPosition(new MapLatLng(marker.position.lat, _this.markerSw.position.lng));
      _this.markerNe.setPosition(new MapLatLng(_this.markerNe.position.lat, marker.position.lng));
    }

    if (_this.marcoCartoPolygon) {
      _this.map.removePolygon(_this.marcoCartoPolygon);
      _this.marcoCartoPolygon = null;
      _this.removePolygonSquare();
    }

    _this.marcoCartoPolygon = _this.map.addPolygon({
      strokeColor: '#ff0000',
      strokeOpacity: 0.3,
      strokeWeight: 4,
      fillColor: '#000000',
      fillOpacity: 0
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: _this.markerSw?.position
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: new MapLatLng(_this.markerNe?.position.lat, _this.markerSw?.position.lng)
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: _this.markerNe?.position
    });
    _this.map.addPolygonPoint(_this.marcoCartoPolygon, {
      dataModel: _this,
      content: '',
      position: new MapLatLng(_this.markerSw?.position.lat, _this.markerNe?.position.lng)
    });

    _this.getSquareBounds(_this.markerSw?.position, _this.markerNe?.position);
    _this.marco = new MarcoGeograficoModel(new MapBounds(_this.markerSw?.position, _this.markerNe?.position));

  }

  // Suscripción a los eventos del mapa
  subscribeMapEvents() {
    // si no hay suscripción, la creo
    if (!this.subscriptionOnMapClick) {
      this.formCuadricula.collapse();
      this.subscriptionOnMapClick = this.map.subscribeOnMapClick(this, this.onCreateMarco);
    }
    if (!this.subscriptionOnMarkerMove) {
      this.formCuadricula.collapse();
      this.subscriptionOnMarkerMove = this.map.subscribeOnMarkerMove(this, this.modificarMarco);
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  ngOnDestroy() {
    if (this.marcoCartoPolygon) {
      this.map.removePolygon(this.marcoCartoPolygon);
      this.marcoCartoPolygon = null;
    }
    if (this.polygon2) {
      this.map.removePolygon(this.polygon2);
      this.polygon2 = null;
    }
    this.removePolygonSquare();
    this.removeMarkerAtMarco();
  }

  onCloseForm() {
    this.form.expand();
    this.viewForm = false;
    this.removeMarkerAtMarco();
    this.unsubscribeMapEvents();
  }

  // Desuscripción a los eventos del mapa
  unsubscribeMapEvents() {
    if (this.subscriptionOnMapClick) {
      this.subscriptionOnMapClick.unsubscribe();
      this.subscriptionOnMapClick = null;
    }
  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

}
