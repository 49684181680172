import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzImageModule } from 'ng-zorro-antd/image';
import { PeriodoSelectModule } from '../periodo-select/periodo-select.module';

import { jqxGridModule } from 'jqwidgets-ng/jqxgrid';
import { jqxInputModule } from 'jqwidgets-ng/jqxinput';
import { jqxLoaderModule } from 'jqwidgets-ng/jqxloader';
import { jqxWindowModule } from 'jqwidgets-ng/jqxwindow';
import { jqxButtonModule } from 'jqwidgets-ng/jqxbuttons';
import { jqxTooltipModule } from 'jqwidgets-ng/jqxtooltip';
import { jqxTextAreaModule } from 'jqwidgets-ng/jqxtextarea';
import { jqxFileUploadModule } from 'jqwidgets-ng/jqxfileupload';
import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';

import { ModalComponent} from './modal/modal.component';
import { EditStateComponent } from './edit-state/edit-state.component';
import { NewIncidenceComponent } from './new-incidence/new-incidence.component';
import { StatusHistoryComponent } from './status-history/status-history.component';
import { GridIncidencesComponent } from './grid-incidences/grid-incidences.component';

@NgModule({
  declarations: [
    ModalComponent,
    EditStateComponent,
    NewIncidenceComponent,
    StatusHistoryComponent,
    GridIncidencesComponent
  ],
  imports: [
    CommonModule,
    NzIconModule,
    jqxGridModule,
    NzImageModule,
    jqxInputModule,
    jqxButtonModule,
    jqxWindowModule,
    jqxLoaderModule,
    jqxTooltipModule,
    jqxTextAreaModule,
    jqxFileUploadModule,
    PeriodoSelectModule,
    jqxDropDownListModule
  ],
  exports: [NewIncidenceComponent]
})
export class IncidencesModule { }
