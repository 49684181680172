import { Component, OnInit, ViewChild } from '@angular/core';

import { AppComponent } from 'src/app/app.component';
import { CustomForms } from '../../forms/custom-forms';
import { MainComponent } from '../../main/main.component';
import { environment } from 'src/environments/environment';
import { MapComponent, MapMarker } from 'movisat-maps';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxRibbonComponent } from 'jqwidgets-ng/jqxribbon';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { SsoService } from 'src/app/services/sso/sso.service';
import { CiudadanosService } from 'src/app/services/ciudadanos/ciudadanos.service';

import { TicketModel } from 'src/app/services/sso/models/ticket.model';
import { CiudadanoModel } from 'src/app/services/ciudadanos/models/ciudadano.model';

import { CiudadanosComponent } from '../ciudadanos.component';

@Component({
  selector: 'app-ciudadanos-edit',
  templateUrl: './ciudadanos-edit.component.html',
  styleUrls: ['./ciudadanos-edit.component.css']
})
export class CiudadanosEditComponent extends CustomForms implements OnInit {
  // Componentes del html
  @ViewChild('windowCiudadanosForm') windowCiudadanosForm: jqxWindowComponent;
  @ViewChild('elemRibbon') elemRibbon: jqxRibbonComponent;
  @ViewChild('gridDirecciones') gridDirecciones: jqxGridComponent;
  @ViewChild('mapSearch') mapSearch: any;
  inputMapSearch: HTMLInputElement;

  // Funcionalidad
  public static _this: CiudadanosEditComponent;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  //Comprobaciones
  public guardar = false;
  ciudadanoExist = false;
  public ecoIdentificadores = false;
  public ecoCompostaje = false;

  // Centrar mapa y añadir marca
  public map: MapComponent;
  public marker: MapMarker;

  // Tamaño de la pantalla sin contar menu gestion y lateral
  mapHeight;
  mapWidth;

  // Ciudadano editandose
  public ciudadano: CiudadanoModel;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private ciudadanoService: CiudadanosService,
    private ssoService: SsoService,
  ){
    super();
    CiudadanosEditComponent._this = this;
  }

  ngOnInit(): void {
    let ticketSso: TicketModel = JSON.parse(sessionStorage.getItem('ticketSSO'));

    // Comprueba si tiene el modulo ecoidentificadores
    ticketSso.Modulos.forEach(element => {
      if(element.Id === 4){
        this.ecoIdentificadores = true;
      }
    });

    // Comprueba si tiene la aplicacion de ecoCompostaje
    if(ticketSso.Aplicacion.Id == 4){
      this.ecoCompostaje = true;
    }

    // Instancia el mapa
    this.map = MainComponent.getInstance().getMap();

    // Obtiene alto y ancho de pantalla sin contar menu inferior y lateral
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;

    // Obtiene idioma
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));

    this.guardar = false;
  }

  public async init(componentRef: any, ciudadano: CiudadanoModel) {
    // Inicializa el componente
    this.componentRef = componentRef;

    // Obtiene el ciudadano para modificar
    this.ciudadano = ciudadano ? ciudadano : new CiudadanoModel();

    // Si tiene ciudadano establece el valor para el campo de validacion
    if(ciudadano){
      /*
        Los valores finales seran 14, 15 y 16,
        se usan 0, 1 y 2 porque se han introducido datos a mano
      */
      switch(ciudadano.estadoValidacion){
        case 14 || 0:
          ciudadano.validadoTexto = this.translate('Pendiente');
          break;
        case 15 || 1:
          ciudadano.validadoTexto = this.translate('Aceptado');
          break;
        case 16 || 2:
          ciudadano.validadoTexto = this.translate('Rechazado');
          break;
        default:
          break;
      }

      // Inicia el array de tarjetas, si no existe
      if(!this.ciudadano.tarjetas){
        this.ciudadano.tarjetas = [];
      }
    }
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.windowCiudadanosForm);

    this.elemRibbon.selectAt(1);
    this.elemRibbon.selectAt(2);
    this.elemRibbon.selectAt(0);

    setTimeout(()=> {
      this.inputMapSearch = this.mapSearch.nativeElement.children[0].children[0].children[0].children[0];
      if(this.ciudadano?.direccion != null){
        let value = '';

        value += this.ciudadano.direccion ? this.ciudadano.direccion : '';
        value += this.ciudadano.numero ? value != '' ? ', ' + this.ciudadano.numero : this.ciudadano.numero : '';
        value += this.ciudadano.municipio ? value != '' ? ', ' + this.ciudadano.municipio : this.ciudadano.municipio : '';
        value += this.ciudadano.provincia ? value != '' ? ', ' + this.ciudadano.provincia : this.ciudadano.provincia : '';
        value += this.ciudadano.comunidadAutonoma ? value != '' ? ', ' + this.ciudadano.comunidadAutonoma : this.ciudadano.comunidadAutonoma : '';

        this.inputMapSearch.value = value;
      }
    }, 500)
  }

  ngAfterViewChecked(){
    this.windowCiudadanosForm.elementRef.nativeElement.style.zIndex = 999;
  }

  // Elimina el marcador del mapa
  removeMarker() {
    if(this.marker){
      this.map.removeMarker(this.marker);
    }
  }

  public onClose() {
    this.removeMarker();
    CiudadanosComponent._this.getCiudadanos();
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  /*
    Comprueba el email del ciudadano,
    si existe se muestra un mensaje de error
  */
  async checkCiudadano(event: any) {
    if(await this.ciudadanoService.checkCiudadanoExist(event.target.value)) {
      this.ciudadanoExist = true;
    }else {
      this.ciudadanoExist = false;
    }
  }

  onMapSearch(event: any) {
    this.ciudadano.direccion = event.place.Street;
    this.ciudadano.codigoPostal = event.place.PostalCode;
    this.ciudadano.poblacion = event.place.District;
    this.ciudadano.municipio = event.place.Locality;
    this.ciudadano.provincia = event.place.AdministrativeArea1;
    this.ciudadano.comunidadAutonoma = event.place.AdministrativeArea2;
    this.ciudadano.numero = event.place.StreetNumber;

    let value = '';

    value += this.ciudadano.direccion ? this.ciudadano.direccion : '';
    value += this.ciudadano.numero ? value != '' ? ', ' + this.ciudadano.numero : this.ciudadano.numero : '';
    value += this.ciudadano.municipio ? value != '' ? ', ' + this.ciudadano.municipio : this.ciudadano.municipio : '';
    value += this.ciudadano.provincia ? value != '' ? ', ' + this.ciudadano.provincia : this.ciudadano.provincia : '';
    value += this.ciudadano.comunidadAutonoma ? value != '' ? ', ' + this.ciudadano.comunidadAutonoma : this.ciudadano.comunidadAutonoma : '';

    this.inputMapSearch.value = value;
  }

  async onGuardar(event: any): Promise<void> {
    if(!this.ciudadanoExist) {
      let ciudadano = null;
      if(ciudadano = await this.ciudadanoService.saveCiudadano(this.ciudadano)) {
        this.guardar = true;
        let tClose = setTimeout(() => {
          clearTimeout(tClose);
          this.removeMarker();
          this.ciudadano = ciudadano;
          CiudadanosComponent._this.getCiudadanos();
          this.windowCiudadanosForm.close();

          MainComponent.getInstance().showInfo(
            'ATENCION',
            'Registro_almacenado',
            2000
          );
        }, 300);
      }else {
        MainComponent.getInstance().showError(
          'ATENCION',
          'Fallo_almacenar_info',
          2000
        );
      }
    }else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Revisa_campos_formulario',
        2000
      );
    }
  }
}
