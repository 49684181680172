<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form)" [width]="760" [height]="402" [zIndex]="90"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Subflotas')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <div style="float:left; width: 100%; height: 325px;">
            <jqxGrid #grid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [source]="dataAdapterSubflotas"
                [columnsresize]="true" [filterable]="true" [showfilterrow]="true" [columns]="columnsSubflotas"
                [sortable]="true" [altrows]="true" [filtermode]="'excel'" [showrowlines]="false" [filterrowheight]="30"
                [rowsheight]="20" [columnsheight]="20" [enabletooltips]="true" [showaggregates]="true"
                [showstatusbar]="true" [statusbarheight]="20" [localization]="langGrid"
                (onRowclick)="onRowSubflotaSelect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>
        </div>
        <div style="float: left; margin-top: 8px;">
            <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]="'imageBeforeText'"
                [textPosition]="'center'" [imgSrc]='"/assets/images/mas.png"' (onClick)="onCrearSubflotaClick($event)"
                [value]="translate('Crear')" [hidden]="!canEdit">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
                [value]="translate('Editar')" (onClick)="onEditarSubflotaClick($event)">
            </jqxButton>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]="'imageBeforeText'" [textPosition]="'center'" [imgSrc]='"/assets/images/borrar.png"'
                [value]="translate('Borrar')" (onClick)="onBorrarSubflotaClick($event)" [hidden]="!canEdit">
            </jqxButton>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditForm">
    <jqxWindow #formEdit style="overflow: hidden;" [theme]="environment.tema" [position]="getFormPos(formEdit)" [width]="950"
        [height]="490" [zIndex]="100" [autoOpen]="true" [isModal]="false" [showCloseButton]="true"
        [showCollapseButton]="true" [resizable]="false" (onClose)="onCloseEdit()" (onOpen)="onOpenEditSubflota()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_subflotas')}}
        </div>
        <div class="form" style="float:left; overflow: hidden;">
            <div style="float: left;  width: 100%;">
                <div style="margin-top: 5px; float: left;">
                    {{translate('Nombre')}}<br>
                    <jqxInput #ebNombre [(ngModel)]="subflotaEdit.Nombre" [width]="450" [height]="25"
                        [disabled]="!canEdit">
                    </jqxInput>
                </div>
                <div style="margin-top: 5px; margin-left: 34px; float:left;">
                    {{translate('Observaciones')}}<br>
                    <jqxInput [(ngModel)]="subflotaEdit.Observaciones" [width]="455" [height]="25"
                        [disabled]="!canEdit">
                    </jqxInput>
                </div>
            </div>
            <div style="float: left; margin-top: 5px; clear: both;">
                <div style="float: left; width: 450px; height: 340px;">
                    <div style="float: none; padding-top: 4px;">{{translate('Resto_flota')}}</div>
                    <jqxGrid #gridMoviles1 [theme]="environment.tema" [height]="'100%'" [width]="'100%'"
                        [source]="dataAdapterMoviles1" [columnsresize]="true" [sortable]="true" [showfilterrow]="true"
                        [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true"
                        [showrowlines]="false" [columns]="columnsMoviles1" [altrows]="true" [enabletooltips]="true"
                        [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true" [filterrowheight]="30"
                        [rowsheight]="20" [columnsheight]="20" [showaggregates]="true" [showstatusbar]="true"
                        [statusbarheight]="20" [localization]="langGrid" [disabled]="!canEdit">
                    </jqxGrid>
                </div>
                <div style="float: left; margin-left: 6px; margin-top: 150px;">
                    <jqxButton style="float:left; clear: both;" [width]='25' [height]='26'
                        [textImageRelation]='"imageAfterText"' [textPosition]='"left"' [imgPosition]='"right"'
                        [imgSrc]='"/assets/images/derecha.png"' (onClick)="onMasClick($event)" [disabled]="!canEdit">
                    </jqxButton>
                    <jqxButton style="float:left; margin-top: 5px; clear: both;" [width]='25' [height]='26'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"right"' [imgPosition]='"left"'
                        [imgSrc]='"/assets/images/izquierda.png"' (onClick)="onMenosClick($event)"
                        [disabled]="!canEdit">
                    </jqxButton>
                </div>
                <div style="float: left; margin-left: 4px; width: 450px; height: 340px;">
                    <div style="float: none; padding-top: 4px;">{{translate('Subflota')}} {{subflotaEdit.Nombre}}</div>
                    <jqxGrid #gridMoviles2 [theme]="environment.tema" [height]="'100%'" [width]="'100%'"
                        [source]="dataAdapterMoviles2" [columnsresize]="true" [sortable]="true" [showfilterrow]="true"
                        [selectionmode]="'checkbox'" [showrowlines]="false" [showcolumnlines]="true"
                        [showrowlines]="false" [columns]="columnsMoviles2" [altrows]="true" [enabletooltips]="true"
                        [filterable]="true" [filtermode]="'excel'" [autoshowfiltericon]="true" [filterrowheight]="30"
                        [rowsheight]="20" [columnsheight]="20" [showaggregates]="true" [showstatusbar]="true"
                        [statusbarheight]="20" [localization]="langGrid" [disabled]="!canEdit">
                    </jqxGrid>
                </div>
            </div>
            <div style="position:absolute; bottom:5px; left:5px; margin-top: 15px; clear: both;">
                <jqxButton style="float:left;" [width]='100' [height]='26' [textImageRelation]="'imageBeforeText'"
                    [textPosition]="'center'" [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardarClick($event)"
                    [value]="translate('Guardar')" [disabled]="!canEdit">
                </jqxButton>
            </div>
        </div>
    </jqxWindow>
</div>
