import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';

import { jqxDropDownListModule } from 'jqwidgets-ng/jqxdropdownlist';
import { jqxDateTimeInputModule } from 'jqwidgets-ng/jqxdatetimeinput';

import { PeriodoSelectComponent } from './periodo-select.component';

@NgModule({
  declarations: [
    PeriodoSelectComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    BrowserModule,
    jqxDropDownListModule,
    jqxDateTimeInputModule
  ],
  exports: [
    PeriodoSelectComponent
  ]
})
export class PeriodoSelectModule { }
