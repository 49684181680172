import { Component, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { AppComponent } from 'src/app/app.component';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { environment } from 'src/environments/environment';
import { CustomForms } from '../forms/custom-forms';
import { ConfiguracionCerraduraModel } from 'src/app/services/cerraduras/models/configuracionCerradura.model';
import { ConfiguracionCerraduraService } from 'src/app/services/cerraduras/configuracion-cerradura.service';
import { MainComponent } from '../main/main.component';
import { ConfiguracionCerradurasEditComponent } from './configuracion-cerraduras-edit/configuracion-cerraduras-edit.component';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ConfiguracionCerraduraAsignarComponent } from './configuracion-cerradura-asignar/configuracion-cerradura-asignar.component';
import { SsoService } from 'src/app/services/sso/sso.service';

@Component({
  selector: 'app-configuracion-cerraduras',
  templateUrl: './configuracion-cerraduras.component.html',
  styleUrls: ['./configuracion-cerraduras.component.css']
})
export class ConfiguracionCerradurasComponent extends CustomForms implements OnInit {
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('gridConfiguraciones') gridConfiguraciones: jqxGridComponent;
  @ViewChild('formConfiguracion', { read: ViewContainerRef }) editConfiguracionComponent;
  @ViewChild('asignarConfiguracion', { read: ViewContainerRef }) configuracionCerraduraAsignarComponent;

  static _this: any;
  private componentRef = null;
  public environment = environment;
  public langGrid = JqWidgets.getLocalization('es');

  formConfiguracion: any;
  asignarConfiguracion: any;
  private rowIndex = -1;
  configuraciones: ConfiguracionCerraduraModel[] = [];
  public configuracionSelect: ConfiguracionCerraduraModel;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(
    private configuracionCerraduraService: ConfiguracionCerraduraService,
    private modal: NzModalService,
    private ssoService: SsoService
  ) {
    super();
    ConfiguracionCerradurasComponent._this = this;
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.initGrid();
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(this.translate('Configuracion_cerraduras'));
    await this.getConfiguraciones();
  }

  public init(componentRef: any) {
    this.componentRef = componentRef;
  }

  onClose() {
    this.componentRef.destroy();
  }

  async getConfiguraciones() {
    this.configuraciones = await this.configuracionCerraduraService.getConfiguracionesCerradua();

    if (!this.configuraciones) {
      this.configuraciones = [];
    }

    this.sourceGridConfiguracion.localdata = this.configuraciones;
    this.adapterGridConfiguracion = new jqx.dataAdapter(this.sourceGridConfiguracion);

    const t = setTimeout(() => {
      clearTimeout(t);
      this.gridConfiguraciones.autoresizecolumns();
    }, 500);
  }

  /* Grid configuraciones */
  public columnsGridConfiguracion;
  public sourceGridConfiguracion: any;
  public adapterGridConfiguracion;
  initGrid() {
    /* Renderiza las columnas de la tabla para mostrar un texto leible al usuario */
    let renderTipoContenedorColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      switch (value) {
        case 0:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + this.translate("Contenedor_tipo_apertura_vaciado") + '</div>';
          break;
        case 1:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + this.translate("Contenedor_tipo_comparte_trampilla") + '</div>';
          break;
        case 2:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + this.translate('Contenedor_tipo_soterrado') + '</div>';
          break;
        case 3:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + this.translate('Papelera') + '</div>';
          break;
        default:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;"></div>';
          break;
      }
    };

    let renderTipoCerraduraColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      switch (value) {
        case 0:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + AppComponent.translate("No_equipada") + '</div>';
          break;
        case 1:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + AppComponent.translate("Cerradura_welock") + '</div>';
          break;
        default:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;"></div>';
          break;
      }
    }

    let renderComunicacionesColumn = (row: number, columnfield: string, value: string | number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      switch (value) {
        case 0:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">NB IoT</div>';
          break;
        case 1:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">LTE Cat M</div>';
          break;
        case 2:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">GPRS 2G</div>';
          break;
        default:
          return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;"></div>';
          break;
      }
    }

    let renderHoraEnvioColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      let horas = Math.floor(value / 60);
      let minutos = Math.floor(value % 60);
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + horas + ':' + minutos + '</div>';
    }

    let renderBateriaBajaColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + value / 100 + '</div>';
    }

    let renderBateriaCriticaColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + value / 100 + '</div>';
    }

    let renderTemperaturaColumn = (row: number, columnfield: string, value: number, defaulthtml: string, columnproperties: any, rowdata: any): string => {
      return '<div class="jqx-grid-cell-left-align" style="margin-top: 4px;">' + value / 10 + '</div>';
    }
    /* FIN RENDERIZADO */

    this.columnsGridConfiguracion = [
      { text: this.translate('Descripcion_breve'), columntype: 'textbox', datafield: 'nombre', width: 350 },
      { text: this.translate('Servidor'), columntype: 'textbox', datafield: 'host', width: 150 },
      { text: this.translate('Puerto'), columntype: 'textbox', datafield: 'puerto', width: 50 },
      { text: this.translate('Tipo_contenedor'), columntype: 'textbox', datafield: 'tipoContenedor', width: 150, cellsrenderer: renderTipoContenedorColumn },
      { text: this.translate('Tipo_cerradura'), columntype: 'textbox', datafield: 'tipoCerradura', width: 150, cellsrenderer: renderTipoCerraduraColumn },
      { text: 'APN', columntype: 'textbox', datafield: 'apn', width: 150 },
      { text: this.translate('USUARIO'), columntype: 'textbox', datafield: 'usuario', width: 150 },
      { text: this.translate('PASSWORD'), columntype: 'textbox', datafield: 'password', width: 150 },
      { text: 'PIN', columntype: 'textbox', datafield: 'pin', width: 50 },
      { text: this.translate('Comunicaciones'), columntype: 'textbox', datafield: 'tecnologia', width: 150, cellsrenderer: renderComunicacionesColumn },
      { text: this.translate('Hora_envio'), columntype: 'textbox', datafield: 'horaEnvio', width: 150, cellsrenderer: renderHoraEnvioColumn },
      { text: this.translate('Intervalo_envio') + '(min.)', columntype: 'textbox', datafield: 'intervaloEnvio', width: 150 },
      { text: this.translate('Tiempo_apertura') + '(seg.)', columntype: 'textbox', datafield: 'tiempoApertura', width: 150 },
      { text: this.translate('Tiempo_aporte') + '(seg.)', columntype: 'textbox', datafield: 'tiempoAporte', width: 150 },
      { text: this.translate('Tiempo_cierre') + '(seg.)', columntype: 'textbox', datafield: 'tiempoCierre', width: 150 },
      { text: this.translate('Alarma_bateria_baja') + '(volt)', columntype: 'textbox', datafield: 'bateriaBaja', width: 150, cellsrenderer: renderBateriaBajaColumn },
      { text: this.translate('Alarma_bateria_critica') + '(volt)', columntype: 'textbox', datafield: 'bateriaCritica', width: 150, cellsrenderer: renderBateriaCriticaColumn },
      { text: this.translate('Alarma_temperatura') + '(ºC)', columntype: 'textbox', datafield: 'temperatura', width: 170, cellsrenderer: renderTemperaturaColumn },
    ];

    this.sourceGridConfiguracion = {
      datatype: 'json',
      datafields: [
        { name: 'nombre', type: 'string', map: 'nombre' },
        { name: 'host', type: 'string', map: 'host' },
        { name: 'puerto', type: 'number', map: 'puerto' },
        { name: 'tipoContenedor', type: 'number', map: 'tipoContenedor' },
        { name: 'tipoCerradura', type: 'number', map: 'tipoCerradura' },
        { name: 'apn', type: 'string', map: 'apn' },
        { name: 'usuario', type: 'string', map: 'apnUsuario' },
        { name: 'password', type: 'string', map: 'apnPassw' },
        { name: 'pin', type: 'number', map: 'pin' },
        { name: 'tecnologia', type: 'number', map: 'tecnologia' },
        { name: 'horaEnvio', type: 'number', map: 'horaEnvio' },
        { name: 'intervaloEnvio', type: 'number', map: 'intervaloEnvio' },
        { name: 'tiempoApertura', type: 'number', map: 'tiempoAbrir' },
        { name: 'tiempoAporte', type: 'number', map: 'tiempoAporte' },
        { name: 'tiempoCierre', type: 'number', map: 'tiempoCerrar' },
        { name: 'bateriaBaja', type: 'number', map: 'bateriaBaja' },
        { name: 'bateriaCritica', type: 'number', map: 'bateriaCritica' },
        { name: 'temperatura', type: 'number', map: 'temperatura' },
      ],
      localdata: this.configuraciones
    };
  }


  /* Acciones del grid */
  onRowClick(event: any) {
    if (this.rowIndex != event.args.rowindex) {
      this.rowIndex = event.args.rowindex;
      this.configuracionSelect = this.configuraciones[event.args.rowindex];
      this.gridConfiguraciones.selectrow(this.rowIndex);
    } else if (this.rowIndex === event.args.rowindex) {
      this.gridConfiguraciones.unselectrow(this.rowIndex);
      this.rowIndex = null;
      this.configuracionSelect = null;
    }
  }

  onRowdoubleclick(event: any) {
    this.rowIndex = event.args.rowindex;
    this.configuracionSelect = this.configuraciones[event.args.rowindex];
    this.gridConfiguraciones.selectrow(this.rowIndex);

    this.onEditarConfiguracion(event);
  }

  onRowSelect(event: any) {
    this.rowIndex = event.args.rowindex;
    this.configuracionSelect = this.configuraciones[event.args.rowindex];

    if (this.gridConfiguraciones.getselectedrowindexes().length > 1) {
      this.gridConfiguraciones.clearselection();
      this.gridConfiguraciones.selectrow(event.args.rowindex);
    }
  }

  onRowUnselect(event: any) {
    this.rowIndex = null;
    this.configuracionSelect = null;
  }

  /* Acciones de los botones inferiores */
  public onCrearConfiguracion(event: any) {
    this.form.collapse();

    this.formConfiguracion = this.editConfiguracionComponent.createComponent(ConfiguracionCerradurasEditComponent);
    this.formConfiguracion.instance.init(this.formConfiguracion);
  }

  public onEditarConfiguracion(event: any) {
    if (this.configuracionSelect) {
      this.form.collapse();

      this.formConfiguracion = this.editConfiguracionComponent.createComponent(ConfiguracionCerradurasEditComponent);
      this.formConfiguracion.instance.init(this.formConfiguracion, this.configuracionSelect);
    }else{
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  /* Muestra la confirmación del borrado */
  public onBorrarConfiguracion(event: any) {
    if (this.configuracionSelect) {
      this.modal.confirm({
        nzTitle: '<i>' + this.translate('ATENCION') + '</i>',
        nzContent: this.translate('Quiere_borrar_configuracion'),
        nzCentered: true,
        nzCancelText: this.translate('CANCELAR'),
        nzOkText: this.translate('SI'),
        nzOnOk: async () => {
          this.removeConfiguracion();
          this.modal.closeAll();
        }
      });
    }else{
      MainComponent.getInstance().showWarning('ATENCION', 'Seleccione_registro', 2000);
    }
  }

  /* Metodo que se llama al confirmar el borrado */
  async removeConfiguracion() {
    if (await this.configuracionCerraduraService.deleteConfiguracionCerradura(this.configuracionSelect.id)) {
      this.configuraciones.splice(this.configuraciones.indexOf(this.configuracionSelect), 1);

      this.adapterGridConfiguracion = new jqx.dataAdapter(this.sourceGridConfiguracion);
      this.rowIndex = null;
      this.configuracionSelect = null;

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_borrado',
        2000
      );
    } else {
      MainComponent.getInstance().showWarning(
        'Error',
        'Error_api',
        2000
      );
    }
  }

  public onAsignarConfiguracion() {
    this.form.collapse();

    this.asignarConfiguracion = this.configuracionCerraduraAsignarComponent.createComponent(ConfiguracionCerraduraAsignarComponent);
    this.asignarConfiguracion.instance.init(this.asignarConfiguracion, this.configuraciones);
  }
}
