<jqxWindow #windowForm [theme]="environment.tema" [position]="getFormPos(windowForm)" [width]="1000" [height]="500" [zIndex]="99"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Vehiculo") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #elemRibbon [theme]="environment.tema" [position]="'top'">
      <ul style="width: 100%; text-align: left">
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Datos_vehiculo") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("GLS_perifericos") }}
        </li>
        <li style="height: 26px; vertical-align: middle">
          {{ translate("Conjunto_vehiculo") }}
        </li>
      </ul>

      <div style="height: 100%;">
        <div class="row tab" style="
        overflow: hidden;
        background-color: transparent;
        margin-top: 5px;">
          <div class="rowC">
            <fieldset>
              <legend>
                {{ translate("Informacion_basica") }}
              </legend>
              <div class="row">
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("CODIGO") }}
                  </div>
                  <jqxInput [(ngModel)]="movil.Codigo" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Nombre") }}
                  </div>
                  <jqxInput [(ngModel)]="movil.Nombre" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Numero_calca") }}
                  </div>
                  <jqxInput [(ngModel)]="movil.numeroCalca" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Matricula") }}
                  </div>
                  <jqxInput [(ngModel)]="movil.Matricula" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Tipo_vehiculo") }}
                  </div>
                  <jqxInput [ngModel]="movil.TipoMovil?.Nombre" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                {{ translate("Disponibilidad_ubicacion") }}
              </legend>
              <div class="row">
                <span style="width: 15%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Disponible") }}
                  </div>
                  <div class="row">
                    <span style="width: 50%;">
                      <jqxRadioButton [checked]="true" [width]="'100%'" [height]="25" [groupName]="'disponible'" (onChecked)="onChangeDisponible(true)">
                        {{translate('SI')}}
                      </jqxRadioButton>
                    </span>

                    <span style="width: 50%;">
                      <jqxRadioButton [width]="'100%'" [height]="25" [groupName]="'disponible'" (onChecked)="onChangeDisponible(false)">
                        {{translate('NO')}}
                      </jqxRadioButton>
                    </span>
                  </div>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Estado_vehiculo") }}
                  </div>
                  <jqxDropDownList [(ngModel)]="movil.estadoVehiculo" [width]="'100%'" [dropDownHeight]="100"
                  [source]="sourceEstadoVehiculo" [disabled]="movil.disponible">
                  </jqxDropDownList>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Base") }}
                  </div>
                  <jqxInput [(ngModel)]="movil.base" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>

                <span style="width: 45%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Domicilio_flota") }}
                  </div>
                  <jqxInput [(ngModel)]="movil.domicilioFlota" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
              </div>
            </fieldset>

            <fieldset>
              <legend>
                {{ translate("Otros") }}
              </legend>
              <div class="row">
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Turno_trabajo") }}
                  </div>
                  <jqxDropDownButton #dropDownTurnoTrabajo [width]="'100%'" (onClose)="onCloseDropTurnoTrabajo($event)"
                    (onOpen)="onOpenDropTurnoTrabajo($event)">
                    <div [hidden]="gridDropTurnoTrabajo" style="width: 100%;">
                      <jqxGrid #gridTurnoTrabajo [height]="150" [source]="sourceTurnoTrabajo" [columnsresize]="true"
                        [selectionmode]="'checkbox'" [columns]="columnsTurnoTrabajo" [sortable]="true"
                        [altrows]="true" [showrowlines]="true" [rowsheight]="20" [columnsheight]="20"
                        [enabletooltips]="true" [localization]="langGrid" [showheader]="false"
                        (onRowselect)="onRowSelectTurnoTrabajo($event)" (onRowunselect)="onRowUnselectTurnoTrabajo($event)">
                      </jqxGrid>
                    </div>
                  </jqxDropDownButton>
                </span>

                <span style="width: 60%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Descripcion") }}
                  </div>
                  <jqxTextArea [(ngModel)]="movil.descripcion" [width]="'100%'" [height]="150" [ngModelOptions]="{standalone: true}">
                  </jqxTextArea>
                </span>

                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Icono_vehiculo") }}
                  </div>
                  <img #imgPrincipal *ngIf="false"
                    src="data:image/png;base64" style="width: 100%; height: 100%;">
                </span>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="row tab" style="
        overflow: hidden;
        background-color: transparent;
        margin-top: 5px;">
          <div class="rowC">
            <fieldset>
              <legend>GLS</legend>

              <div class="row">
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Producto") }}
                  </div>
                  <jqxInput [ngModel]="movil.gls?.producto" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Num_serie") }}
                  </div>
                  <jqxInput [ngModel]="movil.gls?.numeroSerie" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
              </div>

              <div class="row">
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    IMEI
                  </div>
                  <jqxInput [ngModel]="movil.gls?.imei" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    SIM
                  </div>
                  <jqxInput [ngModel]="movil.gls?.sim" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
              </div>
            </fieldset>

            <fieldset>
              <legend>{{ translate('Perifericos') }}</legend>

              <div class="row">
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Producto") }}
                  </div>
                  <jqxInput [ngModel]="movil.periferico?.producto" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
                <span style="width: 20%; clear: both;">
                  <div style="margin-top: 5px;">
                    {{ translate("Num_serie") }}
                  </div>
                  <jqxInput [ngModel]="movil.periferico?.numeroSerie" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                  </jqxInput>
                </span>
              </div>
            </fieldset>
          </div>
        </div>

        <div class="row tab" style="
        overflow: hidden;
        background-color: transparent;
        margin-top: 5px;">
          <jqxRibbon style="overflow: hidden" [theme]="environment.tema" [width]="'100%'" [height]="'100%'"
          [position]="'top'">
          <ul style="width: 100%; text-align: left">
            <li style="height: 26px; vertical-align: middle">
              {{ translate("Chasis_carrozado") }}
            </li>
            <li style="height: 26px; vertical-align: middle">
              {{ translate("Conjunto_vehiculo") }}
            </li>
          </ul>

          <div style="height: 100%;">
            <div class="row tab" style="
            overflow: hidden;
            background-color: transparent;
            margin-top: 40px;">
              <div class="rowC">
                <fieldset>
                  <legend>{{ translate("Chasis") }}</legend>

                  <div class="row">
                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Marca") }}
                      </div>
                      <jqxInput [ngModel]="movil.ConjuntoVehiculo?.Chasis?.Marca" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>

                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Modelo") }}
                      </div>
                      <jqxInput [ngModel]="movil.ConjuntoVehiculo?.Chasis?.Modelo" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>

                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Combustible") }}
                      </div>
                      <jqxInput [ngModel]="movil.ConjuntoVehiculo?.Chasis?.Combustible" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>
                  </div>

                  <div class="row">
                    <span style="width: 15%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Num_personas")}}
                      </div>
                      <app-input-number [value]="movil.ConjuntoVehiculo?.Chasis?.NumPersonas" [render]="renderer()" [width]="'100%'">
                      </app-input-number>
                    </span>

                    <span style="width: 15%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Num_operarios")}}
                      </div>
                      <app-input-number [value]="movil.ConjuntoVehiculo?.Chasis?.NumOperarios" [render]="renderer()" [width]="'100%'">
                      </app-input-number>
                    </span>

                    <span style="width: 20%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Carga_util") }} (Kg)
                      </div>
                      <app-input-number [value]="movil.ConjuntoVehiculo?.Chasis?.CargaMaxima" [render]="renderer()" [width]="'100%'">
                      </app-input-number>
                    </span>

                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Tipo_canbus")}}
                      </div>
                      <jqxInput [ngModel]="movil.ConjuntoVehiculo?.Chasis?.TipoCanbus" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>

                  </div>
                </fieldset>
                <fieldset>
                  <legend>
                    {{ translate("Carrozado") }}
                  </legend>
                  <div *ngFor="let carrozado of movil.ConjuntoVehiculo.Carrozados" class="row">
                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Marca") }}
                      </div>
                      <jqxInput [ngModel]="carrozado.Marca" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>

                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Modelo") }}
                      </div>
                      <jqxInput [ngModel]="carrozado.Modelo" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>

                    <span style="width: 25%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Tipo_canbus")}}
                      </div>
                      <jqxInput [ngModel]="carrozado.TipoCanbus" [width]="'100%'" [ngModelOptions]="{standalone: true}">
                      </jqxInput>
                    </span>
                  </div>

                  <div class="row">
                    <span style="width: 75%; clear: both;">
                      <div style="margin-top: 5px;">
                        {{ translate("Residuos") }}
                      </div>
                    </span>
                  </div>
                </fieldset>
              </div>
            </div>

            <div class="row tab" style="
            overflow: hidden;
            background-color: transparent;
            margin-top: 40px;">
              <div class="row">
                <span style="width: 35%;">
                  <div class="rowC">
                    <fieldset>
                      <legend>{{ translate("Informacion") }}</legend>

                      <div class="row">
                        <span style="width: 33%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Ancho") }} (mm)
                            <app-input-number [value]="movil.ConjuntoVehiculo?.Ancho" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 33%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Alto") }} (mm)
                            <app-input-number [value]="movil.ConjuntoVehiculo?.Alto" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 33%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Largo") }} (mm)
                            <app-input-number [value]="movil.ConjuntoVehiculo?.Largo" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                      </div>

                      <div class="row">
                        <span style="width: 60%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Carga_maxima_neta") }} (Kg)
                            <app-input-number [value]="movil.ConjuntoVehiculo?.CargaMaximaNeta" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 40%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Volumen_m3") }}
                            <app-input-number [value]="movil.ConjuntoVehiculo?.Volumen" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                      </div>

                      <div class="row">
                        <span style="width: 28%; clear: both;">
                          <div style="margin-top: 5px;">
                            Km/Co²
                            <app-input-number [value]="movil.ConjuntoVehiculo?.Kmco2" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 33%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Coste") }} €/Km
                            <app-input-number [value]="movil.ConjuntoVehiculo?.Coste" [render]="renderer()" [width]="'100%'" [decimal]="2">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 39%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Precio_compra") }}
                            <app-input-number [value]="movil.ConjuntoVehiculo?.PrecioCompra" [render]="renderer()" [width]="'100%'" [decimal]="2">
                            </app-input-number>
                          </div>
                        </span>
                      </div>

                      <div class="row">
                        <span style="width: 50%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Años_amortizacion") }}
                            <app-input-number [value]="movil.ConjuntoVehiculo?.AnyoAmortizacion" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                        <span style="width: 50%; clear: both;">
                          <div style="margin-top: 5px;">
                            {{ translate("Año_matriculacion") }}
                            <app-input-number [value]="movil.ConjuntoVehiculo?.AnyoMatriculacion" [render]="renderer()" [width]="'100%'">
                            </app-input-number>
                          </div>
                        </span>
                      </div>
                    </fieldset>
                  </div>
                </span>
                <span style="width: 65%;">
                  <div class="rowC">
                    <fieldset>
                      <legend>{{ translate("Imagenes") }}</legend>

                    </fieldset>
                  </div>
                </span>
              </div>
            </div>
          </div>
          </jqxRibbon>
        </div>
      </div>
    </jqxRibbon>

    <div style="
        position: absolute;
        bottom: 5px;
        left: 0px;
        margin-top: 23px;
        clear: both;
      ">

      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
         [value]="translate('Guardar')">
      </jqxButton>
      <jqxButton style="float: left; margin-left: 5px" [width]="100" [height]="26"
        [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/localizar.png'"
        [value]="translate('Centrar')">
      </jqxButton>
    </div>
  </div>
</jqxWindow>
