<jqxWindow #form [theme]="theme" [position]="getFormPos(form)" [zIndex]="90" [width]="720" [height]="360"
    [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Acciones')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden; ">

        <div style="float: left; margin-top: 6px; display: flex; align-items: flex-end;">
            <app-periodo-select #periodoSelect [noTrimestre]="true" [noSemestre]="true" [noLibre]="true"
                [height]="24"></app-periodo-select>
            <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/search.png"'
                [value]="translate('Buscar')" (onClick)="onSearch($event)">
            </jqxButton>
        </div>

        <div style="float:left; margin-top: 4px; width: 100%;">
            <jqxGrid #myGrid [theme]="theme" [width]="'100%'" [height]="280" [source]="dataAdapter"
                [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
                [columns]="columns" [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true"
                [altrows]="true" [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="20"
                [columnsheight]="20" [enabletooltips]="true" [editable]="false" (onRowclick)="onRowclick($event)">
            </jqxGrid>
        </div>
    </div>
</jqxWindow>