import { Component, OnInit, ViewChild } from '@angular/core';

import * as xlsx from 'xlsx';
import { DateUtils } from 'src/app/utils/date-utils';
import { AppComponent } from 'src/app/app.component';
import { NumberUtils } from 'src/app/utils/number-utils';
import { environment } from 'src/environments/environment';
import { CustomForms } from 'src/app/components/forms/custom-forms';

import { JqWidgets } from 'src/app/utils/jqWidgets';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';

import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';

import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { AlarmaCerraduraModel } from 'src/app/services/cerraduras/models/alarmaCerradura';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';

import { ModeloSelectComponent } from 'src/app/components/modelo-select/modelo-select.component';
import { SsoService } from 'src/app/services/sso/sso.service';
import { HeaderComponent } from 'src/app/components/header/header.component';

@Component({
  selector: 'app-listado-alarma',
  templateUrl: './listado-alarma.component.html',
  styleUrls: ['./listado-alarma.component.css']
})
export class ListadoAlarmaComponent extends CustomForms implements OnInit {
  private componentRef = null;
  public theme = environment.tema;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('grid') myGrid: jqxGridComponent;
  @ViewChild('header') header: HeaderComponent;

  // objects
  elementosSelect: ElementoModel[] = [];
  alarma: AlarmaCerraduraModel = new AlarmaCerraduraModel;
  alarmas: AlarmaCerraduraModel[] = [];
  dateFin = new Date();
  dateInicio = new Date();
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();

  // grid
  dataAdapter: any;
  columns;
  source: any;
  public langGrid = JqWidgets.getLocalization('es');
  editrow: number = -1;

  mapHeight;
  mapWidth;

  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  constructor(private ssoService: SsoService,
    private cerraduraService: CerraduraService) {
    super();
  }

  ngOnInit(): void {
    this.langGrid = JqWidgets.getLocalization(this.ssoService.getTicket().Usuario.Idioma.Codigo.substring(0, 2));
    this.getAlarmasByCerraduras();

    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
  }

  /**Inicializa el componente
  */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  async ngAfterViewInit(): Promise<void> {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Historico_alarmas_IoT'));

    const t = setTimeout(() => {
      clearTimeout(t);
      this.onAceptar();
    }, 500);
  }

  tooltiprenderer(element) {
    element.context.parentElement.title = element.text();
  }

  async getAlarmasByCerraduras() {
    this.columns = [
      { text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'date', datafield: this.translate('Fecha') , cellsrenderer: this.renderDate,
        aggregates: [{
          'Total': function (aggregatedValue, currentValue: number) {
            return aggregatedValue + 1;
          }
        }],
        aggregatesrenderer: function (aggregates) {
          let renderstring = '';
          if (aggregates["Total"] !== undefined) {
            renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
              NumberUtils.format(aggregates["Total"], 0) + '</div>';
          }
          return renderstring;
        }
      },
      { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Ns_movisat') },
      { text: this.translate('Alarma'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Alarma') },

      { text: this.translate('Bateria_porcentaje'), columntype: 'textbox', filtertype: 'textbox', width: 70,
        datafield: this.translate('Bateria_porcentaje'), cellsrenderer: this.numberrendererDecimales,
        rendered: this.tooltiprenderer
      },
      { text: this.translate('Temperatura_grados'), columntype: 'textbox', filtertype: 'textbox', width: 70,
        datafield: this.translate('Temperatura_grados'), cellsrenderer: this.numberrendererDecimales,
        rendered: this.tooltiprenderer
      },
      { text: this.translate('Voltaje_vatios'), columntype: 'textbox', filtertype: 'textbox', width: 70,
        datafield: this.translate('Voltaje_vatios'), cellsrenderer: this.numberrendererDecimales,
        rendered: this.tooltiprenderer
      },

      { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Nombre_elemento') },
      { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Marca') },
      { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: this.translate('Modelo') },
      { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Matricula') },
      { text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Observaciones'), sortable: false, filterable: false },

      { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Direccion') },
      { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Municipio') },
      { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: this.translate('Poblacion') },
    ];

    this.source = {
      datatype: 'json',
      dataFields: [
        { name: this.translate('Fecha'), type: 'date', map: 'fecha' },
        { name: this.translate('Ns_movisat'), type: 'string', map: 'nsCerradura' },
        { name: this.translate('Alarma'), type: 'string', map: 'alarma' },

        { name: this.translate('Bateria_porcentaje'), type: 'number', map: 'bateriaPorcentaje' },
        { name: this.translate('Temperatura_grados'), type: 'number', map: 'temperatura' },
        { name: this.translate('Voltaje_vatios'), type: 'number', map: 'voltaje' },

        { name: this.translate('Nombre_elemento'), type: 'string', map: 'nombreElemento' },
        { name: this.translate('Marca'), type: 'string', map: 'marcaElemento' },
        { name: this.translate('Modelo'), type: 'string', map: 'modeloElemento' },
        { name: this.translate('Matricula'), type: 'string', map: 'matriculaElemento' },
        { name: this.translate('Observaciones'), type: 'string', map: 'observacionesElemento' },

        { name: this.translate('Direccion'), type: 'string', map: 'direccionElemento' },
        { name: this.translate('Municipio'), type: 'string', map: 'municipioElemento' },
        { name: this.translate('Poblacion'), type: 'string', map: 'poblacionElemento' },
      ],
      localdata: this.alarmas
    };
    this.dataAdapter = new jqx.dataAdapter(this.source);
  }

  renderDate(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value) {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 8px;">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    } else if (value === 0) {
      return (
        '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">' +
        NumberUtils.format(value, 0) +
        '</div>'
      );
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (value !== null) {
      if (columnfield == AppComponent.translate('Temperatura_grados')) {
        value = value / 10;
        value = NumberUtils.format(value, 2);
      } else if (columnfield == AppComponent.translate('Voltaje_vatios')) {
        value = value / 100;
        value = NumberUtils.format(value, 2);
      } else {
        value = NumberUtils.format(value, 2);
      }
      return '<div style="margin-right: 4px; margin-top: 8px; text-align: right;">'+ value +'</div>';
    } else  {
      return '<div style="margin-right: 4px; margin-top: 8px; text-align: right;"></div>';
    }
  }

  selecElement(listElement: any) {
    this.elementosSelect = listElement;
    if (this.elementosSelect.length <= 0 && this.source) {
      this.alarmas = [];
      this.source.localdata = this.alarmas;
      this.myGrid.updatebounddata();
    }
  }

  onAceptar() {
    this.dateInicio = this.header.periodoSelect.getFechaIni();
    this.dateFin = this.header.periodoSelect.getFechaFin();
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;

    this.searchAlarmas();
  }

  // resetea los filtros
  onResetFilter() {
    this.header.periodoSelect.desde = new Date();
    this.header.periodoSelect.hasta = new Date();
    this.header.periodoSelect.fecha1.value(new Date());
    this.header.periodoSelect.fecha2.value(new Date());
    this.header.periodoSelect.cbPeriodo.selectedIndex(0);

    this.header.searchInput['nativeElement'].value = '';

    this.onAceptar();
  }

  onCollapse(event) {
    ModeloSelectComponent._this.onCollapse();
  }

  onExpand(event) {
    ModeloSelectComponent._this.onExpand();
  }

  async searchAlarmas() {
    this.alarmas = await this.cerraduraService.getAlarmas(this.dateIdentificacion);

    this.alarmas.forEach(element => {
      element.alarma = '';
      if (element.bateria == 1) {
        element.alarma = AppComponent.translate('Alarma_bateria_baja');
      } else if (element.bateria == 2) {
        element.alarma = AppComponent.translate('Alarma_bateria_critica');
      } else if (element.bateria == 3) {
        element.alarma = AppComponent.translate('Cambio_bateria');
      } else if (element.temperaturaElevado == true) {
        element.alarma = AppComponent.translate('Temperatura_elevado');
      } else if (element.puertaAbierta == true) {
        element.alarma = AppComponent.translate('Puerta_abierta');
      } else if (element.vandalismo == true) {
        element.alarma = AppComponent.translate('Vandalismo');
      } else if (element.vaciado == true) {
        element.alarma = AppComponent.translate('Vaciado');
      } else if (element.puertaViolentada == true) {
        element.alarma = AppComponent.translate('Puerta_violentada');
      }
    });

    this.source.localdata = this.alarmas;
    this.source.sortcolumn = this.translate('Fecha');
    this.source.sortdirection = 'desc';
    this.myGrid.updatebounddata(this.alarmas);

    let filtervalue = this.header.searchInput['nativeElement'].value != null ? this.header.searchInput['nativeElement'].value : '';

    if(filtervalue != ''){
      this.myGrid.columns()['records'].forEach(column => {
        if(column.filtertype === 'textbox'){
          let filtergroup = new jqx.filter();
          filtergroup.operator = 'or'
          let filter_or_operator = 1;
          let filtercondition = 'contains';
          let filter1 = filtergroup.createfilter('stringfilter', filtervalue, filtercondition);

          filtergroup.addfilter(filter_or_operator, filter1);
          this.myGrid.addfilter(column.datafield, filtergroup);
          this.myGrid.applyfilters();
        }
      });
    }

    this.resizeColumns();
  }

  onExportar() {
    const json = JSON.parse(JSON.stringify(this.myGrid.getrows()));
    json.forEach(element => {
      delete element.uid;
      delete element.uniqueid;
      delete element.visibleindex;
      delete element.boundindex;

      if(element[this.translate('Fecha')]){
        element[this.translate('Fecha')] = DateUtils.formatDateTimeShort(new Date(element[this.translate('Fecha')]), true)
      }

      element[this.translate('Temperatura_grados')] = element[this.translate('Temperatura_grados')]/10;
      element[this.translate('Temperatura_grados')] = '' + NumberUtils.format(element[this.translate('Temperatura_grados')], 2);

      element[this.translate('Voltaje_vatios')] = element[this.translate('Voltaje_vatios')] / 100;
      element[this.translate('Voltaje_vatios')] = '' + NumberUtils.format(element[this.translate('Voltaje_vatios')], 2);

      element[this.translate('Bateria_porcentaje')] = '' + NumberUtils.format(element[this.translate('Bateria_porcentaje')], 2);
    });
    const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(json);
    const wb: xlsx.WorkBook = xlsx.utils.book_new();
    xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
    xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_InformeAlarmas.xlsx');
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
  }

  resizeColumns() {
    if(this.alarmas.length != 0){
      setTimeout(() => {
        this.myGrid.attrColumns.forEach((column: any) => {
          try{
            if(column.datafield !== this.translate('Temperatura_grados') && column.datafield !== this.translate('Voltaje_vatios') && column.datafield !== this.translate('Bateria_porcentaje')){
              this.myGrid.autoresizecolumn(column.datafield, column.columntype);
            }
          }catch(e){

          }
        })
      }, 300);
    }
  }
}
