<ng-http-loader [backdrop]="false" [backgroundColor]="'#33B2FF'" [debounceDelay]="100" [opacity]=".6"
    [backdropBackgroundColor]="'#777777'" [spinner]="spinkit.skWave">
    procesando</ng-http-loader>
<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form, 0)" [zIndex]="999" [width]="mapWidth"
    [height]="mapHeight" [autoOpen]="true" [resizable]="true" [showCloseButton]="true" [showCollapseButton]="true"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Zonas_cartograficas')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden; ">
        <app-header #header [exportar]="true" [imprimir]="true" [buscador]="true" (eventImprimir)="onPrint($event)"
            (eventExportar)="onExportar($event)">
        </app-header>
        <div style="float:left; width: 100%; height: 100%;">
            <jqxGrid #gridZonas [theme]="environment.tema" [width]="'100%'" [height]="'83%'" [source]="dataAdapter"
                [columnsresize]="true" [localization]="langGrid" [filterable]="true" [columns]="columns"
                [showrowlines]="false" [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true"
                [showstatusbar]="true" [statusbarheight]="20" [showaggregates]="true" [rowsheight]="25"
                [columnsheight]="25" [editable]="false" [groupable]="true" [selectionmode]="'checkbox'"
                (onRowselect)="onChecked($event)" (onRowunselect)="onUnselect($event)" [showemptyrow]="false"
                (onRowclick)="onRowSelect($event)" (onBindingcomplete)="onBindingComplete()"
                (onRowdoubleclick)="onRowdoubleclick($event)">
            </jqxGrid>
            <div style="float: left;margin-top: 5px">
                <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                    [textPosition]='"left"' [imgSrc]='"/assets/images/ver.png"' [value]="translate('Ver')"
                    (onClick)="onVer($event)">
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
                    [value]="translate('Crear')" (onClick)="onCrearZona($event)" [hidden]="!canEdit">
                </jqxButton>
                <jqxButton #btnEdit style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/editar.png"' [value]="translate('Editar')"
                    (onClick)="onEditarZona($event)" [hidden]="!canEdit">
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/borrar.png"' [value]="translate('Borrar')"
                    (onClick)="onBorrarZona($event)" [hidden]="!canEdit">
                </jqxButton>
            </div>
        </div>
    </div>
</jqxWindow>

<div *ngIf="mostrarEditZona">
    <jqxWindow #formEditZona [position]="getFormPos(formEditZona)" [width]="830" [height]="350" [zIndex]="999"
        [autoOpen]="true" [isModal]="false" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
        (onClose)="onCloseEditZona()" (onOpen)="onOpenEditZona()">
        <div class="formHeader" style="overflow-x: hidden;">
            {{translate('Edicion_zonas_cartograficas')}}
        </div>
        <div class="form" style="float:left; overflow-x: hidden;">
            <div class="container">
                <div class="columnForm" style="margin-right: 2px;">
                    <div style="margin-top: 5px; clear: both;" [hidden]="hiddenTipoMaster">
                        {{translate('Tipo_zona')}}
                        <jqxDropDownList #cbTiposMaster [width]="250" [height]="25" [autoDropDownHeight]="true"
                            (onSelect)="onChangeTipoMaster($event)">
                        </jqxDropDownList>
                    </div>
                    <div style="margin-top: 5px; clear: both;" [hidden]="hiddenTipoZonas">
                        {{translate('Edificios_instalaciones')}}
                        <jqxDropDownList #cbTiposZonas [width]="250" [height]="25" [selectedIndex]="0"
                            (onSelect)="onChangeTipoZona($event)">
                        </jqxDropDownList>
                    </div>
                    <div style="margin-top: 5px; clear: both;" [hidden]="hiddenGeometria">
                        {{translate('Geometria')}}
                        <jqxDropDownList #cbTiposGeo [width]="250" [height]="25" [selectedIndex]="0"
                            (onSelect)="onChangeTipoGeo($event)">
                        </jqxDropDownList>
                    </div>
                    <div class="input-buscar" style="margin-top: 5px; clear: both;" [hidden]="hiddenInputSearch">
                        <div>
                            {{translate('Buscar')}}
                        </div>
                        <div class="row">
                            <input type="text" class="input-search" (keyup)="onChangeInput($event)" list="SearchResults"
                                (change)="onSelectInput($event)" autocomplete="off" [(ngModel)]="stringSearch">
                            <datalist #datalist id="SearchResults">
                                <option *ngFor="let area of areas | keyvalue:keepOriginalOrder" value={{area.value}}>
                                    {{area.value}}
                                </option>
                            </datalist>
                        </div>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Nombre')}}
                    </div>
                    <div style="float:left; clear:both;">
                        <input type="text" class="input-search" #nombre [(ngModel)]="zonaEdit.Nombre" [width]="250"
                            [height]="25" [disabled]="!canEdit" (keyup)="onKey(nombre.value)">
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;">
                        {{translate('Observaciones')}}
                    </div>
                    <div style="float:left; clear: both;">
                        <jqxInput [(ngModel)]="zonaEdit.Observaciones" [width]="250" [height]="25"
                            [disabled]="!canEdit">
                        </jqxInput>
                    </div>
                    <div style="margin-top: 5px; float:left; clear:both;width: 100%;" [hidden]="hiddenELementContainer">
                        <div>
                            {{translate('Elemento_seleccionado')}}
                        </div>
                        <div *ngIf="!infoElement" class="element-container">
                            {{translate('Selecciona_elemento')}}
                        </div>
                        <div class="element-container">
                            <div>
                                <img [src]="infoMarker">
                            </div>
                            <div class="column">
                                <div>
                                    {{infoElement}}
                                </div>
                                <div>
                                    {{infoNombre}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="zonaEdit.TipoGeo==0" [hidden]="hiddenRadius">
                        <div style="margin-top: 5px; float:left; clear:both;">
                            {{translate('Radio_accion_metros')}}
                        </div>
                        <div style="float:left; clear: both;">
                            <jqxNumberInput [(ngModel)]="zonaEdit.Radio" [width]="80" [height]="25"
                                [spinButtons]="false" [min]="0" [max]=5000000 [inputMode]="'simple'" [decimalDigits]="0"
                                [disabled]="!canEdit" (onChange)="onChangeRadius()">
                            </jqxNumberInput>
                        </div>
                    </div>
                    <div *ngIf="zonaEdit.TipoGeo==1">
                        <div style="margin-top: 5px; float:left; clear:both;">
                            {{translate('Superficie')}}
                        </div>
                        <div style="float:left; clear: both;">
                            {{superficie}} m<sup>2</sup>.
                        </div>
                    </div>
                </div>
                <div class="columnGrid">
                    <div>
                        {{translate('Entradas_salidas')}}
                    </div>
                    <!-- <app-entradas-salidas></app-entradas-salidas> -->
                    <app-entrada-salida [editable]="editEntradas"></app-entrada-salida>
                </div>
                <div style="position:absolute; bottom:5px; left:5px; margin-top: 23px; clear: both; ">
                    <jqxButton style="float:left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                        [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' [value]="translate('Guardar')"
                        (onClick)="onGuardarZona()" [disabled]="!canEdit">
                    </jqxButton>
                    <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                        [imgSrc]='"/assets/images/zonas.png"' [value]="translate('Dibujar')"
                        (onClick)="onDibujarZona($event)" [disabled]="!canEdit" [hidden]="hiddenDrawButton">
                    </jqxButton>
                </div>
            </div>
        </div>
    </jqxWindow>
</div>