<jqxWindow #windowStates [position]="getFormPos(windowStates)" [theme]="environment.tema" [width]="600" [height]="463"
    [zIndex]="90" [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false"
    (onClose)="onClose()">
    <div class="formHeader">
    </div>
    <div class="form">
        <div class="selects-container">
            <div class="column">
                <p class="text-column"> {{translate('Periodo')}}</p>
                <app-periodo-select #periodoSelect [noAnno]="true" [noTrimestre]="true" [noSemestre]="true"
                    [noLibre]="true" [height]="24">
                </app-periodo-select>
            </div>
            <div class="column-buttons">
                <jqxButton style="float:left; margin-left: 18px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"'
                    [value]="translate('ACEPTAR')" (onClick)="onClickBuscar()">
                </jqxButton>
                <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
                    [textImageRelation]='"imageBeforeText"' [textPosition]='"left"'
                    [imgSrc]='"/assets/images/exportar.png"' [value]="translate('Exportar')"
                    (onClick)="onExportar($event)">
                </jqxButton>
            </div>
        </div>
        <div class="grid-incidences-container">
            <jqxGrid #statesGrid [theme]="environment.tema" [width]="'100%'" [height]="'100%'" [columnsresize]="true"
                [source]="dataAdapter" [sortable]="true" [showrowlines]="false" [rendertoolbar]="createToolBar"
                [showcolumnlines]="true" [columns]="columns" [altrows]="true" [sortmode]="'many'"
                [enabletooltips]="true" [filterable]="false" [showstatusbar]="true" [statusbarheight]="20"
                (onBindingcomplete)="onBindingComplete()" [showaggregates]="true" [rowsheight]="30" [columnsheight]="20"
                [localization]="langGrid" [groupable]="true" [showtoolbar]="true" [autoloadstate]="true"
                (onRowselect)="onRowSelect($event)">
            </jqxGrid>
            <jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
            </jqxLoader>
        </div>
    </div>


</jqxWindow>