import { Component, OnInit, ViewChild } from '@angular/core';
import { CustomForms } from '../../forms/custom-forms';
import { environment } from 'src/environments/environment';
import { AppComponent } from 'src/app/app.component';
import { DireccionModel } from 'src/app/services/ciudadanos/models/direccion.model';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { MainComponent } from '../../main/main.component';
import { CiudadanosEditComponent } from '../ciudadanos-edit/ciudadanos-edit.component';
import { DireccionService } from 'src/app/services/direccion/direccion.service';
import { DireccionListadoComponent } from '../direccion/direccion-listado/direccion-listado.component';

@Component({
  selector: 'app-ciudadanos-direccion-edit',
  templateUrl: './ciudadanos-direccion-edit.component.html',
  styleUrls: ['./ciudadanos-direccion-edit.component.css']
})
export class CiudadanosDireccionEditComponent extends CustomForms implements OnInit {
  // Componentes del HTML
  @ViewChild('windowForm') windowForm: jqxWindowComponent;
  @ViewChild('mapSearch') mapSearch: any;
  inputMapSearch: HTMLInputElement;

  // Funcionalidad
  private componentRef = null;
  public environment = environment;

  // Direccion editando
  direccion: DireccionModel = new DireccionModel();

  // Traducción de los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  public onClose() {
    if (this.componentRef) {
      CiudadanosEditComponent._this.windowCiudadanosForm.expand();
      this.componentRef.destroy();
    }
  }

  constructor(private direccionService: DireccionService) {
    super();
  }

  public init(componentRef: any, direccion: DireccionModel) {
    this.componentRef = componentRef;
    this.direccion = direccion;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.addCustomForm(this.windowForm);

    this.windowForm.setTitle(this.translate('Ficha_direccion'));

    setTimeout(()=> {
      this.inputMapSearch = this.mapSearch.nativeElement.children[0].children[0].children[0].children[0];
      if(this.direccion?.direccion != null){
        let value = '';

        value += this.direccion.direccion ? this.direccion.direccion : '';
        value += this.direccion.numero ? value != '' ? ', ' + this.direccion.numero : this.direccion.numero : '';
        value += this.direccion.municipio ? value != '' ? ', ' + this.direccion.municipio : this.direccion.municipio : '';
        value += this.direccion.provincia ? value != '' ? ', ' + this.direccion.provincia : this.direccion.provincia : '';
        value += this.direccion.comunidadAutonoma ? value != '' ? ', ' + this.direccion.comunidadAutonoma : this.direccion.comunidadAutonoma : '';

        this.inputMapSearch.value = value;
      }
    }, 500)
  }

  onMapSearch(event: any) {
    this.direccion.direccion = event.place.Street;
    this.direccion.codigoPostal = event.place.PostalCode;
    this.direccion.poblacion = event.place.District;
    this.direccion.municipio = event.place.Locality;
    this.direccion.provincia = event.place.AdministrativeArea1;
    this.direccion.comunidadAutonoma = event.place.AdministrativeArea2;
    this.direccion.numero = event.place.StreetNumber;

    let value = '';

    value += this.direccion.direccion ? this.direccion.direccion : '';
    value += this.direccion.numero ? value != '' ? ', ' + this.direccion.numero : this.direccion.numero : '';
    value += this.direccion.municipio ? value != '' ? ', ' + this.direccion.municipio : this.direccion.municipio : '';
    value += this.direccion.provincia ? value != '' ? ', ' + this.direccion.provincia : this.direccion.provincia : '';
    value += this.direccion.comunidadAutonoma ? value != '' ? ', ' + this.direccion.comunidadAutonoma : this.direccion.comunidadAutonoma : '';

    this.inputMapSearch.value = value;
  }

  async onGuardar(): Promise<void> {
    let direccion = await this.direccionService.saveDireccion(this.direccion);

    if(direccion != null) {
      DireccionListadoComponent._this.savedDirection(this.direccion);

      MainComponent.getInstance().showInfo(
        'ATENCION',
        'Registro_almacenado',
        2000
      );

      this.windowForm.close();
    }else {
      MainComponent.getInstance().showError(
        'ATENCION',
        'Fallo_almacenar_info',
        2000
      );
    }
  }
}
