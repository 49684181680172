import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { CustomForms } from '../../forms/custom-forms';
import { jqxGridComponent } from 'jqwidgets-ng/jqxgrid';
import { jqxWindowComponent } from 'jqwidgets-ng/jqxwindow';
import { JqWidgets } from 'src/app/utils/jqWidgets';
import { NumberUtils } from 'src/app/utils/number-utils';
import { DateUtils } from 'src/app/utils/date-utils';
import { HistoricoEnviosIdentificadorService } from 'src/app/services/historicoEnviosIdentificador/historico-envios-identificador.service';
import { HistoricoEnvioIdentiiicadorModel } from 'src/app/services/historicoEnviosIdentificador/models/historicoEnviosIdentificador.model';
import { DateIdentificacionModel } from 'src/app/services/cerraduras/models/dateIdentificacion';
import { MainComponent } from '../../main/main.component';
import { ElementoModel } from 'src/app/services/elements/models/elem.model';
import { MapComponent, MapLatLng, MapMarker, MapPolyline } from 'movisat-maps';
import { jqxCheckBoxComponent } from 'jqwidgets-ng/jqxcheckbox';
import { CerraduraModel } from 'src/app/services/cerraduras/models/cerradura.model';
import { CerraduraService } from 'src/app/services/cerraduras/cerradura.service';
import { GeoUtils } from 'src/app/utils/geo-utils';
import { jqxTabsComponent } from 'jqwidgets-ng/jqxtabs';
import { LangService } from 'src/app/services/lang/lang.service';
import * as xlsx from 'xlsx';
import { Utils } from 'src/app/utils/utils';
import { HeaderComponent } from '../../header/header.component';



@Component({
  selector: 'app-historico-envios-disp-identificador',
  templateUrl: './historico-envios-disp-identificador.component.html',
  styleUrls: ['./historico-envios-disp-identificador.component.css']
})
export class HistoricoEnviosDispIdentificadorComponent extends CustomForms implements OnInit {
  @ViewChild('header') header: HeaderComponent;
  @ViewChild('form') form: jqxWindowComponent;
  @ViewChild('myGrid') myGrid: jqxGridComponent;
  @ViewChild('checkTodas') checkTodas: jqxCheckBoxComponent;
  @ViewChild('myTabs') myTabs: jqxTabsComponent;

  private componentRef = null;
  public theme = environment.tema;
  // map
  public mapProvider = 'Google';
  public cartoType = 'raster';
  public lang = 'es-ES'; // Idioma por defecto
  public searchCountry = 'ES'; // Pais por defecto (para las búsquedas en el mapa)
  public searchBounds = ''; // Marco por defecto (para las búsquedas en el mapa)
  public environment = environment;
  public zoom = 6;
  public center = { // Cieza
    lat: 38.2378331,
    lng: -1.42102,
    weight: 1
  }
  // grid
  dataAdapter: any;
  source: any;
  public langGrid = JqWidgets.getLocalization('es');
  editrow: number = -1;
  // variables
  gridIdentificaciones: jqxGridComponent;
  historicosEnviosIdentificador: HistoricoEnvioIdentiiicadorModel[] = [];
  envioIdentificador: HistoricoEnvioIdentiiicadorModel = new HistoricoEnvioIdentiiicadorModel();
  dateIdentificacion: DateIdentificacionModel = new DateIdentificacionModel();
  dateFin = new Date();
  dateInicio = new Date();
  elemento: ElementoModel = new ElementoModel();
  elementos: ElementoModel[] = [];
  marker: MapMarker;
  markers: MapMarker[] = [];
  private map: MapComponent;
  cambiarNumberValue: boolean = false;
  cerraduras: CerraduraModel[] = [];
  private polyline: MapPolyline = null;
  private polylines: MapPolyline[] = [];
  distanciaTotal: number = 0;
  mapWidth: number;
  mapHeight: number;
  rowVisible: number = -1;
  public static _this: any;
  showRowDetails: any[] = [];

  columns = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'id', hidden: true },
    {
      text: this.translate('Fecha_envio'), columntype: 'datetimeinput', filtertype: 'date', width: 150, datafield: 'fechaEnvio', cellsrenderer: this.renderRow, aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    { text: this.translate('Ns_movisat'), width: 160, columntype: 'textbox', filtertype: 'textbox', datafield: 'nsMovisat' },
    { text: this.translate('Num_identificaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'cantidad', width: 100, cellsrenderer: this.numberrenderer },
    { text: this.translate('Bateria'), columntype: 'textbox', filtertype: 'textbox', width: 60, datafield: 'bateriaPorcentaje', cellsrenderer: this.numberrenderer },
    { text: this.translate('Temperatura'), columntype: 'textbox', filtertype: 'textbox', datafield: 'temperatura', width: 80, cellsrenderer: this.numberrenderer },
    { text: this.translate('Coordenada_lat'), columntype: 'textbox', filtertype: 'textbox', datafield: 'lat', cellsrenderer: this.numberrenderer },
    { text: this.translate('Coordenada_lng'), columntype: 'textbox', filtertype: 'textbox', datafield: 'lng', cellsrenderer: this.numberrenderer },
    { text: this.translate('Metros'), columntype: 'textbox', filtertype: 'textbox', datafield: 'metros', width: 60, cellsrenderer: this.numberrendererDecimales },
    { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', datafield: 'nombreElemento', width: 100 },
    { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'marcaElemento', width: 100 },
    { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'checkedlist', datafield: 'modeloElemento', width: 100 },
    { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', datafield: 'matriculaElemento', width: 75 },
    { text: this.translate('Direccion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'direccionElemento', width: 100 },
    { text: this.translate('Municipio'), columntype: 'textbox', filtertype: 'textbox', datafield: 'municipioElemento', width: 70 },
    { text: this.translate('Poblacion'), columntype: 'textbox', filtertype: 'textbox', datafield: 'poblacionElemento', width: 70 },
    { text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', datafield: 'observacionesElemento', menu: false, sortable: false, width: 80 },
  ];


  constructor(private historicEnviosIdentificadorService: HistoricoEnviosIdentificadorService,
    private cerraduraService: CerraduraService, private el: ElementRef, private renderer: Renderer2,
    public langService: LangService) {
    super();
    HistoricoEnviosDispIdentificadorComponent._this = this;
  }

  ngOnInit() {
    this.mapHeight = document.getElementById('map-container').offsetHeight;
    this.mapWidth = document.getElementById('map-container').offsetWidth;
    this.map = MainComponent.getInstance().getMap();
  }
  /**Inicializa el componente
  */
  init(componentRef: any) {
    this.componentRef = componentRef;
  }

  ngAfterViewInit(): void {
    this.addCustomForm(this.form);
    this.form.setTitle(AppComponent.translate('Historico_envios_dispositivo_identificador'));
    this.valuesByDefault();
    this.gethistoricoEnviosIdentificador();
    this.myTabs.setTitleAt(0, this.translate("Lecturas"));
    this.myTabs.setTitleAt(1, this.translate("Cartografia"));
    Utils.renderSizeGrid(this.myGrid)
  }

  onRowclick(event: any): void {
    if (event.target.id !== 'nestedGrid1') {
      this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
    } else {
      return;
    }
  }

  valuesByDefault() {
    this.dateInicio.setHours(0, 0, 0);
    this.dateFin.setHours(23, 59, 59);
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;
    this.header.currencyInput.value(50);
    this.header.currencyInput.disabled(true);
  }

  public sortedColumn;

  customsortfunc = (column: any, direction: string | boolean): void => {
    let sortdata = new Array();
    if (direction == 'ascending') direction = true;
    if (direction == 'descending') direction = false;
    if (direction != null) {
      for (let i = 0; i < this.historicosEnviosIdentificador.length; i++) {
        sortdata.push(this.historicosEnviosIdentificador[i]);
      }
    }
    else sortdata = this.historicosEnviosIdentificador;
    this.sortedColumn = column;
    if (direction != null) {
      sortdata.sort(this.compare);
      if (!direction) {
        sortdata.reverse();
      }
    }
    this.source.localdata = sortdata;
    this.myGrid.updatebounddata('sort');
  }

  compare = (value1: any, value2: any): any => {
    value1 = value1[this.sortedColumn];
    value2 = value2[this.sortedColumn];
    try {
      let tmpvalue1 = parseFloat(value1);
      if (isNaN(tmpvalue1)) {
        if (value1 < value2 || !value1) { return -1; }
        if (value1 > value2) { return 1; }
      }
      else {
        let tmpvalue2 = parseFloat(value2);
        if (tmpvalue1 < tmpvalue2) { return -1; }
        if (tmpvalue1 > tmpvalue2) { return 1; }
      }
    }
    catch (error) {
      let er = error;
    }
    return 0;
  };

  onSort(event: any) {
    this.showRowDetails.forEach(row => {
      this.myGrid.getrows().forEach(elem => {
        if (row === elem.id) {
          this.myGrid.showrowdetails(elem.boundindex)
        }
      });
    });
  }

  onRowClick(event: any) {
    this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
    if (this.envioIdentificador.cantidad !== 0) {
      this.myGrid.unselectrow(event.args.rowindex);
    } else {
      this.myGrid.selectrow(event.args.rowindex);
    }

    if (event.args.row.rowdetailshidden) {
      this.showRowDetails.push(event.args.row.bounddata.id);
    } else {
      this.showRowDetails.splice(this.showRowDetails.indexOf(event.args.row.bounddata.id), 1);
    }
  }

  //check elementos validos

  elementosValidos() {
    this.historicosEnviosIdentificador.forEach(historico => {
      let elemento = this.elementos.find(elemento => elemento.Id == historico.idElemento);
      if (!elemento) {
        historico.latElemento = null;
        historico.lngElemento = null;
        historico.idElemento = null;
        historico.nombreElemento = null;
        historico.marcaElemento = null;
        historico.modeloElemento = null;
        historico.observacionesElemento = null;
        historico.direccionElemento = null;
        historico.municipioElemento = null;
        historico.poblacionElemento = null;
      }
    });
  }

  async gethistoricoEnviosIdentificador() {
    this.elementos = MainComponent.getInstance().elementsList;
    this.cerraduras = await this.cerraduraService.getCerraduras();
    this.historicosEnviosIdentificador = await this.historicEnviosIdentificadorService.getHistoricosEnviosIdentificador(this.dateIdentificacion);
    if (this.historicosEnviosIdentificador) {
      this.elementosValidos();
      this.source = {
        sort: this.customsortfunc,
        datatype: 'json',
        datafields: [
          { name: 'id', type: 'number', map: 'id' },
          { name: 'fechaEnvio', type: 'date', map: 'fechaEnvio' },
          { name: 'nsMovisat', type: 'string', map: 'nsMovisat' },
          { name: 'cantidad', type: 'number', map: 'cantidad' },
          { name: 'bateriaPorcentaje', type: 'number', map: 'bateriaPorcentaje' },
          { name: 'temperatura', type: 'number', map: 'temperatura' },
          { name: 'lat', type: 'string', map: 'lat' },
          { name: 'lng', type: 'string', map: 'lng' },
          { name: 'latElemento', type: 'number', map: 'latElemento' },
          { name: 'lngElemento', type: 'number', map: 'lngElemento' },
          { name: 'idElemento', type: 'number', map: 'idElemento' },
          { name: 'metros', type: 'number', map: 'metros' },
          { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
          { name: 'marcaElemento', type: 'string', map: 'marcaElemento' },
          { name: 'modeloElemento', type: 'string', map: 'modeloElemento' },
          { name: 'matriculaElemento', type: 'string', map: 'matriculaElemento' },
          { name: 'direccionElemento', type: 'string', map: 'direccionElemento' },
          { name: 'municipioElemento', type: 'string', map: 'municipioElemento' },
          { name: 'poblacionElemento', type: 'string', map: 'poblacionElemento' },
          { name: 'observacionesElemento', type: 'string', map: 'observacionesElemento' },
        ],
        localdata: this.historicosEnviosIdentificador,
      };
      this.dataAdapter = new jqx.dataAdapter(this.source);
      setTimeout(() => {
        this.myGrid.sortby('fechaEnvio', 'descending');
      }, 200);
    }
  }

  async onMapReady(map: MapComponent) {
    if (this.map) {
      this.map = null;
    }
    this.map = map;
  }

  // asigno las fechas del periodo seleccionado
  async onAceptar() {
    this.dateInicio = this.header.periodoSelect.getFechaIni();
    this.dateFin = this.header.periodoSelect.getFechaFin();
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;
    this.historicosEnviosIdentificador = await this.historicEnviosIdentificadorService.getHistoricosEnviosIdentificador(this.dateIdentificacion);
    this.elementosValidos();
    if (this.header.checkMasXmetros.checked() && this.cambiarNumberValue) {
      let valor: number = this.header.currencyInput.val();
      let datos = this.historicosEnviosIdentificador.filter(x => x.metros >= valor);
      this.source.localdata = datos;
      this.myGrid.updatebounddata();
    } else {
      if (this.source) {
        this.source.localdata = this.historicosEnviosIdentificador;
        this.myGrid.updatebounddata();
      }
    }
  }

  onClose() {
    if (this.componentRef) {
      this.componentRef.destroy();
    }
    this.deleteMapElements();
    this.distanciaTotal = 0;
  }
  // Para traducir los textos
  public translate(text: string): string {
    return AppComponent.translate(text);
  }

  renderRow(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (columnfield == 'fechaEnvio' || columnfield == 'fecha') {
      let date = new Date(value);
      return '<div style="margin-left: 4px; margin-top: 4px">' + DateUtils.formatDateTimeShort(date, true) + '</div>';
    } else if (columnfield == 'tipoPermiso') {

      if (value == 'BLANCA') {
        return '<div style="margin-left: 4px; margin-top: 4px">'+AppComponent.translate('Blanca')+'</div>';
      } else if (value == 'MASTER') {
        return '<div style="margin-left: 4px; margin-top: 4px">'+AppComponent.translate('Master')+'</div>';
      }
    } else {
      return '<div style="margin-left: 4px; margin-top: 4px">' + value + '</div>';
    }
  }

  numberrenderer(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {
    if (columnfield == 'bateriaPorcentaje') {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        '%</div>'
      );
    } else if (columnfield === 'temperatura') {
      return (
        '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' +
        NumberUtils.format(value, 0) +
        'º</div>'
      );
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px; text-align: right">' + NumberUtils.format(value, 0) + '</div>';
    }
  }

  numberrendererDecimales(
    row: number,
    columnfield: string,
    value: any,
    defaulthtml: string,
    columnproperties: any,
    rowdata: any
  ): string {

    return (
      '<div style="margin-right: 4px; margin-top: 5px; text-align: right;">' +
      NumberUtils.format(value, 2) +
      '</div>'
    );
  }

  renderText(row: number, columnfield: string, value: any, defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (rowdata.idElemento == 0 || rowdata.idElemento == null) {
      return '';
    } else if (rowdata.nombreElemento == null || rowdata.nsCerradura == null) {
      return '';
    } else {
      return '<div style="margin-right: 4px; margin-top: 5px;">' + rowdata.nombreElemento + '</div>';
    }
  }


  renderIdentificacion(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (value == 0) {
      return '<div  style="margin-left: 4px; margin-top: 4px">NFC</div>';
    } else if (value == 1) {
      return '<div  style="margin-left: 4px; margin-top: 4px">Bluetooth</div>';
    }
  }

  renderCiudadano(row: number, columnfield: string, value: any,
    defaulthtml: string, columnproperties: any, rowdata: any): string {
    if (rowdata.nombreCiudadano == null) {
      return '';
    } else {
      return '<div style="margin-left: 4px; margin-top: 8px;">' + rowdata.nombreCiudadano + '</div>';
    }
  }

  // identificaciones grid
  dataAdapterIdentificaciones: any;
  sourceIdentificaciones: any;

  public columnsIdentificacines = [
    { text: 'Id', columntype: 'textbox', filtertype: 'textbox', datafield: 'idCerradura', hidden: true },
    {
      text: this.translate('Fecha'), columntype: 'datetimeinput', filtertype: 'textbox', datafield: 'fecha', width: 150, cellsrenderer: this.renderRow, aggregates: [{
        'Total': function (aggregatedValue, currentValue: number) {
          return aggregatedValue + 1;
        }
      }],
      aggregatesrenderer: function (aggregates) {
        let renderstring = '';
        if (aggregates["Total"] !== undefined) {
          renderstring = '<div style="text-align: left; margin-left: 4px;">' + AppComponent.translate('Total') + ': ' +
            NumberUtils.format(aggregates["Total"], 0) + '</div>';
        }
        return renderstring;
      }
    },
    { text: this.translate('Ns_movisat'), columntype: 'textbox', filtertype: 'textbox', width: 150, datafield: 'nsMovisat', cellsrenderer: this.renderRow, },
    { text: this.translate('Ciudadano'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'nombreCiudadano', cellsrenderer: this.renderCiudadano },
    { text: this.translate('Aporte_residuo'), columntype: 'checkbox', filtertype: 'bool', datafield: 'aporteResiduo' },
    { text: this.translate('Apertura_tapa'), columntype: 'checkbox', filtertype: 'bool', datafield: 'aperturaTapa' },
    { text: this.translate('No_cerro_tapa'), columntype: 'checkbox', filtertype: 'bool', datafield: 'noCerroTapa' },
    { text: this.translate('Tipo_identificacion'), columntype: 'textbox', width: 100, filtertype: 'textbox', datafield: 'medio', cellsrenderer: this.renderIdentificacion },
    { text: this.translate('Tipo_permiso'), columntype: 'textbox', width: 100, filtertype: 'textbox', datafield: 'tipoPermiso', cellsrenderer: this.renderRow, },
    { text: this.translate('Nombre_elemento'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'nombreElemento', cellsrenderer: this.renderRow, },
    { text: this.translate('Marca'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'marca', cellsrenderer: this.renderRow, },
    { text: this.translate('Modelo'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'modelo', cellsrenderer: this.renderRow, },
    { text: this.translate('Matricula'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'matricula', cellsrenderer: this.renderRow, },
    { text: this.translate('Observaciones'), columntype: 'textbox', filtertype: 'textbox', width: 100, datafield: 'observaciones', },
    { text: this.translate('Direccion'), columntype: 'textbox', width: 100, filtertype: 'textbox', datafield: 'direccion', cellsrenderer: this.renderRow, },
    { text: this.translate('Municipio'), columntype: 'textbox', width: 100, filtertype: 'textbox', datafield: 'municipio', cellsrenderer: this.renderRow, },
    { text: this.translate('Poblacion'), columntype: 'textbox', width: 100, filtertype: 'textbox', datafield: 'poblacion', cellsrenderer: this.renderRow, },
  ];

  onRowdoubleclick(event: any): void {
    if (event.target.id !== 'nestedGrid1') {
      // Elimino los marcadores del mapa
      this.deleteMapElements();
      this.distanciaTotal = 0;
      this.envioIdentificador = this.historicosEnviosIdentificador.find(x => x.id == event.args.row.bounddata.id);
      this.elemento = this.elementos.find(x => x.Id == this.envioIdentificador.idElemento);
      // busco la cerradura que coincide con el idElemento de this.envioIdentificador
      let cerradura: CerraduraModel = this.cerraduras.find(x => x.idElemento == this.envioIdentificador.idElemento);
      if (this.elemento) {
        this.showEnviosIdentificacionesMap(cerradura);
      } else {
        return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
      }
    } else {
      return;
    }
  }

  showEnviosIdentificacionesMap(cerradura: CerraduraModel): void {
    // Verificar si las coordenadas del objeto this.envioIdentificador del elemento existen
    if (this.envioIdentificador.latElemento != 0 || this.envioIdentificador.lngElemento != 0 || this.envioIdentificador.latElemento != null || this.envioIdentificador.lngElemento != null) {
      let icono: string = 'data:image/png;base64,' + this.elemento.Equipamiento.Icono;
      this.createMarkers(new MapLatLng(this.envioIdentificador.latElemento, this.envioIdentificador.lngElemento), icono);
    } else if (cerradura && (cerradura.lat != 0 || cerradura.lng != 0)) {
      let icono: string = 'data:image/png;base64,' + this.elemento.Equipamiento.Icono;
      this.createMarkers(new MapLatLng(cerradura.lat, cerradura.lng), icono);
    } else {
      return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
    }
    // this.envioIdentificador.lat = 37.98644080567557;
    // this.envioIdentificador.lng = -1.1416920530665142;
    if (this.envioIdentificador.lat != 0 || this.envioIdentificador.lng != 0) {
      let icono: string = 'assets/images/identificacion-inteligente.png';
      let marker = this.createMarkers(new MapLatLng(this.envioIdentificador.lat, this.envioIdentificador.lng), icono);
      marker.content = '<div style="margin: 10px;">' + this.translate('Ns_movisat') + '<br>' + this.envioIdentificador.nsMovisat + '</div>';
    } else {
      return MainComponent.getInstance().showError('ATENCION', 'No_existen_coordenadas', 2000);
    }

    if (this.markers.length >= 2) {
      this.myTabs.select(1);
      this.showDistancePolyline();
      this.addPolylinePoint();
      this.markers[1].animate(2000);
      this.map.setCenter(this.markers[1].position);
      this.map.setZoom(14);
    } else {
      return MainComponent.getInstance().showError('ATENCION', 'Error', 2000);
    }
  }

  showDistancePolyline(): number {
    this.distanciaTotal += GeoUtils.getDistance(this.markers[0].position.lat, this.markers[0].position.lng, this.markers[1].position.lat, this.markers[1].position.lng);
    this.distanciaTotal = Number.parseFloat(this.distanciaTotal.toFixed(2));
    return this.distanciaTotal;
  }

  addPolylinePoint(): void {
    if (!this.polyline) {
      this.polyline = this.addPolyline();
    }
    // Añadir un punto a la polilínea
    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      content: this.distanciaTotal + ' m',
      position: new MapLatLng(this.envioIdentificador.latElemento, this.envioIdentificador.lngElemento)
    });

    this.map.addPolylinePoint(this.polyline, {
      dataModel: this.envioIdentificador,
      position: new MapLatLng(this.envioIdentificador.lat, this.envioIdentificador.lng),
      content: this.distanciaTotal + ' m',
    });
  }

  createMarkers(position: MapLatLng, icono: string,): MapMarker {
    let marker = this.map.addMarker({
      dataModel: '',
      title: '',
      content: '',
      position: position,
      icon: icono,
      zIndex: 999,
      drag: false,
      visible: true
    });
    this.markers.push(marker);
    return marker;
  }

  // añado una polylinea al mapa entre dos puntos (inicio y fin)
  addPolyline(): MapPolyline {
    const polyline = this.map.addPolyline({
      color: 'red',
      weight: 7,
      opacity: 0.5,
      clickable: true,
    });
    this.polylines.push(polyline);
    return polyline;
  }

  deleteMapElements(): void {
    // Elimino los marcadores del mapa
    if (this.markers.length > 0) {
      this.markers.forEach(element => {
        this.map.removeMarker(element);
      });
      this.markers = [];
      this.marker = null;
    }
    // Elimino las polilineas del mapa
    if (this.polylines.length > 0) {
      this.polylines.forEach(element => {
        this.map.removePolyline(element);
      });
      this.polylines = [];
      this.polyline = null;
    }
  }

  onResetFilter() {
    this.dateInicio = new Date();
    this.dateFin = new Date();
    this.dateInicio.setHours(0, 0, 0);
    this.dateFin.setHours(23, 59, 59);
    this.dateIdentificacion.Desde = this.dateInicio;
    this.dateIdentificacion.Hasta = this.dateFin;
    this.header.periodoSelect.setPeriodo(0);
    this.gethistoricoEnviosIdentificador();
    this.header.checkMasXmetros.checked(false);
    this.header.currencyInput.disabled(true);
    this.header.currencyInput.val(50);
    this.cambiarNumberValue = false;
    this.deleteMapElements();
  }

  changeMasXmetros() {
    if (this.header.checkMasXmetros.checked) {
      this.header.currencyInput.disabled(true);
      this.header.currencyInput.val(50);
      this.header.currencyInput.disabled(false);
      this.cambiarNumberValue = true;
    } else {
      this.header.currencyInput.disabled(true);
      this.header.currencyInput.val(50);
      this.cambiarNumberValue = false;
    }
  }

  valueChangedNumber(event: any) {
    this.cambiarNumberValue = true;
  }

  onExportar() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      const json = this.myGrid.exportdata('json');
      let datos = JSON.parse(json);
      datos.forEach(element => {
        element[this.translate('Bateria')] = isNaN(parseFloat(element[this.translate('Bateria')])) ? "" : parseFloat(element[this.translate('Bateria')]).toFixed(0);
        element[this.translate('Temperatura')] = isNaN(parseFloat(element[this.translate('Temperatura')])) ? "" : parseFloat(element[this.translate('Temperatura')]).toFixed(0);
        element[this.translate('Num_identificaciones')] = isNaN(parseFloat(element[this.translate('Num_identificaciones')])) ? "" : parseFloat(element[this.translate('Num_identificaciones')]).toFixed(0);
      });
      // Utiliza el array modificado en lugar del JSON original
      const ws: xlsx.WorkSheet = xlsx.utils.json_to_sheet(datos);
      this.generateAutofilterHeader(ws);

      const wb: xlsx.WorkBook = xlsx.utils.book_new();
      xlsx.utils.book_append_sheet(wb, ws, 'Hoja1');
      xlsx.writeFile(wb, DateUtils.formatDateAMDhms(new Date()) + '_' + this.translate('Historico_envios_dispositivo_identificador') + '.xlsx');
    }
  }

  generateAutofilterHeader(sheet) {
    // Añade filtro a todas las casillas.
    sheet['!autofilter'] = { ref: sheet['!ref'] };
  }

  // Boton para imprimir
  onPrint() {
    if (this.myGrid.getrows().length === 0) {
      return MainComponent.getInstance().showWarning('ATENCION', this.translate('No_existen_datos'), 2000);
    } else {
      this.myGrid.hidecolumn('Botones');
      let gridContent = this.myGrid.exportdata('html');
      let newWindow = window.open('', '', 'width=800, height=500'),
        document = newWindow.document.open(),
        pageContent =
          '<!DOCTYPE html>\n' +
          '<html>\n' +
          '<head>\n' +
          '<meta charset="utf-8" />\n' +
          '<title>jQWidgets Grid</title>\n' +
          '</head>\n' +
          '<body>\n' +
          gridContent +
          '\n</body>\n</html>';
      this.myGrid.showcolumn('Botones');
      document.write(pageContent);
      document.close();
      newWindow.onafterprint = function () {
        newWindow.close();
      };
      newWindow.print();
    }
  }


  rowdetailstemplate = (index: any): any => {
    var details = {
      rowdetails: '<div id="nestedGrid" style="margin: 10px;"></div>',
      rowdetailsheight: 180,
      rowdetailshidden: true,
    };
    this.editrow = index;
    //Saca el usuario y adapta el row height al numero de accesos que tenga
    let rowId = this.myGrid.getcellvalue(index, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (envio) {
      if (!envio.Detalle) {
        return details;
      }
    }
    return details;
  };

  rowExpand(event: any) {
    let rowId = this.myGrid.getcellvalue(event.args.rowindex, 'id');
    let envio = this.historicosEnviosIdentificador.find(x => x.id == rowId);

    if (!envio || envio.cantidad == 0) {
      this.myGrid.hiderowdetails(event.args.rowindex);
    }
  }

  initRowDetails = (index: any,
    parentElement: any,
    gridElement: any,
    datarecord: any): void => {
    if (parentElement && datarecord) {
      let nestedGridContainer = parentElement.children[0];

      let i = this.historicosEnviosIdentificador.findIndex(
        (envio) => envio.id === datarecord.id
      );
      if (this.envioIdentificador) {
        this.envioIdentificador = new HistoricoEnvioIdentiiicadorModel();
      }
      this.envioIdentificador = this.historicosEnviosIdentificador[i];
      if (this.envioIdentificador) {
        let sourceDetalle = {
          datatype: 'array',
          datafields: [
            { name: 'idCerradura', type: 'number', map: 'idCerradura' },
            { name: 'fecha', type: 'date', map: 'fecha' },
            { name: 'nsMovisat', type: 'string', map: 'nsCerradura' },
            { name: 'nombreCiudadano', type: 'string', map: 'nombreCiudadano' },
            { name: 'aporteResiduo', type: 'boolean', map: 'aporte' },
            { name: 'aperturaTapa', type: 'boolean', map: 'aperturaTapa' },
            { name: 'noCerroTapa', type: 'boolean', map: 'noCerroTapa' },
            //{ name: 'nsCerradura', type: 'string', map: 'nsCerradura' },
            { name: 'observaciones', type: 'string', map: 'observacionesElemento' },
            { name: 'matricula', type: 'string', map: 'matriculaElemento' },
            { name: 'medio', type: 'string', map: 'medio' },
            { name: 'tipoPermiso', type: 'string', map: 'tipoPermiso' },
            { name: 'marca', type: 'string', map: 'marcaElemento' },
            { name: 'modelo', type: 'string', map: 'modeloElemento' },
            { name: 'nombreElemento', type: 'string', map: 'nombreElemento' },
            { name: 'direccion', type: 'string', map: 'direccionElemento' },
            { name: 'municipio', type: 'string', map: 'municipioElemento' },
            { name: 'poblacion', type: 'string', map: 'poblacionElemento' },
          ],
          localdata: this.envioIdentificador.Detalle,
          sortcolumn: 'fecha',
          sortdirection: 'desc'
        };
        let dataDetalleEnvios = new jqx.dataAdapter(sourceDetalle);

        if (nestedGridContainer != null) {
          let setting = {
            width: '97%',
            height: '90%',
            source: dataDetalleEnvios,
            theme: this.theme,
            columns: this.columnsIdentificacines,
            localization: this.langGrid,
            editable: false,
            columnsresize: true
            // me subcribo al evento de doble click en la fila
          };
          this.gridIdentificaciones = jqwidgets.createInstance(
            `#${nestedGridContainer.id}`,
            'jqxGrid',
            setting
          );
        }
        setTimeout(() => {
          this.resizeGridDetails(this.gridIdentificaciones)
        }, 200);
      }
    } else {
      return;
    }
  }

  resizeGridDetails(grid: jqxGridComponent) {
    if (grid) {
      grid.setOptions({
        columnsheight: 20,
        rowsheight: 20,
        statusbarheight: 20,
        columnsresize: true,
      });
      grid.refresh();
      grid.showrowdetails(this.editrow);
    }
  }



}
