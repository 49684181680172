<jqxWindow #windowJob [theme]="environment.tema" [position]="getFormPos(windowJob, 0)" [width]="mapWidth" [height]="mapHeight" [zIndex]="999"
[autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="true" (onExpand)="onExpand()" (onClose)="onClose()">
  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Jobs')}}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <div style="float: left; width: 100%; height: 100%;">
      <div>
        <app-header #header [exportar]="true" (eventExportar)="onExportar()">
        </app-header>
      </div>
      <jqxGrid #gridJobs [theme]="environment.tema" [height]="'85%'" [width]="'100%'" [source]="adapterJobs"
        [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="false"
        [selectionmode]="'checkbox'" [columns]="columnsJobs" [showrowlines]="false" [showcolumnlines]="true"
        [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true" [statusbarheight]="20"
        [showaggregates]="true" [rowsheight]="25" [columnsheight]="25" [enabletooltips]="false" [groupable]="true"
        [groupsrenderer]="groupsrenderer" [editable]="editable" (onRowselect)="onRowSelect($event)"
        (onRowunselect)="onRowUnselect($event)" (onGroupschanged)="resizeColumns()" (onCellclick)="onCellClick($event)"
        (onRowdoubleclick)="onRowdoubleclick($event)" (onCellvaluechanged)="onCellvaluechanged($event)" (onFilter)="onfilter($event)">
      </jqxGrid>
      <div style="float: left;" [hidden]="!editable">
        <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
          [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
          [value]="translate('Editar')" (onClick)="onEditarJob()">
        </jqxButton>
      </div>
    </div>
  </div>
</jqxWindow>

<div #formJob></div>
