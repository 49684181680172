<jqxWindow #form [width]="500" [height]="400" [zIndex]="999" [theme]="theme"
  [isModal]="false" [position]="getFormPos(form)" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden">
      {{ translate("Ficha_ciudadano") }}
    </div>

    <div style="float:left; width: 100%; height: 90%;">
      <jqxGrid #gridTarjetas [theme]="theme" [width]="'100%'" [height]="'98%'" [source]="dataAdapterTarjetas"
        [columnsresize]="true" [filterable]="true" [showfilterrow]="true" [columns]="columnsTarjetas" [sortable]="true"
        [altrows]="true" [showrowlines]="false" [filterrowheight]="30" [rowsheight]="25" [columnsheight]="25"
        [enabletooltips]="true" [showaggregates]="true" [showstatusbar]="true" [statusbarheight]="20"
        [localization]="langGrid">
      </jqxGrid>
    </div>
</jqxWindow>
