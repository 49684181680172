<jqxWindow #windowCiudadanosForm [width]="mapWidth" [height]="mapHeight" [zIndex]="999" [theme]="environment.tema"
  [isModal]="false" [position]="getFormPos(windowCiudadanosForm, 0)" [showCloseButton]="true" [showCollapseButton]="true"
  [resizable]="true" (onClose)="onClose()" [title]="translate('Ficha_ciudadano')">

  <div class="formHeader" style="overflow-x: hidden">
    {{ translate("Ficha_ciudadano") }}
  </div>

  <div class="form" style="float: left; overflow-x: hidden;">
    <jqxRibbon #elemRibbon [theme]="environment.tema" [position]="'top'" [height]="'90%'">
      <ul style="width: 100%; text-align: left; z-index: 99; background-color: rgb(225, 229, 231);">
        <li>
          {{ translate('Informacion') }}
        </li>
        <li>
          {{ translate('Direcciones') }}
        </li>
        <li>
          {{ translate('Tarjetas') }}
        </li>
      </ul>

      <div style="height: 100%;">
        <!-- PESTAÑA DEL FORMULARIO -->
        <div style="background-color: transparent; padding: 0%; overflow-y: auto;">
          <div style="display: inline-table !important; width: 100%; height: 100%;">
            <div #fieldsetPersonal id="fieldsetPersonal">
              <fieldset>
                <legend>{{ translate('Personal_contacto') }}</legend>

                <div class="row">
                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Nombre') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.nombre" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 30%;">
                    <div style="clear: both">
                      {{ translate('Apellidos') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.apellidos" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Dni') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.nif" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 15%;">
                    <div style="clear: both">
                      {{ translate('Validado') }}
                    </div>
                    <jqxInput [value]="ciudadano.validadoTexto" style="float: left" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span *ngIf="ecoIdentificadores" style="width: 15%;">
                    <div style="clear: both">
                      {{ translate('Num_tarjetas') }}
                    </div>
                    <jqxInput [value]="ciudadano.tarjetas.length" [rtl]="true" [width]="'100%'" disabled="true">
                    </jqxInput>
                  </span>
                </div>

                <div class="row">
                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Telefono') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.telefono" [rtl]="true" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 40%;">
                    <div style="clear: both">
                      {{ translate('Correo_electronico') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.correoElectronico" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span *ngIf="ecoCompostaje" style="width: 25%;">
                    <div style="margin-top: 5px; clear: both">
                      Tipo
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.tipo" [width]="'100%'" disabled="true">
                    </jqxInput>
                  </span>
                </div>
              </fieldset>
            </div>
            <div #fieldsetDireccion id="fieldsetDireccion">
              <fieldset>
                <legend>{{ translate('Direccion_principal') }}</legend>

                <h3>{{ translate('Direccion_cartografica') }}</h3>

                <div class="row">
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Buscar') }}
                    </div>
                    <span #mapSearch>
                      <movisat-map-search style="float: left; width: 100%;" (mapSearch)="onMapSearch($event)">
                      </movisat-map-search>
                    </span>
                  </span>

                  <span style="width: 20%;">
                    <div style="clear: both">
                      {{ translate('Domicilio') }}
                    </div>
                    <jqxInput [value]="ciudadano.direccion" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 10%;">
                    <div style="clear: both">
                      {{ translate('Numero') }}
                    </div>
                    <jqxInput [value]="ciudadano.numero" [rtl]="true" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 10%;">
                    <div style="clear: both">
                      {{ translate('Codigo_postal') }}
                    </div>
                    <jqxInput [value]="ciudadano.codigoPostal" [rtl]="true" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 5%;">
                    <div style="clear: both">
                      {{ translate('Planta') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.planta" [rtl]="true" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 5%;">
                    <div style="clear: both">
                      {{ translate('Puerta') }}
                    </div>
                    <jqxInput [(ngModel)]="ciudadano.puerta" [rtl]="true" [width]="'100%'">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Poblacion') }}
                    </div>
                    <jqxInput [value]="ciudadano.poblacion" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                </div>
                <div class="row">
                  <span style="width: 25%;">
                    <div style="clear: both">
                      {{ translate('Municipio') }}
                    </div>
                    <jqxInput [value]="ciudadano.municipio" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>

                  <span style="width: 25%;">
                    <div style="clear: both;">
                      {{ translate('Provincia') }}
                    </div>
                    <jqxInput [value]="ciudadano.provincia" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                  <span style="width: 25%;">
                    <div style="clear: both;">
                      {{ translate('Comunidad_autonoma') }}
                    </div>
                    <jqxInput [value]="ciudadano.comunidadAutonoma" [width]="'100%'" [disabled]="true">
                    </jqxInput>
                  </span>
                </div>

                <h3>{{ translate('Catastro') }}</h3>
                <app-selector-catastro [ciudadano]="ciudadano"></app-selector-catastro>
              </fieldset>
            </div>
          </div>
        </div>

        <!-- PESTAÑA DEL LISTADO DE DIRECCIONES -->
        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-direccion-listado [ciudadano]="ciudadano"></app-direccion-listado>
        </div>

        <!-- PESTAÑA DE ASIGNACION DE TARJETAS -->
        <div style="background-color: transparent; padding: 0%; overflow-y: hidden !important;">
          <app-ciudadanos-tarjetas [ciudadano]="ciudadano" [guardar]="guardar"></app-ciudadanos-tarjetas>
        </div>
      </div>
    </jqxRibbon>
    <jqxButton style="float: left; margin-left: 5px; margin-top: 5px;" [width]="100" [height]="26"
      [textImageRelation]="'imageBeforeText'" [textPosition]="'left'" [imgSrc]="'/assets/images/save.png'"
      (onClick)="onGuardar($event)" [value]="translate('Guardar')">
    </jqxButton>
  </div>
</jqxWindow>
