import { jqxGridComponent } from "jqwidgets-ng/jqxgrid";



export class Utils {
    static clone<T>(a: T): T {
        return JSON.parse(JSON.stringify(a));
    }


    static sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    // ajusta el tamaño de las columnas del grid
    static renderSizeGrid(grid: jqxGridComponent, timeout = 100) {
        const t = setTimeout(() => {
            clearTimeout(t);
            grid.autoresizecolumns();
        }, timeout);
    }
    // quita los elementos duplicados del array y lo devuelve sin undefined
    static onChangeArray(list: any[]): any[] {
        list = list.filter((elem, index, self) =>
            index == self.findIndex((t) => t?.Id == elem?.Id) && elem !== undefined && elem !== null
        );
        return list;
    }

}