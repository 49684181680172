<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>
<jqxWindow #form [theme]="environment.tema" [position]="getFormPos(form)" [zIndex]="90" [width]="1600" [height]="490"
  [autoOpen]="true" [showCloseButton]="true" [showCollapseButton]="true" [resizable]="false" (onClose)="onClose()">

  <div class="formHeader" style="overflow-x: hidden;">
    {{translate('Configuracion_cerraduras')}}
  </div>

  <div class="form" style="float:left; overflow-x: hidden; ">
    <div style="float:left; margin-top: 6px; width: 100%;">
      <jqxGrid #gridConfiguraciones [theme]="environment.tema" [width]="'100%'" [source]="adapterGridConfiguracion"
        [columnsresize]="true" [localization]="langGrid" [filterable]="true" [showfilterrow]="true"
        [selectionmode]="'checkbox'" [columns]="columnsGridConfiguracion" [showrowlines]="false"
        [showcolumnlines]="true" [pageable]="false" [sortable]="true" [altrows]="true" [showstatusbar]="true"
        [statusbarheight]="20" [showaggregates]="true" [rowsheight]="25" [columnsheight]="25" [enabletooltips]="true"
        [groupable]="true" [editable]="false" (onRowclick)="onRowClick($event)" (onRowselect)="onRowSelect($event)"
        (onRowunselect)="onRowUnselect($event)" (onRowdoubleclick)="onRowdoubleclick($event)">
      </jqxGrid>
    </div>
    <div style="float: left; margin-top: 6px;">
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/mas.png"'
        [value]="translate('Crear')" (onClick)="onCrearConfiguracion($event)">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/editar.png"'
        [value]="translate('Editar')" (onClick)="onEditarConfiguracion($event)">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/borrar.png"'
        [value]="translate('Borrar')" (onClick)="onBorrarConfiguracion($event)">
      </jqxButton>
      <jqxButton style="float:left; margin-left: 4px;" [width]='100' [height]='26'
        [textImageRelation]='"imageBeforeText"' [textPosition]='"left"' [imgSrc]='"/assets/images/asign.png"'
        [value]="translate('Asignar_cerraduras')" (onClick)="onAsignarConfiguracion()">
      </jqxButton>
    </div>
  </div>
</jqxWindow>

<div #formConfiguracion></div>
<div #asignarConfiguracion></div>
