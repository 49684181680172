<jqxWindow #form [theme]="environment.tema" [width]="1110" [height]="528" [zIndex]="50" [position]="getFormPos(form)"
    [autoOpen]="true" [zIndex]="90" [showCloseButton]="true" [showCollapseButton]="false" [resizable]="false"
    (onClose)="onClose()">
    <div class="formHeader" style="overflow-x: hidden;">
        {{translate('Grafico_medidas')}}
    </div>
    <div class="form" style="float:left; overflow-x: hidden;">
        <jqxChart [width]="'100%'" [height]="'100%'" [title]="translate('Lecturas')" [description]="elemento.Nombre"
            [enableCrosshairs]="true" [enableAnimations]="true" [padding]="padding" [xAxis]="xAxis"
            [titlePadding]="titlePadding" [source]="dataAdapter" [animationDuration]="500" [valueAxis]="valueAxis"
            [seriesGroups]="seriesGroups" [colorScheme]="'scheme01'">
        </jqxChart>
    </div>
</jqxWindow>