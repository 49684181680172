<jqxLoader #loader [imagePosition]="'top'" [width]="100" [height]="60" [text]="translate('Procesando')">
</jqxLoader>

<jqxWindow #form [width]="220" [theme]="environment.tema" [height]="135" [zIndex]="100" [autoOpen]="true"
    [isModal]="false" [position]="getFormPos(form)" [showCloseButton]="true" [showCollapseButton]="true"
    [resizable]="false" (onClose)="onClose()">
    <div class="formHeader" style="overflow: hidden;">
        {{translate('Proveedor_carto')}}
    </div>
    <div class="form" style="float:left; overflow: hidden;">
        <jqxDropDownList #cbCarto style="float:left" [width]="208" [height]="25" [selectedIndex]="0"
            (onSelect)="onChangeCarto($event)">
        </jqxDropDownList>
        <div style="float: left; margin-top: 6px;">
            <input [(ngModel)]="raster" type="checkbox" (change)="onChangeRaster($event)" [disabled]="!canEdit" />
            Raster
        </div>
        <div style="position:absolute; bottom:5px; left:4px; margin-top: 10px; clear: both;">
            <jqxButton style="float: left" [width]='100' [height]='26' [textImageRelation]='"imageBeforeText"'
                [textPosition]='"left"' [imgSrc]='"/assets/images/save.png"' (onClick)="onGuardar()"
                [value]="translate('Guardar')" [disabled]="!canEdit">
            </jqxButton>
        </div>
    </div>
</jqxWindow>